import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { AffidavitSignatureContainer } from 'common/components/AffidavitSignature';
import { ProtectedRoute } from 'common/components/ProtectedRoute';
import ModalReminderContainer from 'common/components/legacy/Modal/ModalReminder/ModalReminderContainer';
import ModalAgreement from 'common/components/legacy/Modal/agreement/ModalAgreement';
import SplashNotif from 'common/components/legacy/SplashNotif/SplashNotifContainer';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { AccountPayableSuppliersPage } from 'modules/bookkeep/accounts-payable';
import { BudgetOverviewPageContainer } from 'modules/budgets/containers/BudgetOverviewPageContainer';
import { BudgetaryExercisesPageContainer } from 'modules/budgets/containers/BudgetaryExercisesPageContainer';
import { KybProcedureUpdatedModal } from 'modules/company';
import { BillingPage } from 'modules/company/billing';
import { Churn } from 'modules/company/churn';
import {
  CostCentersActivationPageContainer,
  CostCentersPageContainer,
} from 'modules/company/cost-centers';
import {
  GeneralSettingsPage,
  useShouldDisplayBankInformation,
} from 'modules/company/general-settings';
import { FXCalculator, WalletContainer } from 'modules/company/wallet';
import { Homepage } from 'modules/homepage';
import { Legals } from 'modules/legals';
import { MembersSettingsPage } from 'modules/members';
import PaymentsAllContainer from 'modules/payments/PaymentsAllContainer';
import {
  CardActivationContainer,
  CardsContainer,
  CardsOrderContainer,
  MyCardContainer,
  MyCardSetPinContainer,
  RecardContainer,
} from 'modules/physical-cards';
import { ProfilePage } from 'modules/profile/pages';
import { PurchaseOrdersPage } from 'modules/purchase-orders';
import { UserNotificationsPage } from 'modules/user-notifications';
import { ExpenseClaimsPageContainer } from 'pages/ExpenseClaimsPage';
import { InvoicesPageContainer } from 'pages/InvoicesPage';
import { NotFound } from 'pages/NotFound/NotFound';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import RequestsContainer from 'src/core/modules/requests/list/components/RequestsContainer';
import { CardsPage } from 'src/core/pages/CardsPage/CardsPage';
import { ExpensesPage } from 'src/core/pages/ExpensesPage/ExpensesPage';
import { InboxInvoicesPageContainer } from 'src/core/pages/InboxInvoicesPage';
import { RequestPage } from 'src/core/pages/RequestPage/RequestPage';
import { SettingsAccountingPage } from 'src/core/pages/SettingsAccountingPage/SettingsAccountingPage';
import { SettingsChartOfAccountsPage } from 'src/core/pages/SettingsChartOfAccountsPage/SettingsChartOfAccountsPage';
import { SettingsCompanyRulesPage } from 'src/core/pages/SettingsCompanyRulesPage/SettingsCompanyRulesPage';
import { SettingsIntegrationPage } from 'src/core/pages/SettingsIntegrationsPage/SettingsIntegrationPage';
import { SettingsOrganisationPage } from 'src/core/pages/SettingsOrganisationPage/SettingsOrganisationPage';
import { SettingsSpendeskPlanPage } from 'src/core/pages/SettingsSpendeskPlanPage/SettingsSpendeskPlanPage';
import { SubscriptionsPage } from 'src/core/pages/subscriptions';
import { type AppState } from 'src/core/reducers';
import {
  getIsSupervisedUserSwitchedModalVisible,
  getShowKybProcedureLimitedAccessOpenedPopup,
  getShowKybProcedureValidatedPopup,
} from 'src/core/selectors/globalSelectors';
import {
  InitialAnnouncement,
  initialAnnouncementsStackManager,
} from 'src/core/utils/initialAnnouncementsStackManager';
import { KycNeedsAction } from 'src/sfs-migration/used-in-js-app/components/KycNeedsAction/KycNeedsAction';

import { Header } from './components/Header/Header';
import { RedirectToHome } from './components/RedirectToHome';
import { SwitchedSupervisedUserModal } from './components/SwitchedSupervisedUserModal/SwitchedSupervisedUserModal';
import { TopBanners } from './components/TopBanners/TopBanners';
import WhatsAValidReceiptPopup, {
  isVisible as isWhatsAValidReceiptPopupVisible,
} from './components/ValidReceiptModal/ValidReceiptModalContainer';
import { Navigation } from './components/VerticalNavigation/Navigation';
import { getDisplayMode } from './redux/selectors';
import { Bookkeep } from '../../bookkeep/components/Bookkeep';
import { BookkeepExportDownloader } from '../../bookkeep/export/components/BookkeepExportDownloader';
import { PayablesAllPage } from '../../bookkeep/payables';
import { LegacyExportsPage } from '../../bookkeep/settings/export/pages/LegacyExportsPage';
import { LegacyIntegrationAccountingPageContainer } from '../../bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage';
import { CheckBankStatementModal } from '../../company/wallet/ach/components/CheckBankStatementModal';
import { AchSuccessModalContainer } from '../../company/wallet/ach/containers/AchSuccessModalContainer';
import { CheckBankStatementContainer } from '../../company/wallet/ach/containers/CheckBankStatementContainer';
import { type RenderPropertyParams } from '../../company/wallet/ach/containers/CheckBankStatementContainer/CheckBankStatementContainer';
import { LegacyCustomFieldsPage } from '../../custom-fields/pages/LegacyCustomFieldPage';
import { IntegrationDetailsContainer } from '../../integrations/settings/containers';
import IntegrationsPage from '../../integrations/settings/pages/IntegrationsPage';
import { ApiOAuth2AuthorizePage } from '../../integrations/settings/pages/api-oauth2-authorize/ApiOAuth2Authorize';
import { ExternalConnectRedirectPage } from '../../integrations/settings/pages/external-connect-redirect/externalConnectRedirect';
import { MemberScreeningModal } from '../../members/components/MemberScreeningModal/MemberScreeningModal';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any;
  layoutDisplayMode: 'webview' | 'browser';
  showKybProcedureValidatedPopup: boolean;
  showKybProcedureLimitedAccessOpenedPopup: boolean;
  isSupervisedUserSwitchedModalVisible: boolean;
};

const Container = ({
  config,
  layoutDisplayMode,
  showKybProcedureValidatedPopup,
  showKybProcedureLimitedAccessOpenedPopup,
  isSupervisedUserSwitchedModalVisible,
}: Props) => {
  const company = useCompany();
  const user = useUser();

  const shouldDisplayBankInformation = useShouldDisplayBankInformation();
  const hasPlayByTheRulesFeature = useFeature(FEATURES.PLAY_BY_RULES);
  const hasBudgetsFeature = useFeature(FEATURES.BUDGETS);
  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS);
  const hasCostCentersActivated = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const hasPayableFeature = useFeature(FEATURES.BOOKKEEP_PAYABLES);
  const hasSettingsAccountingFeature = useFeature(FEATURES.SETTINGS_ACCOUNTING);

  const hasControlOnSpendingMethods = useFeature(
    FEATURES.CONTROL_ON_SPENDING_METHODS,
  );
  const hasWireTransferForExpenseClaims = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_EXPENSE_CLAIMS,
  );
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  const isAoOrController = user.is_account_owner || user.is_controller;

  const renderModalCheckBankStatement = () => (
    <CheckBankStatementContainer
      render={({
        isWalletPage,
        shouldShowModal,
        hasPendingFundingSource,
        hasSuccessfullyCreatedFundingSource,
        clearHasSuccessfullyCreatedFundingSource,
      }: RenderPropertyParams) => {
        // if wallet page, no need to show a modal through LayoutContainer
        if (
          isWalletPage ||
          !shouldShowModal ||
          !user.is_account_owner ||
          !user.is_controller
        ) {
          return null;
        }

        if (hasPendingFundingSource) {
          return <CheckBankStatementModal />;
        }

        if (hasSuccessfullyCreatedFundingSource) {
          return (
            <AchSuccessModalContainer
              onContinue={clearHasSuccessfullyCreatedFundingSource}
            />
          );
        }

        return null;
      }}
    />
  );

  const renderSplashNotif = () => {
    const shouldDisplaySplayNotif =
      initialAnnouncementsStackManager.shouldDisplayAnnouncement(
        InitialAnnouncement.SplashNotif,
      );
    return (
      shouldDisplaySplayNotif && <SplashNotif company={company} user={user} />
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderContent = () => {
    return (
      <Switch>
        <Route path={routes.HOMEPAGE.path} component={Homepage} />

        <Route
          path={routes.INBOX_INVOICES.path}
          component={InboxInvoicesPageContainer}
        />

        {hasNewNavigation ? (
          <Route path={[routes.REQUESTS.path]} component={RequestPage} />
        ) : (
          <Route path={routes.REQUESTS.path} component={RequestsContainer} />
        )}

        {hasNewNavigation ? (
          <Route
            path={[routes.PAYMENTS_ALL.path, routes.PAYABLES_ALL.path]}
            component={ExpensesPage}
          />
        ) : (
          <Route
            path={routes.PAYMENTS_ALL.path}
            component={PaymentsAllContainer}
          />
        )}

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.PAYABLES_ALL.path}
            isAccessAllowed={
              hasPayableFeature && isAoOrController && !company.churning_at
            }
            redirectTo={{
              pathname: routeFor(routes.PAYMENTS_ALL.path, {
                company: company.id,
              }),
            }}
          >
            <PayablesAllPage />
          </ProtectedRoute>
        )}
        <ProtectedRoute
          path={routes.EXPENSE_INBOX.path}
          isAccessAllowed={hasPayableFeature}
          redirectTo={{
            pathname: routeFor(routes.PAYMENTS_ALL.path, {
              company: company.id,
            }),
          }}
        >
          <Bookkeep />
        </ProtectedRoute>

        <Route
          path={routes.PURCHASE_ORDERS.path}
          component={PurchaseOrdersPage}
        />

        <Route path={routes.CARDS_ORDER.path} component={CardsOrderContainer} />
        {hasNewNavigation ? (
          <Route
            path={[
              routes.CARDS.path,
              routes.SUBSCRIPTIONS.path,
              routes.CARD.path,
            ]}
            component={CardsPage}
          />
        ) : (
          <Route path={routes.CARDS.path} component={CardsContainer} />
        )}
        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.SUBSCRIPTIONS.path}
            isAccessAllowed={
              !company.churning_at && company.type !== 'branch_expense_entity'
            }
          >
            <SubscriptionsPage />
          </ProtectedRoute>
        )}
        <Route path={routes.CARD_PIN.path} component={MyCardSetPinContainer} />
        <Route
          path={routes.CARD_ACTIVATION.path}
          component={CardActivationContainer}
        />
        {!hasNewNavigation && (
          <Route path={routes.CARD.path} component={MyCardContainer} />
        )}
        <Route path={routes.RECARD.path} component={RecardContainer} />

        <Route path={routes.INVOICES.path} component={InvoicesPageContainer} />
        <Route
          path={routes.CREDIT_NOTES_REVIEW.path}
          component={InvoicesPageContainer}
        />
        <Route
          path={routes.EXPENSE_CLAIMS.path}
          component={ExpenseClaimsPageContainer}
        />

        {hasNewNavigation ? (
          <Route
            path={[routes.ACCOUNT_ME.path, routes.ACCOUNT_NOTIFICATIONS.path]}
            component={ProfilePage}
          />
        ) : (
          <Route path={routes.ACCOUNT_ME.path} component={ProfilePage} />
        )}

        {!hasNewNavigation && (
          <Route
            path={routes.ACCOUNT_NOTIFICATIONS.path}
            component={UserNotificationsPage}
          />
        )}

        {hasNewNavigation && (
          <Route
            exact
            path={[
              routes.COMPANY_ACCOUNTING_BOOKKEEPING_DATE.path,
              routes.COMPANY_ACCOUNTING.path,
              routes.COMPANY_ACCOUNTING_INTEGRATION.path,
              routes.COMPANY_EXPORTS.path,
              routes.COMPANY_CATEGORIES.path,
            ]}
          >
            <SettingsAccountingPage />
          </Route>
        )}

        {hasNewNavigation && (
          <Route
            exact
            path={[
              routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path,
            ]}
          >
            <SettingsChartOfAccountsPage />
          </Route>
        )}

        <Route path={routes.LEGALS.path} component={Legals} />

        {hasNewNavigation ? (
          <Route
            path={[
              routes.COMPANY_BILLING_PLAN.path,
              routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
              routes.COMPANY_BILLING_INFORMATION.path,
            ]}
          >
            <SettingsSpendeskPlanPage />
          </Route>
        ) : (
          <ProtectedRoute
            path={routes.COMPANY_BILLING_PLAN.path}
            isAccessAllowed={
              company.churning_at
                ? user.is_account_owner || user.is_controller
                : user.is_account_owner || user.is_admin || user.is_controller
            }
          >
            <BillingPage />
          </ProtectedRoute>
        )}

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_BILLING_INFORMATION.path}
            isAccessAllowed={
              company.churning_at
                ? user.is_account_owner || user.is_controller
                : user.is_account_owner || user.is_admin || user.is_controller
            }
          >
            <BillingPage />
          </ProtectedRoute>
        )}

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path}
            isAccessAllowed={
              shouldDisplayBankInformation &&
              (user.is_account_owner || user.is_admin)
            }
          >
            <GeneralSettingsPage />
          </ProtectedRoute>
        )}

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path}
            isAccessAllowed={user.is_account_owner || user.is_admin}
          >
            <GeneralSettingsPage />
          </ProtectedRoute>
        )}

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path}
            isAccessAllowed={
              hasControlOnSpendingMethods &&
              (user.is_account_owner || user.is_admin)
            }
          >
            <GeneralSettingsPage />
          </ProtectedRoute>
        )}
        <ProtectedRoute
          path={routes.COMPANY_GENERAL_SETTINGS_WIRE_TRANSFER.path}
          isAccessAllowed={
            hasWireTransferForExpenseClaims && user.is_account_owner
          }
        >
          <GeneralSettingsPage />
        </ProtectedRoute>

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path}
            isAccessAllowed={user.is_account_owner || user.is_admin}
          >
            <GeneralSettingsPage />
          </ProtectedRoute>
        )}
        <ProtectedRoute
          path={routes.CHURN.path}
          isAccessAllowed={
            user.is_account_owner && company.churning_at !== null
          }
        >
          <Churn />
        </ProtectedRoute>

        {hasNewNavigation ? (
          <Route
            path={[
              routes.COMPANY_MEMBERS.path,
              routes.COMPANY_TEAMS.path,
              routes.COST_CENTERS.path,
            ]}
            component={SettingsOrganisationPage}
          />
        ) : (
          <Route
            path={routes.COMPANY_MEMBERS.path}
            component={MembersSettingsPage}
          />
        )}

        {hasNewNavigation ? (
          <Route
            path={[
              routes.COMPANY_POLICIES.path,
              routes.COMPANY_CONTROL_RULES.path,
              routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path,
              routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path,
              routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path,
            ]}
            component={SettingsCompanyRulesPage}
          />
        ) : (
          <Route
            path={routes.COMPANY_POLICIES.path}
            component={MembersSettingsPage}
          />
        )}

        {!hasNewNavigation && (
          <Route
            path={routes.COMPANY_CONTROL_RULES.path}
            component={MembersSettingsPage}
          />
        )}

        {!hasNewNavigation && (
          <Route
            path={routes.COMPANY_TEAMS.path}
            component={MembersSettingsPage}
          />
        )}
        <ProtectedRoute
          path={routes.COMPANY_BANK.path}
          isAccessAllowed={hasAccessToWallet && isAoOrController}
        >
          <WalletContainer />
        </ProtectedRoute>

        {/* @TODO (Grapes): Put behind the new navigation FT when charts of accounts and accounting sections are done */}
        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_ACCOUNTING.path}
            isAccessAllowed={
              hasSettingsAccountingFeature &&
              (user.is_account_owner || user.is_controller)
            }
          >
            <LegacyIntegrationAccountingPageContainer />
          </ProtectedRoute>
        )}

        {hasNewNavigation ? (
          <Route
            path={routes.COMPANY_ACCOUNTS_PAYABLE.path}
            component={SettingsChartOfAccountsPage}
          />
        ) : (
          <Route
            path={routes.COMPANY_ACCOUNTS_PAYABLE.path}
            component={AccountPayableSuppliersPage}
          />
        )}

        <ProtectedRoute
          path={routes.COMPANY_INTEGRATION_EXTERNAL_CONNECT.path}
          isAccessAllowed={
            !company.churning_at &&
            (user.is_account_owner || user.is_controller)
          }
        >
          <ExternalConnectRedirectPage />
        </ProtectedRoute>

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_INTEGRATION.path}
            isAccessAllowed={
              !company.churning_at &&
              (user.is_account_owner || user.is_controller)
            }
          >
            <IntegrationDetailsContainer key="integration-details" />
          </ProtectedRoute>
        )}

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COMPANY_INTEGRATIONS.path}
            isAccessAllowed={
              !company.churning_at && (user.is_account_owner || user.is_admin)
            }
          >
            <IntegrationsPage key="integrations-list" />
          </ProtectedRoute>
        )}

        {hasNewNavigation && (
          <Route
            path={[
              routes.COMPANY_INTEGRATIONS.path,
              routes.COMPANY_INTEGRATION.path,
            ]}
            component={SettingsIntegrationPage}
          />
        )}
        <ProtectedRoute
          path={routes.SPENDESK_OAUTH2.path}
          isAccessAllowed={!company.churning_at}
        >
          <ApiOAuth2AuthorizePage />
        </ProtectedRoute>

        {!hasNewNavigation && (
          <Route
            path={routes.COMPANY_EXPORTS.path}
            component={LegacyExportsPage}
          />
        )}
        {!hasNewNavigation && (
          <Route
            path={routes.COMPANY_CATEGORIES.path}
            component={LegacyCustomFieldsPage}
          />
        )}
        <ProtectedRoute
          path={routes.BUDGETARY_EXERCISES.path}
          isAccessAllowed={hasBudgetsFeature}
        >
          <BudgetaryExercisesPageContainer />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.BUDGET_OVERVIEW.path}
          isAccessAllowed={hasBudgetsFeature}
        >
          <BudgetOverviewPageContainer />
        </ProtectedRoute>

        {!hasNewNavigation && (
          <ProtectedRoute
            path={routes.COST_CENTERS.path}
            isAccessAllowed={hasCostCentersFeature}
          >
            {hasCostCentersActivated ? (
              <CostCentersPageContainer />
            ) : (
              <CostCentersActivationPageContainer />
            )}
          </ProtectedRoute>
        )}

        <Route
          path={routes.EXPORT_DOWNLOAD.path}
          component={BookkeepExportDownloader}
        />

        <Route path={routes.FX_CALCULATOR.path} component={FXCalculator} />
        <Route
          path={routes.AFFIDAVIT_SIGNATURE.path}
          component={AffidavitSignatureContainer}
        />
        <Route exact path={routes.APP.path} component={RedirectToHome} />
        <Route path={[routes.NOT_FOUND.path, '*']} component={NotFound} />
      </Switch>
    );
  };

  if (layoutDisplayMode === 'webview') {
    return renderContent();
  }

  const showKybProcedureModal =
    showKybProcedureValidatedPopup || showKybProcedureLimitedAccessOpenedPopup;

  return (
    <>
      {showKybProcedureModal &&
        !isWhatsAValidReceiptPopupVisible(company, user) && (
          <KybProcedureUpdatedModal />
        )}
      <MemberScreeningModal />
      {isSupervisedUserSwitchedModalVisible && <SwitchedSupervisedUserModal />}
      {renderModalCheckBankStatement()}
      <TopBanners />
      <KycNeedsAction />
      <ModalAgreement
        supervisor={config?.supervisor ?? null}
        user={user}
        company={company}
      />
      {!isWhatsAValidReceiptPopupVisible(company, user) &&
        !hasPlayByTheRulesFeature && <ModalReminderContainer />}

      <WhatsAValidReceiptPopup user={user} company={company} />
      {hasNewNavigation ? <Navigation /> : <Header />}
      {renderSplashNotif()}
      {renderContent()}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    config: state.global.config,
    layoutDisplayMode: getDisplayMode(state),
    isSupervisedUserSwitchedModalVisible:
      getIsSupervisedUserSwitchedModalVisible(state),
    showKybProcedureValidatedPopup: getShowKybProcedureValidatedPopup(state),
    showKybProcedureLimitedAccessOpenedPopup:
      getShowKybProcedureLimitedAccessOpenedPopup(state),
  };
};

export const PaidLayoutContainer = connect(mapStateToProps)(Container);
