import { type MonetaryValue } from 'ezmoney';

import { type PerDiem } from 'modules/per-diem';

import { type VatAccount } from '../../../prepare-payables/components/PreparePayablesInbox/components/PreparePayablesTaxAccountField';
import {
  type Invitee,
  type SuspendedUser,
  type User,
  type Supplier,
  isUser,
  isSupplier,
  isInvitee,
  type MarvinConfidence,
  isSuspendedUser,
  type Payable,
  type PayableType,
  type PayableSubType,
  type AnalyticalFieldAssociation,
  type AnalyticalFieldAssociationAutomation,
} from '../../../prepare-payables/models';
import { type MileageAllowanceDetails } from '../../../types';

interface RawPayableBooleanCustomField {
  value: boolean;
  customField: {
    id: string;
    name: string;
    optional: boolean;
    __typename: 'CustomFieldBoolean';
  };
}

interface RawPayableListCustomField {
  values: {
    id: string;
    value: string;
  }[];
  customField: {
    id: string;
    name: string;
    optional: boolean;
    __typename: 'CustomFieldList';
  };
}

type RawPayableCustomField =
  | RawPayableBooleanCustomField
  | RawPayableListCustomField;

/**
 * Represents an Account from the API
 */
type RawTaxAccount = {
  id: string;
  name: string;
  rate?: string;
};

type RawExpenseAccount = {
  id: string;
  name: string;
};

/**
 * Represents a reshaped Account
 */
type TaxAccount = {
  id: string;
  name: string;
  rate?: number;
};

type ExpenseAccount = {
  id: string;
  name: string;
};

/**
 * Represents the object manipulated in the interface to assign an Account to an amount
 */
export type TaxItem = {
  amount: MonetaryValue | null;
  vatAccount: TaxAccount | null;
};

type RawPayableItemLine = {
  grossAmount: MonetaryValue;
  netAmount: MonetaryValue;
  natureId: string;
  taxAmount: MonetaryValue;
  taxAccount: RawTaxAccount | null;
  expenseAccount: RawExpenseAccount | null;
  analyticalFieldAssociations: AnalyticalFieldAssociation[];
};

type RawCompliances = {
  spainEreceipt?: {
    receipt?: {
      uniqueId: string;
      path: string;
    };
  } | null;
};

type RawAutomation = {
  expenseAmount: {
    kind: 'recommendation';
    confidence: MarvinConfidence;
    isAppliedOnPayable: boolean;
  } | null;
  expenseAccount:
    | {
        kind: 'default';
        isAppliedOnPayable: boolean | null;
      }
    | {
        kind: 'supplierRule';
        supplierRule: {
          supplier: {
            id: string;
            name: string;
          };
          expenseAccount: {
            id: string;
            name: string;
          };
        };
        isAppliedOnPayable: boolean | null;
      }
    | {
        kind: 'prediction';
        expenseAccount: {
          id: string;
          name: string;
        };
        confidence: number | null;
        isAppliedOnPayable: boolean | null;
      }
    | {
        kind: 'expenseCategoryRule';
        isAppliedOnPayable: boolean | null;
      }
    | null;
  accountPayable: {
    accountPayableMatch: {
      id: string;
      generalAccountCode: string;
      isDefault: boolean;
      isArchived: boolean;
    };
    isAppliedOnPayable: boolean | null;
  } | null;
  tax: {
    kind: 'default' | 'recommendation' | 'prediction' | null;
    confidence: MarvinConfidence | null;
    taxAccount: VatAccount | null;
    taxAmount: MonetaryValue | null;
    isAppliedOnPayable: boolean | null;
  } | null;
  analyticalFieldAssociations: AnalyticalFieldAssociationAutomation[] | null;
};

export type RawPayable = {
  // Fields can come from usePayablesQuery cache
  id: string;
  grossAmount: MonetaryValue;
  netAmount: MonetaryValue;
  functionalAmount: MonetaryValue;
  creationDate: string;
  accountingDate: string | null;
  member: User | SuspendedUser | Invitee;
  counterparty: User | SuspendedUser | Invitee | Supplier;
  supplier: {
    id: string;
    name: string;
    thumbnailUrl: string;
  } | null;
  state: string;
  type: PayableType;
  subtype: PayableSubType | null;
  description: string;
  documentaryEvidence: {
    type:
      | 'creditNote'
      | 'invoice'
      | 'declarationOfMissingInvoice'
      | 'declarationOfMissingCreditNote'
      | 'mileageEntry'
      | 'perDiemEntry';
    invoiceNumber: string | null;
    creditNoteNumber: string | null;
    compliances?: RawCompliances;
  } | null;

  // Fields that won't be in usePayablesQuery cache
  version: number;
  accountPayable: {
    id: string;
    generalAccountCode: string;
    auxiliaryAccountCode?: string | undefined;
  } | null;
  functionalExchangeRate: { amount: number; precision: number };
  fxFees: MonetaryValue | null;
  itemLines: RawPayableItemLine[] | undefined;
  allocations: { amount: MonetaryValue }[] | undefined;
  automation: RawAutomation;
  team: {
    id: string;
    name: string;
  } | null;
  costCenter: {
    id: string;
    name: string;
  } | null;
  settlementDate: string | null;
  vatConfidence: MarvinConfidence | null;
  customFields: RawPayableCustomField[] | undefined;
  mileageDetails: MileageAllowanceDetails | null;
  perDiem: PerDiem | null;
  invoiceNumber: string | null;
  invoiceDate: string | null;
  __typename:
    | 'SupplierExpensePayable'
    | 'ReversalPayable'
    | 'MileageAllowanceExpensePayable'
    | 'PerDiemAllowanceExpensePayable'
    | 'CreditNoteReversalPayable'
    | 'ClaimedBillExpensePayable';
};

/**
 * Reshape an account from the API for consumption on the front-end.
 */
const reshapeTaxAccount = (taxAccount: RawTaxAccount): TaxAccount => ({
  id: taxAccount.id,
  name: taxAccount.name,
  rate: taxAccount.rate ? Number(taxAccount.rate) : -1,
});

const reshapeExpenseAccount = (
  expenseAccount: RawExpenseAccount,
): ExpenseAccount => ({
  id: expenseAccount.id,
  name: expenseAccount.name,
});

function isBooleanCustomField(
  customField: RawPayableBooleanCustomField | RawPayableListCustomField,
): customField is RawPayableBooleanCustomField {
  return (customField as RawPayableBooleanCustomField).value !== undefined;
}

function isListCustomField(
  customField: RawPayableBooleanCustomField | RawPayableListCustomField,
): customField is RawPayableListCustomField {
  return (customField as RawPayableListCustomField).values !== undefined;
}

export const reshapeCustomFields = (
  customFields: RawPayableCustomField[],
  analyticalFieldAssociationAutomation: AnalyticalFieldAssociationAutomation[],
): {
  [key: string]: string | boolean;
} => {
  const reshapedCustomFields: {
    [key: string]: string | boolean;
  } = {};

  customFields?.forEach((customFieldAssociation) => {
    if (isBooleanCustomField(customFieldAssociation)) {
      const { id } = customFieldAssociation.customField;
      const { value } = customFieldAssociation;

      // TODO @finance-accountant: remove this hack
      // We remove automated boolean custom fields as we cannot handle them yet
      // They are only removed from custom fields as they are not splittable
      const isAutomated = analyticalFieldAssociationAutomation?.some(
        (association) =>
          association.fieldEntityId === id && association.isAppliedOnPayable,
      );
      if (!isAutomated) {
        reshapedCustomFields[id] = value;
      }
    } else if (isListCustomField(customFieldAssociation)) {
      const { id } = customFieldAssociation.customField;
      const { values } = customFieldAssociation;
      const { id: value } = values[0];

      reshapedCustomFields[id] = value;
    }
  });

  return reshapedCustomFields;
};

const reshapeAutomation = (
  rawAutomation: RawAutomation | undefined | null,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Payable['automation'] | undefined => {
  if (rawAutomation === null || rawAutomation === undefined) {
    return undefined;
  }

  const automation: Payable['automation'] = {
    analyticalFieldAssociations:
      rawAutomation.analyticalFieldAssociations ?? [],
  };

  if (rawAutomation.tax) {
    if (rawAutomation.tax.kind === 'default') {
      automation.tax = {
        kind: 'default',
        isAppliedOnPayable: !!rawAutomation.tax.isAppliedOnPayable,
      };
    } else if (rawAutomation.tax.kind === 'recommendation') {
      automation.tax = {
        kind: 'recommendation',
        confidence: rawAutomation.tax.confidence ?? undefined,
        isAppliedOnPayable: !!rawAutomation.tax.isAppliedOnPayable,
      };
    } else {
      automation.tax = {
        kind: 'prediction',
        taxAccount: rawAutomation.tax.taxAccount ?? {
          id: '',
          name: '',
        },
        confidence: rawAutomation.tax.confidence ?? undefined,
        isAppliedOnPayable: !!rawAutomation.tax.isAppliedOnPayable,
      };
    }
  }

  if (rawAutomation.expenseAccount) {
    if (
      rawAutomation.expenseAccount.kind === 'expenseCategoryRule' ||
      rawAutomation.expenseAccount.kind === 'default'
    ) {
      automation.expenseAccount = {
        ...rawAutomation.expenseAccount,
        isAppliedOnPayable: !!rawAutomation.expenseAccount.isAppliedOnPayable,
      };
    } else if (
      rawAutomation.expenseAccount.kind === 'supplierRule' &&
      rawAutomation.expenseAccount.supplierRule
    ) {
      automation.expenseAccount = {
        ...rawAutomation.expenseAccount,
        isAppliedOnPayable: !!rawAutomation.expenseAccount.isAppliedOnPayable,
      };
    } else if (rawAutomation.expenseAccount.kind === 'prediction') {
      automation.expenseAccount = {
        ...rawAutomation.expenseAccount,
        expenseAccount: rawAutomation.expenseAccount.expenseAccount ?? {
          id: '',
          name: '',
        },
        confidence: rawAutomation.expenseAccount.confidence ?? 0,
        isAppliedOnPayable: !!rawAutomation.expenseAccount.isAppliedOnPayable,
      };
    }
  }

  if (
    rawAutomation.expenseAmount &&
    rawAutomation.expenseAmount.kind === 'recommendation'
  ) {
    automation.expenseAmount = {
      kind: 'recommendation',
      confidence: rawAutomation.expenseAmount.confidence ?? undefined,
      isAppliedOnPayable: !!rawAutomation.expenseAmount.isAppliedOnPayable,
    };
  }

  if (rawAutomation.accountPayable) {
    automation.accountPayable = {
      ...rawAutomation.accountPayable,
      isAppliedOnPayable: !!rawAutomation.accountPayable.isAppliedOnPayable,
    };
  }

  return automation;
};

const reshapeMember = (rawMember: User | SuspendedUser | Invitee) => {
  if (isUser(rawMember)) {
    return {
      id: rawMember.id,
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      email: rawMember.email,
      avatar: rawMember.avatar,
    };
  }
  if (isSuspendedUser(rawMember)) {
    return {
      id: rawMember.id,
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      avatar: undefined,
    };
  }
  return {
    id: rawMember.id,
    email: rawMember.email,
  };
};

function reshapeMemberToUser(
  rawMember: User | SuspendedUser | Invitee,
): Payable['user'] {
  if (isUser(rawMember)) {
    return {
      id: rawMember.id,
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      email: rawMember.email ?? '',
      avatar: rawMember.avatar ?? '',
    };
  }
  if (isSuspendedUser(rawMember)) {
    return {
      id: rawMember.id,
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      email: '',
      avatar: '',
    };
  }
  return {
    id: rawMember.id,
    email: rawMember.email,
    avatar: '',
    givenName: '',
    familyName: '',
  };
}

function reshapeCounterparty(rawPayable: RawPayable): Payable['counterparty'] {
  if (isSupplier(rawPayable.counterparty)) {
    return rawPayable.counterparty;
  }

  return reshapeMember(rawPayable.counterparty);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const reshapePayable = (rawPayable: RawPayable): Payable | undefined => {
  if (!rawPayable) {
    return undefined;
  }
  const itemLines: Payable['itemLines'] =
    rawPayable.itemLines?.map((itemLine, index) => ({
      id: index,
      grossAmount: itemLine.grossAmount,
      vat: {
        amount: itemLine.taxAmount,
        vatAccount: itemLine?.taxAccount
          ? reshapeTaxAccount(itemLine.taxAccount)
          : null,
      },
      expense: {
        amount: itemLine.netAmount,
        expenseAccount: itemLine.expenseAccount
          ? reshapeExpenseAccount(itemLine.expenseAccount)
          : null,
      },
      analyticalFieldAssociations: itemLine.analyticalFieldAssociations,
    })) ?? [];

  let payable: Payable = {
    id: rawPayable.id,
    version: rawPayable.version,
    description: rawPayable.description,
    accountPayableId: rawPayable.accountPayable?.id,
    amount: rawPayable.functionalAmount,
    exchangeRate: rawPayable.functionalExchangeRate,
    originalAmount: rawPayable.grossAmount,
    createdAt: rawPayable.creationDate,
    creationDate: rawPayable.creationDate,
    accountingDate: rawPayable.accountingDate ?? null,
    supplier: isSupplier(rawPayable.counterparty)
      ? {
          ...rawPayable.counterparty,
          kind: 'supplier',
        }
      : rawPayable.supplier,
    team: rawPayable.team ?? undefined,
    user: reshapeMemberToUser(rawPayable.member),
    costCenter: rawPayable.costCenter ?? undefined,
    itemLines,
    automation: reshapeAutomation(rawPayable.automation),
    vatConfidence:
      rawPayable.automation?.tax &&
      rawPayable.automation?.tax?.isAppliedOnPayable
        ? rawPayable.automation.tax.confidence
        : null,
    customFields: rawPayable.customFields
      ? reshapeCustomFields(
          rawPayable.customFields,
          rawPayable.automation?.analyticalFieldAssociations ?? [],
        )
      : {},
    prepared:
      rawPayable.state !== 'unprepared' && rawPayable.state !== 'created',
    type: rawPayable.type,
    subType: rawPayable.subtype ?? undefined,
    mileageDetails:
      rawPayable.type === 'mileage_allowance'
        ? rawPayable.mileageDetails ?? undefined
        : undefined,
    perDiem:
      rawPayable.type === 'per_diem_allowance'
        ? rawPayable.perDiem ?? undefined
        : undefined,
    invoiceDate: 'payable.invoiceDate', // TODO: Use a real value
    receiptNumber: 'payable.receiptNumber', // TODO: Use a real value
    invoiceNumber: rawPayable.invoiceNumber ?? undefined,
    counterparty: reshapeCounterparty(rawPayable),
    documentaryEvidence: reshapePayableDocumentaryEvidence(rawPayable),
    employeeAccount: undefined,
    supplierAccount: undefined,
  };

  if (isSupplier(rawPayable.counterparty)) {
    payable = {
      ...payable,
      supplierAccount: rawPayable.accountPayable ?? undefined,
    };
  } else if (
    isUser(rawPayable.counterparty) ||
    isInvitee(rawPayable.counterparty)
  ) {
    payable = {
      ...payable,
      employeeAccount: rawPayable.accountPayable,
    };
  }

  return payable;
};

function reshapePayableDocumentaryEvidence(
  rawPayable: RawPayable,
): Payable['documentaryEvidence'] {
  if (rawPayable.documentaryEvidence?.type === 'invoice') {
    return {
      type: 'invoice',
      invoiceNumber: rawPayable.documentaryEvidence.invoiceNumber ?? undefined,
      compliances: reshapeDocumentaryEvidenceCompliances(
        rawPayable.documentaryEvidence,
      ),
    };
  }

  if (rawPayable.documentaryEvidence?.type === 'creditNote') {
    return {
      type: 'creditNote',
      creditNoteNumber:
        rawPayable.documentaryEvidence.creditNoteNumber ?? undefined,
      compliances: reshapeDocumentaryEvidenceCompliances(
        rawPayable.documentaryEvidence,
      ),
    };
  }

  if (rawPayable.documentaryEvidence?.type) {
    return {
      type: rawPayable.documentaryEvidence.type,
      compliances: reshapeDocumentaryEvidenceCompliances(
        rawPayable.documentaryEvidence,
      ),
    };
  }

  return undefined;
}

function reshapeDocumentaryEvidenceCompliances(
  rawDocumentaryEvidence: RawPayable['documentaryEvidence'],
): RawCompliances {
  const compliances: RawCompliances = {};

  if (rawDocumentaryEvidence?.compliances?.spainEreceipt) {
    compliances.spainEreceipt =
      rawDocumentaryEvidence?.compliances?.spainEreceipt;
  }

  return compliances;
}
