import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';

export const useUserScaEnrolledLazyQuery = (): LazyState<{
  isScaUserEnrolled: boolean;
}> => {
  return useLazyQuery<{
    isScaUserEnrolled: boolean;
  }>({
    key: ['sca', 'users', 'enrol'],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: '/sca/users/enrol',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reshapeData: (data: any) => {
      return data;
    },
  });
};
