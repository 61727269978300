import { type MonetaryValue } from 'ezmoney';

import { type PerDiem } from 'modules/per-diem';

import { type User } from './counterparty';
import { type AnalyticalFieldAssociation } from './itemLine';
import { type PayableSubType, type PayableType } from './payableType';
import { type MileageAllowanceDetails } from '../../types';

export type MarvinConfidence = 'low' | 'medium' | 'high' | 'sure';

export type ExpenseAccountAutomation =
  | {
      kind: 'default';
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'supplierRule';
      supplierRule: {
        supplier: {
          id: string;
          name: string;
        };
        expenseAccount: {
          id: string;
          name: string;
        };
      };
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'prediction';
      expenseAccount: {
        id: string;
        name: string;
      };
      confidence: number;
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'expenseCategoryRule';
      isAppliedOnPayable: boolean;
    };

export type ExpenseAmountAutomation = {
  kind: 'recommendation';
  confidence?: MarvinConfidence;
  isAppliedOnPayable: boolean;
};

export type TaxAccountAutomation =
  | {
      kind: 'default';
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'recommendation';
      confidence?: MarvinConfidence;
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'prediction';
      taxAccount: {
        id: string;
        name: string;
      };
      confidence?: MarvinConfidence;
      isAppliedOnPayable: boolean;
    };

export type AnalyticalFieldAssociationAutomation =
  AnalyticalFieldAssociation & {
    kind: string;
    isAppliedOnPayable: boolean;
  };

export type Payable = {
  id: string;
  version: number;
  accountingDate: string | null;
  invoiceDate?: string;
  creationDate: string;
  receiptNumber?: string;
  amount: MonetaryValue;
  originalAmount: MonetaryValue;
  exchangeRate: { amount: number; precision: number };
  description: string;
  supplier?: {
    kind?: string;
    id?: string;
    name: string;
    thumbnailUrl?: string | null;
    isArchived?: boolean | null;
  } | null;
  supplierAccount?: {
    id?: string;
    generalAccountCode?: string;
    auxiliaryAccountCode?: string | undefined;
  };
  employeeAccount?: {
    id?: string;
    generalAccountCode?: string;
    auxiliaryAccountCode?: string | undefined;
  } | null;
  team?: {
    id: string;
    name: string;
  };
  accountPayableId: string | undefined;
  vatConfidence: MarvinConfidence | null;
  itemLines: {
    id: number;
    grossAmount: MonetaryValue;
    vat?: {
      vatAccount: {
        id: string;
        name: string;
        code?: string;
        rate?: number;
      } | null;
      amount: MonetaryValue | null;
    } | null;
    expense: {
      expenseAccount: {
        id: string;
        name: string;
        code?: string;
      } | null;
      amount: MonetaryValue;
    };
    analyticalFieldAssociations: AnalyticalFieldAssociation[];
  }[];
  type: PayableType;
  subType: PayableSubType | undefined;
  costCenter:
    | {
        id?: string;
        name?: string;
      }
    | undefined;
  customFields: { [key: string]: string | boolean };
  mileageDetails?: MileageAllowanceDetails;
  perDiem?: PerDiem;
  automation?: {
    expenseAmount?: ExpenseAmountAutomation;
    expenseAccount?: ExpenseAccountAutomation;
    accountPayable?: {
      accountPayableMatch: {
        id: string;
        generalAccountCode: string;
        isDefault: boolean;
        isArchived: boolean;
      };
      isAppliedOnPayable: boolean | null;
    };
    tax?: TaxAccountAutomation;
    analyticalFieldAssociations?: AnalyticalFieldAssociationAutomation[];
  };
  createdAt: string;
  user: User;
  counterparty:
    | {
        id: string;
        name: string;
        thumbnailUrl: string;
      }
    | {
        id: string;
        givenName: string;
        familyName: string;
        email: string;
        avatar: string;
      }
    | {
        id: string;
        givenName: string;
        familyName: string;
        avatar: undefined;
      }
    | {
        id: string;
        email: string;
      };
  prepared: boolean;
  invoiceNumber?: string;
  documentaryEvidence?: {
    compliances?: Compliances;
  } & (
    | {
        type: 'invoice';
        invoiceNumber: string | undefined;
      }
    | {
        type: 'creditNote';
        creditNoteNumber: string | undefined;
      }
    | {
        type:
          | 'declarationOfMissingInvoice'
          | 'declarationOfMissingCreditNote'
          | 'mileageEntry'
          | 'perDiemEntry';
      }
  );
};

export type SpainEreceiptCompliance = {
  receipt?: {
    path: string;
    uniqueId: string;
  };
  closure?: {
    closedAt: string;
    closingPeriod: string;
  };
};

export type Compliances = {
  spainEreceipt?: SpainEreceiptCompliance | null;
};

export const payableVersionWhenPartiallyLoaded = 0;

export const isMileageAllowance = (payable: Payable): boolean =>
  Boolean(payable.mileageDetails && payable.mileageDetails.vehicle);
