import {
  getReloadType,
  HARD_RELOAD,
  SOFT_RELOAD,
} from "src/core/utils/wallet";

export const getHasWalletWarning = (
  company: {
    suggest_to_reload_amount: number;
    balance_available_all_accounts: number;
    balance_loaded_reloadable_cards: number;
  },
  walletDetails: { monthlyBudget: number },
): boolean => {
  const reloadType = getReloadType(company, walletDetails.monthlyBudget);
  return reloadType === HARD_RELOAD || reloadType === SOFT_RELOAD;
};
