import { Icon, colors } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ButtonsStacked.scss';

class ButtonsStacked extends PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
      }),
    ),
  };

  static defaultProps = {
    buttons: [],
  };

  render() {
    const { buttons, isDisabled } = this.props;

    return (
      <ul
        className={classNames('ButtonsStacked', {
          isDisabled,
        })}
      >
        {map(buttons, (button, index) => (
          // FIXME: Avoid click handler on li; use button instead
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={index}
            className="ButtonsStacked-item"
            onClick={(event) => {
              if (isDisabled) {
                event.preventDefault();
                return;
              }
              button.action();
            }}
          >
            <span className="ButtonsStacked-item__text">{button.text}</span>
            <Icon
              name="caret-right"
              color={isDisabled ? colors.neutral : colors.neutralDark}
            />
          </li>
        ))}
      </ul>
    );
  }
}

export default ButtonsStacked;
