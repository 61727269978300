import { SwitchInput } from '@dev-spendesk/grapes';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { withTranslation } from 'react-i18next';

import CheckboxRedesigned from 'src/core/common/components/legacy/CheckboxRedesigned/CheckboxRedesigned';
import { apiUrl } from 'src/core/utils/api';

class TeamsSelection extends React.Component {
  static propTypes = {
    cfId: PropTypes.string,
    groups: PropTypes.array,
    isAllScopes: PropTypes.bool,
    pushNotif: PropTypes.func.isRequired,
    companyId: PropTypes.string,
  };

  static defaultProps = {
    cfId: undefined,
    groups: undefined,
    isAllScopes: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      selectedGroups: [],
      allDisabled: props.isAllScopes,
    };
  }

  componentDidMount() {
    this.fetchGroupsScopes();
  }

  isGroupChecked = (groupId) => {
    return (
      this.state.allDisabled || this.state.selectedGroups.includes(groupId)
    );
  };

  toggleAllGroups = async (e) => {
    const isAllScopes = !!e.target.checked;
    const { t, pushNotif, companyId } = this.props;

    const jsonBody = JSON.stringify({
      customFieldId: this.props.cfId,
      isAllScopes: isAllScopes ? 'true' : 'false',
    });

    try {
      const response = await fetch(
        apiUrl('/custom-fields/toggle-all-scopes', companyId),
        {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: jsonBody,
        },
      );
      const payload = await response.json();
      if (!isEmpty(payload)) {
        pushNotif({ message: t('customFields.successTeamUpdated') });
      } else {
        pushNotif({
          type: 'danger',
          message: t('errors:somethingWrong_short'),
        });
      }
    } finally {
      this.setState({ allDisabled: isAllScopes });
    }
  };

  toggleGroup = (groupId, scopeId) => {
    const selectedGroups = this.isGroupChecked(groupId)
      ? this.state.selectedGroups.filter((g) => g !== groupId)
      : this.state.selectedGroups.concat(groupId);

    if (this.isGroupChecked(groupId)) {
      this.removeGroupFromScope(groupId, selectedGroups);
    } else {
      this.addGroupToScope(groupId, selectedGroups, scopeId);
    }
  };

  fetchGroupsScopes = async () => {
    const { companyId } = this.props;
    const customFieldId = this.props.cfId;
    const searchParams = new URLSearchParams({ customFieldId });

    const response = await fetch(
      apiUrl(`/custom-fields/scopes?${searchParams.toString()}`, companyId),
      {
        credentials: 'include',
      },
    );
    const payload = await response.json();
    if (!isEmpty(payload)) {
      const cfScopes = head(payload).scopes;
      const cfScopesIds = cfScopes.map((s) => s.entity_id);
      const cfScopesMatching = cfScopes.map((s) => ({
        scopeId: s.id,
        entity_id: s.entity_id,
      }));

      if (cfScopes.length) {
        this.setState({
          selectedGroups: cfScopesIds,
          entities: cfScopesMatching,
        });
      }
    }
  };

  addGroupToScope = async (groupId, allGroups) => {
    const { t, pushNotif, companyId } = this.props;

    const jsonBody = JSON.stringify({
      entityId: groupId,
      customFieldId: this.props.cfId,
    });

    const response = await fetch(apiUrl('/custom-fields/scopes', companyId), {
      method: 'POST',
      credentials: 'include',
      body: jsonBody,
      headers: { 'Content-Type': 'application/json' },
    });
    const payload = await response.json();
    if (!isEmpty(payload)) {
      pushNotif({ message: t('customFields.successTeamAdded') });
      const newEntities = this.state.entities.concat([
        { scopeId: payload.scope_id, entity_id: groupId },
      ]);
      this.setState({ selectedGroups: allGroups, entities: newEntities });
    } else {
      pushNotif({
        type: 'danger',
        message: t('errors:somethingWrong_short'),
      });
    }
  };

  removeGroupFromScope = async (groupId, allGroups) => {
    const { companyId } = this.props;
    const entity = this.state.entities.find((e) => e.entity_id === groupId);

    if (entity && entity.scopeId) {
      const { t, pushNotif } = this.props;

      const jsonBody = JSON.stringify({
        customFieldId: this.props.cfId,
        scopeId: entity.scopeId,
      });

      try {
        const response = await fetch(
          apiUrl('/custom-fields/scopes', companyId),
          {
            method: 'DELETE',
            credentials: 'include',
            body: jsonBody,
            headers: { 'Content-Type': 'application/json' },
          },
        );
        const payload = await response.json();
        if (payload && payload === 1) {
          pushNotif({ message: t('customFields.successTeamRemoved') });
        } else {
          pushNotif({
            type: 'danger',
            message: t('errors:somethingWrong_short'),
          });
        }
      } finally {
        this.setState({ selectedGroups: allGroups });
      }
    }
  };

  displayGroupsSettings = () => {
    return <div className="custom-field__groups">{this.getGroupsList()}</div>;
  };

  getGroupsList = () => {
    return this.props.groups.map((group) => {
      const groupScope = this.state.entities.find(
        (e) => e.entity_id === group.id,
      );
      const groupScopeId = groupScope ? groupScope.scopeId : null;

      return (
        <div
          key={group.id}
          className={`cf-group ${
            this.isGroupChecked(group.id) ? 'selected' : ''
          }`}
        >
          <span className="cf-group__name">{group.name}</span>
          <div className="cf-group__actions">
            <CheckboxRedesigned
              isChecked={this.isGroupChecked(group.id)}
              onChange={() => this.toggleGroup(group.id, groupScopeId)}
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const { allDisabled } = this.state;
    const { t } = this.props;

    return (
      <div className={`custom-field__teams ${!allDisabled ? 'open' : ''}`}>
        <div className="custom-field__teams-header">
          <span className="custom-field__teams-label">
            {t('customFields.teamLabel')}
          </span>
          <SwitchInput
            isChecked={allDisabled}
            onChange={this.toggleAllGroups}
          />
        </div>
        <AnimateHeight height={allDisabled ? 0 : 'auto'} duration={140}>
          {this.displayGroupsSettings()}
        </AnimateHeight>
      </div>
    );
  }
}

export default withTranslation()(TeamsSelection);
