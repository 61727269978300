import cx from 'classnames';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { openSplash, closeSplash } from './redux/actions';
import { SLACK_ANNOUNCEMENT } from './splashTypes';
import SlackAnnouncementSplash from './types/SlackAnnouncementSplash/SlackAnnouncementSplash';

import './SplashNotifContainer.scss';

class SplashNotifContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    data: PropTypes.object,
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    type: PropTypes.oneOf([SLACK_ANNOUNCEMENT]),
    openSplash: PropTypes.func.isRequired,
    closeSplash: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: undefined,
    data: undefined,
    type: undefined,
  };

  isVisible = () =>
    this.props.isOpen && includes([SLACK_ANNOUNCEMENT], this.props.type);

  renderSplash = () => {
    const { user, company, type } = this.props;

    if (type === SLACK_ANNOUNCEMENT) {
      return (
        <SlackAnnouncementSplash
          user={user}
          company={company}
          onClose={this.props.closeSplash}
        />
      );
    }

    return null;
  };

  render() {
    const popupContent = this.renderSplash();
    if (!popupContent) {
      return null;
    }
    return (
      <div
        className={cx('SplashNotifContainer', { visible: this.isVisible() })}
      >
        {popupContent}
      </div>
    );
  }
}

const mapStateToProps = ({ splashNotif: { isOpen, type, data } }) => ({
  isOpen,
  type,
  data,
});

const mapDispatchToProps = {
  openSplash,
  closeSplash,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplashNotifContainer);
