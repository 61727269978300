import { Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';
import map from 'lodash/map';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import without from 'lodash/without';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import ButtonRedesigned from 'src/core/common/components/legacy/ButtonRedesigned/ButtonRedesigned';
import CheckboxRedesigned from 'src/core/common/components/legacy/CheckboxRedesigned/CheckboxRedesigned';
import './TableActions.scss';

class TableActions extends Component {
  static propTypes = {
    className: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          tooltip: PropTypes.string,
          isDisabled: PropTypes.bool,
          isProcessing: PropTypes.bool,
          onClick: PropTypes.func.isRequired,
        }),
      ]),
    ),
    leftLinks: PropTypes.arrayOf(PropTypes.object),
    leftActions: PropTypes.object,
    rightActions: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    selectedItemsCounter: PropTypes.number,
    isSelectionEnabled: PropTypes.bool,
    shouldHideTheAllCheckbox: PropTypes.bool,
    isPanelOpen: PropTypes.bool,
    isItemEligibleForAction: PropTypes.func,
    getBulkSelectedTemplate: PropTypes.func,
    toggleAllItems: PropTypes.func.isRequired,
    togglePanel: PropTypes.func,
    onLeftAction: PropTypes.func,
    onRightAction: PropTypes.func,
  };

  static defaultProps = {
    actions: undefined,
    selectedItems: undefined,
    isPanelOpen: undefined,
    onLeftAction: undefined,
    onRightAction: undefined,
    selectedItemsCounter: 0,
    className: '',
    leftLinks: null,
    leftActions: {},
    rightActions: {},
    items: [],
    isItemEligibleForAction: () => false,
    isSelectionEnabled: true,
    getBulkSelectedTemplate: null,
    togglePanel: null,
    shouldHideTheAllCheckbox: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      actionsCurrentlyProcessed: [],
      areAllItemsChecked: false,
    };
  }

  processAction = (action, items) => {
    const { actionsCurrentlyProcessed } = this.state;

    if (includes(actionsCurrentlyProcessed, action)) {
      return;
    }

    // Add loading state for the action, trigger it, and remove loading state
    this.setState({
      actionsCurrentlyProcessed: (actionsCurrentlyProcessed ?? []).concat(
        action,
      ),
    });

    this.props.onLeftAction(action, items, () => {
      this.setState({
        actionsCurrentlyProcessed: without(
          this.state.actionsCurrentlyProcessed,
          action,
        ),
      });
    });
  };

  // For each selected items, check if they are elligible for the current action
  getLeftItemsToShow = () => {
    const { items, selectedItems, leftActions, isItemEligibleForAction } =
      this.props;
    return reduce(
      items,
      (shown, item) => {
        forEach(keys(leftActions), (action) => {
          const isSelected = includes(selectedItems, item.id);
          const isEligible = isItemEligibleForAction(item, action);
          if (isSelected && isEligible) {
            shown[action] = (shown[action] || []).concat(item.id);
          }
        });
        return shown;
      },
      {},
    );
  };

  isBulkMode = () => this.props.selectedItemsCounter > 0;

  handleToggleAllCheck = () => {
    const isChecked = !this.state.areAllItemsChecked;
    this.setState({ areAllItemsChecked: isChecked });
    this.props.toggleAllItems(isChecked);
  };

  unCheckAll = () => {
    this.setState({ areAllItemsChecked: false });
    this.props.toggleAllItems(false);
  };

  renderToggleAllCheckbox = () => {
    const { leftLinks, shouldHideTheAllCheckbox } = this.props;
    const shownActions = this.getLeftItemsToShow();
    if (
      // TODO: Remove the `shouldHideTheAllCheckbox` prop from this component when the draft request panel has been moved to Grapes
      shouldHideTheAllCheckbox ||
      (size(leftLinks) && isEmpty(shownActions))
    ) {
      return null;
    }
    return (
      <CheckboxRedesigned
        checkSign="neutral"
        className="TableActions__left-checkbox"
        isDisabled={!this.props.isSelectionEnabled}
        isChecked={this.state.areAllItemsChecked}
        onChange={this.handleToggleAllCheck}
        shouldStopClickPropagation
      />
    );
  };

  renderLeftLinks = () => {
    const { leftLinks } = this.props;
    const shownActions = this.getLeftItemsToShow();
    if (!size(leftLinks) || !isEmpty(shownActions)) {
      return null;
    }

    return (
      <div className="TableActions__left-links">
        {map(leftLinks, (a, key) => (
          <NavLink key={key} to={a.link} className="item">
            {a.label}
          </NavLink>
        ))}
      </div>
    );
  };

  renderMainActions = () => {
    const { actions } = this.props;
    if (!size(actions)) {
      return null;
    }

    return (
      <div className="TableActions__left-actions">
        {map(actions, (action, key) => (
          <Tooltip content={action.tooltip} triggerAsChild>
            <span key={key}>
              <ButtonRedesigned
                text={action.label}
                size="small"
                isDisabled={action.isDisabled}
                isLoading={action.isProcessing}
                className={
                  action.isDisabled
                    ? 'TableActions__ButtonRedesigned-disabled'
                    : ''
                }
                onClick={action.isDisabled ? noop : action.onClick}
              />
            </span>
          </Tooltip>
        ))}
      </div>
    );
  };

  renderLeftActions = () => {
    const { leftActions } = this.props;
    const { actionsCurrentlyProcessed } = this.state;
    const shownActions = this.getLeftItemsToShow();

    return (
      <div className="TableActions__left-actions">
        {map(keys(shownActions), (shownAction) => {
          const actionItems = shownActions[shownAction];
          const template = leftActions[shownAction];
          const cta = template(actionItems.length);
          const isLoading = includes(actionsCurrentlyProcessed, shownAction);
          return (
            <ButtonRedesigned
              key={shownAction}
              text={cta}
              size="small"
              onClick={() => this.processAction(shownAction, actionItems)}
              isLoading={isLoading}
            />
          );
        })}
      </div>
    );
  };

  renderRightActions = () => {
    const { rightActions, togglePanel, isPanelOpen, onRightAction, t } =
      this.props;
    if (this.isBulkMode()) {
      return null;
    }

    const panelButton =
      !togglePanel || !isPanelOpen ? null : (
        <ButtonRedesigned
          text={t('misc.hidePanel')}
          size="small"
          onClick={togglePanel}
        />
      );

    const rightButtons = map(keys(rightActions), (r) => {
      const template = get(rightActions, r);
      const cta = template();
      return (
        <ButtonRedesigned
          className="TableActions__right-actions__newRequestBtn"
          key={r}
          text={cta}
          size="small"
          onClick={() => onRightAction(r)}
        />
      );
    });

    return (
      <div className="TableActions__right-actions">
        {rightButtons}
        {panelButton}
      </div>
    );
  };

  renderRightBulkLabel = () => {
    const { t, getBulkSelectedTemplate, selectedItemsCounter } = this.props;
    if (!this.isBulkMode()) {
      return null;
    }

    const counterLabel = isFunction(getBulkSelectedTemplate)
      ? getBulkSelectedTemplate(selectedItemsCounter)
      : t('misc.nItemsSelected', { count: selectedItemsCounter });

    return (
      <div className="TableActions__right-bulk-label">
        <span>{counterLabel}</span>{' '}
        <span className="clear" onClick={this.unCheckAll}>
          {t('misc.clearSelection')}
        </span>
      </div>
    );
  };

  render() {
    return (
      <div className={cx('TableActions', this.props.className)}>
        <div className="TableActions__left">
          {this.renderToggleAllCheckbox()}
          {this.renderLeftLinks()}
          {this.renderMainActions()}
          {this.renderLeftActions()}
        </div>
        <div className="TableActions__right">
          {this.renderRightBulkLabel()}
          {this.renderRightActions()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TableActions);
