import compact from 'lodash/compact';
import get from 'lodash/get';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';
import * as groupsTypes from 'src/core/actionTypes/groups';
import createReducer from 'src/core/config/createReducer';
import PerDiemModule from 'src/core/modules/per-diem';
import RequestsModule from 'src/core/modules/requests';
import { enrichRequestWithExpenseCategory } from 'src/core/utils/entities/request';

import * as types from './actionTypes';

const initialState = {
  stats: {
    all: 0,
    card_load: 0,
    credit_notes: 0,
    drafts: 0,
    expenses: 0,
    invoices: 0,
    mine: 0,
    purchase_order: 0,
    purchases: 0,
    single_purchases: 0,
    subscription_increase: 0,
    subscriptions: 0,
    to_approve: 0,
    inbox_invoices: 0,
  }, // total stats (subnav)
  request: null,
  requestsSections: {
    stats: {},
    requests: {
      invoices: [],
      purchases: [],
      expense_claims: [],
      mileage_allowances: [],
      per_diem_allowances: [],
      mine_expenses: [],
      mine_purchases: [],
      mine_invoices: [],
      team_expenses: [],
      team_purchases: [],
      team_invoices: [],
      mine_mileage_allowances: [],
      team_mileage_allowances: [],
      mine_per_diem_allowances: [],
      team_per_diem_allowances: [],
    },
  },
  draftRequests: [],
  draftRequest: null,
  customFields: [],
  groups: [],
  behavior: {
    isLoading: false,
    errors: null,
    cancelRequestFailureStats: {
      exportedScheduledPaymentsCount: 0,
      paidScheduledPaymentsCount: 0,
      wiretransferRequestsCount: 0,
      transferScheduleCount: 0,
    },
    isFetchingCustomFields: false,
  },
  searchFilters: {
    search: undefined,
  },
};

// Update or delete existing request among sections of requests
const updateRequestAmongSection = (
  request = {},
  requests = {},
  removeRequest = false,
) => {
  if (!request) {
    return requests;
  }
  return reduce(
    keys(requests),
    (all, k) => {
      // Try to update request within current section's requests
      const sectionReqs = map(get(requests, k), (r) => {
        if (r.id === request.id) {
          // Eventually remove it if needed
          return removeRequest ? null : request;
        }
        return r;
      });

      // Eventually remove item from the list
      const reqs = removeRequest ? compact(sectionReqs) : sectionReqs;
      return { ...all, [k]: reqs };
    },
    {},
  );
};

const fetchRequestsLoading = (state) =>
  merge({}, state, { behavior: { isLoading: true, errors: null } });

const fetchRequestsSuccess = (state, action) => {
  const { requestsSections } = action.payload;
  return {
    ...state,
    requestsSections,
    behavior: { ...state.behavior, isLoading: false, errors: null },
  };
};

const fetchRequestsFailure = (state, action) =>
  merge({}, state, {
    errors: action.payload,
    behavior: { errors: action.payload, isLoading: false },
  });

const fetchRequestsSectionsStatsSuccess = (state, action) => {
  const { sectionsStats } = action.payload;
  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      stats: sectionsStats,
    },
  };
};

const updateRequestSuccess = (state, action) => {
  const updatedRequest = action.payload;
  let { request } = state;
  // Maybe update the currently opened request if it's still the same
  if (request && updatedRequest.id === request.id) {
    request = {
      ...request,
      ...updatedRequest,
    };
  }

  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      requests: updateRequestAmongSection(
        request,
        state.requestsSections.requests,
      ),
    },
  };
};

const fetchDraftRequestsLoading = (state) => ({
  ...state,
  behavior: { ...state.behavior, isLoading: true },
});
const fetchDraftRequestsSuccess = (state, action) => {
  const draftRequests = action.payload;
  return {
    ...state,
    draftRequests,
    behavior: { ...state.behavior, isLoading: false },
  };
};
const fetchDraftRequestsFailure = (state, action) => ({
  ...state,
  behavior: { ...state.behavior, isLoading: false, errors: action.payload },
});

const addRequestInRequestsList = (requests = [], request) => {
  return request.state === 'pending'
    ? [request, ...requests]
    : [...requests, request];
};

const addRequestLocally = (state, action) => {
  const request = action.payload;
  const { requests } = state.requestsSections;

  const newRequests = {};
  if (includes(['single_purchase', 'subscription'], request.type)) {
    newRequests.purchases = addRequestInRequestsList(
      state.requestsSections.requests.purchases,
      request,
    );
    newRequests.mine_purchases = addRequestInRequestsList(
      state.requestsSections.requests.mine_purchases,
      request,
    );
  } else {
    switch (request.type) {
      case 'expense': {
        newRequests.expense_claims = addRequestInRequestsList(
          state.requestsSections.requests.expense_claims,
          request,
        );
        newRequests.mine_expenses = addRequestInRequestsList(
          state.requestsSections.requests.mine_expenses,
          request,
        );
        break;
      }
      case 'invoice': {
        newRequests.invoices = addRequestInRequestsList(
          state.requestsSections.requests.invoices,
          request,
        );
        newRequests.mine_invoices = addRequestInRequestsList(
          state.requestsSections.requests.mine_invoices,
          request,
        );

        break;
      }
      case 'mileage_allowance': {
        newRequests.mileage_allowances = addRequestInRequestsList(
          state.requestsSections.requests.mileage_allowances,
          request,
        );
        newRequests.mine_mileage_allowances = addRequestInRequestsList(
          state.requestsSections.requests.mine_mileage_allowances,
          request,
        );

        break;
      }
      case 'per_diem_allowance': {
        newRequests.per_diem_allowances = addRequestInRequestsList(
          state.requestsSections.requests.per_diem_allowances,
          request,
        );
        newRequests.mine_per_diem_allowances = addRequestInRequestsList(
          state.requestsSections.requests.mine_per_diem_allowances,
          request,
        );

        break;
      }
      case 'purchase_order': {
        newRequests.purchase_orders = addRequestInRequestsList(
          state.requestsSections.requests.purchase_orders,
          request,
        );
        newRequests.mine_purchase_orders = addRequestInRequestsList(
          state.requestsSections.requests.mine_purchase_orders,
          request,
        );

        break;
      }
      default: {
        return state;
      }
    }
  }

  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      requests: {
        ...requests,
        ...newRequests,
      },
    },
  };
};

const updateRequestLocally = (state, action) => {
  const request = action.payload;

  const currentEnrichedRequest = enrichRequestWithExpenseCategory(
    request,
    state.expenseCategoryCustomFieldId,
  );

  return {
    ...state,
    request: currentEnrichedRequest,
    requestsSections: {
      ...state.requestsSections,
      requests: updateRequestAmongSection(
        request,
        state.requestsSections.requests,
      ),
    },
  };
};
const removeRequestLocally = (state, action) => {
  const requestToDelete = action.payload;
  const requests = updateRequestAmongSection(
    { id: requestToDelete },
    state.requestsSections.requests,
    true,
  );
  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      requests,
    },
  };
};
const fetchCustomFieldsLoading = (state) => ({
  ...state,
  hasFetchingCustomFieldsError: undefined,
  behavior: {
    ...state.behavior,
    isFetchingCustomFields: true,
  },
});
const fetchCustomFieldsSuccess = (state, action) => ({
  ...state,
  customFields: action.payload.customFields,
  hasFetchingCustomFieldsError: undefined,
  behavior: {
    ...state.behavior,
    isFetchingCustomFields: false,
  },
});
const fetchCustomFieldsFailure = (state) => ({
  ...state,
  hasFetchingCustomFieldsError: true,
  behavior: {
    ...state.behavior,
    isFetchingCustomFields: false,
  },
});
const setExpenseCategoryValues = (state, action) => {
  return {
    ...state,
    expenseCategoryValues: action.payload ?? [],
    behavior: {
      ...state.behavior,
      isFetchingCustomFields: false,
    },
  };
};
const setExpenseCategoryCustomField = (state, action) => {
  return {
    ...state,
    expenseCategory: action.payload,
  };
};
const setExpenseCategoryCustomFieldId = (state, action) => {
  return {
    ...state,
    expenseCategoryCustomFieldId: action.payload,
  };
};

const refreshRequestWithExpenseCategory = (state) => {
  if (!state.request) {
    return state;
  }
  return {
    ...state,
    request: enrichRequestWithExpenseCategory(
      state.request,
      state.expenseCategoryCustomFieldId,
    ),
  };
};

const fetchGroupsSuccess = (state, action) => ({
  ...state,
  groups: action.payload.groups,
});

// Fetch requests stats
const fetchRequestsStatsSuccess = (state, action) => ({
  ...state,
  stats: action.payload,
});

// Update draft requests locally
const addDraftRequestsLocally = (state, action) => {
  const newDraftRequests = action.payload;

  return {
    ...state,
    draftRequests: [...newDraftRequests, ...state.draftRequests],
  };
};
const removeDraftRequestLocally = (state, action) => ({
  ...state,
  draftRequests: state.draftRequests.filter(
    (draftRequest) => draftRequest.id !== action.payload.id,
  ),
});

const resetRequestFailure = (state) => {
  return {
    ...state,
    behavior: {
      ...state.behavior,
      cancelRequestFailureStats: {
        exportedScheduledPaymentsCount: 0,
        paidScheduledPaymentsCount: 0,
        wiretransferRequestsCount: 0,
        transferScheduleCount: 0,
      },
    },
  };
};

const cancelRequestFailure = (state, action) => {
  const {
    exportedScheduledPaymentsCount,
    paidScheduledPaymentsCount,
    wiretransferRequestsCount,
    transferScheduleCount,
  } = state.behavior.cancelRequestFailureStats;

  if (!action.payload) {
    return { ...state };
  }
  return {
    ...state,
    behavior: {
      ...state.behavior,
      cancelRequestFailureStats: {
        // TODO: we need to update this for new SMI flow.
        exportedScheduledPaymentsCount:
          Number(action.payload.hasExportedScheduledPayment) +
          exportedScheduledPaymentsCount,
        paidScheduledPaymentsCount:
          Number(action.payload.hasPaidScheduledPayment) +
          paidScheduledPaymentsCount,
        wiretransferRequestsCount:
          Number(action.payload.hasWiretransferRequest) +
          wiretransferRequestsCount,
        transferScheduleCount:
          Number(action.payload.hasTransferSchedule) + transferScheduleCount,
      },
    },
  };
};

const setRequestFilters = (state, { payload }) => {
  return {
    ...state,
    searchFilters: {
      search: state.searchFilters.search,
      ...payload,
    },
  };
};

const setTextFilter = (state, { payload }) => {
  return {
    ...state,
    searchFilters: {
      ...state.searchFilters,
      search: payload,
    },
  };
};

const resetTextFilter = (state) => {
  return {
    ...state,
    searchFilters: {
      ...state.searchFilters,
      search: undefined,
    },
  };
};

export default createReducer(
  {
    [types.FETCH_REQUESTS_LOADING]: fetchRequestsLoading,
    [types.FETCH_REQUESTS_SUCCESS]: fetchRequestsSuccess,
    [types.FETCH_REQUESTS_FAILURE]: fetchRequestsFailure,
    [types.FETCH_REQUESTS_SECTIONS_STATS_SUCCESS]:
      fetchRequestsSectionsStatsSuccess,
    [types.FETCH_DRAFT_REQUESTS_LOADING]: fetchDraftRequestsLoading,
    [types.FETCH_DRAFT_REQUESTS_SUCCESS]: fetchDraftRequestsSuccess,
    [types.FETCH_DRAFT_REQUESTS_FAILURE]: fetchDraftRequestsFailure,
    [types.UPDATE_REQUEST_SUCCESS]: updateRequestSuccess,
    [types.ADD_REQUEST_LOCALLY]: addRequestLocally,
    [types.UPDATE_REQUEST_LOCALLY]: updateRequestLocally,
    [types.REMOVE_REQUEST_LOCALLY]: removeRequestLocally,
    [customFieldsTypes.FETCH_CF_SUCCESS]: fetchCustomFieldsSuccess,
    [customFieldsTypes.FETCH_CF_LOADING]: fetchCustomFieldsLoading,
    [customFieldsTypes.FETCH_CF_FAILURE]: fetchCustomFieldsFailure,
    [customFieldsTypes.SET_EXPENSE_CATEGORY_CUSTOM_FIELD]:
      setExpenseCategoryCustomField,
    [customFieldsTypes.SET_EXPENSE_CATEGORY_VALUES]: setExpenseCategoryValues,
    [customFieldsTypes.SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID]:
      setExpenseCategoryCustomFieldId,
    [customFieldsTypes.REFRESH_REQUEST_WITH_EXPENSE_CATEGORY]:
      refreshRequestWithExpenseCategory,
    [groupsTypes.FETCH_GROUPS_SUCCESS]: fetchGroupsSuccess,
    [types.FETCH_REQUESTS_STATS_SUCCESS]: fetchRequestsStatsSuccess,
    [types.ADD_DRAFT_REQUESTS_LOCALLY]: addDraftRequestsLocally,
    [types.REMOVE_DRAFT_REQUEST_LOCALLY]: removeDraftRequestLocally,
    [types.CANCEL_REQUEST_FAILURE]: cancelRequestFailure,
    [types.REQUEST_FAILURE_RESET]: resetRequestFailure,
    [RequestsModule.actions.UPDATE_SUCCESS]: updateRequestSuccess,
    [PerDiemModule.actions.CREATE_SUCCESS]: addRequestLocally,
    [types.SET_REQUEST_FILTERS]: setRequestFilters,
    [types.SET_TEXT_FILTER]: setTextFilter,
    [types.RESET_TEXT_FILTER]: resetTextFilter,
  },
  initialState,
);
