import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchCustomFields } from 'src/core/actions/customFields';
import { fetchCustomExports } from 'src/core/actions/exports';
import { uploadFile } from 'src/core/actions/files';
import { toggleFiltersPanel } from 'src/core/actions/filtersPanel';
import { fetchGroups } from 'src/core/actions/groups';
import { fetchUsers } from 'src/core/actions/users';
import withErrorBoundary, {
  ErrorBoundary,
} from 'src/core/common/components/withErrorBoundary';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { addNotification } from 'src/core/modules/app/notifications';
import { GraphQLProvider } from 'src/core/modules/bookkeep/components/GraphQLProvider';
import { usePayableCounts } from 'src/core/modules/bookkeep/graphql/hooks';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep/hooks';
import {
  getIsFeatureEnabled,
  getCustomExports,
  getIsSupervisionActive,
  getImpersonator,
} from 'src/core/selectors/globalSelectors';
import { getUsers, isController } from 'src/core/selectors/users';
import authorizeComponent from 'src/core/utils/authorizeComponent';
import { getUrlParams } from 'src/core/utils/urlParser';

import PaymentsAll from './PaymentsAll';
import {
  savePaymentVatType,
  savePaymentVat,
} from './components/VatSelectorV2/redux/actions';
import { getLegacyFiltersFromUrl } from './components/list/PaymentFilters/types';
import { Payments } from './components/list/Payments';
import * as actions from './redux/actions';
import { getOpenedPayment, getOpenedPaymentInvoices } from './redux/selectors';
import { convertFiltersFromUrl, serialize } from './utils/convertFiltersForApi';
import { getExpenseCategoryCustomFieldId } from '../requests/redux/legacy/selectors';

class PaymentsAllContainer extends Component {
  static propTypes = {
    isCustomExportsFeatureEnabled: PropTypes.bool.isRequired,
    isPayableFeatureEnabled: PropTypes.bool.isRequired,
    isTeamsFeatureEnabled: PropTypes.bool.isRequired,
    hasNewNavigation: PropTypes.bool.isRequired,
    hasGrapesAllPayments: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    payment: PropTypes.object,
    payments: PropTypes.arrayOf(PropTypes.object),
    paymentStats: PropTypes.arrayOf(PropTypes.object),
    invoices: PropTypes.arrayOf(PropTypes.object),
    selection: PropTypes.object,
    filters: PropTypes.object,
    pageInfo: PropTypes.object,
    accountingStats: PropTypes.object,
    bulkEditPayments: PropTypes.arrayOf(PropTypes.object),
    customFields: PropTypes.arrayOf(PropTypes.object),
    customExports: PropTypes.arrayOf(PropTypes.object),
    expenseCategoryCustomFieldId: PropTypes.string,
    notifications: PropTypes.arrayOf(PropTypes.object),
    counters: PropTypes.object,
    approvers: PropTypes.arrayOf(PropTypes.object),
    teams: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object.isRequired,
    isController: PropTypes.bool.isRequired,
    company: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    behavior: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    connectors: PropTypes.arrayOf(PropTypes.object),
    isSupervisionActive: PropTypes.bool.isRequired,
    impersonator: PropTypes.bool.isRequired,

    toggleFiltersPanel: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    fetchPaymentInvoices: PropTypes.func.isRequired,
    fetchPaymentsCounters: PropTypes.func.isRequired,
    fetchCustomExports: PropTypes.func.isRequired,
    resetPayments: PropTypes.func.isRequired,
    resetSelection: PropTypes.func.isRequired,
    fetchCustomFields: PropTypes.func.isRequired,
    fetchPayment: PropTypes.func.isRequired,
    setPaymentsAllOpenedPayment: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    updatePayment: PropTypes.func.isRequired,
    updateSelection: PropTypes.func.isRequired,
    remindInvoices: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    bulkEdit: PropTypes.func.isRequired,
    bulkMarkAsMissing: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    deleteDocumentaryEvidence: PropTypes.func.isRequired,
    bulkActions: PropTypes.object,
    resetBulkEditPayments: PropTypes.func.isRequired,
    deleteInvoice: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    incrementPaymentInvoices: PropTypes.func.isRequired,
  };

  static defaultProps = {
    payment: undefined,
    payments: undefined,
    invoices: [],
    paymentStats: undefined,
    selection: undefined,
    filters: undefined,
    pageInfo: undefined,
    accountingStats: undefined,
    bulkEditPayments: undefined,
    customFields: undefined,
    customExports: undefined,
    notifications: undefined,
    counters: undefined,
    approvers: undefined,
    teams: undefined,
    users: undefined,
    connectors: undefined,
    bulkActions: undefined,
  };

  constructor(props) {
    super(props);

    this.filterParams = this.props.hasGrapesAllPayments
      ? getLegacyFiltersFromUrl(props.location.search)
      : convertFiltersFromUrl(getUrlParams(props.location.search));
  }

  componentDidMount() {
    this.props.resetSelection();
    this.props.resetPayments();
    this.props.resetBulkEditPayments();

    this.props.fetchUsers();
    this.props.fetchGroups();
    this.props.fetchCustomFields();

    if (this.props.isCustomExportsFeatureEnabled && this.props.isController) {
      this.props.fetchCustomExports();
    }

    // Immediately fetch payments, eventually using the URL params
    this.props.updateFilters(this.filterParams);

    if (this.props.match && this.props.match.params.id) {
      this.props.fetchPayment(this.props.match.params.id);
      this.props.fetchPaymentInvoices(this.props.match.params.id);
      this.props.setPaymentsAllOpenedPayment(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filters && this.props.filters !== nextProps.filters) {
      this.props.resetPayments();
      this.fetchPayments({ filters: nextProps.filters });
    }
    if (nextProps.selection && this.props.selection !== nextProps.selection) {
      this.props.fetchPaymentsCounters({
        filters: serialize(this.props.filters),
        selection: nextProps.selection,
      });
    }
    if (
      nextProps.match &&
      nextProps.match.params.id &&
      nextProps.match.params.id !== this.props.match.params.id
    ) {
      this.props.fetchPayment(nextProps.match.params.id);
      this.props.fetchPaymentInvoices(nextProps.match.params.id);
      this.props.setPaymentsAllOpenedPayment(nextProps.match.params.id);
    }
  }

  fetchPayments = (options = {}) => {
    this.props.fetchPayments({
      ...options,
      filters: serialize(options.filters || this.props.filters),
    });
  };

  render() {
    const {
      company,
      user,
      users,
      payments,
      payment,
      match,
      customFields,
      expenseCategoryCustomFieldId,
      selection,
      counters,
      teams,
      approvers,
      behavior,
      history,
      paymentStats,
      pageInfo,
      notifications,
      customExports,
      filters,
      connectors,
      accountingStats,
      bulkEditPayments,
      config,
      invoices,
      deleteDocumentaryEvidence,
    } = this.props;

    const paymentId = match.params.id;
    const stepId = match.params.step;

    return this.props.hasGrapesAllPayments ? (
      <ErrorBoundary
        context={{ scope: 'payments-all-grapes', team: 'finance-accountant' }}
      >
        <Payments
          itemId={paymentId}
          isLoading={behavior.loader}
          payments={payments}
          bulkEditPayments={bulkEditPayments}
          users={users}
          payment={payment}
          invoices={invoices}
          teams={teams}
          customFields={customFields}
          pageInfo={pageInfo}
          expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
          updatePaymentLocally={this.props.updatePaymentLocally}
          fetchPayment={this.props.fetchPayment}
          deleteInvoice={this.props.deleteInvoice}
          fetchPaymentInvoices={this.props.fetchPaymentInvoices}
          incrementPaymentInvoices={this.props.incrementPaymentInvoices}
          deleteDocumentaryEvidence={deleteDocumentaryEvidence}
          paymentStats={paymentStats}
          accountingStats={accountingStats}
          fetchPayments={this.props.fetchPayments}
          updateFilters={this.props.updateFilters}
          selection={selection}
          updateSelection={this.props.updateSelection}
          counters={counters}
          bulkEdit={this.props.bulkEdit}
          download={this.props.download}
          remindInvoices={this.props.remindInvoices}
          bulkActions={this.props.bulkActions}
          bulkMarkAsMissing={this.props.bulkMarkAsMissing}
          isSupervisionActive={this.props.isSupervisionActive}
          hidePanel={this.props.hidePanel}
        />
      </ErrorBoundary>
    ) : (
      <PaymentsAll
        company={company}
        user={user}
        users={users}
        counters={counters}
        payment={payment}
        payments={payments}
        invoices={invoices}
        paymentStats={paymentStats}
        selection={selection}
        pageInfo={pageInfo}
        teams={teams}
        approvers={approvers}
        customFields={customFields}
        expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
        customExports={customExports}
        notifications={notifications}
        filters={filters}
        connectors={connectors}
        accountingStats={accountingStats}
        bulkEditPayments={bulkEditPayments}
        isLoading={behavior.loader}
        isFiltersPanelOpen={!!behavior.isFiltersPanelOpen}
        itemId={paymentId}
        stepId={stepId}
        params={this.filterParams}
        history={history}
        config={config}
        toggleFiltersPanel={this.props.toggleFiltersPanel}
        fetchPayment={this.props.fetchPayment}
        setPaymentsAllOpenedPayment={this.props.setPaymentsAllOpenedPayment}
        updatePayment={this.props.updatePayment}
        fetchPayments={this.fetchPayments}
        fetchCustomFields={this.props.fetchCustomFields}
        updateFilters={this.props.updateFilters}
        updateSelection={this.props.updateSelection}
        remindInvoices={this.props.remindInvoices}
        download={this.props.download}
        deleteInvoice={this.props.deleteInvoice}
        bulkEdit={this.props.bulkEdit}
        bulkMarkAsMissing={this.props.bulkMarkAsMissing}
        bulkActions={this.props.bulkActions}
        hidePanel={this.props.hidePanel}
        updatePaymentLocally={this.props.updatePaymentLocally}
        uploadFile={this.props.uploadFile}
        fetchPaymentInvoices={this.props.fetchPaymentInvoices}
        savePaymentVatType={this.props.savePaymentVatType}
        savePaymentVat={this.props.savePaymentVat}
        pushNotif={this.props.pushNotif}
        errors={this.props.errors}
        incrementPaymentInvoices={this.props.incrementPaymentInvoices}
        deleteDocumentaryEvidence={deleteDocumentaryEvidence}
        isPayableFeatureEnabled={this.props.isPayableFeatureEnabled}
        isTeamsFeatureEnabled={this.props.isTeamsFeatureEnabled}
        hasNewNavigation={this.props.hasNewNavigation}
        isSupervisionActive={this.props.isSupervisionActive}
        impersonator={this.props.impersonator}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    global: { company, user, config },
    payments,
  } = state;

  return {
    hasNewNavigation: getIsFeatureEnabled(
      state,
      FEATURES.TMP_VERTICAL_NAVIGATION,
    ),
    isCustomExportsFeatureEnabled: getIsFeatureEnabled(
      state,
      FEATURES.CUSTOM_EXPORTS,
    ),
    isPayableFeatureEnabled: getIsFeatureEnabled(
      state,
      FEATURES.BOOKKEEP_PAYABLES,
    ),
    isTeamsFeatureEnabled: getIsFeatureEnabled(state, FEATURES.TEAMS),
    hasGrapesAllPayments: getIsFeatureEnabled(
      state,
      FEATURES.TMP_GRAPES_ALL_PAYMENTS,
    ),
    user,
    isController: isController(state),
    config,
    company,
    customExports: getCustomExports(state),
    users: getUsers(state),
    payment: getOpenedPayment(state),
    invoices: getOpenedPaymentInvoices(state),
    expenseCategoryCustomFieldId: getExpenseCategoryCustomFieldId(state),
    payments: payments.allPayments,
    paymentStats: payments.allPaymentStats,
    selection: payments.allPaymentsSelection,
    filters: payments.filters,
    pageInfo: payments.allPaymentsPageInfo,
    teams: payments.groups,
    approvers: payments.approvers,
    customFields: payments.customFields,
    notifications: payments.notifications,
    counters: payments.allPaymentsCounters,
    behavior: payments.behavior,
    connectors: payments.connectors,
    accountingStats: payments.accountingStats,
    bulkEditPayments: payments.bulkEditPayments,
    bulkActions: payments.allPaymentsBulkActions,
    errors: payments.errors,
    isSupervisionActive: getIsSupervisionActive(state),
    impersonator: getImpersonator(state),
  };
};

const mapDispatchToProps = {
  fetchPayment: actions.fetchPayment,
  setPaymentsAllOpenedPayment: actions.setPaymentsAllOpenedPayment,
  fetchPayments: actions.fetchAllPayments,
  fetchPaymentInvoices: actions.fetchPaymentInvoices,
  fetchPaymentsCounters: actions.fetchAllPaymentsCounters,
  resetPayments: actions.resetAllPayments,
  resetSelection: actions.resetAllPaymentsSelection,
  updateFilters: actions.updateFilters,
  updateSelection: actions.updateAllPaymentsSelection,
  remindInvoices: actions.remindInvoices,
  download: actions.downloadPayments,
  bulkEdit: actions.bulkEditPayments,
  bulkMarkAsMissing: actions.bulkMarkAsMissing,
  updatePaymentLocally: actions.updatePaymentLocally,
  hidePanel: actions.hidePaymentPanel,
  resetBulkEditPayments: actions.resetBulkEditPayments,
  deleteInvoice: actions.deleteInvoice,
  updatePayment: actions.updatePayment,
  toggleFiltersPanel,
  fetchCustomFields,
  fetchGroups,
  fetchUsers,
  fetchCustomExports,
  uploadFile,
  savePaymentVatType,
  savePaymentVat,
  pushNotif: addNotification,
  incrementPaymentInvoices: actions.incrementPaymentInvoices,
  deleteDocumentaryEvidence: actions.deleteDocumentaryEvidence,
};

const authorizedComponent = authorizeComponent({
  Comp: withErrorBoundary({
    scope: 'payments-all',
    team: 'finance-accountant',
  })(PaymentsAllContainer),
  componentCanBeShown: ({ user }) =>
    user.has_plastic_card ||
    user.is_account_owner ||
    user.is_controller ||
    user.is_requester ||
    user.is_group_admin,
  redirectUrl: ({ company }) =>
    routeFor(routes.ACCOUNT_ME_PROFILE.path, { company: company.id }),
});

const withNewBookkeepingStats = (Comp) => {
  const PaymentContainerWithNewBookkeepingStats = (props) => {
    const { data: payableCounts } = usePayableCounts();

    const accountingIntegrationQueryResult = useIntegrationStatusQuery();
    const accountingIntegration =
      accountingIntegrationQueryResult.status === 'success'
        ? accountingIntegrationQueryResult.data.integration
        : 'noIntegration';

    const newProps = {
      ...props,
      accountingStats: {
        ...props.accountingStats,
        totalToReview:
          (accountingIntegration !== 'noIntegration' &&
            payableCounts?.toPrepareCount) ||
          0,
      },
    };

    return <Comp {...newProps} />;
  };
  PaymentContainerWithNewBookkeepingStats.displayName =
    'PaymentContainerWithNewBookkeepingStats';
  return PaymentContainerWithNewBookkeepingStats;
};

const withGraphQLProvider = (Comp) => {
  const PaymentContainerWithGraphQLProvider = (props) => {
    return (
      <GraphQLProvider>
        <Comp {...props} />
      </GraphQLProvider>
    );
  };
  PaymentContainerWithGraphQLProvider.displayName =
    'PaymentContainerWithGraphQLProvider';
  return PaymentContainerWithGraphQLProvider;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withRouter(withGraphQLProvider(withNewBookkeepingStats(authorizedComponent))),
);
