import { Button, HighlightIcon } from '@dev-spendesk/grapes';
import { isBefore } from 'date-fns';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';

import {
  useFetchPurchaseOrdersQuery,
  useGoToPurchaseOrders,
} from '../../hooks';
import { type PurchaseOrder } from '../../models';

export const PurchaseOrderDashboardSummary = () => {
  const { t } = useTranslation('global');
  const goToPurchaseOrders = useGoToPurchaseOrders();
  const fetchPurchaseOrdersQueryState = useFetchPurchaseOrdersQuery(
    {
      status: 'open',
    },
    1000,
  );

  return (
    <QuerySuspense
      queryState={fetchPurchaseOrdersQueryState}
      loading={null}
      fallback={() => null}
    >
      {(purchaseOrders) => {
        const latePurchaseOrders = getLatePurchaseOrders(purchaseOrders);
        return latePurchaseOrders.length > 0 ? (
          <section className="box flex items-center gap-s">
            <HighlightIcon name="invoice" size="m" variant="info" />
            <div className="flex flex-col content-stretch">
              <h4 className="leading-[20px] text-complementary title-l">
                {t('purchaseOrders.dashboard.title', {
                  count: latePurchaseOrders.length,
                })}
              </h4>
              <p className="leading-[18px] text-neutral-dark body-m">
                {t('purchaseOrders.dashboard.description')}
              </p>
            </div>
            <Button
              className="ml-auto"
              onClick={() => goToPurchaseOrders()}
              text={t('purchaseOrders.dashboard.cta')}
              variant="secondary"
            />
          </section>
        ) : null;
      }}
    </QuerySuspense>
  );
};

// TODO@Request&Pay: remove this logic once the back end provides filters
const getLatePurchaseOrders = (purchaseOrders: PurchaseOrder[]) => {
  const now = new Date();
  return purchaseOrders.filter((purchaseOrder) =>
    isBefore(new Date(purchaseOrder.endDate), now),
  );
};
