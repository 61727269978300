import { type MonetaryValue } from 'ezmoney';

import { type MileageAllowanceDetails } from 'modules/bookkeep/types';
import { type PerDiem } from 'modules/per-diem';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type VatAccount } from '../../../prepare-payables/components/PreparePayablesInbox/components/PreparePayablesTaxAccountField';
import {
  type ExpenseAccountAutomation,
  type AnalyticalFieldAssociation,
  type MarvinConfidence,
  type Payable,
  type ExpenseAmountAutomation,
  type AnalyticalFieldAssociationAutomation,
} from '../../../prepare-payables/models';

interface AccountPayableFromApi {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
}

interface SupplierFromApi {
  kind: 'supplier';
  id: string;
  name: string;
  thumbnailUrl: string;
}

interface MemberFromApi {
  kind: 'member';
  id: string;
  givenName: string;
  familyName: string;
  email?: string;
  avatar?: string;
}

type CounterpartyFromApi = MemberFromApi | SupplierFromApi;

interface TeamFromApi {
  id: string;
  name: string;
}

interface CostCenterFromApi {
  id: string;
  companyId: string;
  name: string;
  ownerId: string;
  viewerIds: string[];
  isDeleted?: boolean;
}

interface ExpenseAccountFromApi {
  id: string;
  name: string;
  description: string;
  companyId: string;
  isArchived: boolean;
  code: string;
  isDefault?: boolean;
  isAvailable?: boolean;
}

interface VatAccountFromApi {
  id: string;
  name: string;
  description: string;
  companyId: string;
  isArchived: boolean;
  code: string;
  rate: string;
  type: 'vat';
  isDefault?: boolean;
}

interface ReverseChargeAccountFromApi {
  id: string;
  name: string;
  description: string;
  companyId: string;
  isArchived: boolean;
  codeDebit: string;
  codeCredit: string;
  type: 'reverseCharge';
}

type TaxAccountFromApi = VatAccountFromApi | ReverseChargeAccountFromApi;

interface PayableLineFromApi {
  taxAccountId: string | undefined;
  expenseAccountId: string | undefined;
  netAmount: MonetaryValue;
  grossAmount: MonetaryValue;
  vatAmount: MonetaryValue;
  vatAdjustment: MonetaryValue;
  vatRate: { amount: number; precision: number };
  natureId: string;
  expenseAccount?: ExpenseAccountFromApi;
  taxAccount?: TaxAccountFromApi;
  analyticalFieldAssociations: AnalyticalFieldAssociation[];
}

interface BooleanCustomFieldFromApi {
  customField: {
    id: string;
    name: string;
    type: 'boolean';
    is_splittable: number;
    is_required: number;
  };
  value: boolean;
}

interface ListCustomFieldFromApi {
  customField: {
    id: string;
    name: string;
    type: 'list';
    is_splittable: number;
    is_required: number;
  };
  values: {
    id: string;
    value: string;
  }[];
}

export type CustomFieldFromApi =
  | BooleanCustomFieldFromApi
  | ListCustomFieldFromApi;

type AutomationFromApi = {
  expenseAccount: ExpenseAccountAutomation | null;
  expenseAmount: ExpenseAmountAutomation | null;
  accountPayable: {
    accountPayableMatch: {
      id: string;
      generalAccountCode: string;
      auxiliaryAccountCode: string | null;
      isDefault: boolean;
      isArchived: boolean;
      kind: 'supplierAccount' | 'employeeAccount';
    };
    isAppliedOnPayable: boolean;
  } | null;
  tax: {
    kind: 'default' | 'recommendation' | 'prediction' | null;
    confidence: MarvinConfidence | null;
    isAppliedOnPayable: boolean;
    taxAccount: VatAccount | null;
    taxAmount: MonetaryValue | null;
  } | null;
  analyticalFieldAssociations?: AnalyticalFieldAssociationAutomation[];
};

export interface PayableFromRestApi {
  accountPayable: AccountPayableFromApi | undefined;
  counterparty: CounterpartyFromApi;
  commitmentCounterparty?: SupplierFromApi;
  team?: TeamFromApi;
  costCenter?: CostCenterFromApi;
  member: MemberFromApi;
  itemLines: PayableLineFromApi[];
  customFields: CustomFieldFromApi[];
  mileage?: MileageAllowanceDetails;
  perDiem?: PerDiem;
  id: string;
  kind: 'expense' | 'reversal';
  version: number;
  inboxOffset: number;
  memberId: string;
  teamId?: string;
  companyId: string;
  amount: MonetaryValue;
  functionalAmount: MonetaryValue;
  description: string;
  dueDate?: string;
  creationDate: string;
  functionalExchangeRate: { amount: number; precision: number };
  state:
    | 'created'
    | 'prepared'
    | 'notBookkept'
    | 'unprepared'
    | 'discarded'
    | 'to_accounting:pending'
    | 'to_accounting:failed'
    | 'in_accounting'
    | 'in_accounting:manually';
  type:
    | 'bill'
    | 'claimedBill'
    | 'mileageExpense'
    | 'perDiemExpense'
    | 'reverseBill';
  legacyType:
    | 'expense'
    | 'invoice'
    | 'single_purchase'
    | 'subscription'
    | 'physical'
    | 'mileage_allowance'
    | 'per_diem_allowance';
  subType:
    | 'expense'
    | 'invoice'
    | 'singlePurchase'
    | 'subscription'
    | 'physical'
    | 'mileageAllowance'
    | 'perDiemAllowance';
  accountingDate: string | null;
  spendingCommitmentId: string;
  expenseIndex: number;
  reversalId: undefined;
  spendingExchangeRate: { amount: number; precision: number } | undefined;
  invoiceNumber?: string;
  automation?: AutomationFromApi;
  documentaryEvidence: Payable['documentaryEvidence'];
}

// FIXME: we should remove most of the reshaping
export function reshapePayableFromRestApi(
  payable: PayableFromRestApi,
): Payable {
  return {
    id: payable.id,
    version: payable.version,
    accountingDate: payable.accountingDate ?? null,
    // FIXME: unresolved on backend
    invoiceDate: 'payable.invoiceDate',
    // FIXME: unresolved on backend
    receiptNumber: 'payable.receiptNumber',
    amount: payable.functionalAmount,
    // FIXME: useless
    originalAmount: payable.amount,
    // FIXME: useless renaming
    exchangeRate: payable.functionalExchangeRate,
    description: payable.description,
    // FIXME: wrong renaming
    supplier: reshapeSupplier(payable),
    accountPayableId: payable.accountPayable?.id,
    // FIXME: not a good idea to flatten the discriminated union
    supplierAccount:
      payable.counterparty.kind === 'supplier'
        ? payable.accountPayable
        : undefined,
    // FIXME: not a good idea to flatten the discriminated union
    employeeAccount:
      payable.counterparty.kind === 'member'
        ? payable.accountPayable
        : undefined,
    team: payable.team,
    vatConfidence:
      payable.automation?.tax && payable.automation?.tax?.isAppliedOnPayable
        ? payable.automation.tax.confidence
        : null,
    itemLines: reshapeItemLines(payable.itemLines),
    type: getPayableType({ type: payable.type, subType: payable.subType }),
    subType: getPayableSubType(payable.subType),
    costCenter: reshapeCostCenter(payable.costCenter),
    customFields: reshapeCustomFields(
      payable.customFields,
      payable.automation?.analyticalFieldAssociations,
    ),
    mileageDetails: payable.mileage,
    perDiem: payable.perDiem,
    automation: reshapeAutomation(payable.automation),
    // FIXME: createdAt has a totally different meaning
    createdAt: payable.creationDate,
    creationDate: payable.creationDate,
    user: reshapeMember(payable.member),
    counterparty: reshapeCounterparty(payable),
    // FIXME: useless, wrong value, we should always leverage switch +
    prepared: payable.state !== 'unprepared' && payable.state !== 'created',
    invoiceNumber: payable.invoiceNumber ?? undefined,
    documentaryEvidence: payable.documentaryEvidence,
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function reshapeAutomation(
  rawAutomation: AutomationFromApi | undefined,
): Payable['automation'] | undefined {
  if (rawAutomation === undefined) {
    return undefined;
  }

  const automation: Payable['automation'] = {
    analyticalFieldAssociations: rawAutomation.analyticalFieldAssociations,
  };

  if (rawAutomation.tax) {
    if (rawAutomation.tax.kind === 'default') {
      automation.tax = {
        kind: 'default',
        isAppliedOnPayable: !!rawAutomation.tax.isAppliedOnPayable,
      };
    } else if (rawAutomation.tax.kind === 'recommendation') {
      automation.tax = {
        kind: 'recommendation',
        confidence: rawAutomation.tax.confidence ?? undefined,
        isAppliedOnPayable: !!rawAutomation.tax.isAppliedOnPayable,
      };
    } else {
      automation.tax = {
        kind: 'prediction',
        taxAccount: rawAutomation.tax.taxAccount ?? {
          id: '',
          name: '',
        },
        confidence: rawAutomation.tax.confidence ?? undefined,
        isAppliedOnPayable: !!rawAutomation.tax.isAppliedOnPayable,
      };
    }
  }

  if (rawAutomation.expenseAccount) {
    if (
      rawAutomation.expenseAccount.kind === 'default' ||
      rawAutomation.expenseAccount.kind === 'expenseCategoryRule' ||
      (rawAutomation.expenseAccount.kind === 'supplierRule' &&
        rawAutomation.expenseAccount.supplierRule)
    ) {
      automation.expenseAccount = rawAutomation.expenseAccount;
    } else if (rawAutomation.expenseAccount.kind === 'prediction') {
      automation.expenseAccount = {
        kind: rawAutomation.expenseAccount.kind,
        expenseAccount: rawAutomation.expenseAccount.expenseAccount ?? {
          id: '',
          name: '',
        },
        confidence: rawAutomation.expenseAccount.confidence ?? 0,
        isAppliedOnPayable: !!rawAutomation.expenseAccount.isAppliedOnPayable,
      };
    }
  }

  if (rawAutomation.expenseAmount) {
    automation.expenseAmount = {
      kind: rawAutomation.expenseAmount.kind,
      confidence: rawAutomation.expenseAmount.confidence,
      isAppliedOnPayable: !!rawAutomation.expenseAmount.isAppliedOnPayable,
    };
  }

  if (rawAutomation.accountPayable) {
    automation.accountPayable = {
      accountPayableMatch: {
        id: rawAutomation.accountPayable.accountPayableMatch.id,
        generalAccountCode:
          rawAutomation.accountPayable.accountPayableMatch.generalAccountCode,
        isDefault: rawAutomation.accountPayable.accountPayableMatch.isDefault,
        isArchived: rawAutomation.accountPayable.accountPayableMatch.isArchived,
      },
      isAppliedOnPayable: !!rawAutomation.accountPayable.isAppliedOnPayable,
    };
  }

  return automation;
}

function reshapeCostCenter(
  costCenter: PayableFromRestApi['costCenter'],
): Payable['costCenter'] {
  if (!costCenter) {
    return undefined;
  }

  return {
    id: costCenter.id,
    name: costCenter.name,
  };
}

function getPayableType({
  type,
  subType,
}: {
  type: PayableFromRestApi['type'];
  subType: PayableFromRestApi['subType'];
}): Payable['type'] {
  switch (type) {
    case 'mileageExpense':
      return 'mileage_allowance';
    case 'perDiemExpense':
      return 'per_diem_allowance';
    case 'claimedBill':
      return 'expense_claim';
    case 'bill':
      return ['physical', 'singlePurchase', 'subscription'].includes(subType)
        ? 'card_purchase'
        : 'invoice_purchase';
    case 'reverseBill':
      return 'reversal';
    default:
      return rejectUnexpectedValue('type', type);
  }
}

function getPayableSubType(
  subType: PayableFromRestApi['subType'],
): Payable['subType'] {
  switch (subType) {
    case 'physical':
      return 'plasticCard';
    case 'singlePurchase':
      return 'singleUseCard';
    case 'subscription':
      return 'subscriptionCard';
    default:
      return undefined;
  }
}

function reshapeCounterparty(
  payable: PayableFromRestApi,
): Payable['counterparty'] {
  if (payable.counterparty.kind === 'member') {
    if (payable.counterparty.email) {
      return {
        id: payable.counterparty.id,
        givenName: payable.counterparty.givenName,
        familyName: payable.counterparty.familyName,
        email: payable.counterparty.email,
        avatar: payable.counterparty.avatar ?? undefined,
      };
    }
    return {
      id: payable.counterparty.id,
      givenName: payable.counterparty.givenName,
      familyName: payable.counterparty.familyName,
      avatar: undefined,
    };
  }
  return {
    id: payable.counterparty.id,
    name: payable.counterparty.name,
    thumbnailUrl: payable.counterparty.thumbnailUrl,
  };
}

function reshapeMember(member: MemberFromApi): Payable['user'] {
  return {
    id: member.id,
    givenName: member.givenName,
    familyName: member.familyName,
    email: member.email ?? '',
    avatar: '',
  };
}

function reshapeItemLines(
  itemLines: PayableFromRestApi['itemLines'],
): Payable['itemLines'] {
  return itemLines.map((itemLine, index) => {
    const expense = {
      amount: itemLine.netAmount,
      expenseAccount: itemLine.expenseAccount
        ? {
            id: itemLine.expenseAccount.id,
            name: itemLine.expenseAccount.name,
          }
        : null,
    };

    const vat = {
      amount: itemLine.vatAmount,
      vatAccount: itemLine.taxAccount
        ? {
            id: itemLine.taxAccount.id,
            name: itemLine.taxAccount.name,
            rate:
              itemLine.taxAccount.type === 'vat'
                ? Number(itemLine.taxAccount.rate)
                : -1,
          }
        : null,
    };

    return {
      id: index,
      vat,
      expense,
      analyticalFieldAssociations: itemLine.analyticalFieldAssociations,
      grossAmount: itemLine.grossAmount,
    };
  });
}

export function reshapeSupplier(
  payable: PayableFromRestApi,
): Payable['supplier'] {
  if (payable.counterparty.kind === 'supplier') {
    return payable.counterparty;
  }

  if (
    payable.commitmentCounterparty &&
    payable.commitmentCounterparty.kind === 'supplier'
  ) {
    return payable.commitmentCounterparty;
  }

  return undefined;
}

export function reshapeCustomFields(
  customFields: CustomFieldFromApi[],
  analyticalFieldAssociationAutomation?: AnalyticalFieldAssociationAutomation[],
): Record<string, string | boolean> {
  const reshapedCustomFields: Record<string, string | boolean> = {};

  customFields?.forEach((customFieldAssociation) => {
    if (isBooleanCustomField(customFieldAssociation)) {
      const { id } = customFieldAssociation.customField;
      const { value } = customFieldAssociation;

      // TODO @finance-accountant: remove this hack
      // We remove automated boolean custom fields as we cannot handle them yet
      // They are only removed from custom fields as they are not splittable
      const isAutomated = analyticalFieldAssociationAutomation?.some(
        (association) =>
          association.fieldEntityId === id && association.isAppliedOnPayable,
      );
      if (!isAutomated) {
        reshapedCustomFields[id] = value;
      }
    } else if (isListCustomField(customFieldAssociation)) {
      const { id } = customFieldAssociation.customField;
      const { values } = customFieldAssociation;
      const { id: value } = values[0];

      reshapedCustomFields[id] = value;
    }
  });

  return reshapedCustomFields;
}

export function isBooleanCustomField(
  customField: CustomFieldFromApi,
): customField is BooleanCustomFieldFromApi {
  return 'value' in customField && customField.value !== undefined;
}

function isListCustomField(
  customField: CustomFieldFromApi,
): customField is ListCustomFieldFromApi {
  return 'values' in customField && customField.values !== undefined;
}
