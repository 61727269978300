import { z } from 'zod';

export type KomboManagedFields = 'firstName' | 'lastName' | 'bic' | 'iban';
export type CompanyKomboConfiguration = {
  automaticArchiving: boolean;
  syncReportingManager: boolean;
  conditions: {
    fieldName: SupportedKomboFilterDimension;
    fieldValues: unknown[];
  }[];
};

const supportedKomboFilterDimension = [
  'legal_entity',
  'work_location', // TODO: https://spendesk.atlassian.net/browse/ECO-325
  'cost_center',
  'department',
  'team',
] as const;

export const supportedKomboFilterDimensionSchema = z.enum(
  supportedKomboFilterDimension,
);

export type SupportedKomboFilterDimension =
  (typeof supportedKomboFilterDimension)[number];

type FilterNameOption = string;
type FilterIdOption = string;

export type FiltersForDimension = {
  dimension: SupportedKomboFilterDimension;
  values: FilterIdOption[];
};

export type KomboFeatures = 'includesBankDetails';

export type CompanyKomboConfigurationReshaped = {
  persistedFilters: FiltersForDimension[] | undefined;
  automaticArchiving: boolean;
  syncReportingManager: boolean;
};
export type FilterValue = {
  komboName: FilterNameOption;
  komboId: FilterIdOption;
};

export type KomboAvailableFilterByDimension = Map<
  SupportedKomboFilterDimension,
  FilterValue[]
>;

export type KomboFiltersResponse = {
  filters: {
    dimension: string;
    values: FilterValue[];
  }[];
};
