/* eslint-disable unicorn/no-await-expression-member */
import { SkeletonText } from '@dev-spendesk/grapes';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

export const LazyPanelPayment = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "PanelPayment", webpackPrefetch: true */ './PanelPayment'
      )
    ).default,
  }),
  loading: (
    <div className="h-full p-m">
      <div className="flex justify-between">
        <SkeletonText size="l" width="35%" />
        <SkeletonText size="l" width="16px" />
      </div>
    </div>
  ),
  fallback: (props) => <EmptyStateError {...props} />,
});
