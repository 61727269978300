import { Link, Panel, RadioBox, RadioGroup } from '@dev-spendesk/grapes';
import { type SetStateAction } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  type KomboAvailableFilterByDimension,
  type Integration,
} from 'src/core/modules/integrations/types';
import { getIntegrationName } from 'src/core/modules/integrations/utils';

import { Filters } from './Filters';
import { type CurrentConfiguration } from '../types';
import { getFilteredDimensionOptionsByIntegrationName } from '../utils';

type Props = {
  integration: Integration;
  checkErrors: boolean;
  setCheckErrors: (value: SetStateAction<boolean>) => void;
  configuration: CurrentConfiguration;
  setConfiguration: (value: SetStateAction<CurrentConfiguration>) => void;
  availableFiltersByDimension: KomboAvailableFilterByDimension;
  syncReportingManagerSelected: boolean;
  setSyncReportingManagerSelected: (value: SetStateAction<boolean>) => void;
};

export const KomboIntegrationConfigurationPanel = ({
  integration,
  checkErrors,
  setCheckErrors,
  configuration,
  setConfiguration,
  availableFiltersByDimension,
  syncReportingManagerSelected,
  setSyncReportingManagerSelected,
}: Props) => {
  const { t } = useTranslation('global');
  const integrationName = getIntegrationName(t, integration.id);
  const dimensionOptions = getFilteredDimensionOptionsByIntegrationName(
    t,
    integration.id,
  );

  return (
    <Panel className="w-full [&>*]:overflow-visible">
      <div className="flex flex-col gap-s">
        <div className="title-l">
          {t('integration.kombo.settings.syncMethods.label')}
        </div>

        <div>
          <RadioGroup
            name="hasFilterList"
            onChange={(event) => {
              setCheckErrors(false);
              setConfiguration(
                event.target.value === 'true'
                  ? {
                      currentFilters: [],
                      hasNewFilter: true,
                    }
                  : {
                      currentFilters: undefined,
                      hasNewFilter: false,
                    },
              );
            }}
            value={configuration.currentFilters ? 'true' : 'false'}
          >
            <RadioBox
              description={t(
                'integration.kombo.settings.syncMethods.all.description',
                {
                  integrationName,
                },
              )}
              iconName="team"
              label={t('integration.kombo.settings.syncMethods.all.title')}
              value="false"
            />
            <RadioBox
              description={t(
                'integration.kombo.settings.syncMethods.filtered.description',
              )}
              iconName="settings"
              label={t('integration.kombo.settings.syncMethods.filtered.title')}
              value="true"
            />
          </RadioGroup>
        </div>

        {configuration.currentFilters ? (
          <>
            <div className="title-l">
              {t('integration.kombo.settings.filters.label')}
            </div>
            <div className="rounded-xxs bg-neutral-lightest p-s">
              <div className="w-full grow pb-s text-neutral-dark">
                <Trans i18nKey="integration.kombo.settings.filters.subtitle.text">
                  text
                  <Link
                    className="text-complementary"
                    isExternal
                    href={t(
                      'integration.kombo.settings.filters.subtitle.helpCenterUrl',
                    )}
                  >
                    link
                  </Link>
                </Trans>
              </div>
              <Filters
                availableFiltersByDimension={availableFiltersByDimension}
                dimensionOptions={dimensionOptions}
                currentFilters={configuration.currentFilters}
                hasNewFilter={configuration.hasNewFilter}
                setState={setConfiguration}
                setCheckErrors={setCheckErrors}
                checkErrors={checkErrors}
                syncReportingManagerSelected={syncReportingManagerSelected}
                setSyncReportingManagerSelected={
                  setSyncReportingManagerSelected
                }
              />
            </div>
          </>
        ) : null}
      </div>
    </Panel>
  );
};
