import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from "src/core/common/components/legacy/Icon/Icon";

import './ToolbarAction.scss';

const ToolbarAction = ({ icon, text, onClick, disabled, isLoading }) => (
  <button
    type="button"
    className={cx('LegacyToolbarAction', {
      'LegacyToolbarAction--loading': isLoading,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon
      icon={icon}
      fill="currentColor"
      viewBox="0 0 18 18"
      width={16}
      height={16}
      className="LegacyToolbarAction__icon"
    />
    <span className="LegacyToolbarAction__text">{text}</span>
  </button>
);

ToolbarAction.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ToolbarAction.defaultProps = {
  disabled: false,
  isLoading: false,
};

export default ToolbarAction;
