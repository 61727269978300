import { createAction } from '@reduxjs/toolkit';

import * as types from './actionTypes';
import * as slackTypes from '../types/SlackAnnouncementSplash/actions';

export const openSplash = createAction(types.OPEN_SPLASH);
export const closeSplash = createAction(types.CLOSE_SPLASH);

/**
 * The purpose of this action – which is executed at the bootstrap of the app –
 * is to determine if we have a SplashNotif to show, and if so,
 * which one to display.
 *
 * As for now, we only have one SplashNotif that may be shown at the app's launch,
 * (Slack annoucement), but we could have several other ones. It's important to
 * check here which one to show in priority.
 */
export const initializeSplashPopupCenter = () => async (dispatch) => {
  dispatch(slackTypes.checkSlackNotifEligibility(openSplash));
};
