import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { isQueryError } from 'src/core/api/queryError';

import { type PurchaseOrderRequestFormValues } from '../../../../../hooks';
import {
  isFormValidationError,
  type UpdatePurchaseOrderRequestError,
} from '../../../../hooks';

type Props = {
  error: Error;
};

export const UpdateError = ({ error }: Props) => {
  const { t } = useTranslation('global');

  if (isQueryError<UpdatePurchaseOrderRequestError>(error)) {
    return (
      <QueryError
        queryError={error}
        componentType="Callout"
        translations={{
          requestError: updatePurchaseOrderRequestErrorToTranslation,
          serverError: 'requests.panel.purchaseOrder.unknownUpdateError',
        }}
        logContext={{
          team: 'employee',
        }}
      />
    );
  }

  if (isFormValidationError(error)) {
    const fieldsTranslated = error.fields.map((field) =>
      t(purchaseOrderFieldToTranslation(field)),
    );
    return (
      <Callout
        variant="alert"
        title={t('requests.panel.purchaseOrder.invalidRequestFields', {
          values: fieldsTranslated.join(', '),
        })}
      />
    );
  }

  return (
    <Callout
      variant="alert"
      title={t('requests.panel.purchaseOrder.invalidRequest')}
    />
  );
};

const purchaseOrderFieldToTranslation = (
  field: keyof PurchaseOrderRequestFormValues,
): I18nKey => {
  switch (field) {
    case 'amount':
      return 'misc.amount';
    case 'currency':
      return 'misc.currency';
    case 'description':
      return 'misc.description';
    case 'costCenter':
      return 'misc.costCenter';
    case 'customFieldAssociations':
      return 'misc.customFieldAssociations';
    case 'endDate':
      return 'misc.endDate';
    case 'startDate':
      return 'misc.startDate';
    case 'expenseCategory':
      return 'misc.expenseCategory';
    case 'supplier':
      return 'misc.supplier';
    case 'team':
      return 'misc.team';
    default:
      // in this case we display the untranslated field
      return field as I18nKey;
  }
};

const updatePurchaseOrderRequestErrorToTranslation = (
  updatePurchaseOrderRequestError: UpdatePurchaseOrderRequestError,
): I18nKey => {
  switch (updatePurchaseOrderRequestError.reason) {
    case 'startDateCannotBeAfterEndDate':
      return 'requests.panel.purchaseOrder.errors.startDateCannotBeAfterEndDate';
    case 'dateIntervalTooLong':
      return 'requests.panel.purchaseOrder.errors.dateIntervalTooLong';
    case 'startDateTooOld':
      return 'requests.panel.purchaseOrder.errors.startDateTooOld';
    case 'invalidStartDate':
    case 'invalidEndDate':
      return 'requests.panel.purchaseOrder.errors.invalidDates';
    case 'invalidRequestState':
      return 'requests.panel.purchaseOrder.errors.invalidRequestState';
    case 'missingPurchaseOrderFeature':
      return 'requests.panel.purchaseOrder.errors.missingPurchaseOrderFeature';
    case 'invalidPayload':
      return 'requests.panel.purchaseOrder.invalidRequest';
    default:
      return 'requests.panel.purchaseOrder.unknownUpdateError';
  }
};
