/**
 * Avoid creating ugly switch/case reducers. Usage;
 *
 * const fetchUserLoading = (state, action) => ({ ...state, loading: true });
 * const fetchUserError = (state, action) => ({ ...state, loading: false, error: action.payload });
 * const fetchUserSUccess = (state, action) => ({ ...state, loading: false, user: action.payload });
 *
 * const initialState = {
 *   loading: false,
 *   error: null,
 *   user: null,
 * };
 *
 * export default createReducer({
 *   [types.FETCH_USER_LOADING]: fetchUserLoading,
 *   [types.FETCH_USER_ERROR]: fetchUserError,
 *   [types.FETCH_USER_SUCCESS]: fetchUserSUccess,
 * }, initialState);
 */
// eslint-disable-next-line unicorn/no-anonymous-default-export
export default (handlers = {}, initialState = {}) =>
  (state = initialState, action) => {
    if (typeof handlers[action.type] === 'function') {
      return handlers[action.type].call(this, state, action);
    }
    return state;
  };
