import { DATE_FORMAT } from '@dev-spendesk/grapes';
import cx from 'classnames';
import compact from 'lodash/compact';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableFilters, {
  filtersTypes,
} from 'src/core/common/components/legacy/TableLegacy/TableFilters';
import { withTranslation } from 'src/core/common/components/withTranslation';
import FEATURES from 'src/core/constants/features';
import {
  getIsFeatureEnabled,
  getAreVatAccountsEnabled,
} from 'src/core/selectors/globalSelectors';
import { formatMoney } from 'src/core/utils/money';
import Validations from 'src/core/utils/validations';

class PaymentsFilters extends Component {
  static propTypes = {
    isCustomFieldsFeatureEnabled: PropTypes.bool.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    teams: PropTypes.arrayOf(PropTypes.object),
    customFields: PropTypes.arrayOf(PropTypes.object),
    expenseAccounts: PropTypes.arrayOf(PropTypes.object),
    isPanelOpen: PropTypes.bool,
    toggleFiltersPanel: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    params: PropTypes.object,
    fetchCustomFields: PropTypes.func,
    t: PropTypes.func.isRequired,
    localeFormat: PropTypes.func.isRequired,
    hasSubNav: PropTypes.bool,
  };

  static defaultProps = {
    params: {},
    fetchCustomFields: noop,
    users: undefined,
    teams: undefined,
    customFields: undefined,
    expenseAccounts: undefined,
    isPanelOpen: undefined,
    filters: undefined,
    hasSubNav: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: this.hydrateFiltersValuesFromParams(
        this.getStateFilters(props),
        props.params,
      ),
      hasFetchedData: false,
    };
  }

  componentDidMount() {
    if (
      this.props.isPanelOpen ||
      !isEmpty(omit(this.props.params, '_isFromUrl'))
    ) {
      this.fetchData();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const changes = {};
    let nextFilters = this.props.filters;

    if (this.props.teams !== nextProps.teams) {
      changes.teams = nextProps.teams;
    }

    if (this.props.users !== nextProps.users) {
      changes.users = nextProps.users;
    }

    if (this.props.expenseAccounts !== nextProps.expenseAccounts) {
      changes.expenseAccounts = this.formatExpenseAccounts(
        nextProps.expenseAccounts,
      );
    }

    if (this.props.customFields !== nextProps.customFields) {
      changes.customFields = nextProps.customFields;
    }

    if (nextProps.filters && this.props.filters !== nextProps.filters) {
      changes.filters = nextProps.filters;
      nextFilters = nextProps.filters;
    }

    if (!this.props.isPanelOpen && nextProps.isPanelOpen) {
      this.fetchData();
    }

    if (!isEmpty(changes)) {
      this.setState({
        ...changes,
        filters: this.getStateFilters(changes, nextFilters),
      });
    }
  }

  onSearch = (textSearch) => {
    if (trim(textSearch) !== trim(this.state.filters.textSearch.value)) {
      const newFilters = this.getStateFilters(
        { textSearch },
        { ...this.props.filters, textSearch: undefined },
      );

      this.setState({ filters: newFilters }, () => {
        this.props.updateFilters(newFilters);
      });
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getStateFilters = ({ teams, users, textSearch }, nextFilters) => {
    const { customFields, user, company, t, localeFormat } = this.props;
    const currentState = this.state || {};

    const userValues =
      Array.isArray(users) && users.length ? users : currentState.users;
    const teamValues =
      Array.isArray(teams) && teams.length ? teams : currentState.teams;

    // Get eligible filtrable CFs & curried tag name func
    const customFieldFilters = TableFilters.getCustomFieldsFilters(
      customFields,
      user,
      company,
      ['payment', 'expense'],
      (cfId) => `cf_${cfId}`,
      t,
    );
    const selectFilterTagValue = (key) => (value, filtersProps) => {
      // Directly chosen
      if (isObject(value) && value.name && value.key) {
        return { filterKey: key, valueKey: value.key, valueName: value.name };
      }
      // From URL
      if (isString(value)) {
        const found =
          filtersProps[key] &&
          find(filtersProps[key].collection, { key: value });
        return found
          ? { filterKey: key, valueKey: found.key, valueName: found.name }
          : false;
      }
      if (Array.isArray(value)) {
        return map(value, (v) => {
          const found =
            filtersProps[key] &&
            find(filtersProps[key].collection, { key: v.key });
          return found
            ? { filterKey: key, valueKey: found.key, valueName: found.name }
            : false;
        });
      }
      return false;
    };

    let filters = {
      supplier: {
        type: filtersTypes.SUPPLIER,
        label: t('forms.supplier.label'),
        tagObjectFromValue: (value) =>
          value && value.key && value.name
            ? {
                filterKey: 'supplier',
                valueKey: value.key,
                valueName: value.name,
              }
            : false,
        allowMultipleValues: true,
      },
      type: {
        type: filtersTypes.SELECT,
        label: t('forms.paymentType.label'),
        collection: [
          {
            key: 'single_purchase',
            name: t('forms.paymentType.singlePurchase'),
          },
          { key: 'subscription', name: t('forms.paymentType.subscription') },
          { key: 'physical', name: t('forms.paymentType.plasticCard') },
          { key: 'expense', name: t('forms.paymentType.expense') },
          {
            key: 'mileage_allowance',
            name: t('forms.paymentType.mileageAllowance'),
          },
          { key: 'invoice', name: t('forms.paymentType.invoice') },
          {
            key: 'per_diem_allowance',
            name: t('requests.types.per_diem_allowance'),
          },
        ],
        sortValues: false,
        tagObjectFromValue: selectFilterTagValue('type'),
        allowMultipleValues: true,
      },
      payer: {
        type: filtersTypes.SELECT,
        label: t('forms.payer.label'),
        collection: this.formatPayers(userValues),
        tagObjectFromValue: selectFilterTagValue('payer'),
        allowMultipleValues: true,
      },
      team: {
        type: filtersTypes.SELECT,
        label: t('forms.team.label'),
        collection: this.formatTeams(teamValues),
        tagObjectFromValue: selectFilterTagValue('team'),
        allowMultipleValues: true,
      },
      period: {
        type: filtersTypes.DATE,
        label: t('forms.period.label'),
        tagObjectFromValue: (value) => {
          let valueName = value;
          if (isString(value) && Validations.validateDateRange(value)) {
            const [start, end] = value.split('/');
            valueName = `${localeFormat(
              new Date(start),
              DATE_FORMAT.SHORT,
            )} - ${localeFormat(new Date(end), DATE_FORMAT.SHORT)}`;
          }
          return { filterKey: 'period', valueKey: value, valueName };
        },
        allowMultipleValues: true,
      },
      amount: {
        type: filtersTypes.NUMBER_RANGE,
        label: t('forms.amount.label'),
        tagObjectFromValue: (value) => {
          const { currency } = this.props.company;
          const [min, max] = value ? value.split('/') : [];
          let valueName;
          if (!isNaN(Number(min)) && !isNaN(Number(max))) {
            valueName = `${formatMoney(min, currency)} - ${formatMoney(
              max,
              currency,
            )}`;
          } else if (isNaN(Number(min))) {
            valueName = `< ${formatMoney(max, currency)}`;
          } else {
            valueName = `> ${formatMoney(min, currency)}`;
          }

          return {
            filterKey: 'amount',
            valueKey: value,
            valueName,
          };
        },
        allowMultipleValues: true,
      },
      invoice: {
        type: filtersTypes.SELECT,
        label: t('forms.receipt.label'),
        collection: [
          { key: 'provided', name: t('forms.receipt.provided') },
          { key: 'missing', name: t('forms.receipt.missing') },
          { key: 'cannot_provide', name: t('forms.receipt.cannotProvide') },
        ],
        sortValues: false,
        tagObjectFromValue: selectFilterTagValue('invoice'),
        allowMultipleValues: true,
      },
    };

    filters = {
      ...filters,
      textSearch: {
        value: textSearch,
      },
      completionDeadline: {
        value: nextFilters?.completionDeadline,
        tagObjectFromValue: (value) => {
          if (value && value === 'late') {
            return {
              filterKey: 'completionDeadline',
              valueKey: value,
              valueName: t('payments.lateReceipts'),
            };
          }
          return null;
        },
      },
    };

    if (this.props.isCustomFieldsFeatureEnabled) {
      filters = {
        ...filters,
        ...customFieldFilters,
      };
    }

    // All payments filters
    filters.status = {
      type: filtersTypes.SELECT,
      label: t('forms.paymentStatus.label'),
      collection: [
        { key: 'authorised', name: t('forms.paymentStatus.pending') },
        { key: 'settled', name: t('forms.paymentStatus.paid') },
      ],
      sortValues: false,
      tagObjectFromValue: selectFilterTagValue('status'),
    };

    if (nextFilters && nextFilters._isFromUrl) {
      return this.hydrateFiltersValuesFromParams(filters, nextFilters);
    }
    return merge({}, nextFilters, filters);
  };

  fetchData = () => {
    if (this.state.hasFetchedData) {
      return;
    }
    this.setState({ hasFetchedData: true }, () => {
      this.props.fetchCustomFields();
    });
  };

  formatTeams = (teams = []) => {
    const { t } = this.props;
    return [
      { key: null, name: t('forms.team.none') },
      ...map(teams, (g) => ({ key: g.id, name: g.name })),
    ];
  };

  formatPayers = (users = []) =>
    map(users, (u) => ({ key: u.id, name: u.fullname }));

  formatExpenseAccounts = (expenseAccounts) => {
    return map(expenseAccounts, (expenseAccount) => ({
      key: expenseAccount.id,
      name: expenseAccount.name,
    }));
  };

  hydrateFiltersValuesFromParams = (filters, params) => {
    return reduce(
      keys(params),
      // eslint-disable-next-line sonarjs/cognitive-complexity
      (result, key) => {
        // Prefill value only if not filled yet
        if (!!params[key] && filters[key] && !filters[key].value) {
          // There is only one value specified for the key in the URL params
          if (isString(params[key])) {
            // If the filter is a list w/ a collection of choices, find matching one
            if (filters[key]?.collection?.length) {
              const matchingValue = find(filters[key].collection, {
                key: params[key],
              });
              if (matchingValue) {
                return merge({}, result, { [key]: { value: matchingValue } });
              }
            } else if (!filters[key]?.collection) {
              // Otherwise, set it directly
              return merge({}, result, { [key]: { value: params[key] } });
            }
          } else if (Array.isArray(params[key])) {
            const matchingValues = compact(
              map(
                params[key],
                (parameter) =>
                  find(filters[key].collection, { key: parameter }) || null,
              ),
            );

            if (matchingValues) {
              return merge({}, result, { [key]: { value: matchingValues } });
            }
          }
        }
        return result;
      },
      filters,
    );
  };

  render() {
    const { filters } = this.state;
    return (
      <TableFilters
        filters={filters}
        customFields={this.props.customFields}
        company={this.props.company}
        history={this.props.history}
        onSearch={this.onSearch}
        applyFilters={this.props.updateFilters}
        toggleFiltersPanel={this.props.toggleFiltersPanel}
        defaultOpenFilters={Object.keys(this.props.params || {})}
        isPanelOpen={this.props.isPanelOpen}
        type="payment"
        className={cx({ 'Tablefilters--no-subnav': !this.props.hasSubNav })}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isCustomFieldsFeatureEnabled: getIsFeatureEnabled(
    state,
    FEATURES.CUSTOM_FIELDS,
  ),
  areVatAccountsEnabled: getAreVatAccountsEnabled(state),
});

export default connect(mapStateToProps)(
  withTranslation('global')(PaymentsFilters),
);
