import cx from 'classnames';
import type { i18n as nativeI18n, TFunction } from 'i18next';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import map from 'lodash/map';
import noop from 'lodash/noop';
import reject from 'lodash/reject';
import size from 'lodash/size';
import trim from 'lodash/trim';
import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';

import { SupplierLogo } from 'common/components/SupplierLogo';
import { companyAPI } from 'src/core/api/axios';

import './InputSupplierRedesigned.scss';

const findMatchingValue = (
  values: ({
    id: string;
    name: string;
  } | null)[],
  searchValue: string,
) => {
  return find(
    values,
    (value) => value?.name?.toLowerCase() === trim(searchValue).toLowerCase(),
  );
};

type Props = {
  // This is supposed to be the SUPPLIER object, but no one really knows how
  // it looks like. It seems we can pass a supplier name directly as a string,
  // but it seems it could be a supplier ID too. An other option seems to be
  // passing an object with at least a name property.
  value?:
    | string
    | {
        name: string | null;
        id?: string | null;
      }
    | null;
  companyId: string;
  placeholder?: string;
  blacklistedSupplierIds?: string[];
  disabled?: boolean;
  autoFocus?: boolean;
  allowNewBtn?: boolean; // whether we allow the user to add a new one
  onSupplierSelected?: (supplier: { id: string; name: string }) => void; // needed to pick an already existing supplier, it receives the full supplier object as returned by the API which most important properties are id and name
  onRawSupplierEntered?: (searchValue: string) => void; // needed to create a new supplier
  onNewValueSelected?: (supplierName: string) => void; // needed to create a new supplier, it receives the raw supplier name from the <input/> as a string
  isValid?: boolean;
  invoiceSuppliers?: boolean;
  withBrands?: boolean;
  onBlur?: (
    searchValue: string,
    matchingValue?: {
      id: string;
      name: string;
    },
  ) => void; // needed to create a new supplier on blur
  t: TFunction<'global'>;
  tReady: boolean;
  i18n: nativeI18n;
};

type Supplier = {
  id?: string | undefined | null;
  name: string | null;
};

type State = {
  supplier: Supplier | null;
  values: ({ name: string; id: string } | null)[];
  isFocused: boolean;
  isSupplierListOpen: boolean;
  keyboardSelectedItemId: number;
  searchValue: string;
};

/**
 * FIXME: This is an horror: props make no sense, no one knows how it works,
 * it tries to do too many things and at the same time doesn't do the most
 * important things, plus it has a shitty name.
 */
class InputSupplierRedesigned extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
    autoFocus: false,
    allowNewBtn: false,
    onSupplierSelected: noop,
    onRawSupplierEntered: noop,
    onNewValueSelected: noop,
    blacklistedSupplierIds: [],
    isValid: true,
    onBlur: noop,
    invoiceSuppliers: false,
    withBrands: true,
  };

  inputRef = createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      supplier: !props.value || isString(props.value) ? null : props.value,
      values: [],
      isFocused: false,
      isSupplierListOpen: false,
      keyboardSelectedItemId: -1,
      searchValue: isObject(props.value) ? props.value.name || '' : '',
    };

    // Immediately fetch supplier details if an ID is provided
    if (props.value && isString(props.value)) {
      this.fillSupplier(props.value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // Allow the user to reset the supplier field
    if (nextProps && this.props.value && !nextProps.value) {
      this.setState({
        searchValue: '',
        supplier: null,
      });
    } else if (nextProps.value && nextProps.value !== this.props.value) {
      if (isString(nextProps.value)) {
        this.fillSupplier(nextProps.value, () => ({
          searchValue: isObject(nextProps.value) ? nextProps.value.name : '',
        }));
      } else {
        this.setState({
          supplier: nextProps.value,
          searchValue: isObject(nextProps.value)
            ? nextProps.value.name || ''
            : '',
        });
      }
    }
  }

  fillSupplier = async (supplierId: string, callback?: () => void) => {
    const { companyId } = this.props;
    const { data: supplier } = await companyAPI.get(
      `/suppliers/${supplierId}`,
      { companyId },
    );
    if (!supplier) {
      return null;
    }
    // Set value that we just grabbed, and tell the Filters in order
    // to display the proper tag
    this.props.onSupplierSelected?.(supplier);
    this.setState({ supplier }, callback);
    return supplier;
  };

  search = async (search: string) => {
    // Tell the parent component we searched for a raw value
    // (in case we need to create a new supplier)
    this.props.onRawSupplierEntered?.(search);
    const { companyId, invoiceSuppliers, withBrands } = this.props;
    const url = `/suppliers?search=${encodeURIComponent(
      search,
    )}&is_invoice_payable=${invoiceSuppliers}&withBrands=${withBrands}`;
    try {
      const { data: suppliers } = await companyAPI.get(url, {
        companyId,
      });
      const { blacklistedSupplierIds } = this.props;
      if (!size(blacklistedSupplierIds)) {
        this.setState({ values: suppliers });
      }
      return this.setState({
        values: reject(suppliers, (s) =>
          includes(blacklistedSupplierIds, s.id),
        ),
      });
    } catch {
      return this.setState({ values: [] });
    }
  };

  searchSuppliersDebounced = debounce(this.search, 700);

  searchSuppliers = (searchValue = '') => {
    this.setState({
      searchValue,
      supplier: null,
      isSupplierListOpen: true,
    });

    if (searchValue) {
      // Search for the supplier w/ API
      this.searchSuppliersDebounced(searchValue);
    }
  };

  blurInput = () => {
    if (this.inputRef) {
      this.inputRef.current?.blur();
    }
  };

  selectSupplier = (
    supplier: Supplier | null,
    keyboardSelectedItemId: number,
  ) => {
    // close the list and unfocused because mouse down event didn't fire blur
    let stateChanges: State = {
      ...this.state,
      isFocused: false,
      isSupplierListOpen: false,
    };

    if (supplier) {
      stateChanges = {
        ...stateChanges,
        supplier,
        keyboardSelectedItemId,
        isSupplierListOpen: false,
      };
    }

    this.setState(stateChanges);
    if (supplier && supplier.id && supplier.name) {
      this.props.onSupplierSelected?.({ id: supplier.id, name: supplier.name });
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { values, keyboardSelectedItemId } = this.state;

    const navigationEvents = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Enter: () => {
        // Enter key
        e.preventDefault();
        const supplier = get(values, keyboardSelectedItemId);
        if (supplier) {
          this.selectSupplier(supplier, keyboardSelectedItemId);
        } else if (this.props.allowNewBtn) {
          // If the selected item from the list is "Create new xx",
          // propagate new creation action
          this.setState({ isSupplierListOpen: false }, () => {
            this.props.onNewValueSelected?.(this.state.searchValue);
          });
        }
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ArrowDown: () => {
        // Arrow down key
        const selectedId =
          keyboardSelectedItemId < values.length - 1
            ? keyboardSelectedItemId + 1
            : 0;
        this.setState({ keyboardSelectedItemId: selectedId });
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ArrowUp: () => {
        // Arrow up key
        const selectedId =
          keyboardSelectedItemId > 0
            ? keyboardSelectedItemId - 1
            : values.length - 1;
        this.setState({ keyboardSelectedItemId: selectedId });
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Escape: this.blurInput, // Escape key
    } as const;

    if (Object.getOwnPropertyDescriptor(navigationEvents, e.code)) {
      navigationEvents[e.code as keyof typeof navigationEvents]();
    }
  };

  handleBlur = () => {
    this.setState({
      isFocused: false,
      isSupplierListOpen: false,
    });

    const { searchValue, values } = this.state;
    const matchingValue = findMatchingValue(values, searchValue);
    if (matchingValue) {
      // returns the search value + the existing value matching the search
      this.props.onBlur?.(searchValue, matchingValue);
      return;
    }
    this.props.onBlur?.(searchValue);
  };

  renderSupplierLogo = () => {
    const { supplier, searchValue } = this.state;
    return (
      <SupplierLogo
        name={get(supplier, 'name', searchValue) || undefined}
        className="m-xs"
      />
    );
  };

  renderSupplierChoices = () => {
    const choices = map(this.state.values, (value, index) => (
      // FIXME: Avoid click handler on li; use button instead
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        key={value?.id}
        className={cx('InputSupplierRedesigned__choices-item', {
          active: this.state.keyboardSelectedItemId === index,
        })}
        onClick={() => this.selectSupplier(value, index)}
        onMouseDown={(e) => e.preventDefault()} // Prevent firing a blur event on input before the click event is processed
      >
        {value?.name}
      </li>
    ));

    // Eventually add "Add new XXX option"
    if (
      this.props.allowNewBtn &&
      !isEmpty(this.state.searchValue) &&
      !findMatchingValue(this.state.values, this.state.searchValue)
    ) {
      choices.push(
        // FIXME: Avoid click handler on li; use button instead
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          key={-1}
          className={cx('InputSupplierRedesigned__choices-item add-new', {
            active: this.state.keyboardSelectedItemId === size(choices),
          })}
          onMouseDown={(e) => e.preventDefault()} // Prevent firing a blur event on input before the click event is processed
          onClick={() => {
            this.props.onNewValueSelected?.(this.state.searchValue);
            this.setState({ isSupplierListOpen: false });
          }}
        >
          {this.props.t('suppliers.createNew', {
            supplier: this.state.searchValue,
          })}
        </li>,
      );
    }

    return choices;
  };

  render() {
    const { values, searchValue, isFocused, isSupplierListOpen } = this.state;
    const { placeholder, isValid, disabled, allowNewBtn, autoFocus, t } =
      this.props;
    const hasResults = isSupplierListOpen && values && !!values.length;
    const classes = cx('InputSupplierRedesigned', {
      open:
        hasResults || (isSupplierListOpen && allowNewBtn && size(searchValue)),
      focus: isFocused,
      invalid: !isValid,
      disabled,
    });

    return (
      <div className={classes}>
        <div className="InputSupplierRedesigned__input">
          <div className="InputSupplierRedesigned__input-logo">
            {this.renderSupplierLogo()}
          </div>
          <div className="InputSupplierRedesigned__input-text">
            <input
              type="text"
              ref={this.inputRef}
              placeholder={placeholder || t('forms.supplier.placeholder')}
              value={searchValue}
              onChange={(e) => this.searchSuppliers(e.target.value)}
              onKeyDown={this.handleKeyDown}
              onFocus={() => this.setState({ isFocused: true })}
              onBlur={this.handleBlur}
              disabled={disabled}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
            />
          </div>
        </div>
        <ul className="InputSupplierRedesigned__choices">
          {this.renderSupplierChoices()}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(InputSupplierRedesigned);
