import cx from 'classnames';
import { type CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import replace from 'lodash/replace';
import React, { Component } from 'react';

/**
 * Cleave doesn't have the ability to render a custom input component so we re-use the DOM, styles & props from
 * TextInput.
 */
import TextInput from 'src/core/common/components/legacy/TextInput/TextInput';
import { IBANCountries } from 'src/core/config/country';

/**
 * IMPORTANT: MaskedInput should only be used as a controlled input.
 * Masks objects are just a set of Cleave options, check out their docs if you need to edit them or add a new one.
 * (see: https://github.com/nosir/cleave.js/blob/master/doc/options.md)
 * We're not using phone mask because the setup to handle multiple locales is a bit complicated, but that's something we
 * could do if needed.
 * IMPORTANT: you need to set a React `key` on the component instance if you want to switch the mask dynamically,
 * otherwise the component doesn't update.
 */
const IBANCountriesMasks = mapValues(IBANCountries, (country) => ({
  ...country,
  uppercase: true,
}));

/**
 * If you don't specify a `delimiter`, it's assumed to be a whitespace character.
 * For Cleave's "shortcuts" (date, credit card, numeral, etc...), there is generally a default delimiter, but since we
 * can't access it externally, you should set the delimiter yourself. If you don't, the unsmasking of the value will not
 * work correctly and your `onChange` and `onBlur` handler will receive the masked value instead of the raw unmasked one
 */
export const MASKS = {
  DATE: {
    date: true,
    datePattern: ['d', 'm', 'Y'],
    delimiter: '/',
  },
  US_DATE: {
    date: true,
    datePattern: ['m', 'd', 'Y'],
    delimiter: '/',
  },
  MONTH_YEAR: {
    date: true,
    datePattern: ['m', 'Y'],
    delimiter: '-',
  },
  STATE: {
    blocks: [2],
  },
  IBAN: IBANCountriesMasks,
  SORT_CODE: {
    blocks: [2, 2, 2],
    delimiter: '-',
  },
  ACCOUNT_NUMBER: {
    blocks: [20],
  },
  ACCOUNT_NUMBER_UK: {
    blocks: [8],
  },
  BIC: {
    blocks: [11],
  },
  CUC: {
    blocks: [10],
  },
  ROUTING_NUMBER: {
    blocks: [9],
  },
  ACCOUNT_CODE: {
    blocks: [26],
  },
};

/**
 * Unmask the value (i.e. remove delimiter characters).
 *
 * @param {String} value The input value.
 * @param {Object} mask The mask object.
 * @returns {String} The unmasked value.
 */
export const unmaskValue = (value: string, mask: CleaveOptions) => {
  let delimiter = mask.delimiter || '\\s';

  if (delimiter === '.') {
    delimiter = '\\.';
  }

  return replace(value, new RegExp(delimiter, 'gi'), '');
};

type Props = {
  mask: CleaveOptions; // concat(values(MASKS), values(IBANCountriesMasks));
  value?: string;
  onChange?: (e: string) => void;
  onBlur?: (e: string) => void;
  onFocus?: (e: { target: HTMLInputElement }) => void;
  name?: string;
  id?: string;
  type?: string;
  placeholder?: string;
  withPrefix?: boolean;
  size?: 'small' | 'medium' | 'large' | 'block';
  isInvalid?: boolean;
  hasWarning?: boolean;
  className?: string;
  disabled?: boolean;
};

class MaskedInput extends Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any = null;

  static defaultProps = {
    value: '',
    onChange: noop,
    onBlur: noop,
    onFocus: noop,
    name: null,
    id: null,
    type: null,
    placeholder: null,
    size: TextInput.defaultProps.size,
    withPrefix: true,
    isInvalid: false,
    hasWarning: false,
    className: null,
    disabled: false,
  };

  // We use an internal state to store the formatted value, and avoid unexpected
  // position change of the cursor in the input (unexpected jumps to the end)
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const unmaskedValue = unmaskValue(e.target.value, this.props.mask);
    this.setState({ value }, () => {
      if (this.props.onChange === undefined) {
        return;
      }
      this.props.onChange(unmaskedValue);
    });
  };

  handleBlur = (e: { target: HTMLInputElement }) => {
    const rawValue = unmaskValue(e.target.value, this.props.mask);
    if (!this.props.onBlur) {
      return;
    }
    this.props.onBlur(rawValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRef = (ref: any) => {
    this.inputRef = ref;
  };

  // To use this method, get a ref first
  focus() {
    if (this.inputRef) {
      return this.inputRef?.focus();
    }
  }

  render() {
    const {
      name,
      id,
      type,
      placeholder,
      mask,
      size,
      withPrefix,
      isInvalid,
      hasWarning,
      onFocus,
      className,
      disabled,
    } = this.props;

    const options = withPrefix ? mask : omit(mask, ['prefix']);

    return (
      <div
        className={cx('MaskedInput', 'text-input__container', size, className)}
      >
        <div>
          <Cleave
            name={name}
            id={id ?? name}
            type={type}
            value={this.state.value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={onFocus}
            className={cx(size, {
              invalid: isInvalid,
              'has-warning': hasWarning,
            })}
            placeholder={placeholder}
            options={options}
            htmlRef={this.setRef}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

export default MaskedInput;
