import { ModalSlideshow } from '@dev-spendesk/grapes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { type AppDispatch } from 'modules/app/redux/store';
import { markOnboardingPopupAsSeen } from "src/core/actions/global";
import { getShowRequesterOnboardingPopup } from "src/core/selectors/globalSelectors";

import CardsSpendControl from './assets/cards-spend-control.png';
import MobileAppExpenses from './assets/mobile-app-expenses.png';
import SpendeskDesktop from './assets/spendesk-desktop.png';
import TransactionHistory from './assets/transaction-history.png';
import VirtualCard from './assets/virtual-card.png';

export const NewRequestModalSlideshow = () => {
  const { t } = useTranslation('global');
  const showRequesterOnboardingPopup = useSelector(
    getShowRequesterOnboardingPopup,
  );
  const dispatch = useDispatch<AppDispatch>();

  const close = () => {
    dispatch(markOnboardingPopupAsSeen('REQUESTER_SLIDESHOW'));
  };

  return (
    <ModalSlideshow
      slides={[
        {
          title: t('requests.requesterOnboardingModal.step1.title'),
          content: t('requests.requesterOnboardingModal.step1.content'),
          illustration: <img src={SpendeskDesktop} alt="" className="w-full" />,
          illustrationHeight: '320px',
        },
        {
          title: t('requests.requesterOnboardingModal.step2.title'),
          content: t('requests.requesterOnboardingModal.step2.content'),
          illustration: (
            <img src={CardsSpendControl} alt="" className="w-full" />
          ),
          illustrationHeight: '320px',
        },
        {
          title: t('requests.requesterOnboardingModal.step3.title'),
          content: t('requests.requesterOnboardingModal.step3.content'),
          illustration: <img src={VirtualCard} alt="" className="w-full" />,
          illustrationHeight: '320px',
        },
        {
          title: t('requests.requesterOnboardingModal.step4.title'),
          content: t('requests.requesterOnboardingModal.step4.content'),
          illustration: (
            <img src={TransactionHistory} alt="" className="w-full" />
          ),
          illustrationHeight: '320px',
        },
        {
          title: t('requests.requesterOnboardingModal.step5.title'),
          content: t('requests.requesterOnboardingModal.step5.content'),
          illustration: (
            <img src={MobileAppExpenses} alt="" className="w-full" />
          ),
          illustrationHeight: '320px',
        },
      ]}
      isOpen={showRequesterOnboardingPopup}
      translations={{
        cancel: t('misc.cancel'),
        previous: t('misc.back'),
        next: t('misc.next'),
        done: t('requests.requesterOnboardingModal.finalCta'),
      }}
      onCancel={close}
      onClose={close}
      onDone={close}
    />
  );
};
