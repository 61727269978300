import { type FormikErrors } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { COUNTRIES } from 'src/core/config/country';
import { type CurrenciesKey } from 'src/core/config/money';
import { BankFields, getBankInfoErrors } from 'src/core/utils/bankInfoFormats';

import { getShouldFillBankInfoCompletely } from './helpers';
import { type BankDetailsFormValues } from '../../types';

const bankInfoKeyToErrorTranslationKey = (
  bankInfoKey:
    | 'iban'
    | 'bicSwift'
    | 'sortCode'
    | 'routingNumber'
    | 'accountNumber'
    | 'accountCode',
): string => {
  switch (bankInfoKey) {
    case 'iban':
      return 'bookkeep.accountsPayable.panel.bankDetailsSection.ibanError';
    case 'bicSwift':
      return 'bookkeep.accountsPayable.panel.bankDetailsSection.bicSwiftError';
    case 'sortCode':
      return 'bookkeep.accountsPayable.panel.bankDetailsSection.sortCode.error';
    case 'accountNumber':
      return 'bookkeep.accountsPayable.panel.bankDetailsSection.accountNumberError';
    case 'accountCode':
      return 'bookkeep.accountsPayable.panel.bankDetailsSection.accountCodeError';
    case 'routingNumber':
      return 'bookkeep.accountsPayable.panel.bankDetailsSection.routingNumberError';
    default:
      throw new Error(`Unknown bank info key ${bankInfoKey}`);
  }
};

export const validateForm = (
  company: { currency: CurrenciesKey; country: string },
  values: BankDetailsFormValues,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): FormikErrors<BankDetailsFormValues> => {
  const errors: FormikErrors<BankDetailsFormValues> = {};

  const isBankInfoOptional = !getShouldFillBankInfoCompletely({
    companyCurrency: company.currency,
    bankCountry: values.bankCountry,
    iban: values.iban,
    bic: values.bicSwift,
  });

  if (isEmpty(values.bankCountry) || !(values.bankCountry in COUNTRIES)) {
    errors.bankCountry =
      'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryError';
  }

  if (
    (values.iban || values.bicSwift || values.accountNumber) &&
    !values.accountHolderName
  ) {
    errors.accountHolderName =
      'bookkeep.accountsPayable.panel.bankDetailsSection.accountHolderNameError';
  }

  const bankInfoErrors = getBankInfoErrors(
    {
      [BankFields.Iban]: values.iban,
      [BankFields.BicSwift]: values.bicSwift,
      [BankFields.SortCode]: values.sortCode,
      [BankFields.AccountCode]: values.accountCode,
      [BankFields.AccountNumber]: values.accountNumber,
      [BankFields.RoutingNumber]: values.routingNumber,
    },
    company.country,
    values.bankCountry,
    company.currency,
  );

  if (
    (!isBankInfoOptional || !isEmpty(values.iban)) &&
    bankInfoErrors &&
    bankInfoErrors[BankFields.Iban]
  ) {
    errors.iban = bankInfoKeyToErrorTranslationKey('iban');
  }

  if (
    (!isBankInfoOptional || !isEmpty(values.bicSwift)) &&
    bankInfoErrors &&
    bankInfoErrors[BankFields.BicSwift]
  ) {
    errors.bicSwift = bankInfoKeyToErrorTranslationKey('bicSwift');
  }

  if (
    !isEmpty(values.sortCode) &&
    bankInfoErrors &&
    bankInfoErrors[BankFields.SortCode]
  ) {
    errors.sortCode = bankInfoKeyToErrorTranslationKey('sortCode');
  }

  if (
    !isEmpty(values.accountCode) &&
    bankInfoErrors &&
    bankInfoErrors[BankFields.AccountCode]
  ) {
    errors.accountCode = bankInfoKeyToErrorTranslationKey('accountCode');
  }

  if (
    !isEmpty(values.accountNumber) &&
    bankInfoErrors &&
    bankInfoErrors[BankFields.AccountNumber]
  ) {
    errors.accountNumber = bankInfoKeyToErrorTranslationKey('accountNumber');
  }

  if (
    !isEmpty(values.routingNumber) &&
    bankInfoErrors &&
    bankInfoErrors[BankFields.RoutingNumber]
  ) {
    errors.routingNumber = bankInfoKeyToErrorTranslationKey('routingNumber');
  }

  return errors;
};
