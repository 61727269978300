import { FormField, TextArea } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type FormikProps } from 'formik';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import CustomFieldsSelector from 'common/components/legacy/CustomFieldsSelector/CustomFieldsSelector';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import {
  partialUpdateCustomFieldAssociation,
  type CustomFieldAssociation,
} from 'modules/budgets/models/customFieldAssociation';
import { type RawCustomField } from 'modules/custom-fields/models/customField';
import { SupplierAsyncAutocomplete } from 'modules/suppliers';
import FEATURES from 'src/core/constants/features';

type Props = {
  className?: string;
  groups: { id: string; name: string }[];
  customFields: RawCustomField[];
  customFieldTypes: string[];
  teamIds: string[];
} & FormikProps<{
  description: string;
  supplier: { key: string; label: string } | undefined;
  group: { key: string; label: string } | undefined;
  customFields: CustomFieldAssociation[];
}>;

export const BulkEditPayments = ({
  className,
  groups,
  customFields,
  customFieldTypes,
  teamIds,
  // formikProps
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const user = useUser();

  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);

  return (
    <form
      className={cx('flex grow flex-col gap-s overflow-y-auto', className)}
      id="bulk-edit-payments"
      onSubmit={handleSubmit}
    >
      <FormField label={t('payments.bulk.supplierLabel')}>
        <SupplierAsyncAutocomplete
          isInvoicePayable={false}
          fit="parent"
          placeholder={t('payments.bulkSupplierFieldPlaceholder')}
          value={values.supplier}
          onSelect={(selectedSupplier) => {
            if (selectedSupplier) {
              setFieldValue('supplier', selectedSupplier);
            }
          }}
          withAddOption={false}
        />
      </FormField>
      <FormField label={t('payments.bulk.descriptionLabel')}>
        <TextArea
          name="description"
          value={values.description}
          placeholder={t('payments.bulkDescriptionFieldPlaceholder')}
          onChange={handleChange}
        />
      </FormField>
      {hasTeamsFeature && (
        <FormField label={t('forms.team.label')}>
          <AutocompleteSearch
            fit="parent"
            placeholder={t('payments.bulkCustomfieldPlaceholder')}
            options={groups.map((group) => ({
              key: group.id,
              label: group.name,
            }))}
            value={values.group}
            onSelect={(newValue) => {
              if (newValue) {
                setFieldValue('group', newValue);
              }
            }}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
          />
        </FormField>
      )}
      {hasCustomFieldsFeature && (
        <CustomFieldsSelector
          fit="parent"
          user={user}
          company={company}
          teamIds={teamIds}
          types={customFieldTypes}
          customFields={customFields}
          customFieldsValues={reshapeCustomFieldsAssociationForSelector(
            values.customFields,
          )}
          onChange={(
            updatedCustomFieldAssociations: CustomFieldAssociation[],
          ): void => {
            setFieldValue(
              'customFields',
              partialUpdateCustomFieldAssociation(
                values.customFields,
                updatedCustomFieldAssociations,
              ),
            );
          }}
        />
      )}
    </form>
  );
};

// Reshape the values for `CustomFieldsSelector` because it accepts a specific format as `customFieldsValues` props
const reshapeCustomFieldsAssociationForSelector = (
  customFieldAssociations: CustomFieldAssociation[],
): { field: { id: string }; value: { id: string; value: string } }[] => {
  return customFieldAssociations.map((customFieldAssociation) => {
    return {
      field: {
        id: customFieldAssociation.customFieldId,
      },
      value: {
        id: customFieldAssociation.customFieldValueId,
        value: customFieldAssociation.value,
      },
    };
  });
};
