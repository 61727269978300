import { type DateRange } from '@dev-spendesk/grapes';
import { isBefore } from 'date-fns';

import { formatDateToApiString } from 'src/core/common/utils/formatDateToApiString';

import { type Result as AccountingIntegrationHistoryResult } from '../../../hooks/useAccountingIntegrationHistory';
import { type AvailableAccountingIntegrationsResponse as AvailableAccountingIntegrationsResult } from '../../../hooks/useAvailableAccountingIntegrations';

export type PayablesFiltersDependencies = {
  selectedPeriod?: DateRange;
  availableAccountingIntegrations: AvailableAccountingIntegrationsResult;
  accountingIntegrationHistory: AccountingIntegrationHistoryResult;
};

export function getExportPeriods({
  selectedPeriod,
  availableAccountingIntegrations,
  accountingIntegrationHistory,
}: PayablesFiltersDependencies): { from?: string; to?: string }[] {
  const [currentAcccountingIntegration] = accountingIntegrationHistory;

  const hasPayablesSettlementsReExportCapability = currentAcccountingIntegration
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      availableAccountingIntegrations.find(
        ({ accountingIntegration }) =>
          accountingIntegration ===
          currentAcccountingIntegration.accountingIntegrationId,
      )!.coreCapabilitySet.payablesSettlementsReExport
    : false;

  // For integrations that don't support re-export (at time of writing, native integrations), we only export payables from the current accounting integration
  if (!hasPayablesSettlementsReExportCapability) {
    const { from, to } = currentAcccountingIntegration;
    return [{ from: from.toISOString(), to: to?.toISOString() }];
  }

  // For other accounting integrations, we re-export every payable
  let extraPeriod: { from?: string; to?: string }[] = [];
  if (selectedPeriod) {
    const oldestAccountingIntegration = accountingIntegrationHistory.reduce(
      (foundDate: Date, { from }): Date => {
        return from < foundDate ? from : foundDate;
      },
      currentAcccountingIntegration.from,
    );
    const [start, end] = selectedPeriod;
    if (start && end && isBefore(end, oldestAccountingIntegration)) {
      extraPeriod = [
        {
          from: formatDateToApiString(start),
          to: formatDateToApiString(oldestAccountingIntegration),
        },
      ];
    }
  }

  return [
    ...extraPeriod,
    ...accountingIntegrationHistory.map(({ from, to }) => ({
      from: from.toISOString(),
      to: to?.toISOString(),
    })),
  ];
}
