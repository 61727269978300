import { AccordionItem, Button, colors, Icon } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { getContentByTaskType } from './utils';
import { type ReadyToSpendTask } from '../../types';

type Props = {
  task: ReadyToSpendTask;
};

export const ReadyToSpendTaskItem = ({ task }: Props) => {
  const { t } = useTranslation('global');

  const content = getContentByTaskType(task.type);
  if (!content) {
    return null;
  }

  const { title, decription, cta } = content;
  return (
    <div className="border-0 border-t border-solid border-neutral-light py-s">
      <AccordionItem
        renderHeader={() => (
          <div className="-ml-m flex items-center gap-xs">
            {task.status === 'completed' ? (
              <Icon name="success" size="l" color={colors.success} />
            ) : (
              <div className="h-m w-m rounded-[24px] border border-solid border-neutral" />
            )}
            <p className="title-m">{t(title)}</p>
          </div>
        )}
      >
        <div className="flex flex-col gap-s">
          <div className="text-neutral-dark">{t(decription)}</div>
          <Button
            className="mb-xs self-end"
            onClick={() => {}}
            variant="primary"
            text={t(cta)}
          />
        </div>
      </AccordionItem>
    </div>
  );
};
