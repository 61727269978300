import { DATE_FORMAT, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon, { ICONS } from 'src/core/common/components/legacy/Icon/Icon';
import { withTranslation } from 'src/core/common/components/withTranslation';
import { white } from 'src/core/utils/color-palette';
import { formatMoney } from 'src/core/utils/money';

import './PaymentsHeader.scss';

export const PAYMENTS_HEADER_HEIGHT = 24;

class PaymentsHeader extends Component {
  static propTypes = {
    style: PropTypes.object,
    company: PropTypes.object.isRequired,
    month: PropTypes.number,
    year: PropTypes.number,
    nbPayments: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    totalFxFeeAmount: PropTypes.number.isRequired,
    isSticky: PropTypes.bool,
    localeFormat: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isSticky: false,
    style: undefined,
    month: undefined,
    year: undefined,
  };

  renderTitle() {
    const { year, month, isSticky, t, localeFormat } = this.props;
    const isComing = !year;

    if (!isComing) {
      const label = localeFormat(new Date(year, month), DATE_FORMAT.CUSTOM, {
        month: 'long',
        year: 'numeric',
      });
      return <span className="u-oh">{label}</span>;
    }

    return (
      <span>
        {t('payments.pendingTransactions')}
        {isSticky && (
          <Tooltip
            content={t('payments.pendingTransactionsNotice')}
            placement="bottom"
            triggerAsChild
          >
            <Icon
              className="pending-transactions__icon"
              icon={ICONS.INFO}
              fill={white}
              width={16}
              height={16}
              viewBox="0 0 18 20"
            />
          </Tooltip>
        )}
      </span>
    );
  }

  renderDetails() {
    const {
      company: { currency },
      nbPayments,
      totalAmount,
      totalFxFeeAmount,
      t,
    } = this.props;
    const totalAmountFormated = formatMoney(
      totalAmount + totalFxFeeAmount,
      currency,
    );

    return (
      <span className="u-fr PaymentsHeader_details">
        {[
          t('misc.paymentWithCount', { count: nbPayments }),
          totalAmountFormated,
        ].join(' - ')}
        {totalFxFeeAmount !== 0
          ? ` (${t('misc.includingFxFeesWithAmount', {
              amount: formatMoney(totalFxFeeAmount, currency),
            })})`
          : ''}
      </span>
    );
  }

  render() {
    const { style, year, month, isSticky } = this.props;
    const key = year ? `${year}-${month}` : 'pending';
    return (
      <div
        key={key}
        style={style}
        className={cx('l-row-header', { 'sticky-header': isSticky })}
      >
        {this.renderTitle()}
        {this.renderDetails()}
      </div>
    );
  }
}

export default withTranslation('global')(PaymentsHeader);
