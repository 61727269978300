export type CustomFieldValue = {
  id: string;
  value: string;
  is_default: boolean;
  created_at: string;
};

export type RawCustomField = {
  id: string;
  perms_add_values: string[];
  perms_visibility: string[];
  name: string;
  type: 'list' | 'boolean';
  is_scopable: boolean;
  is_splittable?: boolean;
  is_all_scopes: boolean;
  is_required: boolean;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  total_values: number;
  eligible_types: { type: string }[];
  scope: { entity_type: string; entity_id: string }[];
  custom_fields_values: CustomFieldValue[];
};

export type CustomField = {
  id: string;
  name: string;
  values: {
    id: string;
    name: string;
  }[];
  type: 'list' | 'boolean';
  isScopable: boolean;
  isSplittable?: boolean;
  isRequired: boolean;
  isDeleted: boolean;
  isAllScopes: boolean;
  totalValues: number;
  eligibleTypes: { type: string }[];
  permsAddValues: string[];
};

export const reshapeCustomField = (
  rawCustomField: RawCustomField,
): CustomField => {
  return {
    id: rawCustomField.id,
    name: rawCustomField.name,
    totalValues: rawCustomField.total_values,
    isScopable: rawCustomField.is_scopable,
    isSplittable: rawCustomField.is_splittable,
    isAllScopes: rawCustomField.is_all_scopes,
    type: rawCustomField.type,
    isRequired: rawCustomField.is_required,
    isDeleted: Boolean(rawCustomField.deleted_at),
    eligibleTypes: rawCustomField.eligible_types,
    values: rawCustomField.custom_fields_values
      ? rawCustomField.custom_fields_values.map((cfv) => ({
          id: cfv.id,
          name: cfv.value,
        }))
      : [],
    permsAddValues: rawCustomField.perms_add_values,
  };
};

export const addValueToCustomField = (
  customFields: RawCustomField[],
  value: CustomFieldValue,
  customFieldId: string,
): RawCustomField[] => {
  return customFields.map((customField) => {
    if (customField.id === customFieldId) {
      return {
        ...customField,
        custom_fields_values: [...customField.custom_fields_values, value],
      };
    }
    return customField;
  });
};

export const removeValueFromCustomField = (
  customFields: RawCustomField[],
  fieldValueId: string,
  customFieldId: string,
): RawCustomField[] => {
  return customFields.map((customField) => {
    if (customField.id === customFieldId) {
      return {
        ...customField,
        custom_fields_values: customField.custom_fields_values.filter(
          (cfv) => cfv.id !== fieldValueId,
        ),
      };
    }
    return customField;
  });
};

export const updateValueForCustomField = (
  customFields: RawCustomField[],
  customFieldId: string,
  newValue: string,
  fieldValueId: string,
): RawCustomField[] => {
  return customFields.map((customField) => {
    if (customField.id === customFieldId) {
      return {
        ...customField,
        custom_fields_values: customField.custom_fields_values.map(
          (customFieldsValue) => {
            if (customFieldsValue.id === fieldValueId) {
              return {
                ...customFieldsValue,
                value: newValue,
              };
            }
            return customFieldsValue;
          },
        ),
      };
    }
    return customField;
  });
};
