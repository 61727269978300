import { Accordion, Icon, NavigationItem, Popover } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { ReadyToSpendTaskItem } from './components/ReadyToSpendTaskItem';
import { useGetReeadyToSpendTasksQuery } from './hooks/useGetReeadyToSpendTasksQuery';

export const ReadyToSpendSetupGuide = () => {
  const { t } = useTranslation('global');

  const tasksQueryState = useGetReeadyToSpendTasksQuery();

  if (tasksQueryState.status !== 'success') {
    return null;
  }

  const tasks = tasksQueryState.data;
  if (tasks.length === 0) {
    return null;
  }

  const completedTasks = tasks.filter((task) => task.status === 'completed');
  if (completedTasks.length === tasks.length) {
    return null;
  }

  const progress = Math.floor((completedTasks.length / tasks.length) * 100);

  return (
    <Popover
      renderTrigger={(triggerProps) => (
        <NavigationItem
          {...triggerProps}
          text={`Setup ${progress}%`}
          variant="mainNavigation"
          leftAddon={<Icon name="expense-claim" />}
          rightAddon={<Icon name="caret-down" />}
        />
      )}
    >
      {() => (
        <div className="w-[500px] p-m">
          <div className="mb-s">
            <p className="mb-xs text-complementary title-xl">
              {t('readyToSpend.title')}
            </p>
            <p className="text-neutral-dark body-m">
              {t('readyToSpend.subtitle')}
            </p>
          </div>
          <div className="mb-m flex items-center gap-xs">
            <label
              className="text-neutral-darker title-m"
              htmlFor="ready-to-spend-progress"
            >
              {`${progress}%`}
            </label>
            <progress
              className="h-[10px] flex-1 accent-primary"
              id="ready-to-spend-progress"
              max="100"
              value={progress}
            />
          </div>
          <Accordion>
            {tasks.map((task) => (
              <ReadyToSpendTaskItem key={task.id} task={task} />
            ))}
          </Accordion>
        </div>
      )}
    </Popover>
  );
};
