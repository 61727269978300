import type { IntegrationsSettings } from 'src/core/utils/analytics';

/**
 * @see https://type-level-typescript.com/06-template-literal-types
 * extract params from an url
 * @example
 * ParseUrlParams<"/api/:companyId/budgets/:budgetId/:modalId?">
 * {
 *  companyId: string,
 *  budgetId: string,
 *  modalId?: string
 * }
 */
export type ParseUrlParams<url> = url extends `${infer start}/${infer rest}`
  ? ParseUrlParams<start> & ParseUrlParams<rest>
  : url extends `:${infer parameter}?`
    ? { [k in parameter]?: string }
    : url extends `:${infer parameter}`
      ? { [k in parameter]: string }
      : NonNullable<unknown>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteAttributeGetter = (params: any) => string;
type RouteIntegrationsGetter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
) => IntegrationsSettings | undefined;

export type Route = {
  path: string;
  analytics: {
    category: RouteAttributeGetter | ReturnType<RouteAttributeGetter>;
    name: RouteAttributeGetter | ReturnType<RouteAttributeGetter>;
    disablePageViews?: boolean;
    useNamePrefix?: boolean;
    useCategoryPrefix?: boolean;
    integrations?:
      | RouteIntegrationsGetter
      | ReturnType<RouteIntegrationsGetter>;
  };
};

type Routes = typeof routes;
export type AppRouteKeys = keyof Routes;
export type RoutesUrl = Routes[AppRouteKeys]['path'];

/**
 * @example
 * const params: RouteParams<"REQUEST"> = useParams()
 */
export type RouteParams<Key extends AppRouteKeys> = ParseUrlParams<
  Routes[Key]['path']
>;

/**
 * Composes a route from a path and its parameters - acts like a reverse-router:
 *   - input:  path= '/route/to/:my/awesome/:id? | params= { my: 'foo', id: 123 }
 *   - Output: '/route/to/foo/awesome/123'
 */
export function routeFor<
  TUrl extends RoutesUrl | (string & Record<never, never>), // @see https://twitter.com/diegohaz/status/1524257274012876801
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(path: TUrl, params: ParseUrlParams<TUrl> = {} as any): string {
  return path
    .split('/')
    .map((part) => {
      const match = part.match(/^:([^?]+)\??$/);
      const isOptional = part.endsWith('?');

      if (!match) {
        return part;
      }

      // @ts-expect-error
      const replacement = params[match[1]];

      if (!isOptional && replacement === undefined) {
        throw new Error(
          `Missing mandatory route param for path "${path}": ${match[1]}`,
        );
      }

      return replacement;
    })
    .filter((part) => part !== undefined)
    .join('/');
}

/**
 * Required to correctly infer the types
 * without that, AppRouteKeys would be string instead of the real list of keys
 * @see https://github.com/Spendesk/app-desktop/pull/4814
 */
function getRoutes<
  T extends {
    [Key in Y]: Route;
  },
  Y extends string,
>(routes: T): T {
  return routes;
}

export const routes = getRoutes({
  APP: {
    path: '/app/:company?',
    analytics: {
      category: 'App',
      name: 'App',
      disablePageViews: true, // prefix route
    },
  },
  ACCOUNT_ME: {
    path: '/app/:company/account/me',
    analytics: {
      category: 'Account',
      name: 'Me',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_ME_PROFILE: {
    path: '/app/:company/account/me/profile',
    analytics: {
      category: 'Account',
      name: 'Me - Profile',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_ME_SECURITY: {
    path: '/app/:company/account/me/security',
    analytics: {
      category: 'Account',
      name: 'Me - Security',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_ME_BANKINFO: {
    path: '/app/:company/account/me/bankinfo',
    analytics: {
      category: 'Account',
      name: 'Me - Bank Info',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_ME_RULES: {
    path: '/app/:company/account/me/rules',
    analytics: {
      category: 'Account',
      name: 'Me - rules',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_ME_ROLES: {
    path: '/app/:company/account/me/roles',
    analytics: {
      category: 'Account',
      name: 'Me - roles',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_ME_RIGHTS: {
    path: '/app/:company/account/me/rights',
    analytics: {
      category: 'Account',
      name: 'Me - rights',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_NOTIFICATIONS: {
    path: '/app/:company/account/notifications',
    analytics: {
      category: 'Account',
      name: 'Notifications',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_NOTIFICATIONS_GENERAL: {
    path: '/app/:company/account/notifications/general',
    analytics: {
      category: 'Account',
      name: 'Notifications',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_NOTIFICATIONS_CONTROL: {
    path: '/app/:company/account/notifications/control',
    analytics: {
      category: 'Account',
      name: 'Notifications',
      useCategoryPrefix: true,
    },
  },
  ACCOUNT_NOTIFICATIONS_USAGE: {
    path: '/app/:company/account/notifications/usage',
    analytics: {
      category: 'Account',
      name: 'Notifications',
      useCategoryPrefix: true,
    },
  },
  CHURN: {
    path: '/app/:company/company/churn',
    analytics: {
      category: 'Churn',
      name: 'Churn',
      useNamePrefix: true,
    },
  },
  CHURN_REDEMPTION: {
    path: '/app/:company/company/churn/redemption',
    analytics: {
      category: 'Churn',
      name: 'ChurnRedemption',
      useNamePrefix: true,
    },
  },
  LEGALS: {
    path: '/app/:company/legals',
    analytics: {
      category: 'Legals',
      name: 'Legals',
      useNamePrefix: true,
    },
  },

  REQUESTS: {
    path: '/app/:company/requests/:type/:id?/:newRequestType?',
    analytics: {
      category: 'Requests',
      name: (params: {
        id?: string;
        newRequestType?: string;
        type?: string;
        // eslint-disable-next-line sonarjs/cognitive-complexity
      }) => {
        if (params.id === 'new') {
          if (params.newRequestType === 'virtual') {
            return 'New virtual card request';
          }
          if (params.newRequestType === 'invoice') {
            return 'New invoice request';
          }
          if (params.newRequestType === 'reimbursement') {
            return 'New reimbursement request';
          }
          if (params.newRequestType === 'purchase-request') {
            return 'New purchase request';
          }
          if (params.newRequestType === 'purchase-order') {
            return 'New purchase order request';
          }
          if (params.newRequestType === 'mileage') {
            return 'New mileage allowance request';
          }
          if (params.newRequestType === 'perdiem') {
            return 'New perdiem allowance request';
          }

          return 'New request';
        }

        if (params.id) {
          return 'Request details';
        }

        switch (params.type) {
          case 'to-approve':
            return 'To approve';
          case 'mine':
            return 'Mine';
          case 'drafts':
            return 'Drafts';
          default:
            return 'All';
        }
      },
      useCategoryPrefix: true,
      integrations: (params: { id?: string }) => {
        if (!params.id || params.id === 'new') {
          return;
        }

        return {
          Amplitude: {
            groups: {
              requestId: params.id,
            },
          },
        };
      },
    },
  },

  PAYMENTS_ALL: {
    path: '/app/:company/payments/all/:id?',
    analytics: {
      category: 'Payments',
      name: (params: { id?: string }): string => {
        if (params.id) {
          return 'Payment details';
        }
        return 'All';
      },
      useCategoryPrefix: true,
    },
  },

  PAYABLES_ALL: {
    path: '/app/:company/payables/:id?',
    analytics: {
      category: 'Payables',
      name: (params: { id?: string }): string => {
        if (params.id) {
          return 'Payable details';
        }
        return 'All';
      },
      useCategoryPrefix: true,
      integrations: (params: { id?: string }) => {
        if (!params.id) {
          return;
        }

        return {
          Amplitude: {
            groups: {
              payableId: params.id,
            },
          },
        };
      },
    },
  },

  INBOX_INVOICES: {
    path: '/app/:company/requests/inbox/:inboxInvoiceId?',
    analytics: {
      category: 'Requests',
      name: 'inbox-invoices',
      useNamePrefix: true,
    },
  },

  EXPENSE_INBOX: {
    path: '/app/:company/expense-inbox',
    analytics: {
      category: 'Expense Inbox',
      name: 'expense-inbox',
      disablePageViews: true, // because it's an index route
    },
  },
  EXPENSE_INBOX_PREPARE: {
    path: '/app/:company/expense-inbox/prepare/:payableId?',
    analytics: {
      category: 'Expense Inbox',
      name: 'Prepare',
      useNamePrefix: true,
      integrations: (parameters: { payableId: string | undefined }) => {
        if (!parameters.payableId) {
          return;
        }

        return {
          Amplitude: {
            groups: {
              payableId: parameters.payableId,
            },
          },
        };
      },
    },
  },
  EXPENSE_INBOX_EXPORT: {
    path: '/app/:company/expense-inbox/export',
    analytics: {
      category: 'Expense Inbox',
      name: 'Export',
      useNamePrefix: true,
    },
  },
  EXPENSE_INBOX_REVIEW_EXPORT: {
    path: '/app/:company/expense-inbox/export/review/:payableId?',
    analytics: {
      category: 'Expense Inbox',
      name: 'Review',
      useNamePrefix: true,
    },
  },
  EXPENSE_INBOX_SETTLEMENT_REVIEW_EXPORT: {
    path: '/app/:company/expense-inbox/export/settlements/review/:settlementId?',
    analytics: {
      category: 'Expense Inbox',
      name: 'Settlements review',
      useNamePrefix: true,
    },
  },
  EXPENSE_INBOX_FAILED_SETTLEMENT_REVIEW_EXPORT: {
    path: '/app/:company/expense-inbox/export/settlements/review/failed/:settlementId?',
    analytics: {
      category: 'Expense Inbox',
      name: 'Failed settlements review',
      useNamePrefix: true,
    },
  },
  EXPENSE_INBOX_EXPORT_HISTORY: {
    path: '/app/:company/expense-inbox/export/history',
    analytics: {
      category: 'Expense Inbox',
      name: 'Export history',
      useNamePrefix: true,
    },
  },

  HOMEPAGE: {
    path: '/app/:company/home',
    analytics: {
      category: 'Home',
      name: 'Home',
      useCategoryPrefix: true,
    },
  },

  SUBSCRIPTIONS: {
    path: '/app/:company/subscriptions/:id?',
    analytics: {
      category: 'Subscriptions',
      name: 'All',
      useCategoryPrefix: true,
    },
  },

  PURCHASE_ORDERS: {
    path: '/app/:company/purchase-orders/:id?',
    analytics: {
      category: 'Purchase Orders',
      name: 'purchase-orders',
      useCategoryPrefix: true,
    },
  },

  CARD: {
    path: '/app/:company/card',
    analytics: {
      category: 'Cards',
      name: 'Card',
      useNamePrefix: true,
    },
  },
  CARD_PIN: {
    path: '/app/:company/card/pin',
    analytics: {
      category: 'Cards',
      name: 'Card - Set PIN',
      useNamePrefix: true,
    },
  },
  CARD_ORDER: {
    path: '/app/:company/card/order',
    analytics: {
      category: 'Cards',
      name: 'Card - Order',
      useNamePrefix: true,
    },
  },
  CARD_ACTIVATION: {
    path: '/app/:company/card/activation/:cardId',
    analytics: {
      category: 'Cards',
      name: 'Card - Activation',
      useNamePrefix: true,
    },
  },
  CARD_DETAILS: {
    path: '/app/:company/card/:id',
    analytics: {
      category: 'Cards',
      name: 'Card - Details',
      useNamePrefix: true,
    },
  },
  RECARD: {
    path: '/app/:company/recard/:status?',
    analytics: {
      category: 'Cards',
      name: 'Recard',
      useNamePrefix: true,
    },
  },
  CARDS: {
    path: '/app/:company/cards/:id?',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },
  CARDS_ORDER: {
    path: '/app/:company/cards/order',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },
  CARDS_ORDER_NO_FUND: {
    path: '/app/:company/cards/order/nofund',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },
  CARDS_ORDER_EDIT: {
    path: '/app/:company/cards/order/edit',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },
  CARDS_ORDER_ADD_DELIVERY_ADDRESS: {
    path: '/app/:company/cards/order/adddelivery',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },
  CARDS_ORDER_REVIEW: {
    path: '/app/:company/cards/order/review',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },
  CARDS_ORDER_COMPLETE: {
    path: '/app/:company/cards/order/complete',
    analytics: {
      category: 'Cards',
      name: 'All',
      useCategoryPrefix: true,
    },
  },

  INVOICES: {
    path: '/app/:company/invoices',
    analytics: {
      category: 'Invoices',
      name: 'invoices',
      disablePageViews: true, // prefix route
    },
  },
  INVOICES_REVIEW: {
    path: '/app/:company/invoices/review/:id?',
    analytics: {
      category: 'Invoices',
      name: (params: { id?: string }) => {
        if (params.id) {
          return 'Review item';
        }
        return 'Empty review';
      },
      useNamePrefix: true,
    },
  },
  CREDIT_NOTES_REVIEW: {
    path: '/app/:company/credit-notes/review/:id',
    analytics: {
      category: 'Invoices',
      name: 'Review credit note',
      useNamePrefix: true,
    },
  },
  INVOICES_PAY: {
    path: '/app/:company/invoices/pay/:paymentToScheduleId?',
    analytics: {
      category: 'Invoices',
      name: (params: { paymentToScheduleId?: string }) => {
        if (params.paymentToScheduleId) {
          return 'Pay item';
        }
        return 'Pay';
      },
      useNamePrefix: true,
    },
  },
  INVOICES_CONFIRM: {
    path: '/app/:company/invoices/confirm/:transferId?',
    analytics: {
      category: 'Invoices',
      name: 'Confirm',
      useNamePrefix: true,
    },
  },
  INVOICES_HISTORY: {
    path: '/app/:company/invoices/history/:paymentId?',
    analytics: {
      category: 'Invoices',
      name: 'History',
      useNamePrefix: true,
    },
  },
  EXPENSE_CLAIMS: {
    path: '/app/:company/expense-claims',
    analytics: {
      category: 'Expense claims',
      name: 'expense-claims',
      disablePageViews: true, // prefix route
    },
  },
  EXPENSE_CLAIMS_REVIEW: {
    path: '/app/:company/expense-claims/review/:id?',
    analytics: {
      category: 'Expense claims',

      name: (params: { id?: string }) => {
        if (params.id) {
          return 'Review item';
        }
        return 'Empty review';
      },
      useNamePrefix: true,
    },
  },
  EXPENSE_CLAIMS_PAY: {
    path: '/app/:company/expense-claims/pay/:userId?',
    analytics: {
      category: 'Expense claims',
      name: (params: { userId?: string }) => {
        if (params.userId) {
          return 'Pay item';
        }
        return 'Pay';
      },
      useNamePrefix: true,
    },
  },
  EXPENSE_CLAIMS_CONFIRM: {
    path: '/app/:company/expense-claims/confirm/:scheduledPaymentId?',
    analytics: {
      category: 'Expense claims',
      name: 'Confirm',
      useNamePrefix: true,
    },
  },
  EXPENSE_CLAIMS_HISTORY: {
    path: '/app/:company/expense-claims/history/:scheduledPaymentId?',
    analytics: {
      category: 'Expense claims',
      name: 'History',
      useNamePrefix: true,
    },
  },
  COMPANY_BILLING: {
    path: '/app/:company/company/billing',
    analytics: {
      category: 'Settings',
      name: 'Billing',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BILLING_PLAN: {
    path: '/app/:company/company/billing/plan',
    analytics: {
      category: 'Settings',
      name: 'Billing',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BILLING_INFORMATION: {
    path: '/app/:company/company/billing/information',
    analytics: {
      category: 'Settings',
      name: 'Billing',
      useCategoryPrefix: true,
    },
  },
  COMPANY_GENERAL_SETTINGS: {
    path: '/app/:company/company/settings',
    analytics: {
      category: 'Settings',
      name: 'General Settings',
      useCategoryPrefix: true,
    },
  },
  COMPANY_GENERAL_SETTINGS_BANK_INFORMATION: {
    path: '/app/:company/company/settings/bank-information',
    analytics: {
      category: 'Settings',
      name: 'General Settings',
      useCategoryPrefix: true,
    },
  },
  COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION: {
    path: '/app/:company/company/settings/company-information',
    analytics: {
      category: 'Settings',
      name: 'General Settings',
      useCategoryPrefix: true,
    },
  },
  COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS: {
    path: '/app/:company/company/settings/payment-methods',
    analytics: {
      category: 'Settings',
      name: 'General Settings',
      useCategoryPrefix: true,
    },
  },
  COMPANY_GENERAL_SETTINGS_WIRE_TRANSFER: {
    path: '/app/:company/company/settings/wire-transfer',
    analytics: {
      category: 'Settings',
      name: 'General Settings',
      useCategoryPrefix: true,
    },
  },
  COMPANY_GENERAL_SETTINGS_NOTIFICATIONS: {
    path: '/app/:company/company/settings/notifications',
    analytics: {
      category: 'Settings',
      name: 'General Settings',
      useCategoryPrefix: true,
    },
  },
  COMPANY_MEMBERS: {
    path: '/app/:company/company/members/:id?',
    analytics: {
      category: 'Settings',
      name: 'Members',
      useCategoryPrefix: true,
    },
  },
  COMPANY_TEAMS: {
    path: '/app/:company/company/teams',
    analytics: {
      category: 'Settings',
      name: 'Teams',
      useCategoryPrefix: true,
    },
  },
  COMPANY_POLICIES: {
    path: '/app/:company/company/policies',
    analytics: {
      category: 'Settings',
      name: 'Policies',
      useCategoryPrefix: true,
    },
  },
  COMPANY_CONTROL_RULES: {
    path: '/app/:company/company/control-rules',
    analytics: {
      category: 'Settings',
      name: 'Control on receipt',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK: {
    path: '/app/:company/company/bank',
    analytics: {
      category: 'Settings',
      name: 'Wallet',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_DETAIL: {
    path: '/app/:company/company/bank/detail',
    analytics: {
      category: 'Settings',
      name: 'Wallet Detail',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_FUNDING_SOURCE: {
    path: '/app/:company/company/bank/source',
    analytics: {
      category: 'Settings',
      name: 'Funding Source',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_FUNDING: {
    path: '/app/:company/company/bank/funding',
    analytics: {
      category: 'Settings',
      name: 'Wallet Funding',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_FUNDING_ONE_OFF: {
    path: '/app/:company/company/bank/funding/one-off',
    analytics: {
      category: 'Settings',
      name: 'One-off Wallet Funding',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_FUNDING_AUTOMATED: {
    path: '/app/:company/company/bank/funding/automated',
    analytics: {
      category: 'Settings',
      name: 'Automated Wallet Funding',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_STATEMENTS: {
    path: '/app/:company/company/bank/statements',
    analytics: {
      category: 'Settings',
      name: 'Wallet Statements',
      useCategoryPrefix: true,
    },
  },
  COMPANY_BANK_HISTORY: {
    path: '/app/:company/company/bank/history',
    analytics: {
      category: 'Settings',
      name: 'Wallet History',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING: {
    path: '/app/:company/company/accounting',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATION_EXTERNAL_CONNECT: {
    path: '/app/:company/company/integrations/:integration/external-connect',
    analytics: {
      category: 'Settings',
      name: 'Integration',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATIONS: {
    path: '/app/:company/company/integrations',
    analytics: {
      category: 'Settings',
      name: 'Integrations',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATION: {
    path: '/app/:company/company/integrations/all/:integration',
    analytics: {
      category: 'Settings',
      name: 'Integration',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATIONS_ALL: {
    path: '/app/:company/company/integrations/all',
    analytics: {
      category: 'Settings',
      name: 'Integrations list',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT: {
    path: '/app/:company/company/integrations/api-access-management',
    analytics: {
      category: 'Settings',
      name: 'Integrations API access management',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATIONS_API_ACCESS_NEW: {
    path: '/app/:company/company/integrations/api-access-management/new',
    analytics: {
      category: 'Settings',
      name: 'Add new API access',
      useCategoryPrefix: true,
    },
  },
  COMPANY_INTEGRATIONS_API_ACCESS_VIEW: {
    path: '/app/:company/company/integrations/api-access-management/view/:id',
    analytics: {
      category: 'Settings',
      name: 'View one API access',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_INTEGRATION: {
    path: '/app/:company/company/accounting/integration',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  SPENDESK_OAUTH2: {
    path: '/app/:company/public-api/spendesk-oauth2',
    analytics: {
      category: 'Settings',
      name: 'Spendesk OAuth2',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_BANK_ACCOUNTS: {
    path: '/app/:company/company/accounting/bank-accounts',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS: {
    path: '/app/:company/company/accounting/expense-accounts',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_TAX_ACCOUNTS: {
    path: '/app/:company/company/accounting/tax-accounts',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS: {
    path: '/app/:company/company/accounting/supplier-accounts',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS: {
    path: '/app/:company/company/accounting/employee-accounts',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_ANALYTICAL_FIELDS: {
    path: '/app/:company/company/accounting/analytical-fields',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTING_BOOKKEEPING_DATE: {
    path: '/app/:company/company/accounting/bookeeping-date',
    analytics: {
      category: 'Settings',
      name: 'Accounting',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTS_PAYABLE: {
    path: '/app/:company/company/accounts-payable',
    analytics: {
      category: 'Settings',
      name: 'Accounts Payable',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS: {
    path: '/app/:company/company/accounts-payable/:accountPayableId',
    analytics: {
      category: 'Settings',
      name: 'Accounts Payable',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL: {
    path: '/app/:company/company/accounts-payable/:accountPayableId/supplier/:supplierId',
    analytics: {
      category: 'Settings',
      name: 'Accounts Payable',
      useCategoryPrefix: true,
    },
  },
  COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL: {
    path: '/app/:company/company/accounts-payable/supplier/:supplierId',
    analytics: {
      category: 'Settings',
      name: 'Accounts Payable',
      useCategoryPrefix: true,
    },
  },
  COMPANY_EXPORTS: {
    path: '/app/:company/company/exports',
    analytics: {
      category: 'Settings',
      name: 'Exports',
      useCategoryPrefix: true,
    },
  },
  COMPANY_CATEGORIES: {
    path: '/app/:company/company/categories',
    analytics: {
      category: 'Settings',
      name: 'Custom fields',
      useCategoryPrefix: true,
    },
  },
  BUDGETARY_EXERCISES: {
    path: '/app/:company/budgetary-exercises/:id?',
    analytics: {
      category: 'Budgets',
      name: 'Budgetary Exercises',
      useCategoryPrefix: true,
    },
  },
  BUDGETS_LANDING: {
    path: '/app/:company/budgets/landing',
    analytics: {
      category: 'Budgets',
      name: 'Landing page',
      useCategoryPrefix: true,
    },
  },
  BUDGETS: {
    path: '/app/:company/budgets',
    analytics: {
      category: 'Budgets',
      name: 'Budgets',
      useCategoryPrefix: true,
    },
  },
  BUDGET_OVERVIEW: {
    path: '/app/:company/budgets/:id',
    analytics: {
      category: 'Budgets',
      name: 'Budget overview',
      useCategoryPrefix: true,
    },
  },
  COST_CENTERS: {
    path: '/app/:company/company/cost-centers',
    analytics: {
      category: 'Settings',
      name: 'Cost Centers',
      useCategoryPrefix: true,
    },
  },
  EXPORT_DOWNLOAD: {
    path: '/app/:company/export-download/:exportId',
    analytics: {
      category: 'Exports',
      name: 'Download',
    },
  },
  FX_CALCULATOR: {
    path: '/app/:company/fx-calculator',
    analytics: {
      category: 'Foreign Exchange Calculator',
      name: 'Foreign Exchange Calculator',
    },
  },
  AFFIDAVIT_SIGNATURE: {
    path: '/app/:company/affidavit-signature',
    analytics: {
      category: 'Affidavits',
      name: 'Affidavit signature for missing receipt',
    },
  },
  ONBOARDING_HUB: {
    path: '/app/:company/onboarding-hub',
    analytics: {
      category: 'Onboarding hub',
      name: 'In-app onboarding hub',
    },
  },
  NOT_FOUND: {
    path: '/app/:company/404',
    analytics: {
      category: 'Not found page',
      name: 'Not found page',
    },
  },
} as const);
