import classNames from 'classnames';
import React from 'react';

import { AccountCodePreview } from './AccountCodePreview';
import { AccountNumberPreview } from './AccountNumberPreview';
import { BicPreview } from './BicPreview';
import { IbanPreview } from './IbanPreview';
import { SortCodePreview } from './SortCodePreview';
import styles from './SupplierBankInfoPreview.module.css';
import { type SupplierBankInfos } from '../../models';

type Props = {
  className?: string;
  filter?: (keyof SupplierBankInfos)[];
  bankInfos: SupplierBankInfos;
};

export const SupplierBankInfoPreview = ({
  className,
  filter = ['accountCode', 'accountNumber', 'bic', 'iban', 'sortCode'],
  bankInfos,
}: Props) => {
  const fields = [];

  if (filter.includes('iban') && bankInfos.iban) {
    fields.push(
      <div key="iban">
        <IbanPreview iban={bankInfos.iban} />
      </div>,
    );
  }

  if (filter.includes('bic') && bankInfos.bic) {
    fields.push(
      <div key="bic">
        <BicPreview bic={bankInfos.bic} />
      </div>,
    );
  }

  if (filter.includes('accountNumber') && bankInfos.accountNumber) {
    fields.push(
      <div key="accountNumber">
        <AccountNumberPreview accountNumber={bankInfos.accountNumber} />
      </div>,
    );
  }

  if (filter.includes('accountCode') && bankInfos.accountCode) {
    fields.push(
      <div key="accountCode">
        <AccountCodePreview accountCode={bankInfos.accountCode} />
      </div>,
    );
  }

  if (filter.includes('sortCode') && bankInfos.sortCode) {
    fields.push(
      <div key="sortCode">
        <SortCodePreview sortCode={bankInfos.sortCode} />
      </div>,
    );
  }

  return fields.length ? (
    <section className={classNames(styles.SupplierBankInfoPreview, className)}>
      {fields}
    </section>
  ) : null;
};
