import * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';
import {
  Button,
  colors,
  Icon,
  IconButton,
  Tooltip,
} from '@dev-spendesk/grapes';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useAccountPayableSuppliersListQuery } from 'modules/bookkeep/accounts-payable/hooks/useAccountPayableSuppliersListQuery';
import { useGetSuppliersWithoutAccountPayableListQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSuppliersWithoutAccountPayableListQuery';
import { apiUrl } from 'src/core/utils/api';

import { AccountPayableSuppliersListLoader } from './AccountPayableSuppliersListLoader';
import { AccountPayableSuppliersListSearch } from './AccountPayableSuppliersListSearch';
import { AccountPayableSuppliersMainList } from './AccountPayableSuppliersMainList';
import { type IntegrationStatusWithIntegration } from '../../../integration/status';
import { useBulkArchiveSupplierMutation } from '../../hooks/useBulkArchiveSupplierMutation';
import { useBulkUnarchiveSupplierMutation } from '../../hooks/useBulkUnarchiveSupplierMutation';

type Props = {
  integrationStatus: IntegrationStatusWithIntegration;
  setIsSupplierCreationModalOpen: (isModalOpen: boolean) => void;
};

export const AccountPayableSuppliersList = ({
  integrationStatus,
  setIsSupplierCreationModalOpen,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const [selectedTab, setSelectedTab] = useState<'current' | 'archived'>(
    'current',
  );
  const { search } = useLocation();
  const [supplierSelectionToBulkArchive, setSupplierSelectionToBulkArchive] =
    useState<string[]>([]);
  const [accountPayableIdToBulkArchive, setAccountPayableIdToBulkArchive] =
    useState<string | undefined>();
  const [bulkArchiveSupplier] = useBulkArchiveSupplierMutation();
  const [bulkUnarchiveSupplier] = useBulkUnarchiveSupplierMutation();

  const parsedSearch =
    (queryString.parse(search).search as string | undefined) || '';
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  useDebounce(
    () => {
      setDebouncedSearchValue(parsedSearch);
    },
    500,
    [parsedSearch],
  );

  const suppliersQuery = useAccountPayableSuppliersListQuery(
    debouncedSearchValue,
    selectedTab,
  );

  const suppliersWithoutAccountPayableQuery =
    useGetSuppliersWithoutAccountPayableListQuery(
      debouncedSearchValue,
      selectedTab,
    );

  const handleBulkArchiveOrUnarchiveSelectedSuppliers = () => {
    if (accountPayableIdToBulkArchive !== undefined) {
      if (selectedTab === 'archived') {
        bulkUnarchiveSupplier({
          accountPayableIds: NEA.fromSingleItem(accountPayableIdToBulkArchive),
        });
      } else {
        bulkArchiveSupplier({
          accountPayableIds: NEA.fromSingleItem(accountPayableIdToBulkArchive),
        });
      }

      setSupplierSelectionToBulkArchive([]);
      setAccountPayableIdToBulkArchive(undefined);

      return;
    }

    const supplierIds = NEA.fromArray(supplierSelectionToBulkArchive);

    if (supplierIds !== undefined) {
      if (selectedTab === 'archived') {
        bulkUnarchiveSupplier({ supplierIds });
      } else {
        bulkArchiveSupplier({ supplierIds });
      }
    }

    setSupplierSelectionToBulkArchive([]);
    setAccountPayableIdToBulkArchive(undefined);
  };

  return (
    <>
      <div className="mb-s flex items-center">
        <h1 className="mr-xs title-xl">
          {t('bookkeep.accountsPayable.list.title')}
        </h1>
        <Tooltip
          content={t('bookkeep.accountsPayable.list.titleTooltip')}
          triggerAsChild
        >
          <Icon name="info" color={colors.neutral} />
        </Tooltip>
        <div className="flex-1" />
        <AccountPayableSuppliersListSearch />
        {supplierSelectionToBulkArchive.length === 0 ? (
          <Button
            variant="primary"
            className="ml-s"
            text={t('bookkeep.accountsPayable.list.createSupplier')}
            onClick={() => setIsSupplierCreationModalOpen(true)}
          />
        ) : (
          <Button
            className="ml-s"
            variant="contrasted"
            text={
              selectedTab === 'archived'
                ? t('bookkeep.accountsPayable.panel.bulkUnarchive')
                : t('bookkeep.accountsPayable.panel.bulkArchive')
            }
            onClick={() => handleBulkArchiveOrUnarchiveSelectedSuppliers()}
          />
        )}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={apiUrl(
            `/accounting-integration/chart-of-accounts/supplier-accounts/export`,
            companyId,
          )}
          aria-label={t('bookkeep.accountsPayable.list.downloadSupplierList')}
        >
          <Tooltip
            content={t('bookkeep.accountsPayable.list.downloadSupplierList')}
            triggerAsChild
          >
            <IconButton
              iconName="download"
              variant="border"
              className="ml-xs"
              aria-hidden="true"
            />
          </Tooltip>
        </a>
      </div>
      {(suppliersQuery.status === 'loading' ||
        suppliersWithoutAccountPayableQuery.status === 'loading') && (
        <AccountPayableSuppliersListLoader />
      )}
      {suppliersQuery.status === 'success' &&
        suppliersWithoutAccountPayableQuery.status === 'success' && (
          <AccountPayableSuppliersMainList
            integrationStatus={integrationStatus}
            suppliers={suppliersQuery.data}
            suppliersWithoutAccountPayable={
              suppliersWithoutAccountPayableQuery.data
            }
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            hasNextPage={suppliersQuery.hasNextPage}
            onFetchNextPage={suppliersQuery.fetchNextPage}
            supplierSelectionToBulkArchive={supplierSelectionToBulkArchive}
            setSupplierSelectionToBulkArchive={(_, id, checked) => {
              setSupplierSelectionToBulkArchive((options) => {
                if (checked) {
                  return options.concat(id);
                }
                return options.filter((optionId) => optionId !== id);
              });
            }}
            onAllSuppliersToBulkArchiveSelectionChange={(_, ids, checked) => {
              setSupplierSelectionToBulkArchive(checked ? ids : []);
            }}
            setAccountPayableIdToBulkArchive={setAccountPayableIdToBulkArchive}
          />
        )}
    </>
  );
};
