import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';

export const MembersTableKomboInformation = () => {
  const { t } = useTranslation('global');
  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const isCompanyManagedByIntegration =
    unwrapQuery(isCompanyManagedByIntegrationQueryState)?.isManagedByKombo ??
    false;

  if (!isCompanyManagedByIntegration) {
    return null;
  }
  return (
    <Callout
      className="mb-s"
      title={t('integration.members.companyActiveIntegrationTitle')}
    >
      {t('integration.members.companyActiveIntegrationContent')}
    </Callout>
  );
};
