import isValid from 'date-fns/isValid';
import keys from 'lodash/keys';

type Payment = {
  paid_at: null | string;
};

type PaymentStat = {
  total_amount_billed: number;
  total_count: number;
  total_fx_fee_amount: number;
} & (
  | {
      month: null;
      year: null;
    }
  | {
      month: string;
      year: string;
    }
);

type MergedPaymentAndStats = PaymentStat & {
  payments: Payment[];
};

type Row =
  | (MergedPaymentAndStats & {
      isHeader?: true;
    })
  | Payment;

export function mergePaymentsAndStats(
  payments: Payment[],
  paymentStats: PaymentStat[],
): Row[] {
  if (!payments || !paymentStats) {
    return [];
  }

  // Transform stats to an object [year-month]: stat
  const sectionsByDate: Record<string, MergedPaymentAndStats> =
    paymentStats.reduce((result, stat) => {
      const date = stat.year ? `${stat.year}-${stat.month}` : 'none';
      return {
        ...result,
        [date]: {
          ...stat,
          payments: [],
        },
      };
    }, {});

  // Partition payments in sections (months)
  payments.forEach((payment) => {
    const date = payment.paid_at ? new Date(payment.paid_at) : null;
    const key =
      date && isValid(date)
        ? `${date.getUTCFullYear()}-${date.getUTCMonth()}`
        : 'none';
    const section = sectionsByDate[key];
    if (!section) {
      return;
    }
    section.payments.push(payment);
  });

  // Flatten result
  return keys(sectionsByDate).reduce((allPayments: Row[], sectionName) => {
    const section = sectionsByDate[sectionName];
    if (section.payments.length === 0) {
      return allPayments;
    }
    const header = { ...section, isHeader: true as const };
    return (allPayments ?? []).concat(header, section.payments);
  }, []);
}
