import { CheckboxInput } from '@dev-spendesk/grapes';
import cx from 'classnames';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import React, {
  useState,
  useEffect,
  createRef,
  type SyntheticEvent,
} from 'react';
import { useClickAway } from 'react-use';

import Icon, { ICONS } from 'common/components/legacy/Icon/Icon';
import { useTranslation } from 'common/hooks/useTranslation';

import { isMatchingOption, isAlphaNumeric, computeListHeight } from './helpers';
import checkImg from './images/check.svg';
import { type Option } from './option';
import {
  DELETE_KEY_CODE,
  FALLBACK_IMAGE,
  LIST_OFFSET,
  ESCAPE_KEY_CODE,
} from './variables';
import './AutoCompleteMulti.scss';

type Props = {
  options: Option[];
  selectedValues: string[];
  placeholder?: string;
  selectAllOptionsKey?: string;
  withCheckboxes?: boolean;
  withoutTags?: boolean;
  withoutBorder?: boolean;
  closeOnSelect?: boolean;
  placeholderIcon?: React.ReactElement;
  onSelect(selectedValues: string[]): void;
};

export const AutoCompleteMulti = ({
  options,
  placeholder,
  selectAllOptionsKey,
  selectedValues,
  withoutBorder = false,
  withCheckboxes = false,
  withoutTags = false,
  closeOnSelect = false,
  placeholderIcon,
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [rootHeight, setRootHeight] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const rootReference = createRef<HTMLDivElement>();
  const inputReference = createRef<HTMLInputElement>();

  useClickAway(rootReference, () => setIsOpen(false));

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.keyCode === ESCAPE_KEY_CODE) {
        // close dropdown on escape
        setIsOpen(false);
      }
    };
    document.addEventListener('keydown', listener, false);
    return () => {
      document.removeEventListener('keydown', listener, false);
    };
  }, []);

  useEffect(() => {
    if (rootReference.current) {
      const computedStyle = getComputedStyle(rootReference.current);
      if (computedStyle.height) {
        setRootHeight(Number.parseInt(computedStyle.height));
      }
    }
  }, [rootReference.current, setRootHeight]);

  const filteredOptions = options.filter((option) =>
    isMatchingOption(option, searchValue),
  );
  const shouldRenderOptionList = Boolean(isOpen && filteredOptions.length);

  const isSelected = (value: string) => selectedValues.includes(value);

  const checkKeyCode = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isAlphaNumeric(event.keyCode)) {
      setIsOpen(true);
    }
  };

  const handleSearchChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setSearchValue((event.target as HTMLInputElement).value ?? '');
  };

  const handleOptionClick = (value: string) => {
    if (inputReference.current) {
      inputReference.current.focus();
    }
    if (closeOnSelect) {
      setIsOpen(false);
    }
    setSearchValue('');
    return isSelected(value)
      ? onSelect(without(selectedValues, value))
      : onSelect([...selectedValues, value]);
  };

  const handleAllOptionClick = () => {
    if (inputReference.current) {
      inputReference.current.focus();
    }
    if (closeOnSelect) {
      setIsOpen(false);
    }
    setSearchValue('');
    if (selectedValues.length) {
      return onSelect([]);
    }
    const filteredOptionsValues = filteredOptions.map((option) => option.value);
    return onSelect(uniq(selectedValues.concat(filteredOptionsValues)));
  };

  const handleOptionRemove = (value: string) => {
    setSearchValue('');
    onSelect(without(selectedValues, value));
    if (inputReference.current) {
      inputReference.current.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      !withoutTags &&
      !searchValue.length &&
      event.keyCode === DELETE_KEY_CODE &&
      document.activeElement === inputReference.current
    ) {
      const value = (selectedValues ?? []).at(-1);
      if (value) {
        handleOptionRemove(value);
      }
    }
  };

  const renderSelectedOption = (selectedValue: string) => {
    const option = options.find((o) => o.value === selectedValue);
    if (!option) {
      return null;
    }
    return (
      <div
        data-testid={`test__autoCompleteMultiTag-${option.value}`}
        className="AutoCompleteMulti__tag"
        key={option.value}
      >
        <div
          className="AutoCompleteMulti__avatar AutoCompleteMulti__tag__avatar"
          style={{
            backgroundImage: `url(${option.image || FALLBACK_IMAGE})`,
          }}
        />
        <span>{option.label}</span>
        <Icon
          icon={ICONS.CROSS}
          width={30}
          height={30}
          onClick={() => handleOptionRemove(option.value)}
        />
      </div>
    );
  };

  const getCheckboxIsSelected = (value: string) => {
    return (
      isSelected(value) ||
      (value === 'all' && filteredOptions.length === selectedValues.length)
    );
  };

  const getCheckboxIsIndeterminate = (value: string) => {
    return (
      value === 'all' &&
      selectedValues.length !== 0 &&
      filteredOptions.length !== selectedValues.length
    );
  };

  // TODO: could be extracted in an external `AutoCompleteMultiOption` component
  const renderOption = ({
    value,
    label,
    sublabel,
    image,
    displayMode,
    onOptionClick,
  }: {
    value: string;
    label: string;
    sublabel?: string;
    image?: string;
    displayMode?: 'normal' | 'light';
    onOptionClick(value: string): void;
  }) => (
    <li
      className={cx('AutoCompleteMulti__list__option', {
        'AutoCompleteMulti__list__option--light': displayMode === 'light',
      })}
      key={value}
    >
      <div
        role="presentation"
        className="AutoCompleteMulti__list__option__wrapper"
        onClick={() => onOptionClick(value)}
      >
        {!withCheckboxes && isSelected(value) && (
          <div className="AutoCompleteMulti__list__option__check-wrapper">
            <img
              src={checkImg}
              className="AutoCompleteMulti__list__option__check-img"
              alt=""
            />
          </div>
        )}
        {withCheckboxes && (
          <CheckboxInput
            isChecked={getCheckboxIsSelected(value)}
            onChange={() => onOptionClick(value)}
            isIndeterminate={getCheckboxIsIndeterminate(value)}
            className="AutoCompleteMulti__list__option__checkbox"
          />
        )}
        {image && (
          <div
            style={{
              backgroundImage: `url(${image})`,
            }}
            className="AutoCompleteMulti__avatar AutoCompleteMulti__list__option__avatar"
          />
        )}
        <span className="AutoCompleteMulti__list__option__label">
          {label}
          {sublabel ? (
            <span className="AutoCompleteMulti__list__option__sublabel">
              {sublabel}
            </span>
          ) : null}
        </span>
      </div>
    </li>
  );

  const allLabel =
    selectedValues.length > 0 ? t('misc.unselectAll') : t('misc.selectAll');

  return (
    <div
      className={cx('AutoCompleteMulti', {
        'AutoCompleteMulti__without-border': withoutBorder,
      })}
      ref={rootReference}
      onKeyDown={handleKeyDown}
      role="presentation"
      data-testid="test__autoCompleteMulti"
    >
      <div className="AutoCompleteMulti__wrapper">
        {placeholderIcon}
        {withoutTags ? null : selectedValues.map(renderSelectedOption)}
        <input
          className={cx('AutoCompleteMulti__input', {
            'AutoCompleteMulti__input--without-placeholder':
              !withoutTags && selectedValues.length,
          })}
          ref={inputReference}
          value={searchValue}
          onChange={handleSearchChange}
          onClick={() => setIsOpen(!isOpen)}
          placeholder={
            !withoutTags && options.some(({ value }) => isSelected(value))
              ? undefined
              : placeholder
          }
          onKeyDown={checkKeyCode}
        />
      </div>
      {shouldRenderOptionList && (
        <ul
          className="AutoCompleteMulti__list"
          style={{
            height: computeListHeight(
              selectAllOptionsKey
                ? filteredOptions.length + 1
                : filteredOptions.length,
            ),
            top: rootHeight + LIST_OFFSET,
          }}
        >
          {selectAllOptionsKey &&
            filteredOptions.length > 0 &&
            renderOption({
              value: 'all',
              label: allLabel,
              sublabel: `(${filteredOptions.length.toString()} ${t(
                selectAllOptionsKey,
                { count: filteredOptions.length },
              ).toLowerCase()})`,
              onOptionClick: handleAllOptionClick,
            })}
          {filteredOptions.map((option) =>
            renderOption({
              ...option,
              image: option.image || FALLBACK_IMAGE,
              onOptionClick: handleOptionClick,
            }),
          )}
        </ul>
      )}
    </div>
  );
};
