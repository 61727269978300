import { IconButton, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import type { i18n as nativeI18n, TFunction } from 'i18next';
import React, {
  createRef,
  PureComponent,
  type ReactNode,
  type RefObject,
} from 'react';
import { withTranslation } from 'react-i18next';

import {
  FileViewerToolbar,
  type Modes,
} from '../FileViewerToolbar/FileViewerToolbar';
import { InvalidProofBanner } from '../InvalidProofBanner/InvalidProofBanner';

import './ImageViewer.scss';

export type ImageViewerProps = {
  url: string;
  className?: string;
  toolbarMode?: Modes;
  withZoom?: boolean;
  withRotate?: boolean;
  withDeleteTooltip?: ReactNode;
  isInvalid?: boolean;
  invalidProofReason?: string;
  leftActions?: ReactNode[];
  withoutCrossOrigin?: boolean;
  onDownload?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  onToggleZoom?: (isZoomed: boolean) => void;
  t: TFunction<'global'>;
  tReady: boolean;
  i18n: nativeI18n;
};

type State = {
  originX: number;
  originY: number;
  scale: number;
  isZoomed: boolean;
  rotation: number | null;
  isLoaded: boolean;
  isRotating: boolean;
};

class ImageViewerWithTransalation extends PureComponent<
  ImageViewerProps,
  State
> {
  activeZoomStyles: { scale: number } = { scale: 2 };
  idleZoomStyles = { scale: -1, originX: 0, originY: 0 };
  imageRef = createRef<HTMLImageElement>();
  canvasRef: RefObject<HTMLCanvasElement> | null = null;
  // Canvas API use angles in radians, the following corresponds to 0, 90, 180 and 270 degrees
  ANGLES = [0 * Math.PI, 0.5 * Math.PI, Math.PI, 1.5 * Math.PI];
  TRANSITION_DURATION = 200;
  canvasCtx: CanvasRenderingContext2D | null = null;
  imgWidth = 0;
  imgHeight = 0;

  static defaultProps = {
    className: '',
    withZoom: false,
    withRotate: false,
    isInvalid: false,
    leftActions: [],
    withoutCrossOrigin: false,
    onToggleZoom: () => {},
  };

  constructor(props: ImageViewerProps) {
    super(props);
    this.state = {
      originX: 0,
      originY: 0,
      scale: -1,
      isZoomed: false,
      rotation: 0,
      isLoaded: false,
      isRotating: false,
    };
    this.canvasRef = props.withRotate ? createRef() : null;
  }

  componentDidMount() {
    this.canvasCtx = this.props.withRotate
      ? this.canvasRef?.current?.getContext('2d') || null
      : null;
  }

  UNSAFE_componentWillReceiveProps(nextProps: ImageViewerProps) {
    if (nextProps.url !== this.props.url) {
      // We've got a new image, clear cache and reset rotation
      this.setState({
        rotation: 0,
      });
    }

    if (nextProps.withRotate && !this.props.withRotate) {
      this.canvasRef = createRef();
    }
  }

  componentDidUpdate(previousProps: ImageViewerProps) {
    if (this.props.withRotate && !previousProps.withRotate) {
      this.canvasCtx = this.canvasRef?.current?.getContext('2d') || null;
    }
  }

  onMouseOver = (event: React.MouseEvent) => {
    if (!this.state.isZoomed) {
      return;
    }
    this.zoomIn(this.calculateZoomPosition(event));
  };

  onMouseMove = (event: React.MouseEvent) => {
    if (!this.state.isZoomed) {
      return;
    }
    this.zoomIn(this.calculateZoomPosition(event));
  };

  onMouseOut = () => {
    if (!this.state.isZoomed) {
      return;
    }
    this.zoomOut();
  };

  getComputedStyle = () => {
    const { withZoom } = this.props;

    if (!withZoom) {
      return null;
    }

    const { isZoomed, scale, originX, originY } = this.state;
    return {
      transform: scale > 0 ? `scale(${scale})` : 'initial',
      transformOrigin: `${originX}% ${originY}%`,
      cursor: isZoomed ? 'zoom-out' : 'zoom-in',
      height: isZoomed ? '100%' : 'auto',
    };
  };

  calculateZoomPosition = (mouseEvent: React.MouseEvent) => {
    const {
      nativeEvent: { pageX, pageY, target },
    } = mouseEvent;

    const { x, y, clientWidth, parentElement } = target as HTMLImageElement;

    const { clientHeight } = parentElement as Element;

    const originX = Math.min(((pageX - x) / clientWidth) * 100, 100);
    const originY = Math.min(((pageY - y) / clientHeight) * 100, 100);

    return { originX, originY };
  };

  zoomIn = ({ originX, originY }: { originX: number; originY: number }) => {
    const wasZoomed = this.state.isZoomed;
    this.setState(
      {
        isZoomed: true,
        originX,
        originY,
        ...this.activeZoomStyles,
      },
      () => {
        if (this.props.onToggleZoom && !wasZoomed) {
          this.props.onToggleZoom(this.state.isZoomed);
        }
      },
    );
  };

  zoomOut = () => {
    const wasZoomed = this.state.isZoomed;
    this.setState(
      {
        isZoomed: false,
        ...this.idleZoomStyles,
      },
      () => {
        if (this.props.onToggleZoom && wasZoomed) {
          this.props.onToggleZoom(this.state.isZoomed);
        }
      },
    );
  };

  toggleZoom = (mouseEvent: React.MouseEvent) => {
    if (!this.props.withZoom) {
      return;
    }

    return this.state.isZoomed
      ? this.zoomOut()
      : this.zoomIn(this.calculateZoomPosition(mouseEvent));
  };

  getMainActions = () => {
    const { withRotate, onDownload, withDeleteTooltip, onDelete, t } =
      this.props;
    const { isLoaded, isRotating } = this.state;
    const mainActions = [];

    if (withRotate) {
      mainActions.push(
        <IconButton
          key="rotate"
          variant="borderless"
          onClick={this.rotateImage}
          iconName="rotate"
          aria-label={t('misc.rotate')}
          /**
           * We need the image to be fully loaded to get its dimensions and be able to rotate it and we want to
           * disable rotation when its already in progress
           */

          isDisabled={!isLoaded || isRotating}
        />,
      );
    }

    if (onDownload) {
      mainActions.push(
        <IconButton
          variant="borderless"
          key="download"
          onClick={onDownload}
          iconName="download"
          aria-label={t('misc.download')}
        />,
      );
    }

    if (onDelete) {
      mainActions.push(
        <Tooltip
          key="delete"
          content={withDeleteTooltip}
          placement="right"
          maxWidth={544}
          isDisabled={!withDeleteTooltip}
        >
          <IconButton
            variant="borderless"
            aria-label={t('misc.delete')}
            onClick={onDelete}
            iconName="trash"
            isDisabled={Boolean(withDeleteTooltip)}
          />
        </Tooltip>,
      );
    }

    return mainActions;
  };

  getLeftActions = () => {
    const { onClose, leftActions = [] } = this.props;

    if (onClose) {
      const mainActions = this.getMainActions();
      return leftActions.concat(mainActions);
    }

    return leftActions;
  };

  getRightActions = () => {
    const { onClose, t } = this.props;
    const mainActions = this.getMainActions();

    return onClose
      ? [
          <IconButton
            key="close"
            variant="borderless"
            aria-label={t('misc.close')}
            onClick={onClose}
            iconName="cross"
          />,
        ]
      : mainActions;
  };

  handleOriginalImageLoaded = () => {
    const image = this.imageRef.current;

    if (!image) {
      return;
    }

    // Store original image natural size
    this.imgWidth = image.naturalWidth;
    this.imgHeight = image.naturalHeight;
    // Store original image src as rotation 0 source so we don't recalculate it
    this.setState({ isLoaded: true });
  };

  /**
   * Rotation works by drawing the original image to a canvas and rotating it.
   * Using a CSS rotation wouldn't work because it's a transform and that doesn't actually affect the image box in the
   * DOM, so it would be too complicated to have the viewer match the size of the rotated image and position it
   * correctly.
   * Ideally, we would like to use an OffscreenCanvas and defer this to a worker, because canvas manipulation is a
   * blocking operation on the main thread which may hang the UI with large images. But unfortunately, OffscreenCanvas
   * is pretty new and has no browser support at all as of today (August 2018).
   * Stolen from https://jsfiddle.net/Hq7p2/326/
   * TODO: use OffscreenCanvas
   */
  rotateImage = () => {
    // Store the next rotation position
    const nextRotation = ((this.state.rotation ?? 0) + 1) % this.ANGLES.length;

    // Flag rotation in progress because we want a loading state as the operation can take a few seconds
    this.setState({ isRotating: true }, () => {
      // Wrap in a setTimeout so the browser has time to apply the rotating styles before we block the thread
      setTimeout(() => {
        const image = this.imageRef.current;
        const canvas = this.canvasRef?.current;

        if (!canvas || !image) {
          return;
        }

        if (nextRotation === 0 || nextRotation === 2) {
          // When angle is 0 or 180 we want canvas dimensions to match image dimensions
          canvas.width = this.imgWidth;
          canvas.height = this.imgHeight;
        } else {
          // When angle is 90 or 270 we want canvas dimensions to be switched because we will change orientation
          canvas.width = this.imgHeight;
          canvas.height = this.imgWidth;
        }

        // Place cursor at center
        this.canvasCtx?.translate(canvas.width * 0.5, canvas.height * 0.5);
        this.canvasCtx?.rotate(this.ANGLES[nextRotation]);
        // Put back the cursor before drawing the image
        this.canvasCtx?.translate(-this.imgWidth * 0.5, -this.imgHeight * 0.5);
        this.canvasCtx?.drawImage(image, 0, 0);

        // Reset canvas transforms for future rotations
        this.canvasCtx?.setTransform(1, 0, 0, 1, 0, 0);
        this.setState({
          rotation: nextRotation,
          isRotating: false,
        });
      }, this.TRANSITION_DURATION);
    });
  };

  render() {
    const {
      url,
      className,
      toolbarMode,
      withRotate,
      isInvalid,
      invalidProofReason,
      withoutCrossOrigin,
    } = this.props;
    const { rotation, isRotating } = this.state;

    return (
      <div
        className={cx('ImageViewer', className, {
          'ImageViewer--rotating': isRotating,
        })}
      >
        <FileViewerToolbar
          leftActions={this.getLeftActions()}
          rightActions={this.getRightActions()}
          mode={toolbarMode}
        />
        {isInvalid && <InvalidProofBanner reason={invalidProofReason} />}
        <div className="ImageViewer__zoomboxContainer">
          <div
            className="ImageViewer__zoombox"
            onMouseOver={this.onMouseOver}
            onMouseMove={this.onMouseMove}
            onMouseOut={this.onMouseOut}
            onClick={this.toggleZoom}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {withRotate && (
              <canvas
                ref={this.canvasRef}
                className="ImageViewer__canvas"
                draggable="false"
                style={{
                  ...this.getComputedStyle(),
                  display: rotation !== 0 ? 'block' : 'none',
                }}
              />
            )}
            {/* We need to keep this one in the DOM to be able to access the original image through the React ref */}
            <img
              ref={this.imageRef}
              src={url}
              crossOrigin={withoutCrossOrigin ? undefined : 'anonymous'}
              alt="invoice"
              className="ImageViewer__image"
              draggable="false"
              onLoad={this.handleOriginalImageLoaded}
              style={{
                ...this.getComputedStyle(),
                display: rotation !== 0 ? 'none' : 'block',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const ImageViewer = withTranslation('global')(
  ImageViewerWithTransalation,
);
