import { IconButton } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './PanelTopTitle.module.css';

interface Props {
  title: string;
  className?: string;
  onClose?: () => void;
  onGoBack?: () => void;
}

const PanelTopTitle = ({ title, className, onGoBack, onClose }: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className={classNames(styles.PanelTopTitle, className)}>
      {onGoBack && (
        <IconButton
          className="mr-xs"
          iconName="caret-left"
          variant="borderless"
          onClick={onGoBack}
          hasNegativeMargins
          aria-label={t('misc.goBack')}
        />
      )}
      <div className="text-complementary title-l">{title}</div>
      {onClose && (
        <IconButton
          className="ml-auto"
          iconName="cross"
          variant="borderless"
          onClick={onClose}
          hasNegativeMargins
          aria-label={t('misc.close')}
        />
      )}
    </div>
  );
};

export default PanelTopTitle;
