import { isValid } from 'date-fns';
import { checkVAT, countries } from 'jsvat-next';

import { toAmount } from 'src/core/utils/money';

const Validations = {
  validateAmount(amount) {
    const clearedAmount = toAmount(amount);
    // FIXME: Don't use isNaN
    // eslint-disable-next-line no-restricted-globals, unicorn/prefer-number-properties
    return typeof clearedAmount === 'number' && !isNaN(clearedAmount);
  },
  validatePositiveAmount(amount) {
    return Validations.validateAmount(amount) && amount > 0;
  },
  validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },
  validateDateRange(dateRange = '') {
    const [from, to] = dateRange.split('/');
    return isValid(new Date(from)) && isValid(new Date(to));
  },
  validateVat(vat) {
    if (!vat) {
      return true;
    }

    return checkVAT(vat, countries).isValid;
  },
  validateDomain(domain) {
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainPattern.test(domain);
  },
};

export default Validations;
