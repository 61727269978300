import {
  AutocompleteNoOptions,
  Avatar,
  colors,
  DropdownItem,
  Icon,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useApproversQuery } from '../../hooks/useApproversQuery';
import { type SelectedApprover } from '../../types';

type Props = {
  onSelect: (selectedApprover: SelectedApprover) => void;
  onBlur: () => void;
  currentApproverId: string;
  className?: string;
  costCenterOwnerId: string;
};

export const EditApproverDropdown = ({
  onSelect,
  costCenterOwnerId,
  onBlur,
  currentApproverId,
  className,
}: Props) => {
  const approversQueryState = useApproversQuery();
  const { t } = useTranslation('global');

  if (approversQueryState.status !== 'success') {
    return null;
  }

  const approvers = approversQueryState.data
    .filter((member) => member.id !== currentApproverId)
    .map((member) => ({
      key: member.id,
      label: member.fullname ?? member.email,
      avatar: member.avatar,
      isCostCenterOwner: false,
    }));

  const costCenterOwner = approversQueryState.data.find(
    (member) => member.id === costCenterOwnerId,
  );
  const approversWithCostCenterOwner =
    appConfig.isApprovalFlowSelectionByCCOwnerEnabled
      ? [
        {
          key: costCenterOwnerId,
          label: costCenterOwner?.fullname ?? costCenterOwner?.email ?? '',
          avatar: costCenterOwner?.avatar,
          isCostCenterOwner: true,
        },
        ...approvers,
      ]
      : approvers;

  return (
    <AutocompleteSearch
      name="ownerId"
      className={className}
      fit="parent"
      // ref={searchReference} TODO: Implement ref for Autocomplete Grapes component
      options={approversWithCostCenterOwner}
      isInputVisible
      isAutocompletePlace
      placeholder={t('teams.approvalFlows.changeApproverPlaceholder')}
      value={undefined}
      renderPrefix={() => <Icon name="search" />}
      onBlur={() => {
        onBlur();
      }}
      renderOption={(option, state) => {
        if (option.isCostCenterOwner) {
          return (
            <DropdownItem
              {...option}
              {...state}
              label={
                <div className="flex items-center gap-xs">
                  <span className="text-complementary title-m">
                    {t('teams.approvalFlows.costCenterOwner')}
                  </span>
                  <Avatar
                    size="s"
                    src={option.avatar ?? ''}
                    text={option.label}
                  />
                  <span>{option.label}</span>
                </div>
              }
              prefix={
                <div className="mt-xxs flex h-m w-m items-center justify-center rounded-[50%] bg-primary-lighter">
                  <Icon name="person" color={colors.white} />
                </div>
              }
            />
          );
        }
        return (
          <DropdownItem
            {...option}
            {...state}
            prefix={
              <Avatar size="s" src={option.avatar ?? ''} text={option.label} />
            }
          />
        );
      }}
      onSelect={(option) => {
        if (option) {
          if (option.isCostCenterOwner) {
            onSelect({ type: 'costCenterOwner' });
            return;
          }
          onSelect({
            id: option.key,
            type: 'user',
          });
        }
      }}
      renderNoOptions={(rawValue) => (
        <AutocompleteNoOptions className="text-center body-m">
          <div>
            <Trans
              i18nKey="misc.noOptions"
              values={{ account: rawValue }}
              components={[
                <span key="complementary" className="text-complementary" />,
              ]}
            />
          </div>
        </AutocompleteNoOptions>
      )}
    />
  );
};
