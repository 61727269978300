/* eslint-disable promise/prefer-await-to-then */
import { Callout } from '@dev-spendesk/grapes';
import cx from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';

import { ICONS } from "src/core/common/components/legacy/Icon/Icon";
import Label from "src/core/common/components/legacy/Label/Label";
import TextInput from "src/core/common/components/legacy/TextInput/TextInput";
import { apiUrl } from "src/core/utils/api";
import { grey6Cheerful } from "src/core/utils/color-palette";
import {
  getCfLabel,
  prettyCfValue,
  formatCustomFieldValue,
  userCanCreateCfValue,
} from "src/core/utils/custom-fields";

import './CustomFieldAutocomplete.scss';

/**
 * This component is an async-based auto-complete.
 * No values are offered by default, the user must
 * start typing something so as to fetch matching results.
 *
 * It handles both selection of an existing value – proposed – and
 * the eventual creation of a new one that do not match anything
 * (when hitting "enter" whilst no value is chosen in the dropdown)
 */
class CustomFieldAutocomplete extends Component {
  static propTypes = {
    user: PropTypes.object,
    customField: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    entityCf: PropTypes.object,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    hasLabel: PropTypes.bool,
    isReadMode: PropTypes.bool,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    onSelectedValue: PropTypes.func,
    onNewValue: PropTypes.func,
    t: PropTypes.func.isRequired,
    defaultSearch: PropTypes.string,
  };

  static defaultProps = {
    user: null,
    entityCf: undefined,
    hasLabel: true,
    isReadMode: false,
    disabled: false,
    isValid: true,
    className: '',
    placeholder: null,
    onSelectedValue: noop,
    onNewValue: noop,
  };

  inputRef = null;

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      search:
        prettyCfValue(props.customField, props.entityCf) ||
        this.props.defaultSearch ||
        '',
      displayResults: false,
      keyboardSelectedItemId: -1,
    };
  }

  search = (query) => {
    const { company, customField, t } = this.props;
    const url = apiUrl(
      `/custom-fields/${customField.id}/values?search=${encodeURIComponent(
        query,
      )}`,
      company.id,
    );
    return fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(t('forms.customField.couldNotFindValues'));
        }
        return res.json();
      })
      .then((values) =>
        map(get(values, 'custom_fields_values'), (value) =>
          formatCustomFieldValue(value, customField),
        ),
      );
  };

  triggerBlur = () => {
    if (this.inputRef) {
      this.inputRef.blur();
    }
  };

  toggleResults = () => {
    this.setState({ displayResults: !this.state.displayResults });
  };

  selectValue = (value) => {
    this.props.onSelectedValue(value);
    this.setState({
      displayResults: false,
      search: value.name,
    });
  };

  handleClickOutside = () => {
    this.setState({
      displayResults: false,
      keyboardSelectedItemId: -1,
    });
  };

  handleKeyDown = (e) => {
    const { search, values, keyboardSelectedItemId } = this.state;
    const navigationEvents = {
      13: () => {
        // Enter key
        const currentValue = get(values, keyboardSelectedItemId);
        if (keyboardSelectedItemId !== -1 && currentValue) {
          this.selectValue(currentValue);
        } else {
          this.props.onNewValue(search);
          this.setState({ displayResults: false });
        }
      },
      40: () => {
        // Arrow down key
        const selectedId =
          keyboardSelectedItemId < values.length - 1
            ? keyboardSelectedItemId + 1
            : 0;
        this.setState({ keyboardSelectedItemId: selectedId });
      },
      38: () => {
        // Arrow up key
        const selectedId =
          keyboardSelectedItemId > 0
            ? keyboardSelectedItemId - 1
            : values.length - 1;
        this.setState({ keyboardSelectedItemId: selectedId });
      },
      27: this.triggerBlur, // Escape key
    };

    if (navigationEvents[e.keyCode]) {
      navigationEvents[e.keyCode]();
    }
  };

  handleChange = (e) => {
    const query = e.target.value;
    this.setState({ search: query });

    if (this.props.customField.type === 'boolean' || !query) {
      return;
    }

    this.search(query)
      .then((values) =>
        this.setState({
          values,
          keyboardSelectedItemId: -1,
          displayResults: size(values) > 0,
        }),
      )
      .catch(noop);
  };

  isActiveItem = (_, index) => {
    // Selected item by keyboard navigation (up/down)
    return this.state.keyboardSelectedItemId === index;
  };

  renderField() {
    const {
      t,
      user,
      customField,
      placeholder,
      disabled,
      hasLabel,
      isReadMode,
      isValid,
    } = this.props;
    const { search } = this.state;
    const totalValues = get(customField, 'total_values', 0);
    const hasValues = size(this.state.values) > 0;
    const createValueInfo =
      !isReadMode && user && userCanCreateCfValue(user, customField)
        ? t('forms.customField.hitEnterToSaveOld')
        : null;
    const labelId = `customField-selectLabel-${customField.id}`;

    return (
      <div className="CustomFieldAutocomplete__field">
        {hasLabel && (
          <Label
            id={labelId}
            helpText={!customField.is_required && t('misc.optional')}
          >
            {getCfLabel(customField)}
          </Label>
        )}
        {totalValues > 0 && !customField.deleted_at && !disabled && (
          <Callout
            variant="info"
            className="CustomFieldAutocomplete__field-info"
            title={t('forms.customField.tooManyValues', { count: totalValues })}
          >
            {createValueInfo && <p>{createValueInfo}</p>}
          </Callout>
        )}
        <TextInput
          labelledBy={labelId}
          ref={(input) => {
            this.inputRef = input;
          }}
          placeholder={placeholder || t('misc.chooseValue')}
          value={search}
          size="block"
          className="form-item__field"
          icon={ICONS.BOTTOM_ARROW_HEAD}
          iconAction={hasValues ? this.toggleResults : noop}
          iconColor={grey6Cheerful}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={() => this.setState({ displayResults: hasValues })}
          disabled={disabled}
          isValid={isValid}
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
        />
        {!isValid && (
          <Callout
            variant="alert"
            className="CustomFieldAutocomplete__field-error"
            title={t('forms.customField.errorAlt')}
          />
        )}
      </div>
    );
  }

  renderResultRow = (cfv, index) => (
    <div
      key={index}
      onClick={() => this.selectValue(cfv)}
      className={cx('autocomplete__result-row', {
        active: this.isActiveItem(cfv, index),
      })}
    >
      <span>{cfv.name}</span>
    </div>
  );

  renderResults = () => {
    const { values, displayResults } = this.state;
    if (!displayResults) {
      return null;
    }

    return (
      <div className="autocomplete__results block">
        {map(values, this.renderResultRow)}
      </div>
    );
  };

  render() {
    const { disabled, className } = this.props;

    return (
      <div
        className={cx(
          'CustomFieldAutocomplete',
          'autocomplete__container',
          className,
          { disabled },
        )}
      >
        {this.renderField()}
        <div className="autocomplete__container block">
          {this.renderResults()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(onClickOutside(CustomFieldAutocomplete));
