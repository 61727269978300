import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './FileViewerModal.scss';

const KEYCODE_ESC = 27;

class FileViewerModal extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
  };

  static defaultProps = {
    fullWidth: false,
    className: '',
  };

  componentDidMount() {
    window.addEventListener('keydown', this.listenToKeyboardEvents);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenToKeyboardEvents);
  }

  onClickOutside = (e) => {
    if (this.modalRootRef === e.target) {
      this.props.onClose();
    }
  };

  listenToKeyboardEvents = (e) => {
    if (e.keyCode === KEYCODE_ESC) {
      this.props.onClose();
    }
  };

  render() {
    const { fullWidth, className, children } = this.props;

    return ReactDOM.createPortal(
      <div
        className={cx(
          'LegacyFileViewerModal dialog dialog-dark active',
          className,
          { 'LegacyFileViewerModal--fullwidth': fullWidth },
        )}
        onClick={this.onClickOutside}
        ref={(element) => {
          this.modalRootRef = element;
        }}
      >
        <div className="dialog__content">{children}</div>
      </div>,
      document.getElementById('react__file-viewer-modal'),
    );
  }
}

export default FileViewerModal;
