import { FormField, TextInput, Select } from '@dev-spendesk/grapes';
import React, {
  type Dispatch,
  type SetStateAction,
  useState,
  useEffect,
} from 'react';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useGetValidTaxCodesQuery } from '../../../../../../hooks/useGetValidTaxCodesQuery';
import { type ModalState } from '../ModalState';
import styles from '../TaxAccountLocalOnlySection.module.css';

interface Props {
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
}

const validVatRates = ['0', '5', '5.5', '7', '10', '13', '16', '19', '20'];

type BuOption = {
  key: string;
  label: string;
};

const makeVatRateOption = (vatRate: string): BuOption => {
  return { key: vatRate, label: vatRate };
};

export const DatevAddEditForm = ({ formState, setFormState }: Props) => {
  const { t } = useTranslation();
  const [buCodes, setBuCodes] = useState<BuOption[]>([]);

  const useGetValidTaxCodes = useGetValidTaxCodesQuery(false);
  const buCodesWithRates =
    useGetValidTaxCodes &&
    useGetValidTaxCodes.status === 'success' &&
    useGetValidTaxCodes.data
      ? useGetValidTaxCodes.data.buCodes
      : [];

  useEffect(() => {
    if (formState.kind === 'addVat' && formState.rate) {
      const buCodeOptions = buCodesWithRates
        .filter((code) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          code.rates!.includes(Number.parseFloat(formState.rate)),
        )
        .map((buCode) => ({
          key: buCode.code,
          label: buCode.code,
        }));
      setBuCodes(buCodeOptions);
    }
  }, [formState]);

  return (
    <>
      {formState.kind === 'addVat' && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.vatAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={formState.name}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value,
                  inputChanged: true,
                })
              }
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.settings.vatAccountsTable.vatRate')}
            className={styles.formField}
          >
            <Select
              fit="parent"
              options={validVatRates.map((validVatRate) =>
                makeVatRateOption(validVatRate),
              )}
              value={
                formState.rate
                  ? { key: formState.rate, label: formState.rate }
                  : undefined
              }
              onSelect={(option) => {
                setFormState({
                  ...formState,
                  rate: option.key,
                  code: '',
                  inputChanged: true,
                });
              }}
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.datev.vatAddEdit.buCode')}
            className={styles.formField}
          >
            <AutocompleteSearch
              fit="parent"
              options={buCodes}
              value={
                formState.code
                  ? { key: formState.code, label: formState.code }
                  : undefined
              }
              onSelect={(option) => {
                if (option) {
                  setFormState({
                    ...formState,
                    code: option.key,
                    inputChanged: true,
                  });
                }
              }}
              renderNoOptions={() => (
                <>{t('bookkeep.integrations.datev.vatAddEdit.noOption')}</>
              )}
              isDisabled={!formState.rate}
            />
          </FormField>
        </>
      )}
    </>
  );
};
