import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

const LoaderTxt = () => {
  const { t } = useTranslation('global');
  return (
    <div className="page__content page__content--full page__content--empty">
      <div className="txt-low fs-small u-tc">
        <strong className="co-title ff-m fs-extra u-db">
          {t('misc.loading')}
          ...
        </strong>
        {t('misc.loadingContent')}
      </div>
    </div>
  );
};

export default LoaderTxt;
