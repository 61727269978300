import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ExpenseCategoryValue } from '../../../../models/expenseCategory';
import { type CostCenter } from '../../../PayablePanelContainer';
import { type AccountingFormValues } from '../../hooks/usePayableAccountingEditForm';
import { PayableAccountingSectionCostCenter } from '../PayableAccountingSectionCostCenter';
import { PayableAccountingSectionExpenseCategory } from '../PayableAccountingSectionExpenseCategory';

type Props = {
  costCenters: CostCenter[];
  expenseCategoryValues: ExpenseCategoryValue[];
  items: {
    label: string;
    value: string;
    type?: string;
    expenseCategoryValueId?: string;
    deleted?: boolean;
  }[];
};

export const PayableAccountingEditSection = ({
  costCenters,
  expenseCategoryValues,
  items,
  // formik props
  values,
  errors,
  setFieldValue,
}: Props & FormikProps<AccountingFormValues>) => {
  const { t } = useTranslation('global');
  return (
    <div className="PayableDetailsEditSection">
      {items.map((item) => {
        switch (item.type) {
          case 'costCenter':
            return (
              <div className="mb-m flex flex-col" key={item.label}>
                <PayableAccountingSectionCostCenter
                  costCenters={costCenters}
                  costCenter={values.costCenter}
                  onChange={(costCenter) => {
                    setFieldValue('costCenter', costCenter);
                  }}
                  error={errors.costCenter}
                />
              </div>
            );
          case 'expenseCategory':
            return (
              <div className="mb-m flex flex-col" key={item.label}>
                <PayableAccountingSectionExpenseCategory
                  expenseCategoryValues={expenseCategoryValues}
                  expenseCategoryValue={values.expenseCategoryValue}
                  onChange={(expenseCategory) => {
                    setFieldValue('expenseCategoryValue', expenseCategory);
                  }}
                  error={errors.expenseCategoryValue}
                />
              </div>
            );
          default:
            return (
              <div className="mb-m flex flex-col" key={item.label}>
                <FormField
                  label={item.label}
                  alertMessage={
                    item.deleted
                      ? t('payables.panel.deletedTooltip')
                      : undefined
                  }
                >
                  <TextInput
                    fit="parent"
                    isInvalid={false}
                    value={item.value}
                    isDisabled
                  />
                </FormField>
              </div>
            );
        }
      })}
    </div>
  );
};
