import { useDispatch } from 'react-redux';

import type { AppDispatch } from 'modules/app/redux/store';
import {
  fetchRequestsStats,
  removeRequestLocally,
} from 'src/core/modules/requests/redux/legacy/actions';

import { type RequestId } from '../../models';

export const useSynchronizeRequestRemoval = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (requestId: RequestId) => {
    dispatch(fetchRequestsStats());
    // @ts-expect-error Action is defined in a JS file
    dispatch(removeRequestLocally(requestId));
  };
};
