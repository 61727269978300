import { Icon, colors } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { type LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem, setLocalItem } from 'src/core/utils/storage';
import './MarketingAnnouncement.scss';

type Props = {
  className?: string;
  icon: ReactNode;
  title: string;
  description: string;
  link: {
    label: string;
    onClick?(dismiss: () => void): void;
  } & (
    | { type: 'internal'; path: string }
    | { type: 'external'; url: string }
    | { type: 'button' }
  );
  dismissStorageKey: LocalStorageKey;
};

export const MarketingAnnouncement = ({
  className,
  icon,
  title,
  description,
  link,
  dismissStorageKey,
}: Props) => {
  const [isDismissed, setIsDismissed] = useState(
    Boolean(getLocalItem(dismissStorageKey)),
  );

  const handleDismiss = () => {
    setLocalItem(dismissStorageKey, true);
    setIsDismissed(true);
  };

  if (isDismissed) {
    return null;
  }

  return (
    <div
      className={cx('MarketingAnnouncement', className)}
      data-testid="marketing-announcement"
    >
      <div className="MarketingAnnouncement__icon">{icon}</div>
      <div className="MarketingAnnouncement__content">
        <h4 className="MarketingAnnouncement__title title-l">
          {title}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            type="button"
            className="MarketingAnnouncement__closeButton"
            onClick={handleDismiss}
          >
            <Icon name="cross" color={colors.neutral} />
          </button>
        </h4>
        <p className="MarketingAnnouncement__description body-s">
          {description}
        </p>
        {linkToElement(link, handleDismiss)}
      </div>
    </div>
  );
};

const linkToElement = (link: Props['link'], handleDismiss: () => void) => {
  if (link.type === 'internal') {
    return (
      <Link
        to={link.path}
        className="MarketingAnnouncement__link title-s"
        onClick={() => link.onClick?.(handleDismiss)}
      >
        {link.label}
      </Link>
    );
  }
  if (link.type === 'external') {
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noreferrer"
        className="MarketingAnnouncement__link title-s"
        onClick={() => link.onClick?.(handleDismiss)}
      >
        {link.label}
      </a>
    );
  }
  return (
    <button
      type="button"
      className="MarketingAnnouncement__link title-s"
      onClick={() => link.onClick?.(handleDismiss)}
    >
      {link.label}
    </button>
  );
};
