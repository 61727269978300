import {
  Button,
  CalendarRange,
  type DateRange,
  Icon,
  DATE_FORMAT,
} from '@dev-spendesk/grapes';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import TextInput from 'src/core/common/components/legacy/TextInput/TextInput';
import {
  last30DaysRange,
  last7DaysRange,
  lastMonthRange,
  lastWeekRange,
  monthRange,
  weekRange,
} from 'src/core/utils/date';

// Not grapes to harmonize with the rest of the filters
import styles from './DateRangeFilter.module.css';
import { useTranslation } from '../../../hooks/useTranslation';
import Separator from '../Separator/Separator';

type Props = {
  addFilter: (
    filterName: string,
    filterType: string,
    filterValue: string,
  ) => void;
  filterName: string;
  value: DateRange;
};

export const DateRangeFilter = ({ addFilter, filterName, value }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const [isOpen, setIsOpen] = useState(false);
  const [internalState, setInternalState] = useState<DateRange>(value);

  useEffect(() => {
    if (value) {
      setInternalState(value);
    }
  }, [value]);

  let textInputValue = '';
  if (value[0] && !value[1]) {
    textInputValue = format(value[0], DATE_FORMAT.SHORT);
  } else if (value[0] && value[1]) {
    textInputValue = `${localeFormat(
      value[0],
      DATE_FORMAT.SHORT,
    )} - ${localeFormat(value[1], DATE_FORMAT.SHORT)}`;
  }

  const onSelect = (range: DateRange) => {
    if (!range[0] || !range[1]) {
      return;
    }

    const startDate = range[0].toISOString();
    const endDate = range[1].toISOString();
    addFilter(filterName, 'value', `${startDate}/${endDate}`);

    setIsOpen(false);
  };

  const fixedTimeRanges = [
    {
      label: t('misc.periods.thisWeek'),
      value: weekRange(),
    },
    {
      label: t('misc.periods.thisMonth'),
      value: monthRange(),
    },
    {
      label: t('misc.periods.last7Days'),
      value: last7DaysRange(),
    },
    {
      label: t('misc.periods.last30Days'),
      value: last30DaysRange(),
    },
    {
      label: t('misc.periods.lastWeek'),
      value: lastWeekRange(),
    },
    {
      label: t('misc.periods.lastMonth'),
      value: lastMonthRange(),
    },
  ];

  return (
    <div>
      <TextInput
        size="large"
        iconText={<Icon name="calendar" />}
        placeholder={t('dateRangePicker.placeholder')}
        onFocus={() => setIsOpen(true)}
        inputStyle={{ display: 'block' }}
        value={textInputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const dateString = event.target.value;

          // eslint-disable-next-line security/detect-unsafe-regex
          const regex = /^(?:\d{2}\/){2}\d{4} - (?:\d{2}\/){2}\d{4}$/g;
          if (!regex.test(dateString)) {
            return;
          }

          const [start, end] = dateString.split(' - ');
          const startDate = parse(start, 'dd/MM/yyyy', new Date());
          const endDate = parse(end, 'dd/MM/yyyy', new Date());
          if (isValid(startDate) && isValid(endDate)) {
            onSelect([startDate, endDate]);
          }
        }}
        autoComplete="off"
        autoCorrect="off"
        spellCheck={false}
      />
      <AnimateHeight height={isOpen ? 'auto' : 0} duration={140}>
        <div className="mt-s">
          <CalendarRange
            numberOfCalendars={1}
            value={internalState}
            onClick={(newRange) => {
              setInternalState(newRange);
              if (newRange[0] && newRange[1]) {
                onSelect(newRange);
              }
            }}
          />
        </div>
        <Separator full style={{ margin: '0', opacity: '.8' }} />
        <div className={styles.timeRanges}>
          {fixedTimeRanges.map((timeRange) => (
            <Button
              fit="content"
              className={styles.timeRangeButton}
              key={timeRange.label}
              onClick={() => {
                onSelect(timeRange.value);
              }}
              text={timeRange.label}
              type="button"
              variant="contrasted"
            />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};
