import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { ApproversListItem } from './ApproversListItem';
import { type SelectedApprover, type Member } from '../../../types';

import './ApproversList.css';

type Props = {
  approvers: Array<Member & { isCostCenterOwner: boolean }>;
  isAllFollowingMode: boolean;
  costCenterOwnerId: string;
  onDeleteClicked: (index: number) => void;
  onUpdateRule: (
    selectedApprover: SelectedApprover,
    index: number,
  ) => void;
};

export const ApproversList = ({
  approvers,
  isAllFollowingMode,
  onDeleteClicked,
  onUpdateRule,
  costCenterOwnerId,
}: Props) => {
  return (
    <div className="ApproversList">
      {approvers.map((approver, index) => (
        <ApproversListItem
          index={index}
          // disable the rule because duplicate approvers can be added
          // eslint-disable-next-line react/no-array-index-key
          key={`${approver.id}-${index}`}
          costCenterOwnerId={costCenterOwnerId}
          approver={approver}
          onDelete={() => onDeleteClicked(index)}
          onUpdateRule={(selectedApprover) => {
            onUpdateRule(
              selectedApprover,
              index,
            );
            track(AnalyticEventName.APPROVALS_WORKFLOW_SETUP_EDIT_APPROVER, {
              previousApproverId: approver.id,
              previousApproverType: 'user',
              newApproverId: selectedApprover.type === 'user' ? selectedApprover.id : null,
              newApproverType: 'user',
            });
          }}
          isAllFollowingMode={isAllFollowingMode}
        />
      ))}
    </div>
  );
};
