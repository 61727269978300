import { Callout, FormField, Input, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps, getIn } from 'formik';
import React, { useEffect } from 'react';

import { MASKS } from 'common/components/legacy/MaskedInput/MaskedInput';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { getCountryCodeFromIban } from 'src/core/utils/bankInfoFormats';

import { type Member } from '../../models/member';
import {
  type BankInfoFormValues,
  type MemberEditFormikErrors,
  type MemberEditFormikValues,
} from '../MemberEditModal/validate';

type RequiredInfoTypesForEmployeeBankAddress = (keyof BankInfoFormValues)[];

type Props = {
  requiredInfoTypesForEmployeeBankAddress: RequiredInfoTypesForEmployeeBankAddress;
  isEditingDisabled: boolean;
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberBankInfoForm = ({
  requiredInfoTypesForEmployeeBankAddress = ['iban', 'bic'],
  isEditingDisabled,
  formikProps,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const companyCountry = company.country;
  const { values, errors, setValues } = formikProps;

  const bankInfoValues = values.bankInfo ?? {};
  const bankInfoErrors: NonNullable<MemberEditFormikErrors['bankInfo']> =
    getIn(errors, 'bankInfo') ?? {};

  const shouldUserFillInfo = (field: keyof BankInfoFormValues) => {
    return requiredInfoTypesForEmployeeBankAddress.includes(field);
  };

  const sanitizeByRequiredBankInfoTypes = () => {
    const bankInfo: Member['bankInfo'] = {};
    requiredInfoTypesForEmployeeBankAddress.forEach(
      (item: keyof BankInfoFormValues) => {
        if (values.bankInfo[item]) {
          bankInfo[item] = values.bankInfo[item];
        }
      },
    );
    setValues({ ...values, bankInfo });
  };

  useEffect(() => {
    sanitizeByRequiredBankInfoTypes();
  }, []);

  const setBankInfo = (field: keyof BankInfoFormValues, value: string) => {
    setValues({
      ...values,
      bankInfo: {
        ...values.bankInfo,
        [field]: value,
      },
    });
  };

  return (
    <div className="mt-m">
      {shouldUserFillInfo('iban') && (
        <FormField
          className="mb-m"
          label={t('bankInfoForm.iban')}
          alertMessage={bankInfoErrors.iban}
        >
          <Input
            id="iban"
            name="iban"
            key="iban"
            value={bankInfoValues.iban}
            maskOptions={getMaskFromIban(bankInfoValues.iban)}
            placeholder={t('bankInfoForm.placeholders.iban')}
            onChange={(e) =>
              setBankInfo('iban', e.target.value.replaceAll(' ', ''))
            }
            isDisabled={isEditingDisabled}
          />
        </FormField>
      )}
      {shouldUserFillInfo('bic') && (
        <FormField
          className="mb-m"
          label={t('bankInfoForm.bic')}
          alertMessage={bankInfoErrors.bic}
        >
          <Input
            id="bic"
            name="bic"
            value={bankInfoValues.bic}
            maskOptions={MASKS.BIC}
            placeholder={t('bankInfoForm.placeholders.bic')}
            onChange={(e) =>
              setBankInfo('bic', e.target.value.replaceAll(' ', ''))
            }
            isDisabled={isEditingDisabled}
          />
        </FormField>
      )}
      {shouldUserFillInfo('cuc') && (
        <FormField
          className="mb-m"
          label={t('bankInfoForm.cuc')}
          alertMessage={bankInfoErrors.cuc}
        >
          <Input
            id="cuc"
            name="cuc"
            value={bankInfoValues.cuc}
            maskOptions={MASKS.CUC}
            placeholder={t('bankInfoForm.placeholders.cuc')}
            onChange={(e) =>
              setBankInfo('cuc', e.target.value.replaceAll(' ', ''))
            }
            isDisabled={isEditingDisabled}
          />
        </FormField>
      )}
      {shouldUserFillInfo('sortCode') && (
        <FormField
          className="mb-m"
          label={t('bankInfoForm.sortCode')}
          alertMessage={bankInfoErrors.sortCode}
        >
          <Input
            id="sortCode"
            name="sortCode"
            value={bankInfoValues.sortCode}
            maskOptions={MASKS.SORT_CODE}
            placeholder={t('bankInfoForm.placeholders.sortCode')}
            onChange={(e) =>
              setBankInfo('sortCode', e.target.value.replaceAll('-', ''))
            }
            isDisabled={isEditingDisabled}
          />
        </FormField>
      )}
      {shouldUserFillInfo('accountNumber') && (
        <FormField
          className="mb-m"
          label={t('bankInfoForm.accountNumber')}
          alertMessage={bankInfoErrors.accountNumber}
        >
          <Input
            id="accountNumber"
            name="accountNumber"
            value={bankInfoValues.accountNumber}
            maskOptions={
              companyCountry === 'GB'
                ? MASKS.ACCOUNT_NUMBER_UK
                : MASKS.ACCOUNT_NUMBER
            }
            placeholder={t('bankInfoForm.placeholders.accountNumber')}
            onChange={(e) =>
              setBankInfo('accountNumber', e.target.value.replaceAll(' ', ''))
            }
            isDisabled={isEditingDisabled}
          />
        </FormField>
      )}
      {shouldUserFillInfo('routingNumber') && (
        <FormField
          className="mb-m"
          label={t('bankInfoForm.routingNumber')}
          alertMessage={bankInfoErrors.routingNumber}
        >
          <Input
            id="routingNumber"
            name="routingNumber"
            value={bankInfoValues.routingNumber}
            maskOptions={MASKS.ROUTING_NUMBER}
            placeholder={t('bankInfoForm.placeholders.routingNumber')}
            onChange={(e) =>
              setBankInfo('routingNumber', e.target.value.replaceAll(' ', ''))
            }
            isDisabled={isEditingDisabled}
          />
        </FormField>
      )}
      <FormField
        label={t('bankInfoForm.accountHolderName')}
        alertMessage={bankInfoErrors.accountHolderName}
      >
        <TextInput
          name="accountHolderName"
          value={bankInfoValues.accountHolderName}
          placeholder={t('bankInfoForm.placeholders.accountHolderName')}
          onChange={(e) => setBankInfo('accountHolderName', e.target.value)}
          isDisabled={isEditingDisabled}
        />
      </FormField>
      {bankInfoErrors.apiError && (
        <Callout
          className="mb-m mt-xs"
          variant="alert"
          title={bankInfoErrors.apiError}
        />
      )}
    </div>
  );
};

const getMaskFromIban = (iban: string | undefined) => {
  const code = getCountryCodeFromIban(iban);
  if (!code || !MASKS.IBAN[code]) {
    return undefined;
  }
  return {
    ...MASKS.IBAN[code],
    prefix: undefined,
  };
};
