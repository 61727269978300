import { getDate } from 'date-fns';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import sum from 'lodash/sum';

import appConfig from 'src/core/config';

export const HARD_RELOAD = 'hard-reload';
export const SOFT_RELOAD = 'soft-reload';
export const NO_RELOAD = 'no-reload';

/**
 * Check that the target currency is allowed
 * and that the company hasn't spent more than 15K while still unvalidated
 *
 * Note: If there's no company, it means that it's being checked from the onboarding,
 *       hence no need to check spending limits
 */
// hard coded limit
export const isCardFundingAvailable = (currency, company) => {
  if (company && company.banking_provider !== 'bankable') {
    return false;
  }

  const isCurrencyAuthorized = includes(
    ['EUR', 'GBP', 'USD', 'SEK', 'NOK', 'DKK'],
    currency,
  );
  if (!isCurrencyAuthorized) {
    return false;
  }
  const { unvalidatedSpendingLimit } = appConfig.stripe.amounts[currency];
  return (
    !company ||
    company.validated_at ||
    company.spending_total <= unvalidatedSpendingLimit
  );
};

export const getAmountNeeded = (company, budget) => {
  const available = sum(
    compact([
      company.balance_available_all_accounts,
      company.balance_loaded_reloadable_cards,
    ]),
  );
  return budget - available;
};

export const getReloadType = (company, budget) => {
  if (getDate(new Date()) < 20) {
    return NO_RELOAD;
  }
  if (!budget) {
    return NO_RELOAD;
  }
  const needed = getAmountNeeded(company, budget);
  if (needed >= 0) {
    return HARD_RELOAD;
  }
  if (needed + company.suggest_to_reload_amount >= 0) {
    return SOFT_RELOAD;
  }
  return NO_RELOAD;
};
