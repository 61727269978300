import {
  Avatar,
  Button,
  HighlightIcon,
  Icon,
  Link,
  colors,
} from '@dev-spendesk/grapes';
import React from 'react';
import { Link as NavLink } from 'react-router-dom';

import { MarketingAnnouncement } from 'common/components/legacy/MarketingAnnouncement';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import Features from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { LocalStorageKey } from 'src/core/constants/storage';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMoney } from 'src/core/utils/money';

import PbrAnnouncementIcon from './pbrAnnouncementIcon.svg';
import { usePaging } from './usePaging';
import { AssignControlRuleModalContainer } from '../../containers/AssignControlRuleModalContainer';
import { type LeaderboardItem } from '../../leaderboardItem';

import './MissingReceipts.css';

type Props = {
  currency: string;
  companyId: string;
  userRole: 'admin' | 'controller';
  leaderboardItems: LeaderboardItem[];
  isControlRulesUsed: boolean;
};

const getHelpCenterArticleLink = (language: string): string => {
  // TODO: i18n German
  return language === 'fr'
    ? 'https://helpcenter.spendesk.com/fr/articles/4240924-parametrer-les-regles-sur-justificatifs'
    : 'https://helpcenter.spendesk.com/en/articles/4240924-set-up-play-by-the-rules-to-collect-more-receipts-admin';
};

export const MissingReceipts = ({
  currency,
  companyId,
  userRole,
  leaderboardItems,
  isControlRulesUsed,
}: Props) => {
  const { t, i18n } = useTranslation('global');

  const [
    assignControlRuleModal,
    showAssignControlRuleModal,
    hideAssignControlRuleModal,
  ] = useModal((user: { fullname: string; id: string }) => (
    <AssignControlRuleModalContainer
      onExit={() => {
        hideAssignControlRuleModal();
      }}
      user={user}
    />
  ));

  const hasPlayByRulesFeature = useFeature(Features.PLAY_BY_RULES);
  const [visibleLeaderboardItems, sortBy, pagingInfo] =
    usePaging(leaderboardItems);

  if (leaderboardItems.length === 0) {
    return (
      <div className="box" data-testid="test__total">
        <div className="MissingReceipts__placeholder">
          <img
            className="mb-xs h-3xl"
            src="/static/img/homepage/party.png"
            alt=""
          />
          <p className="text-complementary body-l">
            {t('homepage.missingReceipts.congratulations')}
          </p>
        </div>
      </div>
    );
  }

  const totalPaymentsAmount = formatMoney(
    Math.abs(
      leaderboardItems.reduce((total, item) => total + item.sumPayments, 0),
    ),
    currency,
  );
  const totalOverduePaymentsAmount = formatMoney(
    Math.abs(
      leaderboardItems.reduce(
        (total, item) =>
          item.sumPaymentsOverdue ? total + item.sumPaymentsOverdue : total,
        0,
      ),
    ),
    currency,
  );

  const paymentsAllUrl = routeFor(routes.PAYMENTS_ALL.path, {
    company: companyId,
  });
  const viewAllUrl = `${paymentsAllUrl}?${
    isControlRulesUsed ? 'completionDeadline=late' : 'invoice=missing'
  }`;

  return (
    <div className="box flex flex-col">
      {assignControlRuleModal}
      <div className="mb-m flex items-center gap-[10px]">
        <HighlightIcon variant="info" size="m" name="eye" />
        <div>
          <h3 className="leading-[16px] text-complementary title-l">
            {isControlRulesUsed
              ? t('homepage.missingReceipts.keepWatchLate')
              : t('homepage.missingReceipts.keepWatchMissing')}
          </h3>
          <p className="leading-[17px] text-neutral-dark body-m">
            {t('homepage.missingReceipts.allTime')}
          </p>
        </div>
      </div>
      {!isControlRulesUsed && (
        <MarketingAnnouncement
          className="MissingReceipts__announcement"
          icon={<img width="80" height="80" alt="" src={PbrAnnouncementIcon} />}
          title={t('homepage.missingReceipts.announcement.title')}
          description={t('homepage.missingReceipts.announcement.description')}
          link={
            userRole === 'admin'
              ? {
                  type: 'internal',
                  label: t('homepage.missingReceipts.announcement.linkAdmin'),
                  path: routeFor(routes.COMPANY_POLICIES.path, {
                    company: companyId,
                  }),
                }
              : {
                  type: 'external',
                  label: t(
                    'homepage.missingReceipts.announcement.linkController',
                  ),
                  url: getHelpCenterArticleLink(i18n.language),
                }
          }
          dismissStorageKey={LocalStorageKey.PbrAnnouncementDismissed}
        />
      )}

      {hasPlayByRulesFeature ? (
        <>
          <div className="text-neutral-dark body-l">
            <span
              className="text-neutral-darker body-xl"
              data-testid="total-overdue"
            >
              {totalOverduePaymentsAmount}
            </span>{' '}
            {t('homepage.missingReceipts.spendLateReceipts')}
          </div>
          <div className="text-neutral-dark body-m">
            {t('homepage.missingReceipts.totalAmountNoReceipt', {
              amount: totalPaymentsAmount,
            })}
          </div>
        </>
      ) : (
        <div className="text-neutral-dark body-l">
          <span
            className="text-neutral-darker body-xl"
            data-testid="total-without-receipts"
          >
            {totalPaymentsAmount}
          </span>{' '}
          {t('homepage.missingReceipts.spendNoReceipts')}
        </div>
      )}
      <div className="mt-s flex flex-col">
        <div className="flex w-full items-center justify-between py-xs text-neutral-dark title-m">
          <div className="MissingReceipts__cell MissingReceipts__cell--name">
            <span
              className="cursor-pointer"
              onClick={() => sortBy('user.fullname')}
            >
              {t('homepage.missingReceipts.userColumn')}
            </span>
          </div>
          {isControlRulesUsed && (
            <div className="MissingReceipts__cell MissingReceipts__cell--number">
              <span
                className="cursor-pointer"
                onClick={() => sortBy('nbPaymentsOverdue')}
              >
                {t('homepage.missingReceipts.overdueReceiptsColumn')}
              </span>
            </div>
          )}
          <div className="MissingReceipts__cell MissingReceipts__cell--number">
            <span
              className="cursor-pointer"
              onClick={() => sortBy('nbPayments')}
            >
              {t('homepage.missingReceipts.missingReceiptsColumn')}
            </span>
          </div>
          <div className="MissingReceipts__cell MissingReceipts__cell--number">
            <span
              className="cursor-pointer"
              onClick={() => sortBy('sumPayments')}
            >
              {t('homepage.missingReceipts.spendColumn')}
            </span>
          </div>
        </div>
        {visibleLeaderboardItems.map((item) => (
          <div
            className="MissingReceipts__tableLine"
            key={item.user.fullname}
            data-testid="table-line"
          >
            <div className="MissingReceipts__cell MissingReceipts__cell--name flex items-center gap-xs">
              <Avatar
                variant="circle"
                src={item.user.avatar ?? undefined}
                text={item.user.fullname}
              />
              <span className="ml-xs title-m" data-testid="cell-user-name">
                {item.user.fullname}
              </span>
            </div>
            {isControlRulesUsed && (
              <div className="MissingReceipts__cell MissingReceipts__cell--number">
                {item.user.hasControlRule ? (
                  <span data-testid="cell-payment-overdue-number">
                    {item.nbPaymentsOverdue}
                  </span>
                ) : (
                  <Button
                    variant="ghost"
                    text={t('homepage.missingReceipts.noRule')}
                    onClick={() => {
                      showAssignControlRuleModal(item.user);
                    }}
                  />
                )}
              </div>
            )}
            <div
              className="MissingReceipts__cell MissingReceipts__cell--number"
              data-testid="cell-payment-number"
            >
              {item.nbPayments}
            </div>
            <div className="MissingReceipts__cell MissingReceipts__cell--number">
              <span data-testid="cell-sum-payments">
                {isControlRulesUsed
                  ? formatMoney(item.sumPaymentsOverdue ?? 0, currency)
                  : formatMoney(item.sumPayments, currency)}
              </span>
              {isControlRulesUsed &&
                item.sumPayments !== undefined &&
                item.sumPayments > 0 && (
                  <div
                    className="text-neutral-dark body-s"
                    data-testid="cell-additional"
                  >
                    {t('homepage.missingReceipts.totalAmountNoReceipt', {
                      amount: formatMoney(item.sumPayments, currency),
                    })}
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-s flex justify-between text-neutral-darker body-m">
        <div>
          <Link
            as={NavLink}
            to={viewAllUrl}
            onClick={() => {
              track(AnalyticEventName.HOMEPAGE_LEADERBOARD_ALL_BUTTON_CLICKED);
            }}
          >
            {isControlRulesUsed
              ? t('homepage.missingReceipts.viewAllLate')
              : t('homepage.missingReceipts.viewAll')}
          </Link>
        </div>
        <div className="flex items-center gap-xs">
          <a
            onClick={() => {
              pagingInfo.previousPage();
              track(
                AnalyticEventName.HOMEPAGE_LEADERBOARD_PREVIOUS_PAGE_BUTTON_CLICKED,
              );
            }}
          >
            <Icon className="block" name="caret-left" color={colors.neutral} />
          </a>
          {pagingInfo.currentPage} / {pagingInfo.nbOfPages}
          <a
            onClick={() => {
              pagingInfo.nextPage();
              track(
                AnalyticEventName.HOMEPAGE_LEADERBOARD_NEXT_PAGE_BUTTON_CLICKED,
              );
            }}
          >
            <Icon className="block" name="caret-right" color={colors.neutral} />
          </a>
        </div>
      </div>
    </div>
  );
};
