import { createAction } from '@reduxjs/toolkit';

import { companyAPI } from 'src/core/api/axios';
import FEATURES from 'src/core/constants/features';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';

import * as types from './actionTypes';
import { canUserSeeSlackPopup } from './utils';
import { SLACK_ANNOUNCEMENT } from '../../splashTypes';

/**
 * Eventually dispatch an action to trigger the Slack
 * announcement modal if necessary
 */
export const checkSlackNotifEligibility =
  (openSplash) => async (dispatch, getState) => {
    const state = getState();
    const isSlackFeatureEnabled = getIsFeatureEnabled(
      state,
      FEATURES.SLACK_INTEGRATION,
    );
    const { user, company } = state.global;
    if (!canUserSeeSlackPopup(user, company, isSlackFeatureEnabled)) {
      return;
    }

    dispatch(openSplash({ type: SLACK_ANNOUNCEMENT }));
  };

export const saveSlackPopupSeenStateLoading = createAction(
  types.SAVE_SLACK_POPUP_SEEN_STATE_LOADING,
);
export const saveSlackPopupSeenStateSuccess = createAction(
  types.SAVE_SLACK_POPUP_SEEN_STATE_SUCCESS,
);
export const saveSlackPopupSeenState = () => async (dispatch, getState) => {
  const companyId = getState().global.company.id;
  dispatch(saveSlackPopupSeenStateLoading());

  await companyAPI.post(
    '/user/popup-seen',
    { code: SLACK_ANNOUNCEMENT },
    { companyId },
  );

  dispatch(saveSlackPopupSeenStateSuccess());
};
