import type { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import { type CodeResponseExtended } from 'src/auth/common/components/GoogleIdentityServicesButton/GoogleIdentityServicesButton';
import { authAPI } from 'src/core/api/axios';
import { sessionMaxActivity } from 'src/core/config';
import { SESSION_EXPIRE_LOCAL_STORAGE_KEY } from 'src/core/constants/storage';
import logout from 'src/core/utils/logout';

import * as types from './actionTypes';
import { LOGIN } from '../../../constants/api';
import type { LoginResult } from '../login';

export type LoginStateError = {
  code: string;
  source: 'google' | 'googleIdentityServices' | 'microsoft' | 'server';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
} | null;

export type LoginState = {
  loadingSource: 'password' | 'google' | 'microsoft' | null;
  error: LoginStateError;
  panelNotInvited:
    | {
        displayed: true;
        companyName: string;
      }
    | {
        displayed: false;
        companyName: null;
      };
};

export const displayPanelLogin = createAction(types.DISPLAY_PANEL_LOGIN);
export const displayPanelRequestInvite = createAction<{ companyName: string }>(
  types.DISPLAY_PANEL_REQUESTINVITE,
);

export const loginLoading = createAction<'password' | 'google' | 'microsoft'>(
  types.LOGIN_LOADING,
);
export const loginSuccess = createAction<object>(types.LOGIN_SUCCESS);
export const loginFailure = createAction<LoginStateError>(types.LOGIN_FAILURE);

export const loginWithPassword =
  (payload: { email: string; password: string }) =>
  async (dispatch: Dispatch): Promise<LoginResult> => {
    dispatch(loginLoading('password'));

    try {
      const { data } = await authAPI.post(LOGIN, {
        // eslint-disable-next-line camelcase
        auth_type: 'password',
        username: payload.email,
        password: payload.password,
      });

      dispatch(loginSuccess(data));

      return {
        isLoggedIn: true,
        accessToken: data.accessToken,
        goToCompanySelection: data.hasImpersonationTargets,
      };
    } catch (error) {
      return handleLoginError(error, dispatch);
    }
  };

export const loginWithOpenId =
  (payload: { idToken: string; accessToken: string }) =>
  async (dispatch: Dispatch): Promise<LoginResult> => {
    dispatch(loginLoading('microsoft'));

    try {
      const { data } = await authAPI.post(LOGIN, {
        // eslint-disable-next-line camelcase
        auth_type: 'openid',
        // eslint-disable-next-line camelcase
        id_token: payload.idToken,
        // eslint-disable-next-line camelcase
        access_token: payload.accessToken,
      });

      dispatch(loginSuccess(data));

      return {
        isLoggedIn: true,
        accessToken: data.accessToken,
        goToCompanySelection: data.hasImpersonationTargets,
      };
    } catch (error) {
      return handleLoginError(error, dispatch);
    }
  };

export const loginWithGoogleIdentityServices =
  (payload: CodeResponseExtended) =>
  async (dispatch: Dispatch): Promise<LoginResult> => {
    dispatch(loginLoading('google'));

    try {
      const { data } = await authAPI.post(LOGIN, {
        // eslint-disable-next-line camelcase
        auth_type: 'googleIdentityServices',
        code: payload.code,
        redirectUrl: payload.redirectUrl,
      });

      dispatch(loginSuccess(data));

      return {
        isLoggedIn: true,
        accessToken: data.accessToken,
        goToCompanySelection: data.hasImpersonationTargets,
      };
    } catch (error) {
      return handleLoginError(error, dispatch);
    }
  };

export const loginWithSca =
  (scaToken: string, redirectUrl?: string) =>
  async (dispatch: Dispatch): Promise<LoginResult> => {
    try {
      const { data } = await authAPI.post(LOGIN, {
        // eslint-disable-next-line camelcase
        auth_type: 'sca',
        token: scaToken,
        redirectUrl,
      });

      dispatch(loginSuccess(data));

      return {
        isLoggedIn: true,
        accessToken: data.accessToken,
        goToCompanySelection: data.hasImpersonationTargets,
      };
    } catch (error) {
      return handleLoginError(error, dispatch);
    }
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleLoginError = (error: any, dispatch: Dispatch): LoginResult => {
  const data = get(error, 'response.data', { error: 'unknown_error' });

  if (data.error === 'existing_domain') {
    dispatch(displayPanelRequestInvite({ companyName: data.data.companyName }));
  } else {
    dispatch(loginFailure({ code: data.error, source: 'server', data }));
  }

  if (error.isAxiosError) {
    return { isLoggedIn: false, data };
  }

  throw error;
};

const killSession = async (): Promise<void> => {
  await logout();
  window.location.href = `/auth/login?session-expired=1&targetUrl=${encodeURIComponent(
    window.location.href,
  )}`;
};

const setLastActivity = throttle(
  () => {
    localStorage.setItem(
      SESSION_EXPIRE_LOCAL_STORAGE_KEY,
      Date.now().toString(),
    );
  },
  5000,
  { leading: true, trailing: false },
);

const getLastActivity = (): Date | undefined => {
  const lastActivity = localStorage.getItem(SESSION_EXPIRE_LOCAL_STORAGE_KEY);
  if (lastActivity) {
    return new Date(Number.parseInt(lastActivity));
  }
};

let autoLogoutTimer: number | undefined;
export const automaticSessionExpire = () => async (): Promise<void> => {
  // update the value on login so that we don't disconnect after 45s
  setLastActivity();

  window.addEventListener('mousemove', setLastActivity);
  window.addEventListener('keypress', setLastActivity);

  if (autoLogoutTimer) {
    return;
  }

  autoLogoutTimer = window.setInterval(async () => {
    const now = Date.now();
    const lastActivity = getLastActivity();
    const inactivity = lastActivity ? now - lastActivity.getTime() : undefined;

    if (inactivity && inactivity > sessionMaxActivity) {
      killSession();
    }
  }, sessionMaxActivity / 2);
};
