import classnames from 'classnames';
import React from 'react';

import {
  ImageViewerModal,
  PDFViewerModal,
} from "src/core/common/components/legacy/LegacyFileViewer";

export type MediaType =
  | 'application/pdf'
  | 'image/png'
  | 'image/jpeg'
  | 'image/gif';

type FileViewerModalWrapperProps = {
  className?: string;
  fileUrl: string;
  fileMediaType: MediaType;
  onModalClose: () => void;
};

export const FileViewerModalWrapper = ({
  className,
  fileUrl,
  fileMediaType,
  onModalClose,
}: FileViewerModalWrapperProps) =>
  fileMediaType === 'application/pdf' ? (
    <PDFViewerModal
      url={fileUrl}
      withDownload
      fileViewerModalClassName={classnames('h-full', className)}
      onDownload={() => window.open(fileUrl, '_blank')}
      onClose={onModalClose}
    />
  ) : (
    <ImageViewerModal
      url={fileUrl}
      withZoom
      withDownload
      withRotate
      fileViewerModalClassName={classnames('h-full', className)}
      onDownload={() => window.open(fileUrl, '_blank')}
      onClose={onModalClose}
    />
  );
