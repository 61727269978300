import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { TableActions } from 'src/core/common/components/legacy/TableLegacy';
import { routes, routeFor } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { getExportExtension } from '../../utils/getExportExtension';

class PaymentsActions extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selection: PropTypes.object,
    counters: PropTypes.object,
    payments: PropTypes.arrayOf(PropTypes.object),
    selectedPayments: PropTypes.arrayOf(PropTypes.string),
    isSelectionEnabled: PropTypes.bool.isRequired,
    customExports: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.object,
    bulkActions: PropTypes.object,
    isPanelOpen: PropTypes.bool,
    isSupervisionActive: PropTypes.bool.isRequired,
    togglePanel: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    updateSelection: PropTypes.func.isRequired,
    remindInvoices: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    bulkEdit: PropTypes.func.isRequired,
    bulkMarkAsMissing: PropTypes.func.isRequired,
    pushNotif: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selection: undefined,
    counters: undefined,
    payments: undefined,
    selectedPayments: undefined,
    filters: undefined,
    isPanelOpen: undefined,
    bulkActions: undefined,
    customExports: undefined,
  };

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bulkActions, t, pushNotif } = this.props;
    const nextBulkActions = nextProps.bulkActions;

    if (
      get(nextBulkActions, 'remindInvoices.result') &&
      !bulkActions.remindInvoices.result
    ) {
      pushNotif({ message: t('payments.actions.remindInvoiceSuccess') });
    }
    if (get(nextBulkActions, 'download.error') && !bulkActions.download.error) {
      pushNotif({ type: 'danger', message: t('errors:somethingWrong') });
    }
  }

  onInvoiceRemind = () => {
    const { remindInvoices, selection, filters } = this.props;
    remindInvoices({ selection, filters });
  };

  onDownload = ({ withReceipts, customExportId }) => {
    const { download, selection, filters } = this.props;
    download({ selection, filters, withReceipts });
    track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
      withReceipts,
      customExportId,
    });
  };

  onEdit = () => {
    const { history, bulkEdit, selection, filters, company } = this.props;
    bulkEdit({ selection, filters });
    history.push(routeFor(routes.PAYMENTS_ALL.path, { company: company.id }));
  };

  onMarkAsMissing = async () => {
    const { selection, filters, bulkMarkAsMissing, pushNotif, t } = this.props;
    try {
      const result = await bulkMarkAsMissing({ selection, filters });
      pushNotif({
        message: t('payments.actions.markAsMissingSuccess', {
          count: result.nbPaymentsMarked,
        }),
      });
    } catch {
      pushNotif({
        type: 'danger',
        message: t('payments.actions.markAsMissingError'),
      });
    }
  };

  getEligibleActions() {
    const {
      counters,
      selection,
      bulkActions,
      customExports,
      isSupervisionActive,
      t,
    } = this.props;

    if (!counters) {
      return [];
    }

    const actions = [];
    const { remindable_for_invoice, downloadable, editable } = counters;

    if (remindable_for_invoice > 0) {
      const isDisabled = remindable_for_invoice > 100;
      actions.push({
        isDisabled,
        label: !isDisabled
          ? t('payments.actions.sendReminderForPayment', {
              count: remindable_for_invoice,
            })
          : t('payments.actions.sendReminderLimitDisclaimer', { count: 100 }),
        onClick: this.onInvoiceRemind,
        isProcessing: get(bulkActions, 'remindInvoices.processing'),
      });
    }
    if (downloadable > 0) {
      actions.push(
        ...getDownloadActions({
          downloadItemsCount: downloadable,
          isDownloading: get(bulkActions, 'download.processing'),
          customExports,
          onDownload: this.onDownload,
          translator: t,
        }),
      );
    }
    if (editable > 1) {
      actions.push({
        label: t('payments.actions.editPayment', { count: editable }),
        onClick: this.onEdit,
        isProcessing: get(bulkActions, 'edit.processing'),
      });
    }

    const hasSelectedPayments =
      selection.all || selection.include?.length !== 0;
    if (isSupervisionActive && hasSelectedPayments) {
      actions.push({
        label: t('payments.actions.markAsMissing'),
        onClick: this.onMarkAsMissing,
        isProcessing: get(bulkActions, 'markAsMissing.processing'),
        tooltip: t('payments.actions.markAsMissingTooltip'),
      });
    }

    return actions;
  }

  checkAll = (checked) => {
    this.props.updateSelection({ all: checked });

    if (!checked) {
      // Close the bulk edition panel when clearing the selection
      this.props.hidePanel();
    }
  };

  render() {
    return (
      <div>
        <TableActions
          actions={this.getEligibleActions()}
          toggleAllItems={this.checkAll}
          isPanelOpen={this.props.isPanelOpen}
          isSelectionEnabled={this.props.isSelectionEnabled}
          selectedItemsCounter={get(this.props, 'counters.downloadable', 0)}
          getBulkSelectedTemplate={(count) =>
            this.props.t('payments.actions.nPaymentsSelected', { count })
          }
        />
      </div>
    );
  }
}

export default withTranslation()(PaymentsActions);

const getDownloadActions = ({
  downloadItemsCount,
  customExports,
  isDownloading,
  translator,
  onDownload,
}) => {
  const extension = getExportExtension(customExports);
  const actions = [];

  if (downloadItemsCount > 10_000) {
    return [
      {
        label: translator('payments.actions.tooManyPaymentsToDownload'),
        isDisabled: true,
      },
    ];
  }

  if (downloadItemsCount > 300) {
    return [
      {
        label: translator('payments.actions.downloadPaymentCsvOnly', {
          count: downloadItemsCount,
          extension,
        }),
        isProcessing: isDownloading,
        onClick: () => onDownload({ withReceipts: false }),
      },
      {
        label: translator(
          'payments.actions.tooManyPaymentsToDownloadWithReceipts',
        ),
        isDisabled: true,
      },
    ];
  }

  actions.push({
    label: translator('payments.actions.downloadPaymentCsvOnly', {
      count: downloadItemsCount,
      extension,
    }),
    isProcessing: isDownloading,
    onClick: () => onDownload({ withReceipts: false }),
  });
  actions.push({
    label: translator('payments.actions.downloadPaymentWithReceipts', {
      count: downloadItemsCount,
      extension,
    }),
    isProcessing: isDownloading,
    onClick: () => onDownload({ withReceipts: true }),
  });
  return actions;
};
