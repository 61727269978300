import cx from 'classnames';
import React from 'react';

import './Label.scss';

type Props = {
  id?: string;
  size?: 'medium' | 'big';
  className?: string;
  helpText?: string;
  htmlFor?: string;
  children: React.ReactNode;
};

const Label = ({
  id,
  size = 'medium',
  className = undefined,
  helpText = undefined,
  htmlFor = undefined,
  children,
}: Props) => (
  <label
    id={id}
    className={cx(
      'Label__label',
      `Label__size__${size}`,
      {
        'Label--with-help-text': Boolean(helpText),
      },
      className,
    )}
    htmlFor={htmlFor}
  >
    {children}
    {helpText && <span className="Label__help-text">{helpText}</span>}
  </label>
);

export default Label;
