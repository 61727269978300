import { ModalSlideshow } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';

import exports from './assets/exports.png';
import invoices from './assets/invoices.png';
import reimbursement from './assets/reimbursement.png';

export const ExpenseEntityEducationalModal = () => {
  const { t } = useTranslation('global');
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const shouldDisplayExpenseEntityWelcomeModal = searchParams.get(
    'expenseEntityWelcomeModal',
  );
  const [isOpen, setIsOpen] = useState(
    shouldDisplayExpenseEntityWelcomeModal === 'true',
  );

  const closeModal = () => {
    history.replace({
      search: '',
    });
    setIsOpen(false);
  };

  return (
    <ModalSlideshow
      slides={[
        {
          title: t('expenseEntityEducationalModal.reimbursement.title'),
          content: t('expenseEntityEducationalModal.reimbursement.description'),
          illustration: <img src={reimbursement} alt="" className="w-full" />,
          illustrationHeight: '320px',
        },
        {
          title: t('expenseEntityEducationalModal.invoices.title'),
          content: t('expenseEntityEducationalModal.invoices.description'),
          illustration: <img src={invoices} alt="" className="w-full" />,
          illustrationHeight: '320px',
        },
        {
          title: t('expenseEntityEducationalModal.exports.title'),
          content: t('expenseEntityEducationalModal.exports.description'),
          illustration: <img src={exports} alt="" className="w-full" />,
          illustrationHeight: '320px',
        },
      ]}
      translations={{
        cancel: t('misc.cancel'),
        previous: t('misc.back'),
        next: t('misc.continue'),
        done: t('expenseEntityEducationalModal.finishCta'),
      }}
      isOpen={isOpen}
      onCancel={closeModal}
      onClose={closeModal}
      onDone={closeModal}
    />
  );
};
