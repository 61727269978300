import { Button, FileCard, Panel, PanelSection } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { PanelItemsSection } from 'common/components/Panel';
import {
  FileViewerInline,
  type MediaType,
} from 'common/components/legacy/LegacyFileViewer';
import { useTranslation } from 'common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';

import { buildSupplierBankInfoItems, buildSupplierInfoItems } from './helpers';
import { type Supplier } from '../supplier';

type Props = {
  company: {
    id: string;
    country: string;
    currency: CurrenciesKey;
  };
  supplier: Supplier;
  onEditSupplierClicked: React.MouseEventHandler;
};

export const SupplierView = ({
  company,
  supplier,
  onEditSupplierClicked,
}: Props) => {
  const { t } = useTranslation('global');

  const [showFilePreview, setShowFilePreview] = useState(false);

  return (
    <Panel
      footer={
        <Button
          fit="parent"
          variant="contrasted"
          text={t('submitMyInvoice.supplier.editSupplierInfo')}
          onClick={onEditSupplierClicked}
        />
      }
    >
      <PanelItemsSection
        title={t('submitMyInvoice.supplier.legalInfo')}
        items={buildSupplierInfoItems({
          supplier,
          translator: t,
        })}
      />

      <PanelItemsSection
        title={t('submitMyInvoice.supplier.bankInfo')}
        items={buildSupplierBankInfoItems({
          supplier,
          companyCountry: company.country,
          companyCurrency: company.currency,
          translator: t,
        })}
      />

      {supplier.details.bankProofFile && (
        <PanelSection title={t('submitMyInvoice.supplier.proof')}>
          <FileCard
            title={supplier.details.bankProofFile.filename}
            onClick={() => setShowFilePreview(!showFilePreview)}
          />
          {showFilePreview && (
            <FileViewerInline
              className="ToReviewPanelSupplier__bankProofModal mt-s"
              fileMediaType={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                supplier.details.bankProofFile.mimetype! as MediaType
              }
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fileUrl={supplier.details.bankProofFile.viewUrl!}
              onClose={() => setShowFilePreview(!showFilePreview)}
            />
          )}
        </PanelSection>
      )}
    </Panel>
  );
};
