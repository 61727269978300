import { Button, EmptyState, HighlightIcon } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DownloadAppIllustration } from 'common/components/DownloadAppIllustration';
import { getComponentByIllustrationName } from 'common/components/legacy/EmptyState';
import { useTranslation } from 'common/hooks/useTranslation';
import { useShouldCompleteKyc } from 'modules/kyc/hooks/useShouldCompleteKyc';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { getImpersonator } from 'src/core/selectors/globalSelectors';
import { getUserPaymentCount } from 'src/core/selectors/users';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

export const LatePaymentsSectionEmpty = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const history = useHistory();
  const userPaymentCount = useSelector((state) =>
    getUserPaymentCount(state, company.id),
  );
  const impersonator = useSelector(getImpersonator);
  const shouldCompleteKyc = useShouldCompleteKyc();

  const handleNewRequestClick = () => {
    track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, { from: 'filter_bar' });
    const requestRoute = routeFor(routes.REQUESTS.path, {
      company: company.id,
      id: 'new',
      type: 'all',
    });
    history.push(requestRoute);
  };

  if (shouldCompleteKyc) {
    return (
      <div className="px-3xl pt-xs">
        <div className="mb-m text-center">
          <HighlightIcon name="clock" variant="primary" />
          <h2 className="mt-s title-l">
            {t('requests.notAvailableForRequesterYet.title')}
          </h2>
          <p className="body-m">
            {t('requests.notAvailableForRequesterYet.message')}
          </p>
        </div>
        <DownloadAppIllustration className="flex" hasBottomMargin />
      </div>
    );
  }

  return (
    <EmptyState
      className="pb-3xl pt-l"
      illustration={getComponentByIllustrationName(
        userPaymentCount > 0 ? 'cocktail' : 'payment',
      )}
      title={
        userPaymentCount > 0
          ? t('homepage.paymentsList.empty.title')
          : t('homepage.paymentsList.empty.onboardingTitle')
      }
      subtitle={
        userPaymentCount > 0
          ? t('homepage.paymentsList.empty.desc')
          : t('homepage.paymentsList.empty.onboardingDesc')
      }
      actions={
        !impersonator ? (
          <Button
            type="primary"
            text={
              userPaymentCount > 0
                ? t('homepage.paymentsList.empty.button')
                : t('homepage.paymentsList.empty.onboardingButton')
            }
            onClick={handleNewRequestClick}
          />
        ) : undefined
      }
    />
  );
};
