import { fromNumber } from 'ezmoney';
import { type FormikErrors, type FormikProps, useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { eligibleTypes } from 'common/components/legacy/CustomFieldsSelector/CustomFieldsSelector';
import { useFeature } from 'common/hooks/useFeature';
import { useShouldDisplayExpenseCategoryField } from 'modules/company/expense-categories';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatDateToApiString } from 'src/core/common/utils/formatDateToApiString';
import FEATURES from 'src/core/constants/features';
import {
  getCustomFields,
  getExpenseCategory,
  getExpenseCategoryCustomFieldId,
  getUserVisibleTeams,
} from 'src/core/modules/requests/redux/legacy/selectors';
import { getSelf as getCurrentUser } from 'src/core/selectors/users';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getEligibleCustomFields } from 'src/core/utils/custom-fields';

import { type PurchaseOrderRequestFormValues as FormValues } from './purchaseOrderRequestFormValues';
import { useCostCentersQuery } from '../../budgets/apis';
import { addExpenseCategoryToCustomFieldAssociations } from '../../budgets/models/expenseCategory';
import {
  type CostCenter,
  type CustomFieldDefinition,
  type DraftPurchaseOrderRequest,
  isPurchaseOrderRequestAmountValid,
  isPurchaseOrderRequestNetAmountValid,
  isPurchaseOrderRequestCostCenterValid,
  isPurchaseOrderRequestCustomFieldValid,
  isPurchaseOrderRequestDescriptionValid,
  isPurchaseOrderRequestEndDateValid,
  isPurchaseOrderRequestExpenseCategoryValid,
  isPurchaseOrderRequestStartDateBeforeEndDate,
  isPurchaseOrderRequestStartDateValid,
  isPurchaseOrderRequestSupplierValid,
  isPurchaseOrderRequestTeamValid,
  type Team,
  isPurchaseOrderRequestNetAmountExists,
  purchaseOrderRequestQuotesValidation,
} from '../models';

type Props = {
  initialValues: FormValues;
  onSubmit: (draft: DraftPurchaseOrderRequest) => Promise<void>;
};

export type PurchaseOrderFormData = {
  customFields: CustomFieldDefinition[];
  costCenters: CostCenter[];
  userTeams: Team[];
  shouldDisplayTeamField: boolean;
  shouldDisplayCostCenterField: boolean;
  shouldDisplayCustomFieldsField: boolean;
  shouldDisplayExpenseCategoryField: boolean;
  isExpenseCategoryFieldRequired: boolean;
  formikProps: FormikProps<FormValues>;
};

export const usePurchaseOrderRequestForm = ({
  initialValues,
  onSubmit,
}: Props): PurchaseOrderFormData => {
  const { t } = useTranslation('global');
  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
  const hasExpenseCategoriesFeature = useFeature(FEATURES.EXPENSE_CATEGORIES);

  const costCentersQuery = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQuery) ?? [];

  const userTeams = useSelector(getUserVisibleTeams);

  const expenseCategoryCustomFieldId = useSelector(
    getExpenseCategoryCustomFieldId,
  );

  const customFields = useSelector(
    getCustomFields,
  ) as unknown as CustomFieldDefinition[];

  const expenseCategoryField = useSelector(
    getExpenseCategory,
  ) as unknown as CustomFieldDefinition;

  const shouldDisplayTeamField = hasTeamsFeature && userTeams.length > 1;

  const shouldDisplayCostCenterField =
    hasCostCentersFeature && costCenters.length > 0;

  const user = useSelector(getCurrentUser);

  const [panelOpenedTime] = useState(Date.now());

  const formikProps = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors: FormikErrors<
        Omit<typeof values, 'customFieldAssociations'>
      > & {
        // `CustomFieldsSelector` expects the errors to be an object with the custom field id as keys
        customFieldAssociations?: { [key: string]: string };
      } = {};

      const draftPurchaseOrderRequest = toDraftPurchaseOrderRequest({
        formValues: values,
        hasExpenseCategoriesFeature,
        expenseCategoryCustomFieldId,
      });

      if (!isPurchaseOrderRequestAmountValid(draftPurchaseOrderRequest)) {
        errors.amount = 'misc.requiredField';
        track(AnalyticEventName.REQUEST_FIELD_VALIDATION_ERROR, {
          fieldName: 'amountInclTaxes',
        });
      }

      if (!isPurchaseOrderRequestNetAmountExists(draftPurchaseOrderRequest)) {
        errors.netAmount = 'misc.requiredField';
        track(AnalyticEventName.REQUEST_FIELD_VALIDATION_ERROR, {
          fieldName: 'amountExclTaxes',
        });
      }
      if (!isPurchaseOrderRequestNetAmountValid(draftPurchaseOrderRequest)) {
        errors.netAmount =
          'requests.panel.purchaseOrder.validation.wrongAmount';
        track(AnalyticEventName.REQUEST_PO_NET_GREATER_THAN_GROSS);
      }
      if (!isPurchaseOrderRequestStartDateValid(draftPurchaseOrderRequest)) {
        errors.startDate = 'misc.requiredField';
      }
      if (
        !isPurchaseOrderRequestStartDateBeforeEndDate(draftPurchaseOrderRequest)
      ) {
        errors.startDate =
          'requests.panel.purchaseOrder.startDateBeforeEndDateError';
      }
      if (!isPurchaseOrderRequestEndDateValid(draftPurchaseOrderRequest)) {
        errors.endDate = 'misc.requiredField';
      }
      if (!isPurchaseOrderRequestDescriptionValid(draftPurchaseOrderRequest)) {
        errors.description = 'misc.requiredField';
      }
      if (
        !isPurchaseOrderRequestTeamValid(
          draftPurchaseOrderRequest,
          hasTeamsFeature,
          // @ts-expect-error waiting for requests reducer to have group typing
          userTeams,
        )
      ) {
        errors.team = 'misc.requiredField';
      }
      if (
        !isPurchaseOrderRequestCostCenterValid(
          draftPurchaseOrderRequest,
          hasCostCentersFeature,
          costCenters,
        )
      ) {
        errors.costCenter = 'misc.requiredField';
      }

      const eligibleCustomFields = getEligibleCustomFields(customFields, {
        types: [eligibleTypes.REQUEST],
        teamIds: [values.team?.id],
        user,
      });
      eligibleCustomFields.forEach((eligibleCustomField) => {
        if (
          !isPurchaseOrderRequestCustomFieldValid(
            draftPurchaseOrderRequest,
            eligibleCustomField,
          )
        ) {
          errors.customFieldAssociations = {
            ...errors.customFieldAssociations,
            [eligibleCustomField.id]: 'misc.requiredField',
          };
        }
      });

      if (!isPurchaseOrderRequestSupplierValid(draftPurchaseOrderRequest)) {
        errors.supplier = 'misc.requiredField';
      }

      const quotesError = purchaseOrderRequestQuotesValidation(
        draftPurchaseOrderRequest,
      );

      if (quotesError.status === 'error') {
        errors.quotes = t('requests.panel.thisFileIsTooLarge', quotesError);
      }

      if (
        shouldDisplayExpenseCategoryField &&
        !isPurchaseOrderRequestExpenseCategoryValid(
          draftPurchaseOrderRequest,
          expenseCategoryField.total_values > 0,
        ) &&
        expenseCategoryField.is_required
      ) {
        errors.expenseCategory = 'misc.requiredField';
      }
      return errors;
    },
    onSubmit: (values) => {
      const panelClosedTime = Date.now();
      track(AnalyticEventName.REQUEST_TIME_SPENT_ON_PO_PANEL_WHEN_SUBMITTED, {
        time: panelClosedTime - panelOpenedTime,
      });
      const draftPurchaseOrderRequest = toDraftPurchaseOrderRequest({
        formValues: values,
        hasExpenseCategoriesFeature,
        expenseCategoryCustomFieldId,
      });
      return onSubmit(draftPurchaseOrderRequest);
    },
  });
  const shouldDisplayExpenseCategoryField =
    useShouldDisplayExpenseCategoryField(
      formikProps.values.costCenter?.id,
      expenseCategoryField?.eligible_types ?? [],
      'request',
    );
  return {
    customFields,
    costCenters,
    shouldDisplayTeamField,
    shouldDisplayCostCenterField,
    shouldDisplayCustomFieldsField: hasCustomFieldsFeature,
    shouldDisplayExpenseCategoryField,
    isExpenseCategoryFieldRequired: expenseCategoryField.is_required,
    // @ts-expect-error waiting for requests group to have the proper typing
    userTeams,
    formikProps,
  };
};

const toDraftPurchaseOrderRequest = ({
  formValues,
  hasExpenseCategoriesFeature,
  expenseCategoryCustomFieldId,
}: {
  formValues: FormValues;
  hasExpenseCategoriesFeature: boolean;
  expenseCategoryCustomFieldId: string;
}): DraftPurchaseOrderRequest => {
  let amount = null;
  let netAmount = null;
  if (formValues.amount && formValues.currency) {
    amount = fromNumber(formValues.amount, formValues.currency.key, 2);
  }
  if (formValues.netAmount && formValues.currency) {
    netAmount = fromNumber(formValues.netAmount, formValues.currency.key, 2);
  }

  const customFieldAssociations = addExpenseCategoryToCustomFieldAssociations({
    customFieldAssociations: formValues.customFieldAssociations ?? [],
    expenseCategoryId: formValues.expenseCategory?.id ?? undefined,
    isExpenseCategoriesFeatureEnabled: hasExpenseCategoriesFeature,
    expenseCategoryCustomFieldId,
    expenseCategoryValue: '',
  });

  const isNewSupplierSelected =
    formValues.supplier && formValues.supplier.id === formValues.supplier.name;

  return {
    description: formValues.description,
    startDate: formValues.startDate
      ? formatDateToApiString(formValues.startDate)
      : null,
    endDate: formValues.endDate
      ? formatDateToApiString(formValues.endDate)
      : null,
    teamId: formValues.team?.id ?? null,
    costCenterId: formValues.costCenter?.id ?? null,
    expenseCategoryId: formValues.expenseCategory?.id ?? null,
    amount,
    netAmount,
    customFieldAssociations,
    ...(isNewSupplierSelected
      ? { newSupplierName: formValues.supplier?.name ?? '' }
      : { supplierId: formValues.supplier?.id ?? '' }),
    quotes: formValues.quotes,
    items: formValues.items,
  };
};
