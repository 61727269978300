import { IconButton, Select, Tag } from '@dev-spendesk/grapes';

import { AutocompleteMultipleSearch } from 'src/core/common/components/AutocompleteSearch';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { type SupportedKomboFilterDimension } from 'src/core/modules/integrations/types';

import { FilterError } from './FilterError';
import { FilterLayout } from './FilterLayout';
import { type DimensionOption } from '../types';

type Option = {
  key: string;
  label: string;
};

type FilterOptionProps = {
  availableDimensions: DimensionOption[];
  selectedDimension: SupportedKomboFilterDimension | null;
  selectedValues: string[]; // this will be a FilterValue when we move to ids.
  availableFilters: Option[];
  onChange: (
    dimension: SupportedKomboFilterDimension,
    values: string[],
  ) => void;
  hasDeleteButton: boolean;
  onDelete: () => void;
  index: number;
  checkErrors: boolean;
};

export const FilterOption = ({
  availableDimensions,
  selectedDimension,
  selectedValues,
  availableFilters,
  onChange,
  hasDeleteButton,
  onDelete,
  index,
  checkErrors,
}: FilterOptionProps) => {
  const { t } = useTranslation('global');
  const values = calculateValues(selectedValues, availableFilters);

  function handleOnSelect(options: Option[]) {
    const optionsKeys = options.map((filter) => filter.key);
    if (selectedDimension) {
      onChange(selectedDimension, optionsKeys);
    }
  }

  return (
    <div>
      <FilterLayout
        groupOperator={
          index > 0 && (
            <Tag variant="info">
              {t('integration.kombo.settings.filters.groupOperators.and')}
            </Tag>
          )
        }
        dimension={
          <Select
            fit="parent"
            placeholder={t(
              'integration.kombo.settings.filters.dimension.placeholder',
            )}
            isInvalid={checkErrors && !selectedDimension}
            onSelect={(event) =>
              onChange(event.key as SupportedKomboFilterDimension, [])
            }
            value={availableDimensions.find(
              (option) => option.key === selectedDimension,
            )}
            options={availableDimensions}
          />
        }
        values={
          <AutocompleteMultipleSearch
            fit="parent"
            values={values}
            placeholder={t(
              'integration.kombo.settings.filters.values.dropdownButton',
            )}
            options={availableFilters}
            isDisabled={!selectedDimension || availableFilters.length === 0}
            onSelect={handleOnSelect}
            translations={{
              selected: calculateText(values, availableFilters.length, t),
            }}
            renderNoOptions={() =>
              t(
                'integration.kombo.settings.filters.values.dropdownButtonNoOptions',
              )
            }
          />
        }
        actions={
          hasDeleteButton && (
            <IconButton iconName="trash" onClick={() => onDelete()} />
          )
        }
      />

      <FilterError
        dimensionValues={selectedValues || []}
        selectedDimension={selectedDimension}
        shouldDisplayErrors={checkErrors}
      />
    </div>
  );
};

const calculateValues = (
  selectedValues: string[],
  availableFilters: Option[],
) =>
  selectedValues
    .map((selectedValue) => {
      const selectedOption = availableFilters.find(
        (filter) => filter.key === selectedValue,
      );
      if (selectedOption) {
        return {
          key: selectedOption.key,
          label: selectedOption.label,
        };
      }
      return undefined;
    })
    .filter((v) => v !== undefined) as { key: string; label: string }[];

const calculateText = (
  selectedOptionLabels: Option[],
  maxLength: number,
  t: TGlobalFunctionTyped,
) => {
  switch (selectedOptionLabels.length) {
    case 1: {
      return selectedOptionLabels[0].label;
    }
    case 2: {
      return selectedOptionLabels
        .map((option) => option.label)
        .join(` ${t('misc.and')} `);
    }
    case maxLength: {
      return t(
        'integration.kombo.settings.filters.values.dropdownButtonAllValuesSelected',
      );
    }
    default: {
      return t(
        'integration.kombo.settings.filters.values.dropdownButtonWithCount',
        {
          count: selectedOptionLabels.length,
        },
      );
    }
  }
};
