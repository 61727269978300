import {
  Button,
  IconButton,
  ModalFooter,
  TextInput,
  colors,
  FormField,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUsersCountLimitFeatures } from 'modules/members/hooks/useUsersCountLimitFeatures';
import { type Member } from 'modules/members/models/member';
import isValidEmail from 'src/auth/utils/isValidEmail';

import styles from './EmailsInputFields.module.css';
import {
  type DataSources,
  type InviteField,
  type SendInvitesPayload,
  type Settings,
  type SettingsKeys,
} from '../../../models/invite';
import { MemberInviteSettings } from '../MemberInviteSettings';

type Props = {
  members: Member[];
  settingsDataSources: DataSources;
  remainingSeatsForUsersCount?: number;
  isTeamsFeatureEnabled: boolean;
  isCostCenterActivatedFeatureEnabled: boolean;
  showMembersTipModal?: () => void;
  onCancel?: () => void;
  sendInvites: (payload: SendInvitesPayload) => Promise<void>;
  inviteFields: InviteField[];
  setInviteFields: (inviteFields: InviteField[]) => void;
  setSettings: (settings: Settings) => void;
  settings: Settings;
  setEmailInputType: (emailInputType: 'fields' | 'text') => void;
  accountOwnerDomain?: string;
};

export const EmailsInputFields = ({
  settingsDataSources,
  members,
  remainingSeatsForUsersCount,
  isTeamsFeatureEnabled,
  isCostCenterActivatedFeatureEnabled,
  showMembersTipModal,
  onCancel,
  sendInvites,
  inviteFields,
  setInviteFields,
  settings,
  setSettings,
  setEmailInputType,
  accountOwnerDomain,
}: Props) => {
  const { t } = useTranslation('global');

  const [canInviteAnotherUser, setCanInviteAnotherUser] = useState(
    (remainingSeatsForUsersCount ?? 0) > 1,
  );
  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();

  const currentUserCount = members.filter(
    (member: Member) => !member.isDeleted,
  ).length;

  useEffect(() => {
    setCanInviteAnotherUser((remainingSeatsForUsersCount ?? 0) > 1);
  }, [remainingSeatsForUsersCount]);

  const removeInviteField = (index: number) => {
    const indexedFields = inviteFields
      .filter((inviteField) => inviteField.index !== index)
      .map((field, fieldIndex) => ({
        ...field,
        index: fieldIndex,
      }));

    const remainingSeats =
      (remainingSeatsForUsersCount ?? 0) - indexedFields.length;

    setCanInviteAnotherUser(remainingSeats > 0);
    setInviteFields(indexedFields);
  };

  const updateInviteFields = (
    updatedField: InviteField,
    fieldIndex: number,
  ) => {
    const updatedFields = [
      ...inviteFields.slice(0, fieldIndex),
      updatedField,
      ...inviteFields.slice(fieldIndex + 1),
    ];
    setInviteFields(updatedFields);
  };

  const inviteFieldOnChange = (rawValue: string, index: number) => {
    const value = rawValue.trim();

    const fieldIndex = inviteFields.findIndex((field) => field.index === index);
    if (fieldIndex === -1) {
      return;
    }

    const updatedField = { ...inviteFields[fieldIndex], value };
    // On change:
    // - when the email was previously invalid, the input stops being red as soon as it becomes valid
    // - when the email becomes invalid, it only becomes red on unfocus (done in inviteFieldOnBlur)
    if (updatedField.valid === false) {
      updatedField.valid = value.trim() ? isValidEmail(value) : true;
    }

    updateInviteFields(updatedField, fieldIndex);
  };

  const inviteFieldOnBlur = (rawValue: string, index: number) => {
    const value = rawValue.trim();

    const fieldIndex = inviteFields.findIndex((field) => field.index === index);
    if (fieldIndex === -1) {
      return;
    }

    const valid = isValidEmail(value);
    const duplicate = members.some((member: Member) => member.email === value);
    const updatedField = { ...inviteFields[fieldIndex], duplicate, valid };

    updateInviteFields(updatedField, fieldIndex);
  };

  const renderInviteFields = (totalUsers: number, maxUsers: number = -1) => {
    return inviteFields.map((field, fieldIndex) => {
      const { value, index, valid } = field;
      const isOverLimit = totalUsers + fieldIndex >= maxUsers;
      const hasNoLimit = maxUsers === -1;

      const isInvalid = !!value.trim() && !valid;

      return (
        <FormField
          key={index}
          visuallyHideLabel
          label=""
          alertMessage={
            isInvalid && inviteFields.length < 2
              ? t('misc.emailAddressInvalid')
              : undefined
          }
        >
          <TextInput
            className={index === 0 ? 'mt-s' : 'mt-xs'}
            key={index}
            fit="parent"
            isInvalid={isInvalid}
            placeholder="name@company.com"
            value={value}
            onBlur={(event) => {
              inviteFieldOnBlur(event.target.value, index);
            }}
            onChange={(event) => {
              inviteFieldOnChange(event.target.value, index);
            }}
            isDisabled={!hasNoLimit && isOverLimit}
            rightAddon={
              inviteFields.length > 1 && (
                <IconButton
                  iconColor={isInvalid ? colors.alert : undefined}
                  iconName="cross"
                  onClick={() => removeInviteField(index)}
                />
              )
            }
          />
        </FormField>
      );
    });
  };

  const addInviteField = () => {
    const newFieldsCount = inviteFields.length + 1;
    const remainingSeats = (remainingSeatsForUsersCount ?? 0) - newFieldsCount;

    setInviteFields([
      ...inviteFields,
      {
        value: `@${accountOwnerDomain}`,
        index: inviteFields.length,
      },
    ]);

    setCanInviteAnotherUser(remainingSeats > 0);
  };

  const renderInviteActions = (totalUsers: number) => {
    return (
      <div className="my-s flex items-center justify-between">
        <div className="flex items-center">
          {canInviteAnotherUser && (
            <Button
              variant="ghost"
              hasNegativeMargins
              text={t('members.inviteAddAnother')}
              onClick={addInviteField}
            />
          )}
          {canInviteAnotherUser && !hasUsersCountLimitFeature && (
            <span className="mx-xs">{t('members.inviteOr')}</span>
          )}
          {!hasUsersCountLimitFeature && (
            <Button
              variant="ghost"
              hasNegativeMargins
              text={t('members.inviteManyUsersAtOnce')}
              onClick={() => setEmailInputType('text')}
            />
          )}
        </div>
        {!hasUsersCountLimitFeature && (
          <div>{t('members.countMembers', { count: totalUsers })}</div>
        )}
      </div>
    );
  };

  const handleSelectionChange = (nextSettings: Settings) => {
    setSettings(nextSettings);
  };

  const renderRolesTip = () => {
    const tip =
      (isCostCenterActivatedFeatureEnabled &&
        t('members.tipMembersWithCostCenter')) ||
      (isTeamsFeatureEnabled && t('members.tipMembersWithTeams')) ||
      t('members.tipMembers');

    return (
      <Button
        className="mt-s"
        variant="ghost"
        text={tip}
        onClick={showMembersTipModal}
      />
    );
  };

  const handleInvitations = async () => {
    await sendInvites({
      emails: inviteFields.map((inviteField) =>
        inviteField.value.toLowerCase(),
      ),
      settings: {
        role: settings.role,
        policy: settings.policy,
        team: settings.team,
        costCenter: settings.costCenter,
      },
    });
  };

  const renderActions = () => (
    <>
      <Button variant="secondary" text={t('misc.cancel')} onClick={onCancel} />
      <Button text={t('members.sendInvitations')} onClick={handleInvitations} />
    </>
  );

  return (
    <div>
      <div
        className={cx({
          [styles.EmailListContainer]: inviteFields.length > 1,
        })}
      >
        {renderInviteFields(currentUserCount)}
      </div>
      {renderInviteActions(currentUserCount)}
      <MemberInviteSettings
        dataSources={settingsDataSources}
        onSelect={handleSelectionChange}
        settings={settings}
        setOneSetting={(key: SettingsKeys, value: Settings[SettingsKeys]) => {
          return setSettings({
            ...settings,
            [key]: value,
          });
        }}
      />
      {renderRolesTip()}
      <ModalFooter>{renderActions()}</ModalFooter>
    </div>
  );
};
