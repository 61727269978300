import { Modal, SkeletonText, Timeline } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';

import { SupplierChangeTimelineItem } from './SupplierChangeTimelineItem';
import { useFetchSupplierBankInfosHistory } from '../../hooks/api/useFetchSupplierBankInfosHistory';

export type Props = {
  isOpen: boolean;
  supplierId: string;
  onClose: () => void;
};

export const SupplierHistoryModal = ({
  isOpen,
  supplierId,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  const supplierBankInfosHistoryQueryState =
    useFetchSupplierBankInfosHistory(supplierId);

  return (
    <Modal
      isOpen={isOpen}
      title={t('suppliers.latestUpdater.historyModal.title')}
      subtitle={t('suppliers.latestUpdater.historyModal.subtitle')}
      iconName="clock"
      iconVariant="info"
      onClose={onClose}
    >
      <QuerySuspense
        queryState={supplierBankInfosHistoryQueryState}
        fallback={(error) => (
          <QueryError
            queryError={error}
            componentType="Callout"
            logContext={{
              team: 'finance-operations',
            }}
          />
        )}
        loading={
          <>
            <SkeletonText size="l" />
            <SkeletonText size="l" />
            <SkeletonText size="l" />
          </>
        }
      >
        {(supplierDetailsChanges) => (
          <Timeline>
            {supplierDetailsChanges.map((change) => (
              <SupplierChangeTimelineItem
                supplierChange={change}
                key={`${change.field}${change.updatedAt}`}
              />
            ))}
          </Timeline>
        )}
      </QuerySuspense>
    </Modal>
  );
};
