import cx from 'classnames';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './MultiSelect.scss';

class MultiSelect extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['small', 'large']),
    selected: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.object,
    ]),
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func,
    isDisabled: PropTypes.bool,
    values: PropTypes.array,
    displayProp: PropTypes.string,
    matchingProp: PropTypes.string,
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    selected: [],
    size: 'small',
    values: [],
    multiple: true,
    onDeselect: noop,
    isDisabled: undefined,
    displayProp: undefined,
    matchingProp: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: this.getSelectedInProps(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ selected: this.getSelectedInProps(nextProps) });
  }

  getSelectedInProps(props) {
    if (!props.multiple) {
      return [props.selected];
    }
    return Array.isArray(props.selected) ? props.selected : [props.selected];
  }

  getDisplay = (value) => {
    if (isObject(value)) {
      return value[this.props.displayProp];
    }
    return value;
  };

  findInList = (list, value) => {
    const { matchingProp } = this.props;
    if (isObject(value) && matchingProp) {
      return (list ?? []).findIndex(
        (o) => o[matchingProp] === value[matchingProp],
      );
    }
    return isObject(value)
      ? (list ?? []).findIndex((item) => isEqual(item, value))
      : (list ?? []).indexOf(value);
  };

  selectValue = (value) => {
    const { matchingProp, multiple, onSelect, onDeselect } = this.props;

    if (multiple) {
      if (this.findInList(this.state.selected, value) === -1) {
        this.setState({ selected: this.state.selected.concat(value) });
        onSelect(value);
      } else {
        this.setState({
          selected: filter(
            this.state.selected,
            (v) => this.getDisplay(v) !== this.getDisplay(value),
          ),
        });
        onDeselect(value);
      }
    } else {
      const selectedElement = this.state.selected[0];
      const valueToCheck =
        isObject(value) && matchingProp
          ? selectedElement[matchingProp]
          : selectedElement;
      if (valueToCheck === value) {
        return;
      }
      onSelect(value);
      this.setState({ selected: [value] });
    }
  };

  renderSelectEls = (values) =>
    map(values, (value) => {
      const selected = this.findInList(this.state.selected, value) > -1;
      const content = isFunction(value.renderer)
        ? value.renderer(selected)
        : this.getDisplay(value);
      return (
        <div
          key={this.getDisplay(value)}
          className={cx({ selected })}
          onClick={() => !this.props.isDisabled && this.selectValue(value)}
        >
          {content}
        </div>
      );
    });

  render() {
    const { className, size } = this.props;
    const classes = cx('multiselect__container', className, size, {
      disabled: this.props.isDisabled,
    });

    return (
      <div className={classes}>{this.renderSelectEls(this.props.values)}</div>
    );
  }
}

export default MultiSelect;
