import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { useBuildConsentUrlMutation } from './useBuildConsentUrlMutation';
import { useBuildExternalConsentUrlMutation } from './useBuildExternalConsentUrlMutation';
import { useMfaModalActions } from './useMfaModalActions';
import {
  type AccountingSoftware,
  type IntegrationStatusWithIntegration,
} from '../../../integration/status';

type Props = {
  hasIntegrationOAuth: (
    accountingSoftware:
      | AccountingSoftware
      | 'noIntegration'
      | 'switchInProgress',
  ) => boolean;
  hasXeroMfaCheckEnabled: boolean;
  userHasValidMfa: boolean;
  setIsPreloginModalOpen: (isOpen: boolean) => void;
  setIsMfaModalOpen: (isOpen: boolean) => void;
  fetchUserHasValidMfa: () => Promise<boolean | undefined>;
  setHasMfaUserError: (hasError: boolean) => void;
  handleOAuth1: () => void;
  status: IntegrationStatusWithIntegration;
};

export const useNotConnectedModalsActions = ({
  hasIntegrationOAuth,
  hasXeroMfaCheckEnabled,
  userHasValidMfa,
  setIsPreloginModalOpen,
  setIsMfaModalOpen,
  fetchUserHasValidMfa,
  setHasMfaUserError,
  handleOAuth1,
  status,
}: Props) => {
  const [buildConsentUrl] = useBuildConsentUrlMutation();
  const [buildExternalConsentUrl] = useBuildExternalConsentUrlMutation();

  const handleConnect = () => {
    switch (status.authorization.kind) {
      case 'noAuthorization':
      case 'token':
        return;
      case 'oauth1':
        return handleOAuth1();
      case 'oauth2OpenId':
      case 'oauth2ScopedOpenId':
        return buildConsentUrl();
      case 'external':
        return buildExternalConsentUrl();
      default:
        rejectUnexpectedValue('authorization', status.authorization);
    }
  };

  const handleClick = () => {
    if (hasIntegrationOAuth(status.integration)) {
      if (hasXeroMfaCheckEnabled) {
        return userHasValidMfa
          ? setIsPreloginModalOpen(true)
          : setIsMfaModalOpen(true);
      }
      return setIsPreloginModalOpen(true);
    }

    return handleConnect();
  };

  const {
    actions: { handleMfaSetup },
  } = useMfaModalActions({
    fetchUserHasValidMfa,
    setHasMfaUserError,
    closeMfaModal: () => setIsMfaModalOpen(false),
  });

  return {
    actions: {
      handleClick,
      handleMfaSetup,
      handleConnect,
    },
  };
};
