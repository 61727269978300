import {
  Button,
  FormField,
  Panel,
  PanelSection,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import {
  FileViewerInline,
  type MediaType,
} from 'common/components/legacy/LegacyFileViewer';
import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import { type BankInfosErrors } from 'modules/suppliers/models';
import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete';
import { getFileTypeFromMime, srcFromFile } from 'src/core/utils/files';
import { isVatEnabled } from 'src/core/utils/vat';

import { SupplierFormAccountPayableModalWarning } from './SupplierFormAccountPayableModalWarning';
import { SupplierFormBankInfoFields } from './SupplierFormBankInfoFields';
import BankProofUpload from '../../../ToReview/components/BankProofUpload';
import { type BankInfoOcrResult, type Supplier } from '../supplier';

import './SupplierForm.css';

export type FormMode = 'creation' | 'edition';

export type FormValues = {
  supplier: {
    id?: string;
    name: string;
  } | null;
  accountHolderName: string | null;
  legalName: string | null;
  supplierAccountCode: string | null;
  registrationNumber: string | null;
  vatNumber: string | null;
  address: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
  bankCountry: string | null;
  iban: string | null;
  bic: string | null;
  sortCode: string | null;
  routingNumber: string | null;
  accountNumber: string | null;
  accountCode: string | null;
  bankInfoSuggestion: {
    iban: BankInfoOcrResult | null;
    bic: BankInfoOcrResult | null;
  };
  bankProofFile: {
    fileToUpload?: File;
    mimetype: string | null;
    filename: string | null;
    viewUrl: string | null;
    thumbnailUrl: string | null;
    downloadUrl: string | null;
  } | null;
};

type Props = {
  mode: FormMode;
  supplier?: Supplier;
  isBankProofFileDisplayed: boolean;
  onShowBankProofFile: (
    bankProofFile?: Supplier['details']['bankProofFile'],
  ) => void;
  blockSubmit?: boolean;
  showBankProofFileInline: boolean;
};

export const SupplierForm = ({
  mode,
  isBankProofFileDisplayed,
  onShowBankProofFile,
  blockSubmit = false,
  // Formik props
  values,
  errors,
  isSubmitting,
  setFieldValue,
  handleChange,
  handleSubmit,
  supplier,
  showBankProofFileInline,
}: Props & FormikProps<FormValues>) => {
  const { t } = useTranslation('global');
  const [
    accountPayableWarningModal,
    showAccountPayableWarningModal,
    hideAccountPayableWarningModal,
  ] = useModal(() => (
    <SupplierFormAccountPayableModalWarning
      onConfirm={() => handleSubmit()}
      onCancel={() => hideAccountPayableWarningModal()}
    />
  ));

  const handleUploadBankProofFile = (file: File) => {
    if (!file) {
      return;
    }
    setFieldValue('bankProofFile', {
      fileToUpload: file,
      mimetype: file.type,
      filename: file.name,
      viewUrl: srcFromFile(file),
      thumbnailUrl: srcFromFile(file),
    });
  };

  const handleDeleteBankProofFile = () => {
    setFieldValue('bankProofFile', null);
    onShowBankProofFile();
  };

  const handleSubmitControl = (
    event: React.FormEvent<HTMLFormElement>,
  ): void | undefined => {
    event.preventDefault();
    if (
      supplier?.supplierAccountCode &&
      values.supplierAccountCode &&
      supplier?.supplierAccountCode !== values.supplierAccountCode.trim()
    ) {
      showAccountPayableWarningModal();
    } else {
      handleSubmit(event);
    }
  };

  return (
    <form className="h-full" onSubmit={handleSubmitControl} noValidate>
      <Panel
        footer={
          <Button
            fit="parent"
            type="submit"
            text={
              mode === 'creation'
                ? t('submitMyInvoice.supplier.createSupplier')
                : t('submitMyInvoice.supplier.updateSupplier')
            }
            isLoading={isSubmitting}
            isDisabled={isSubmitting || blockSubmit}
          />
        }
      >
        <PanelSection title={t('submitMyInvoice.supplier.legalInfo')}>
          <FormField
            className="mt-s"
            label={t('submitMyInvoice.fields.supplier.legalName')}
            alertMessage={errors.legalName}
          >
            <TextInput
              name="legalName"
              placeholder={t('submitMyInvoice.fields.supplier.placeholder')}
              value={values.legalName ?? ''}
              isDisabled
              onChange={handleChange}
            />
          </FormField>

          <FormField
            className="mt-s"
            label={t('submitMyInvoice.fields.registrationNumber.label')}
            hint={t('misc.optional')}
          >
            <TextInput
              name="registrationNumber"
              placeholder={t(
                'submitMyInvoice.fields.registrationNumber.placeholder',
              )}
              value={values.registrationNumber ?? ''}
              onChange={handleChange}
            />
          </FormField>
          {isVatEnabled(values.country) && (
            <FormField
              className="mt-s"
              label={t('submitMyInvoice.fields.vatNumber.label')}
              hint={t('misc.optional')}
            >
              <TextInput
                name="vatNumber"
                placeholder={t('submitMyInvoice.fields.vatNumber.placeholder')}
                value={values.vatNumber ?? ''}
                onChange={handleChange}
              />
            </FormField>
          )}

          <FormField
            className="mt-s"
            label={t('submitMyInvoice.fields.address.label')}
            hint={t('misc.optional')}
          >
            <TextInput
              name="address"
              className="mb-xs"
              placeholder={t(
                'submitMyInvoice.fields.address.addressPlaceholder',
              )}
              value={values.address ?? ''}
              onChange={handleChange}
            />
            <TextInput
              name="zipcode"
              className="mb-xs"
              placeholder={t(
                'submitMyInvoice.fields.address.zipcodePlaceholder',
              )}
              value={values.zipcode ?? ''}
              onChange={handleChange}
            />
            <div className="SupplierForm__address">
              <TextInput
                className="SupplierForm__address-half-width"
                name="city"
                placeholder={t(
                  'submitMyInvoice.fields.address.cityPlaceholder',
                )}
                value={values.city ?? ''}
                onChange={handleChange}
              />
              <CountryAutocomplete
                className="SupplierForm__address-half-width"
                fit="parent"
                countryCode={values.country ?? ''}
                onSelect={(countryCode) => {
                  if (countryCode) {
                    setFieldValue('country', countryCode);
                  }
                }}
              />
            </div>
          </FormField>
        </PanelSection>

        <PanelSection title={t('submitMyInvoice.supplier.bankInfo')}>
          <SupplierFormBankInfoFields
            bankInfoSuggestion={values.bankInfoSuggestion}
            values={{
              bankCountry: values.bankCountry,
              accountHolderName: values.accountHolderName,
              iban: values.iban,
              bic: values.bic,
              sortCode: values.sortCode,
              accountCode: values.accountCode,
              accountNumber: values.accountNumber,
              routingNumber: values.routingNumber,
            }}
            errors={errors as BankInfosErrors}
            setFieldValue={(field, value) => setFieldValue(field, value, false)}
          />
        </PanelSection>

        <PanelSection title={t('submitMyInvoice.supplier.proof')}>
          <BankProofUpload
            file={values.bankProofFile}
            fileName={
              values.bankProofFile ? values.bankProofFile.filename : null
            }
            fileType={
              values.bankProofFile && values.bankProofFile.mimetype
                ? getFileTypeFromMime(values.bankProofFile.mimetype)
                : null
            }
            fileThumb={
              values.bankProofFile ? values.bankProofFile.thumbnailUrl : null
            }
            onUpload={(files: File[]) => handleUploadBankProofFile(files[0])}
            onDelete={handleDeleteBankProofFile}
            onOpenPreview={() => onShowBankProofFile(values.bankProofFile)}
            ctaUpload={t('submitMyInvoice.fields.bankProofFile.attachFileCta')}
            description={t('submitMyInvoice.fields.bankProofFile.description')}
            dropFile={t('requests.panel.newIbanDropFile')}
            showHideWording={
              isBankProofFileDisplayed
                ? t('submitMyInvoice.supplier.hide')
                : t('submitMyInvoice.supplier.show')
            }
          />
          {showBankProofFileInline && values.bankProofFile && (
            <FileViewerInline
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fileMediaType={values.bankProofFile.mimetype! as MediaType}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fileUrl={values.bankProofFile.viewUrl!}
              onClose={() => onShowBankProofFile()}
            />
          )}
        </PanelSection>
        {accountPayableWarningModal}
      </Panel>
    </form>
  );
};
