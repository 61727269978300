import React from 'react';

import {
  PDFViewer,
  ImageViewer,
} from 'src/core/common/components/legacy/LegacyFileViewer';

export type Document = {
  url: string;
  mediaType: string;
  validity: {
    isValid: boolean;
    reason: string | undefined;
  };
};

export const getDocumentViewer = (document: Document, onClose: () => void) => {
  if (document.mediaType === 'application/pdf') {
    return (
      <PDFViewer
        withZoom
        withDownload
        withRotate
        withClose
        url={document.url}
        isInvalid={!document.validity.isValid}
        invalidProofReason={document.validity.reason}
        onDownload={() => window.open(document.url, '_blank')}
        onClose={onClose}
      />
    );
  }
  return (
    <ImageViewer
      withZoom
      withDownload
      withRotate
      withClose
      url={document.url}
      isInvalid={!document.validity.isValid}
      invalidProofReason={document.validity.reason}
      onDownload={() => window.open(document.url, '_blank')}
      onClose={onClose}
    />
  );
};
