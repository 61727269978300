import { Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import compact from 'lodash/compact';
import noop from 'lodash/noop';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React, { PureComponent, createRef } from 'react';
import { withTranslation } from 'react-i18next';

import { ICONS } from 'src/core/common/components/legacy/Icon/Icon';

import FileViewerToolbar, {
  MODES,
} from '../FileViewerToolbar/FileViewerToolbar';
import ToolbarAction from '../FileViewerToolbar/ToolbarAction';
import InvalidProofBanner from '../InvalidProofBanner/InvalidProofBanner';

import './ImageViewer.scss';

class ImageViewer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    toolbarMode: PropTypes.oneOf(values(MODES)),
    withZoom: PropTypes.bool,
    withDownload: PropTypes.bool,
    withRotate: PropTypes.bool,
    withDelete: PropTypes.bool,
    withDeleteTooltip: PropTypes.string,
    withClose: PropTypes.bool,
    isInvalid: PropTypes.bool,
    invalidProofReason: PropTypes.string,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    onToggleZoom: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    toolbarMode: null,
    withZoom: false,
    withDownload: false,
    withRotate: false,
    withDelete: false,
    withDeleteTooltip: '',
    withClose: false,
    isInvalid: false,
    invalidProofReason: null,
    onDownload: noop,
    onDelete: noop,
    onClose: noop,
    onToggleZoom: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      originX: 0,
      originY: 0,
      scale: -1,
      isZoomed: false,
      rotation: 0,
      isLoaded: false,
      isRotating: false,
    };

    this.activeZoomStyles = { scale: 2 };
    this.idleZoomStyles = { scale: -1, originX: 0, originY: 0 };
    this.imageRef = createRef();
    this.canvasRef = props.withRotate ? createRef() : null;
    // Canvas API use angles in radians, the following corresponds to 0, 90, 180 and 270 degrees
    this.ANGLES = [0 * Math.PI, 0.5 * Math.PI, Math.PI, 1.5 * Math.PI];
    this.TRANSITION_DURATION = 200;
  }

  componentDidMount() {
    this.canvasCtx = this.props.withRotate
      ? this.canvasRef.current.getContext('2d')
      : null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      // We've got a new image, clear cache and reset rotation
      this.setState({
        rotation: 0,
      });
    }

    if (nextProps.withRotate && !this.props.withRotate) {
      this.canvasRef = createRef();
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props.withRotate && !previousProps.withRotate) {
      this.canvasCtx = this.canvasRef.current.getContext('2d');
    }
  }

  onMouseOver = (e) => {
    if (!this.state.isZoomed) {
      return;
    }
    this.zoomIn(this.calculateZoomPosition(e));
  };

  onMouseMove = (e) => {
    if (!this.state.isZoomed) {
      return;
    }
    this.zoomIn(this.calculateZoomPosition(e));
  };

  onMouseOut = () => {
    if (!this.state.isZoomed) {
      return;
    }
    this.zoomOut();
  };

  getComputedStyle = () => {
    const { withZoom } = this.props;

    if (!withZoom) {
      return null;
    }

    const { isZoomed, scale, originX, originY } = this.state;
    return {
      transform: scale > 0 ? `scale(${scale})` : 'initial',
      transformOrigin: `${originX}% ${originY}%`,
      cursor: isZoomed ? 'zoom-out' : 'zoom-in',
      height: isZoomed ? '100%' : 'auto',
    };
  };

  calculateZoomPosition = (mouseEvent) => {
    const {
      nativeEvent: {
        pageX,
        pageY,
        target: {
          x,
          y,
          clientWidth,
          parentElement: { clientHeight },
        },
      },
    } = mouseEvent;

    const originX = Math.min(((pageX - x) / clientWidth) * 100, 100);
    const originY = Math.min(((pageY - y) / clientHeight) * 100, 100);

    return { originX, originY };
  };

  zoomIn = ({ originX, originY }) => {
    const wasZoomed = this.state.isZoomed;
    this.setState(
      {
        isZoomed: true,
        originX,
        originY,
        ...this.activeZoomStyles,
      },
      () => {
        if (!wasZoomed) {
          this.props.onToggleZoom(this.state.isZoomed);
        }
      },
    );
  };

  zoomOut = () => {
    const wasZoomed = this.state.isZoomed;
    this.setState(
      {
        isZoomed: false,
        ...this.idleZoomStyles,
      },
      () => {
        if (wasZoomed) {
          this.props.onToggleZoom(this.state.isZoomed);
        }
      },
    );
  };

  toggleZoom = (e) => {
    if (!this.props.withZoom) {
      return;
    }

    return this.state.isZoomed
      ? this.zoomOut()
      : this.zoomIn(this.calculateZoomPosition(e));
  };

  getLeftActions = () => {
    const {
      t,
      withDownload,
      onDownload,
      withDelete,
      withDeleteTooltip,
      onDelete,
      withClose,
      withRotate,
    } = this.props;
    const { isLoaded, isRotating } = this.state;

    const rotateAction = withRotate ? (
      <ToolbarAction
        key="rotate"
        onClick={this.rotateImage}
        text={t('misc.rotate')}
        icon={ICONS.ROTATE}
        /**
         * We need the image to be fully loaded to get its dimensions and be able to rotate it and we want to
         * disable rotation when its already in progress
         */
        disabled={!isLoaded || isRotating}
        isLoading={isRotating}
      />
    ) : null;

    const downloadAction = withDownload ? (
      <ToolbarAction
        key="download"
        onClick={onDownload}
        text={t('misc.download')}
        icon={ICONS.DOWNLOAD}
      />
    ) : null;

    if (withClose) {
      return compact([
        withDelete && (
          <Tooltip
            key="delete"
            content={withDeleteTooltip || ''}
            placement="right"
            maxWidth={544}
            isDisabled={withDeleteTooltip === ''}
          >
            <ToolbarAction
              onClick={onDelete}
              text={t('misc.delete')}
              icon={ICONS.DELETE}
              disabled={withDeleteTooltip !== ''}
            />
          </Tooltip>
        ),
        downloadAction,
        rotateAction,
      ]);
    }

    return compact([downloadAction, rotateAction]);
  };

  getRightActions = () => {
    const { t, withDelete, withDeleteTooltip, onDelete, withClose, onClose } =
      this.props;

    if (withClose) {
      return [
        <ToolbarAction
          key="close"
          onClick={onClose}
          text={t('misc.close')}
          icon={ICONS.CLOSE}
        />,
      ];
    }

    return compact([
      withDelete && (
        <Tooltip
          key="delete"
          content={withDeleteTooltip || ''}
          placement="right"
          maxWidth={544}
          isDisabled={withDeleteTooltip === ''}
        >
          <ToolbarAction
            key="delete"
            onClick={onDelete}
            text={t('misc.delete')}
            icon={ICONS.DELETE}
          />
        </Tooltip>
      ),
    ]);
  };

  handleOriginalImageLoaded = () => {
    const image = this.imageRef.current;

    // Store original image natural size
    this.imgWidth = image.naturalWidth;
    this.imgHeight = image.naturalHeight;
    // Store original image src as rotation 0 source so we don't recalculate it
    this.setState({ isLoaded: true });
  };

  /**
   * Rotation works by drawing the original image to a canvas and rotate it.
   * Using a CSS rotation wouldn't work because it's a transform and that doesn't actually affect the image box in the
   * DOM, so it would be too complicated to have the viewer match the size of the rotated image and position it
   * correctly.
   * Ideally, we would like to use an OffscreenCanvas and defer this to a worker, because canvas manipulation is a
   * blocking operation on the main thread which may hang the UI with large images. But unfortunately, OffscreenCanvas
   * is pretty new and has no browser support at all as of today (August 2018).
   * Stolen from https://jsfiddle.net/Hq7p2/326/
   * TODO: use OffscreenCanvas
   */
  rotateImage = () => {
    // Store the next rotation position
    const nextRotation = (this.state.rotation + 1) % this.ANGLES.length;

    // Flag rotation in progress because we want a loading state as the operation can take a few seconds
    this.setState({ isRotating: true }, () => {
      // Wrap in a setTimeout so the browser has time to apply the rotating styles before we block the thread
      setTimeout(() => {
        const image = this.imageRef.current;
        const canvas = this.canvasRef.current;

        if (nextRotation === 0 || nextRotation === 2) {
          // When angle is 0 or 180 we want canvas dimensions to match image dimensions
          canvas.width = this.imgWidth;
          canvas.height = this.imgHeight;
        } else {
          // When angle is 90 or 270 we want canvas dimensions to be switched because we will change orientation
          canvas.width = this.imgHeight;
          canvas.height = this.imgWidth;
        }

        // Place cursor at center
        this.canvasCtx.translate(canvas.width * 0.5, canvas.height * 0.5);
        this.canvasCtx.rotate(this.ANGLES[nextRotation]);
        // Put back the cursor before drawing the image
        this.canvasCtx.translate(-this.imgWidth * 0.5, -this.imgHeight * 0.5);

        this.canvasCtx.drawImage(image, 0, 0);

        // Reset canvas transforms for future rotations
        this.canvasCtx.setTransform(1, 0, 0, 1, 0, 0);
        this.setState({
          rotation: nextRotation,
          isRotating: false,
        });
      }, this.TRANSITION_DURATION);
    });
  };

  render() {
    const {
      url,
      className,
      toolbarMode,
      withRotate,
      isInvalid,
      invalidProofReason,
    } = this.props;
    const { rotation, isRotating } = this.state;

    return (
      <div
        className={cx('LegacyImageViewer', className, {
          'LegacyImageViewer--rotating': isRotating,
        })}
      >
        <FileViewerToolbar
          leftActions={this.getLeftActions()}
          rightActions={this.getRightActions()}
          mode={toolbarMode}
        />
        {isInvalid && <InvalidProofBanner reason={invalidProofReason} />}
        <div
          className="LegacyImageViewer__zoombox"
          onMouseOver={this.onMouseOver}
          onMouseMove={this.onMouseMove}
          onMouseOut={this.onMouseOut}
          onClick={this.toggleZoom}
          onFocus={noop}
          onBlur={noop}
        >
          {withRotate && (
            <canvas
              ref={this.canvasRef}
              className="LegacyImageViewer__canvas"
              alt="invoice"
              draggable="false"
              style={{
                ...this.getComputedStyle(),
                display: rotation !== 0 ? 'block' : 'none',
              }}
            />
          )}
          {/* We need to keep this one in the DOM to be able to access the original image through the React ref */}
          <img
            ref={this.imageRef}
            src={url}
            alt="invoice"
            className="LegacyImageViewer__image"
            draggable="false"
            onLoad={this.handleOriginalImageLoaded}
            style={{
              ...this.getComputedStyle(),
              display: rotation !== 0 ? 'none' : 'block',
            }}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ImageViewer);
