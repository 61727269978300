import React from 'react';

import Icon, {
  ICONS,
} from "src/core/common/components/legacy/Icon/Icon";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { statusWarning } from "src/core/utils/color-palette";

import './InvalidProofBanner.scss';

const InvalidProofBanner = ({ reason }: { reason?: string }) => {
  const { t } = useTranslation('global');

  return (
    <div className="LegacyInvalidProofBanner">
      <Icon
        icon={ICONS.NEW_WARNING}
        width={16}
        height={16}
        fill={statusWarning}
        className="LegacyInvalidProofBanner__icon"
      />
      {t('misc.receiptDeclaredAsInvalid')}
      {reason && (
        <div className="LegacyInvalidProofBanner__reason body-m ">
          « {reason} »
        </div>
      )}
    </div>
  );
};

export default InvalidProofBanner;
