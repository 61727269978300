import { Button } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { BASE_PATH } from 'src/sfs-migration/common/constants/basePath';
import { getSfsMigrationPhaseUrlPath } from 'src/sfs-migration/used-in-js-app/hooks/useMigrationStep';
import { useSFSMigration } from 'src/sfs-migration/used-in-js-app/hooks/useSFSMigration';

import styles from './SfsMigrationWidget.module.css';
import heroImage from './hero-image.svg';

export const SfsMigrationWidget = () => {
  const { t } = useTranslation('global');
  const { t: sfsTranslations } = useTranslation('sfs-migration');
  const { id: companyId } = useCompany();
  const isSfsMigrationPageEnabled = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const isUkCustomerGroup = useCompany().currency === 'GBP';

  const sfsMigrationQuery = useSFSMigration({
    isEnabled: isSfsMigrationPageEnabled,
  });

  if (
    !isSfsMigrationPageEnabled ||
    sfsMigrationQuery.status !== 'success' ||
    !sfsMigrationQuery.data.widget
  ) {
    return null;
  }

  const activePhaseUrlPath = getSfsMigrationPhaseUrlPath(
    sfsMigrationQuery.data.widget.activePhase,
  );

  const migrationPageLink = `${BASE_PATH}/${companyId}/overview/${activePhaseUrlPath}`;
  const shouldHaveWarningDisplay =
    (sfsMigrationQuery.data.banner?.uncompletedTaskCount ?? 0) > 0;

  return (
    <div
      className={classNames(
        shouldHaveWarningDisplay ? styles.WarningWidgetBox : styles.WidgetBox,
        'elevation-10',
      )}
    >
      <div className="flex flex-col content-stretch gap-xxs p-m">
        <div className="flex items-center gap-xs">
          <img
            height="22"
            src="/static/img/logo.svg"
            alt={sfsTranslations('spendeskAlt')}
          />
          <div className="w-[1px] self-stretch bg-primary" />
          <p className="text-primary">
            {isUkCustomerGroup
              ? sfsTranslations('header.titleUkCustomerGroup')
              : sfsTranslations('header.title')}
          </p>
        </div>
        <div className="flex flex-col items-start gap-m">
          <p className="text-complementary body-m">
            {isUkCustomerGroup
              ? t('homepage.sfsMigrationWidget.descriptionUkCustomerGroup')
              : t('homepage.sfsMigrationWidget.description')}
          </p>
          <Button
            component="a"
            href={migrationPageLink}
            rel="noopener noreferrer"
            target="_blank"
            variant="contrasted"
            text={
              isUkCustomerGroup
                ? t('homepage.sfsMigrationWidget.ctaLabelUkCustomerGroup')
                : t('homepage.sfsMigrationWidget.ctaLabel')
            }
          />
        </div>
      </div>
      <div className="my-auto ml-auto">
        <img src={heroImage} alt="" height="152px" />
      </div>
    </div>
  );
};
