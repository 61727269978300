import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './TableResults.scss';

const TableResults = (props) => {
  const { className, children } = props;

  return (
    <table className={cx('TableResults page__table', className)}>
      <tbody>{children}</tbody>
    </table>
  );
};

TableResults.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

TableResults.defaultProps = {
  className: '',
  children: undefined,
};

export default TableResults;
