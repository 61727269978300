/* eslint-disable @typescript-eslint/naming-convention */
import { type DateFormatter } from '@dev-spendesk/grapes';
import type { TFunction } from 'i18next';
import React, { type ComponentType } from 'react';

import { type Namespace } from 'src/core/config/i18n';

import { useTranslation } from '../../hooks/useTranslation';

export function withTranslation<T>(namespace: Namespace) {
  return (
    WrappedComponent: ComponentType<
      T & { t: TFunction; localeFormat: DateFormatter }
    >,
  ) => {
    const ComponentWithTranslation = (props: T) => {
      const { t, localeFormat } = useTranslation(namespace);
      return <WrappedComponent {...props} t={t} localeFormat={localeFormat} />;
    };

    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ComponentWithTranslation.displayName = `withTranslation${displayName}`;

    return ComponentWithTranslation;
  };
}
