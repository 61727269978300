import cx from 'classnames';
import React from 'react';

import './Separator.scss';

interface Props {
  full?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const Separator = ({ style, full = false, className = '' }: Props) => (
  <hr
    className={cx('l-page__separator', { '-full': full }, className)}
    style={style}
  />
);

export default Separator;
