import cx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import DropZone from 'react-dropzone';
import { withTranslation } from 'react-i18next';

import ButtonRedesigned from "src/core/common/components/legacy/ButtonRedesigned/ButtonRedesigned";
import Icon, {
  ICONS,
} from "src/core/common/components/legacy/Icon/Icon";
import { grey6Cheerful } from "src/core/utils/color-palette";
import { getFileType } from "src/core/utils/files";
import './BankProofUpload.scss';

const BankProofUpload = ({
  file,
  fileName,
  fileType,
  fileThumb,
  onDelete,
  onOpenPreview,
  showHideWording,
  ctaUpload,
  description,
  dropFile,
  onUpload,
}) => {
  const renderExistingFile = () => {
    const name = get(file, 'extension')
      ? `${fileName}.${file.extension}`
      : fileName;
    return (
      <div className="BankProofUpload current">
        <div>
          {fileThumb && fileType && (
            <div className="BankProofUpload__preview">
              {fileType === 'Image' ? (
                <div
                  className="preview__thumbnail"
                  style={{ backgroundImage: `url('${fileThumb}')` }}
                />
              ) : (
                <div className="preview" />
              )}
            </div>
          )}
          <span className="BankProofUpload__metadata">
            {fileType || getFileType(fileName)} | {name}
          </span>
        </div>
        <div
          className={cx('BankProofUpload__action', {
            'can-be-enlarged': isFunction(onOpenPreview),
          })}
        >
          {isFunction(onOpenPreview) && (
            <button type="button" onClick={onOpenPreview}>
              {showHideWording}
            </button>
          )}
          <Icon
            icon={ICONS.DELETE}
            fill={grey6Cheerful}
            width={13}
            height={16}
            viewBox="7 0 7 20"
            onClick={onDelete}
          />
        </div>
      </div>
    );
  };

  const renderNewFileUpload = () => {
    return (
      <div className="BankProofUpload upload">
        <DropZone onDrop={onUpload}>
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <div
              {...getRootProps({
                onClick: (e) => e.stopPropagation(),
              })}
              className={cx('upload-wrapper', {
                'is-dragging': isDragActive,
              })}
            >
              <input {...getInputProps()} />
              <div>{description}</div>
              <div className="drop-description">{dropFile}</div>
              <ButtonRedesigned size="small" onClick={open}>
                <Icon
                  icon={ICONS.UPLOAD}
                  fill={grey6Cheerful}
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                />
                <span>{ctaUpload}</span>
              </ButtonRedesigned>
            </div>
          )}
        </DropZone>
      </div>
    );
  };

  return isNil(file) || isEmpty(file)
    ? renderNewFileUpload()
    : renderExistingFile();
};

BankProofUpload.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileThumb: PropTypes.string,
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  ctaUpload: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dropFile: PropTypes.string.isRequired,
  showHideWording: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenPreview: PropTypes.func,
  t: PropTypes.func.isRequired,
};

BankProofUpload.defaultProps = {
  showHideWording: '',
};

export default withTranslation()(BankProofUpload);
