import { IconButton, Table } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type IntegrationVatAccount } from 'modules/bookkeep/settings/accounting/taxAccount';

import { type ModalState } from './ModalState';
import { TableFooter } from './TableFooter';
import { CellWithButton } from '../../../components/CellWithIconButton/CellWithIconButton';
import { getVatCodeTranslationKey } from '../translations';

interface Props {
  setModalState: Dispatch<SetStateAction<ModalState>>;
  vatAccounts: IntegrationVatAccount[];
  integrationStatus: IntegrationStatusWithIntegration;
}

export const VatAccountsTable = ({
  setModalState,
  vatAccounts,
  integrationStatus,
}: Props) => {
  const { t } = useTranslation('global');
  const vatAccountsErrorsList =
    integrationStatus.settingsValidation.taxAccounts.flatMap(({ id }) => id);
  const data = vatAccounts.map((vatAccount) => ({
    hasErrors: vatAccountsErrorsList.includes(vatAccount.id),
    ...vatAccount,
  }));

  return (
    <Table
      rowHeight="compact"
      data={data}
      hasColumnSeparator
      columns={[
        {
          id: 'vat-account-name',
          header: t('bookkeep.integrations.settings.vatTable.name'),
          width: '33.333%',
          renderCell(row) {
            return row.name;
          },
        },
        {
          id: 'vat-account-rate',
          header: t('bookkeep.integrations.settings.vatTable.rate'),
          width: '33.333%',
          renderCell(row) {
            return t('format.percent', {
              value: Number.parseFloat(row.rate) / 100,
            });
          },
        },
        {
          id: 'vat-account-code',
          header: t(getVatCodeTranslationKey(integrationStatus.integration)),
          width: '33.333%',
          getCellVariant: (row) => (row.hasErrors ? 'alert' : undefined),
          renderCell(row, { isRowHovered }) {
            return (
              <CellWithButton
                isButtonVisible={isRowHovered}
                button={
                  <span className="CellWithButton__button">
                    <IconButton
                      iconName="trash"
                      variant="borderless"
                      onClick={() =>
                        setModalState({
                          kind: 'confirmDeleteVat',
                          id: row.id,
                          name: row.name,
                          rate: row.rate,
                          code: row.code,
                        })
                      }
                    />
                  </span>
                }
              >
                {row.code}
              </CellWithButton>
            );
          },
        },
      ]}
      footer={vatAccounts.length === 0 ? <TableFooter type="vat" /> : undefined}
      getRowId={(row) => row.id}
    />
  );
};
