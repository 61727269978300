import cx from 'classnames';
import hoistStatics from 'hoist-non-react-statics';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import map from 'lodash/map';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AutoComplete from 'src/core/common/components/legacy/AutoComplete/AutoComplete';
import ButtonRedesigned from 'src/core/common/components/legacy/ButtonRedesigned/ButtonRedesigned';
import CustomFieldAutocomplete from 'src/core/common/components/legacy/CustomFieldsSelector/CustomFieldAutocomplete';
import Icon, { ICONS } from 'src/core/common/components/legacy/Icon/Icon';
import InputAmountMoney from 'src/core/common/components/legacy/InputAmountMoney/InputAmountMoney';
import InputSupplierRedesigned from 'src/core/common/components/legacy/InputSupplierRedesigned/InputSupplierRedesigned';
import MultiSelect from 'src/core/common/components/legacy/MultiSelect/MultiSelect';
import SelectAutoComplete from 'src/core/common/components/legacy/SelectAutoComplete/SelectAutoComplete';
import Tag from 'src/core/common/components/legacy/Tag/Tag';
import FEATURES from 'src/core/constants/features';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import {
  grey7Pleased,
  grey6Cheerful,
  white,
} from 'src/core/utils/color-palette';
import {
  getEligibleCustomFields,
  hasTooManyValues,
  prettyCfValue,
} from 'src/core/utils/custom-fields';
import { logger } from 'src/utils/datadog-log-wrapper';

import { DateRangeFilter } from './DateRangeFilter';
import { RequestersSelectContainer } from './RequestersSelectContainer';

import './TableFilters.scss';

export const filtersTypes = {
  SELECT: 'select',
  SUPPLIER: 'supplier',
  DATE: 'date',
  BOOLEAN: 'boolean',
  NUMBER_RANGE: 'number_range',
  ASYNC_AUTOCOMPLETE: 'async_select',
  ASYNC_SELECT_REQUESTERS: 'async_select_requesters',
};

class TableFilters extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    company: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    customFields: PropTypes.arrayOf(PropTypes.object),
    allowMultipleValues: PropTypes.bool,
    isPanelOpen: PropTypes.bool,
    isInvoiceSupplierSelector: PropTypes.bool,
    isTeamsFeatureEnabled: PropTypes.bool.isRequired,
    applyFilters: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    toggleFiltersPanel: PropTypes.func,
    defaultOpenFilters: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    className: '',
    onSearch: noop,
    toggleFiltersPanel: noop,
    allowMultipleValues: undefined,
    isPanelOpen: undefined,
    isInvoiceSupplierSelector: false,
    customFields: [],
    defaultOpenFilters: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isPanelOpen: false,
      filters: props.filters,
      activeFilters: props.filters,
      openFilters: props.defaultOpenFilters,
    };

    this.debouncedSearch = debounce(props.onSearch, 250);
    this.handleSearch = (e) => {
      this.debouncedSearch(e.target.value, this.state.filters);
    };
  }

  static getCustomFieldsFilters = (
    customFields,
    user,
    company,
    entityTypes,
    cfNamePattern,
    translator,
  ) => {
    const teamIds = get(user, `data_by_company.${company.id}.groups_ids`, []);
    const eligibleCustomFields = reject(
      getEligibleCustomFields(customFields, {
        types: entityTypes,
        all: true,
        teamIds,
        user,
      }),
      'deleted_at',
    );

    return reduce(
      eligibleCustomFields,
      (result, cf) => {
        if (!cf.total_values) {
          return result;
        }

        const filterId = isFunction(cfNamePattern)
          ? cfNamePattern(cf.id)
          : cf.id;
        const cfHasTooManyValues = hasTooManyValues(cf);
        const type = cfHasTooManyValues
          ? filtersTypes.ASYNC_AUTOCOMPLETE
          : filtersTypes.SELECT;
        const collection = cfHasTooManyValues
          ? []
          : [
              {
                key: null,
                name: translator('customFields.notProvided'),
              },
              ...sortBy(
                map(cf.custom_fields_values, (cfv) => ({
                  key: cfv.id,
                  name: prettyCfValue(cf, cfv),
                })),
                'name',
              ),
            ];

        return {
          ...result,
          [filterId]: {
            type,
            collection,
            field: cf,
            label: cf.name,
            sortValues: false,
            tagObjectFromValue(value) {
              if (Array.isArray(value) && value.length) {
                return value.map((v) => ({
                  filterKey: cf.id,
                  valueKey: v.key,
                  valueName: `${cf.name}: ${v.name}`,
                }));
              }
              if (isObject(value)) {
                return {
                  filterKey: cf.id,
                  valueKey: value.key,
                  valueName: `${cf.name}: ${value.name}`,
                };
              }
              return null;
            },
            allowMultipleValues: !hasTooManyValues,
          },
        };
      },
      {},
    );
  };

  componentDidMount() {
    const amountValue = get(this.props, 'filters.amount.value');

    // Initialize amount filter inputs from URL value
    if (amountValue && isString(amountValue) && includes(amountValue, '/')) {
      const [number_min_amount, number_max_amount] = amountValue.split('/');
      if (!isNil(number_min_amount) && !isNil(number_max_amount)) {
        this.setState({ number_min_amount, number_max_amount });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const changes = {};
    let updateUrl = false;

    if (nextProps.filters && nextProps.filters !== this.props.filters) {
      changes.filters = nextProps.filters;
      changes.activeFilters = nextProps.filters;
      updateUrl = true;
    }

    if (
      nextProps.isPanelOpen !== undefined &&
      nextProps.isPanelOpen !== this.state.isPanelOpen
    ) {
      changes.isPanelOpen = nextProps.isPanelOpen;
    }

    if (!isEmpty(changes)) {
      this.setState(changes, () => {
        if (updateUrl) {
          this.reflectFiltersInURL();
        }
      });
    }
  }

  setFilterValue = (whichFilter, key, value, callback = noop) => {
    const newFilterValues = {
      ...this.state.filters[whichFilter],
      [key]: value,
    };
    const newFilters = {
      ...this.state.filters,
      [whichFilter]: newFilterValues,
    };
    this.setState({ filters: newFilters }, callback);
  };

  toggleFilter = (whichFilter) => {
    this.setState(({ openFilters: previousOpenFilters }) => ({
      openFilters: previousOpenFilters.includes(whichFilter)
        ? previousOpenFilters.filter((name) => name !== whichFilter)
        : [...previousOpenFilters, whichFilter],
    }));
  };

  addFilter = (whichFilter, key, value, callback = noop) => {
    this.setFilterValue(whichFilter, key, value, callback);
  };

  removeFilter = (filterName, filterValueKey) => {
    if (!this.state.filters[filterName]) {
      filterName = `cf_${filterName}`;
    }
    const targetFilter = this.state.filters[filterName];
    const newFilterValues = Array.isArray(targetFilter.value)
      ? reject(targetFilter.value, { key: filterValueKey })
      : null;

    this.setFilterValue(filterName, 'value', newFilterValues, () => {
      const { filters } = this.state;
      this.reflectFiltersInURL();
      this.setState({ activeFilters: filters }); // Remove active filter from the list
      this.props.applyFilters(filters);
    });

    if (filterName === 'amount') {
      // Cleanup local `amount` values if needed
      this.setState({
        number_min_amount: undefined,
        number_max_amount: undefined,
      });
    }
  };

  resetFilters = () => {
    const { filters } = this.state;

    if (filters.erroredAccountingSyncOnly) {
      filters.erroredAccountingSyncOnly.value = false;
    }

    // Cleanup all filters' values, close them & reflect in URL
    this.setState(
      {
        activeFilters: {},
        number_min_amount: undefined,
        number_max_amount: undefined,
        openFilters: [],
      },
      () => {
        this.reflectFiltersInURL();
        this.toggleFilters();
        this.props.applyFilters({});
      },
    );
  };

  applyFilters = () => {
    const { filters } = this.state;

    this.setState(
      {
        activeFilters: filters,
      },
      () => {
        this.reflectFiltersInURL();
        this.toggleFilters();
        this.props.applyFilters(filters);
      },
    );
  };

  // Evaluate all filters to generate clean URL params
  filtersToParams = (filters) =>
    omitBy(
      reduce(
        keys(filters),
        (result, k) => {
          const currentFilter = get(filters, k);
          if (isEmpty(currentFilter) || !currentFilter.value) {
            return result;
          }

          let value;
          if (Array.isArray(currentFilter.value)) {
            value = map(currentFilter.value, 'key');
          } else if (isObject(currentFilter.value)) {
            value = get(currentFilter, 'value.key');
          } else {
            value = get(currentFilter, 'value');
          }

          return {
            ...result,
            [k]: value,
          };
        },
        {},
      ),
      (value) => value === undefined,
    );

  reflectFiltersInURL = () => {
    const filteredQueryString = omit(
      queryString.parse(this.props.history.location.search),
      keys(this.state.activeFilters),
    );

    const search = queryString.stringify({
      ...this.filtersToParams(this.state.activeFilters),
      ...filteredQueryString,
    });
    const newUrl = compact([window.location.pathname, search]).join('?');
    window.history.replaceState('', '', newUrl);
  };

  toggleFilters = () => this.props.toggleFiltersPanel(!this.state.isPanelOpen);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  renderFilter = (filterName, filterOptions = {}) => {
    const { t } = this.props;

    switch (filterOptions.type) {
      case filtersTypes.SELECT: {
        if (filterOptions.allowMultipleValues) {
          const { collection, sortValues = true } = filterOptions;
          const values = sortValues ? sortBy(collection, 'name') : collection;
          return (
            <SelectAutoComplete
              values={
                filterOptions.value ? [].concat(filterOptions.value) : undefined
              }
              items={values}
              placeholder={filterOptions.label}
              itemToString={(item) => get(item, 'name', '')}
              itemKey="key"
              onSearch={(query, items) =>
                filter(items, (item) =>
                  includes(item.name?.toLowerCase(), query?.toLowerCase()),
                )
              }
              onChange={(selected) =>
                this.addFilter(filterName, 'value', selected)
              }
              allowMultipleValues
            />
          );
        }

        return (
          <AutoComplete
            size="block"
            activeItemClassName="active"
            onSelect={(selected) =>
              this.addFilter(filterName, 'value', selected)
            }
            placeholder={filterOptions.label}
            values={sortBy(filterOptions.collection, 'name')}
            value={filterOptions.value || ''}
            getResultTemplate={(result) => <span>{result.name}</span>}
            getSelectedDisplay={(result) => (result ? result.name : result)}
            isResultMatching={(row, search) =>
              includes(row.name?.toLowerCase(), search?.toLowerCase())
            }
          />
        );
      }
      case filtersTypes.ASYNC_SELECT_REQUESTERS: {
        return (
          <RequestersSelectContainer
            value={
              filterOptions.value
                ? {
                    key: filterOptions.value.key,
                    label: filterOptions.value.name,
                  }
                : undefined
            }
            onSelect={(option) => {
              this.addFilter(
                filterName,
                'value',
                option
                  ? {
                      key: option.key,
                      name: option.label,
                    }
                  : null,
              );
            }}
          />
        );
      }
      case filtersTypes.SUPPLIER: {
        return (
          <InputSupplierRedesigned
            companyId={this.props.company.id}
            placeholder={t('forms.supplier.placeholder')}
            value={filterOptions.value}
            onSupplierSelected={(supplier) => {
              if (supplier && supplier.id) {
                this.addFilter(
                  filterName,
                  'value',
                  supplier.id
                    ? { key: supplier.id, name: supplier.name }
                    : null,
                );
              }
            }}
            invoiceSuppliers={Boolean(filterOptions.isInvoiceSupplierSelector)}
            withBrands={false}
          />
        );
      }
      case filtersTypes.DATE: {
        const [start, end] = filterOptions.value?.split('/') || [];
        const value = [
          start ? new Date(start) : undefined,
          end ? new Date(end) : undefined,
        ];
        return (
          <DateRangeFilter
            value={value}
            filterName={filterName}
            addFilter={this.addFilter}
          />
        );
      }
      case filtersTypes.BOOLEAN: {
        return (
          <MultiSelect
            size="small"
            values={[
              { value: 1, display: t('misc.yes') },
              { value: 0, display: t('misc.no') },
            ]}
            onSelect={(selected) =>
              this.addFilter(filterName, 'value', selected)
            }
            onDeselect={() => ({})}
            selected={compact([filterOptions.value])}
            displayProp="display"
            matchingProp="value"
          />
        );
      }
      case filtersTypes.NUMBER_RANGE: {
        const { currency } = this.props.company;
        const currencyObject = { key: currency, name: currency };
        const min = Number(this.state[`number_min_${filterName}`]);
        const max = Number(this.state[`number_max_${filterName}`]);
        const isValid =
          (isNumber(min) && !isNaN(min)) || (isNumber(max) && !isNaN(max));
        const submit = () => {
          if (isValid) {
            this.addFilter(filterName, 'value', `${min}/${max}`, () => {
              this.toggleFilter(filterName);
            });
          }
        };
        const onKeyPress = (event) => {
          if (event.key === 'Enter') {
            submit();
          }
        };

        return (
          <div>
            <InputAmountMoney
              amount={min}
              placeholder={t('forms.numberRange.placeholder.min')}
              currency={currencyObject}
              availableCurrencies={[currencyObject]}
              onAmountChange={(newMin) =>
                this.setState({ [`number_min_${filterName}`]: newMin })
              }
              onKeyPress={onKeyPress}
            />
            <InputAmountMoney
              amount={max}
              placeholder={t('forms.numberRange.placeholder.max')}
              currency={currencyObject}
              availableCurrencies={[currencyObject]}
              onAmountChange={(newMax) =>
                this.setState({ [`number_max_${filterName}`]: newMax })
              }
              onKeyPress={onKeyPress}
            />
            <ButtonRedesigned
              size="small"
              className="primary"
              text={t('forms.numberRange.okButton')}
              style={{ width: '100%' }}
              isDisabled={!isValid}
              onClick={submit}
            />
          </div>
        );
      }
      // Only used for custom fields w/ too many values (>200)
      case filtersTypes.ASYNC_AUTOCOMPLETE: {
        const { field } = filterOptions;
        if (!field) {
          throw new Error(
            `No custom field given for the filter "${filterName}"`,
          );
        }
        return (
          <CustomFieldAutocomplete
            hasLabel={false}
            key={filterName}
            customField={field}
            company={this.props.company}
            onSelectedValue={(value) =>
              this.addFilter(filterName, 'value', value)
            }
            isReadMode
          />
        );
      }
      default:
        return null;
    }
  };

  renderFilters = () => {
    const { isTeamsFeatureEnabled } = this.props;
    const { filters, openFilters } = this.state;
    if (isEmpty(filters)) {
      return null;
    }
    let orderedFilters = Object.entries(filters);
    try {
      // Sort by label, because of the custom field IDs
      orderedFilters = sortBy(
        Object.entries(filters),
        (index) => (index ?? []).at(-1)?.label,
      );
    } catch (error) {
      logger.info('Rendering filters', {
        scope: 'requests::TableFilters',
        team: 'employee',
        filters,
        errorMessage: error?.message ?? '',
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const filteredFilters = filter(orderedFilters, ([filterKey, filter]) => {
      if (!filter) {
        return false;
      }
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (
        !isTeamsFeatureEnabled &&
        // The "Team" filter is named differently across pages so we try to
        // match all possible values it could take
        (filterKey === 'groups' ||
          filterKey === 'group' ||
          filterKey === 'teams' ||
          filterKey === 'team')
      ) {
        return false;
      }
      return true;
    });

    return map(
      filteredFilters,
      // @TODO if I fix this line the filters disappear, there might be a scope or filter problem
      // eslint-disable-next-line @typescript-eslint/no-shadow
      ([filterKey, filter]) =>
        filter.type && (
          <li
            key={filterKey}
            className={cx('filters-list__item', {
              open: openFilters.includes(filterKey),
            })}
          >
            <button
              type="button"
              className="filter-title"
              onClick={() => this.toggleFilter(filterKey)}
            >
              <span>{filter.label}</span>
              <Icon
                icon={ICONS.CARRET}
                width={18}
                height={16}
                viewBox="-4 -6 24 24"
                fill={grey6Cheerful}
              />
            </button>
            <AnimateHeight
              height={openFilters.includes(filterKey) ? 'auto' : 0}
              duration={140}
            >
              <div className="filter-input">
                {this.renderFilter(filterKey, filter)}
              </div>
            </AnimateHeight>
          </li>
        ),
    );
  };

  renderActiveFilters = () => {
    const { activeFilters } = this.state;
    const filtersValues = compact(
      // Compact as we might have some filters set w/ old or unknown values
      flatten(
        // Flatten as we might have filters w/ multiple values
        keys(activeFilters)
          .filter(
            (k) =>
              !!activeFilters[k].value && !!activeFilters[k].tagObjectFromValue,
          ) // Filter out items w/o value(s) or a callback to display its related tag
          .map(
            (k) =>
              activeFilters[k].tagObjectFromValue(
                activeFilters[k].value,
                activeFilters,
              ) || null,
          ),
      ),
    );

    if (isEmpty(filtersValues)) {
      return null;
    }

    return map(sortBy(filtersValues, 'valueName'), (f, index) => (
      <Tag
        theme="info"
        key={index}
        text={f.valueName}
        onClick={() => this.removeFilter(f.filterKey, f.valueKey)}
        hasCloseIcon
        hasBorder={false}
      />
    ));
  };

  onKeyDown = (e) => {
    if (e.keyCode !== 9) {
      return;
    } // Prevent Tab
    e.preventDefault();
  };

  renderFiltersActions = () => {
    const { t } = this.props;
    return (
      <div className="filters-actions">
        <ButtonRedesigned
          text={t('misc.applyFilters')}
          type="primary"
          size="small"
          onClick={this.applyFilters}
        />
        <ButtonRedesigned
          text={t('misc.resetFilters')}
          size="small"
          onClick={this.resetFilters}
        />
      </div>
    );
  };

  getSearchPlaceholder = (type) => {
    const { t } = this.props;
    switch (type) {
      case 'team':
        return t('tableFilters.searchFor_team');
      case 'policy':
        return t('tableFilters.searchFor_policy');
      case 'card':
        return t('tableFilters.searchFor_card');
      case 'request':
      case 'mine':
      case 'to-approve':
        return t('tableFilters.searchFor_request');
      case 'payment':
        return t('tableFilters.searchFor_payment');
      case 'subscription':
        return t('tableFilters.searchFor_subscription');
      case 'invoices':
        return t('tableFilters.searchFor_invoices');
      case 'expenses':
        return t('tableFilters.searchFor_expenses');
      case 'purchases':
        return t('tableFilters.searchFor_purchases');
      case 'supplier':
        return t('tableFilters.searchFor_supplier');
      case 'purchase-order':
        return t('tableFilters.searchFor_purchaseOrder');
      default:
    }
  };

  render() {
    const { type, className, t } = this.props;
    const {
      isPanelOpen,
      filters: { textSearch },
    } = this.state;
    const iconColor = isPanelOpen ? white : grey7Pleased;
    const searchValue = textSearch && textSearch.value ? textSearch.value : '';

    return (
      <div className={cx('TableFilters page__search', className)}>
        <div className={cx('page__search-container', { open: isPanelOpen })}>
          <button
            type="button"
            className="page__search-toggle"
            onClick={this.toggleFilters}
          >
            <span>{t('misc.filter_plural')}</span>
            <Icon
              className="filters-icon"
              icon={ICONS.FILTER}
              fill={iconColor}
              width={16}
              height={14}
              viewBox="0 0 16 14"
            />
          </button>
          <div className="page__search__input">
            <Icon
              icon={ICONS.SEARCH}
              fill={grey6Cheerful}
              width={18}
              height={18}
              viewBox="0 0 20 20"
            />
            <input
              type="search"
              defaultValue={searchValue}
              placeholder={this.getSearchPlaceholder(type)}
              onChange={this.handleSearch}
              onKeyDown={this.onKeyDown}
            />
          </div>
          <div className="page__search__active-filters">
            {this.renderActiveFilters()}
          </div>
          <div className="page__search-content">
            <div className="filters-list">
              <ul className="filters-list__items">{this.renderFilters()}</ul>
            </div>
            {this.renderFiltersActions()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isTeamsFeatureEnabled: getIsFeatureEnabled(state, FEATURES.TEAMS),
});

export default hoistStatics(
  withTranslation()(connect(mapStateToProps)(TableFilters)),
  TableFilters,
);
