import {
  Button,
  Callout,
  DropdownItem,
  DropdownMenu,
  IconButton,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import {
  type ChangeEvent,
  type KeyboardEvent,
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import { usePrevious } from 'react-use';

import { AutoCompleteMulti } from 'src/core/common/components/legacy/AutoCompleteMulti';
import { useModal } from 'src/core/common/hooks/useModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { currencySymbol, formatMoney } from 'src/core/utils/money';

import { ApproversList } from './components/ApproversList';
import reducer, {
  startEditing,
  stopEditing,
  type State,
  updateUpTo,
  startFadeOut,
  stopFadeOut,
  startBlink,
  stopBlink,
  setIsMultiStep,
} from './reducer';
import {
  createRight,
  isMultiStep,
  toMultipleStep,
  toSingleStep,
} from '../../models';
import {
  type ApprovalRule,
  type ApprovalStep,
  type Member,
  type SelectedApprover,
} from '../../types';
import Card from '../Card/Card';
import DeleteThresholdModal from '../DeleteThresholdModal';

import './ApprovalThreshold.scss';

type Props = {
  approvalRule: ApprovalRule & {
    index: number;
    isLastRule: boolean;
  };
  members: Member[];
  costCenterOwnerId?: string;
  hasError: boolean;
  onUpdateRule(ruleIndex: number, rule: ApprovalRule): void;
  onDeleteRule(ruleId: string): void;
};

export const ApprovalThreshold = ({
  approvalRule,
  members,
  costCenterOwnerId,
  hasError,
  onUpdateRule,
  onDeleteRule,
}: Props) => {
  const ruleApprovers = useMemo(() => {
    return approvalRule.steps.reduce((approvers, rule) => {
      const approverIds = rule.rights.map((r) => {
        if (r.approverType === 'costCenterOwner') {
          return costCenterOwnerId;
        }
        return r.approverId;
      });
      approverIds.forEach((id) => {
        const member = members.find((m) => m.id === id);
        if (member) {
          approvers.push(member);
        }
      });

      return approvers;
    }, [] as Member[]);
  }, [approvalRule.steps, members]);

  const initialState: State = {
    isEditing: false,
    upTo: approvalRule.upTo.value,
    originalUpToValue: approvalRule.upTo.value || Number.POSITIVE_INFINITY,
    fadeOut: false,
    blink: false,
    isMultiStep: isMultiStep(approvalRule.steps),
    isOpenDropDown: false,
  };

  const { t } = useTranslation();
  const previousApproval = usePrevious(approvalRule);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (
      !state.isEditing &&
      previousApproval &&
      previousApproval.upTo.value !== approvalRule.upTo.value &&
      previousApproval.upTo.value === state.upTo
    ) {
      dispatch(startFadeOut());
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(updateUpTo(approvalRule.upTo.value!));
    }
  }, [approvalRule]);

  useEffect(() => {
    if (state.fadeOut) {
      const timeoutId = setTimeout(() => dispatch(stopFadeOut()), 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [state.fadeOut]);

  useEffect(() => {
    if (state.blink) {
      const timeoutId = setTimeout(() => dispatch(stopBlink()), 600);
      return () => clearTimeout(timeoutId);
    }
  }, [state.blink]);

  const [deleteModal, showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteThresholdModal
      onConfirm={() => {
        onDeleteRule(approvalRule.id);
        hideDeleteModal();
      }}
      onCancel={() => hideDeleteModal()}
      approversCount={ruleApprovers.length}
    />
  ));

  const onUpdateSteps = (steps: ApprovalStep[]) => {
    onUpdateRule(approvalRule.index, {
      ...approvalRule,
      steps,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!state.isEditing && approvalRule.upTo.value) {
      dispatch(startEditing(approvalRule.upTo.value));
    }
    const value = Math.abs(Number.parseInt(e.target.value));
    dispatch(updateUpTo(value));
  };

  const handleBlur = () => {
    dispatch(stopEditing());

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (Number.isNaN(state.upTo!)) {
      return dispatch(updateUpTo(state.originalUpToValue));
    }

    if (
      approvalRule.from.value !== null &&
      state.upTo !== null &&
      approvalRule.from.value >= state.upTo
    ) {
      dispatch(startBlink());
      return dispatch(updateUpTo(state.originalUpToValue));
    }

    const rule = { ...approvalRule };
    rule.upTo.value = state.upTo;

    onUpdateRule(approvalRule.index, rule);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  const handleSelectApprover = (approverIds: string[]) => {
    track(AnalyticEventName.APPROVALS_WORKFLOW_SETUP_ADD_APPROVER, {
      approvers: approverIds.map((id) => ({
        id,
        type: 'user',
      })),
    });
    // TODO: Handle selection of cost center owner
    const steps = state.isMultiStep
      ? approverIds.map((id) => ({
          rights: [createRight({ id, type: 'user' })],
        }))
      : [
          {
            rights: approverIds.map((id) => createRight({ id, type: 'user' })),
          },
        ];
    onUpdateSteps(steps);
  };

  const handleDeleteApprover = (index: number) => {
    let steps = approvalRule.steps;

    if (!state.isMultiStep) {
      steps = approvalRule.steps.map((step) => {
        return {
          ...step,
          rights: step.rights.filter((_, rightIndex) => rightIndex !== index),
        };
      });
    } else {
      steps = approvalRule.steps.filter((_, stepIndex) => stepIndex !== index);
    }
    onUpdateSteps(steps);
  };

  const handleChangeApprover = (
    newApprover: SelectedApprover,
    index: number,
  ) => {
    let steps = approvalRule.steps;
    if (!state.isMultiStep) {
      steps = approvalRule.steps.map((step) => {
        return {
          ...step,
          rights: step.rights.map((right, rightIndex) => {
            if (rightIndex === index) {
              return createRight(newApprover);
            }
            return right;
          }),
        };
      });
    } else {
      steps = approvalRule.steps.map((step, stepIndex) => {
        if (stepIndex === index) {
          return {
            ...step,
            rights: [createRight(newApprover)],
          };
        }
        return step;
      });
    }

    onUpdateSteps(steps);
  };

  const handleDeleteThreshold = () => {
    if (ruleApprovers.length > 0) {
      showDeleteModal();
    } else {
      onDeleteRule(approvalRule.id);
    }
  };

  const renderDeleteThreshold = () => (
    <div className="ApprovalThreshold__header__delete">
      <IconButton
        iconName="trash"
        variant="borderless"
        onClick={handleDeleteThreshold}
        className="ApprovalThreshold__delete-icon"
        data-testid="ApprovalThreshold__delete-threshold"
        aria-label={t('misc.delete')}
      />
    </div>
  );

  const renderRuleHeader = () => {
    const { from, index, isLastRule } = approvalRule;
    const formattedAmount = formatMoney(from.value || 0, from.currency);

    if (isLastRule) {
      return (
        <div className="ApprovalThreshold__header body-m">
          <span className="flex h-[32px] items-center">
            {index === 0
              ? t('teams.approvalFlows.canApproveAllRequests')
              : `${t('teams.approvalFlows.above')} ${formattedAmount}`}
          </span>
          {index !== 0 && renderDeleteThreshold()}
        </div>
      );
    }

    return (
      <div className="ApprovalThreshold__header body-m">
        <div className="relative">
          <span>
            {from.value === 0
              ? t('teams.approvalFlows.upTo')
              : t('teams.approvalFlows.fromTo', { value: formattedAmount })}
          </span>
          <input
            className={cx('ApprovalThreshold__header__up-to__input', {
              ApprovalThreshold__fadeOut: state.fadeOut,
              ApprovalThreshold__blink: state.blink,
            })}
            type="number"
            min="0"
            step="1"
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value={state.isEditing ? state.upTo! : approvalRule.upTo.value!}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          <span className="ApprovalThreshold__header__up-to__currency">
            {currencySymbol(approvalRule.upTo.currency)}
          </span>
        </div>
        {renderDeleteThreshold()}
      </div>
    );
  };

  const followingModeMenuOptions = [
    {
      key: 'allFollowingUsers',
      label: t('teams.approvalFlows.allFollowingUsers'),
      action: () => {
        dispatch(setIsMultiStep(true));
        onUpdateSteps(toMultipleStep(approvalRule).steps);
      },
    },
    {
      key: 'anyFollowingUsers',
      label: t('teams.approvalFlows.anyFollowingUsers'),
      action: () => {
        dispatch(setIsMultiStep(false));
        onUpdateSteps(toSingleStep(approvalRule).steps);
      },
    },
  ];

  const shouldDisplayAllFollowingHint = useMemo(() => {
    const hasSuperApprover = ruleApprovers.some((a) => a.isAccountOwner);
    return state.isMultiStep && hasSuperApprover;
  }, [state.isMultiStep, ruleApprovers]);

  return (
    <div>
      {deleteModal}
      <Card
        className={cx('ApprovalThreshold', {
          'ApprovalThreshold--has-error': hasError,
        })}
        header={renderRuleHeader()}
      >
        <div className="relative">
          <div className="mb-xs flex items-center p-0">
            <span className="text-neutral-dark body-m">
              {t('teams.approvalFlows.approvedBy')}&nbsp;
            </span>
            <DropdownMenu
              options={followingModeMenuOptions}
              onSelect={(option) => option.action()}
              renderOption={(option) => {
                return (
                  <DropdownItem
                    label={option.label}
                    data-testid={`ApprovalThreshold_${option.key}`}
                  />
                );
              }}
              renderButton={(getToggleButtonProps) => (
                <Button
                  {...getToggleButtonProps()}
                  text={
                    state.isMultiStep
                      ? t('teams.approvalFlows.allFollowingUsers')
                      : t('teams.approvalFlows.anyFollowingUsers')
                  }
                  variant="ghost"
                  data-testid="ApprovalThreshold__body__message__dropdown"
                />
              )}
            />
          </div>
          <ApproversList
            approvers={ruleApprovers.map((a) => ({
              ...a,
              isCostCenterOwner: a.id === costCenterOwnerId,
            }))}
            costCenterOwnerId={costCenterOwnerId ?? ''}
            isAllFollowingMode={state.isMultiStep}
            onDeleteClicked={handleDeleteApprover}
            onUpdateRule={handleChangeApprover}
          />
          {hasError && (
            <Callout
              variant="alert"
              title={t('teams.approvalFlows.requiredOneApprover')}
            />
          )}
          <div className="ml-xs">
            <AutoCompleteMulti
              placeholder={`+ ${t('members.addApprover')}`}
              options={members.map((member) => {
                const label = member.isPending ? member.email : member.fullname;
                return { label, value: member.id, image: member.avatar };
              })}
              selectedValues={ruleApprovers.map((a) => a.id)}
              onSelect={handleSelectApprover}
              withoutBorder
              withoutTags
              closeOnSelect
            />
          </div>
          {shouldDisplayAllFollowingHint && (
            <Callout
              variant="info"
              title={t('teams.approvalFlows.hintApproversAfterAccountOwner')}
            />
          )}
        </div>
      </Card>
    </div>
  );
};
