/**
 * This service manages which announcement should be displayed or not when the app loads.
 * To avoid multiple announcements displayed at the same time, the service stacks them
 * and allow to display them one by one, page loading after page loading.
 *
 * An example of announcement is the modal to ask user phone number if missing,
 * the slash notifications for Chrome extension or Slack integration, ...
 *
 * TODO: limit the announcements to a company / user
 */

import forEach from 'lodash/forEach';
import values from 'lodash/values';

import { LocalStorageKey } from '../../constants/storage';
import {
  getLocalItem as getItem,
  setLocalItem as setItem,
  removeLocalItem as removeItem,
} from '../storage';

export enum InitialAnnouncement {
  SplashNotif = 'splashNotif', // Chrome extension + Slack integration
}

const toLocalStorageKey = (announcement: InitialAnnouncement) => {
  switch (announcement) {
    case InitialAnnouncement.SplashNotif:
      return LocalStorageKey.HideInitialAnnouncementSplashNotif;
    default:
      throw new Error(
        `Cannot find local storage key for announcement ${announcement}`,
      );
  }
};

export const createInitialAnnouncementsStackManager = (
  localStorage = { getItem, setItem, removeItem },
) => {
  // The order of the keys corresponds to the display order.
  // If the value is `true`, the announcement is hidden
  const initialAnnouncementStack: {
    [initialAnnouncement in InitialAnnouncement]: boolean;
  } = {
    [InitialAnnouncement.SplashNotif]: Boolean(
      localStorage.getItem(
        toLocalStorageKey(InitialAnnouncement.SplashNotif),
        true,
      ),
    ),
  };

  const nextAnnouncementToDisplay = (
    Object.keys(initialAnnouncementStack) as Array<
      keyof typeof initialAnnouncementStack
    >
  ).find((key) => initialAnnouncementStack[key]);

  // Remove next announcement to display from local storage to display it on the next reload
  if (nextAnnouncementToDisplay) {
    localStorage.removeItem(toLocalStorageKey(nextAnnouncementToDisplay), true);
  }

  // When init is called, hide all initial announcements by default
  // Note: should be called on the signup step for instance
  const initialAnnouncements = values(
    InitialAnnouncement,
  ) as InitialAnnouncement[];
  const init = () => {
    forEach(initialAnnouncements, (announcement) => {
      const localStorageKey = toLocalStorageKey(announcement);
      localStorage.setItem(localStorageKey, true, true);
      initialAnnouncementStack[announcement] = true;
    });
  };

  const shouldDisplayAnnouncement = (announcement: InitialAnnouncement) => {
    return !initialAnnouncementStack[announcement];
  };

  return {
    init,
    shouldDisplayAnnouncement,
  };
};

export const initialAnnouncementsStackManager =
  createInitialAnnouncementsStackManager();
