import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type {
  CompanyKomboConfiguration,
  FiltersForDimension,
  CompanyKomboConfigurationReshaped,
} from '../types';

export const useGetKomboConfigurationQuery = (
  isEnabled: boolean,
): QueryState<CompanyKomboConfigurationReshaped> => {
  const { company: companyId } = useParams();
  return useQuery<CompanyKomboConfigurationReshaped, CompanyKomboConfiguration>(
    {
      key: ['komboSettings', companyId],
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/integrations/kombo/company-settings',
      },
      isEnabled,
      reshapeData: ({
        conditions,
        automaticArchiving,
        syncReportingManager,
      }) => {
        if (!conditions.length) {
          return {
            persistedFilters: undefined,
            automaticArchiving: !!automaticArchiving,
            syncReportingManager: !!syncReportingManager,
          };
        }

        const filters: FiltersForDimension[] = [];
        conditions.forEach((condition) => {
          filters.push({
            dimension: condition.fieldName,
            values: condition.fieldValues as string[],
          });
        });

        return {
          persistedFilters: filters,
          automaticArchiving: !!automaticArchiving,
          syncReportingManager: !!syncReportingManager,
        };
      },
    },
  );
};
