/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/no-nesting */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/always-return */
import { createAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import queryString from 'query-string';

import { companyAPI } from 'src/core/api/axios';
import {
  addNotification,
  NotificationType,
} from 'src/core/modules/app/notifications';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { apiUrl, graphql } from 'src/core/utils/api';

import * as types from './actionTypes';
import { fetchAllPayments as fetchAllPaymentsRequest } from '../graphql';
import { serialize } from '../utils/convertFiltersForApi';

const lastRequests = {
  fetchAllPayments: null,
  fetchAllPaymentsCounters: null,
  fetchEditablePaymentsCounters: null,
};

// Reset
export const hidePaymentPanel = createAction(types.HIDE_PAYMENT_PANEL);
export const resetAllPayments = createAction(types.RESET_ALL_PAYMENTS);
export const resetAllPaymentsSelection = createAction(
  types.RESET_ALL_PAYMENTS_SELECTION,
);

// Filters and selection
const internalUpdateFilters = createAction(types.UPDATE_FILTERS);
export const updateFilters =
  (...arguments_) =>
  (dispatch) => {
    dispatch(resetAllPaymentsSelection());
    dispatch(internalUpdateFilters(...arguments_));
  };
export const updateAllPaymentsSelection = createAction(
  types.UPDATE_ALL_PAYMENTS_SELECTION,
);

// Fetch all payments
// Fetch payments
const fetchAllPaymentsLoading = createAction(types.FETCH_ALL_PAYMENTS_LOADING);
const fetchAllPaymentsSuccess = createAction(types.FETCH_ALL_PAYMENTS_SUCCESS);
const fetchAllPaymentsFailure = createAction(types.FETCH_ALL_PAYMENTS_FAILURE);

const isCompletionDeadlineFilter = ({ type }) => {
  return type === 'completionDeadline';
};

const toFiltersV1 = (filters) => {
  const completionDeadlineFilter = filters?.find(isCompletionDeadlineFilter);
  if (completionDeadlineFilter?.value?.includes('late')) {
    return ['LateReceipt'];
  }
  return [];
};

const toFiltersV2 = (filters) => {
  return filters?.filter((filter) => !isCompletionDeadlineFilter(filter));
};

export const fetchAllPayments =
  (options = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(fetchAllPaymentsLoading());

    const variables = {
      companyId,
      first: options.first ?? 60,
      after: options.after,
      filtersV1: toFiltersV1(options.filters),
      filtersV2: toFiltersV2(options.filters),
    };
    const request = fetchAllPaymentsRequest(graphql, variables);

    lastRequests.fetchAllPayments = request;

    try {
      const result = await request;
      if (result && lastRequests.fetchAllPayments === request) {
        dispatch(fetchAllPaymentsSuccess(result.company));
      }
    } catch (error) {
      if (lastRequests.fetchAllPayments === request) {
        dispatch(fetchAllPaymentsFailure(error));
      }
    }

    return request;
  };

const fetchAllPaymentsCountersLoading = createAction(
  types.FETCH_ALL_PAYMENTS_COUNTERS_LOADING,
);
const fetchAllPaymentsCountersSuccess = createAction(
  types.FETCH_ALL_PAYMENTS_COUNTERS_SUCCESS,
);
const fetchAllPaymentsCountersFailure = createAction(
  types.FETCH_ALL_PAYMENTS_COUNTERS_FAILURE,
);
export const fetchAllPaymentsCounters =
  (options = {}) =>
  (dispatch, getState) => {
    dispatch(fetchAllPaymentsCountersLoading());

    const genericFilters = [
      ...options.filters,
      { type: 'selection', value: options.selection },
    ];

    const variables = {
      companyId: getState().global.company.id,
      filtersBookkeeping: [
        ...genericFilters,
        { type: 'bookkeepable', value: true },
      ],
      filtersRemindInvoice: [
        ...genericFilters,
        { type: 'remindable_for_invoice', value: true },
      ],
      filtersDownload: [
        ...genericFilters,
        { type: 'downloadable', value: true },
      ],
      filtersEdit: [...genericFilters, { type: 'editable', value: true }],
    };
    const request = `
      query FetchPaymentsAndStats($companyId: String!, $filtersBookkeeping: [JSON], $filtersRemindInvoice: [JSON], $filtersDownload: [JSON], $filtersEdit: [JSON]) {
        company(id: $companyId) {
          bookkeepable: payments(filters_v2: $filtersBookkeeping) { total }
          remindable_for_invoice: payments(filters_v2: $filtersRemindInvoice) { total }
          downloadable: payments(filters_v2: $filtersDownload) { total }
          editable: payments(filters_v2: $filtersEdit) { total }
        }
      }
    `;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const request_ = graphql(request, variables)
      .catch(
        (error) =>
          lastRequests.fetchAllPaymentsCounters === request_ &&
          dispatch(fetchAllPaymentsCountersFailure(error)),
      )
      .then(
        (result) =>
          lastRequests.fetchAllPaymentsCounters === request_ &&
          dispatch(fetchAllPaymentsCountersSuccess(result.company)),
      );

    lastRequests.fetchAllPaymentsCounters = request_;

    return request_;
  };

const fetchPaymentLoading = createAction(types.FETCH_SINGLE_PAYMENT_LOADING);
const fetchPaymentSuccess = createAction(types.FETCH_SINGLE_PAYMENT_SUCCESS);
const fetchPaymentFailure = createAction(types.FETCH_SINGLE_PAYMENT_FAILURE);
export const fetchPayment = (paymentId) => async (dispatch, getState) => {
  const companyId = getState().global.company.id;
  const handlerAction = fetchPaymentSuccess;

  dispatch(fetchPaymentLoading(paymentId));

  let payment;
  try {
    // we fetch and aggregate payment + related missing receipt data
    const [{ data: paymentData }, missingReceipt] = await Promise.all([
      companyAPI.get(`/payments/${paymentId}`, { companyId }),
      fetchMissingReceipt({ companyId, paymentId }),
    ]);
    payment = {
      ...paymentData,
      missingReceipt,
    };
  } catch (error) {
    dispatch(fetchPaymentFailure(paymentId));
    throw error;
  }

  dispatch(handlerAction(payment));
};

const fetchMissingReceipt = async ({ companyId, paymentId }) => {
  const {
    data: { missingReceipt },
  } = await companyAPI.get(`/missing-receipt/${paymentId}`, {
    companyId,
  });

  if (missingReceipt?.affidavit?.filename) {
    const { data: affidavitDocumentData } = await companyAPI.get(
      `/documentary-evidence/document/${missingReceipt.affidavit.filename}`,
      {
        companyId,
      },
    );
    return {
      ...missingReceipt,
      affidavitDocument: affidavitDocumentData,
    };
  }

  return missingReceipt;
};

const updatePaymentLoading = createAction(types.UPDATE_SINGLE_PAYMENT_LOADING);
const updatePaymentSuccess = createAction(types.UPDATE_SINGLE_PAYMENT_SUCCESS);
export const updatePaymentLocally = createAction(
  types.UPDATE_SINGLE_PAYMENT_LOCALLY,
);

export const updatePayment =
  (paymentId, body = {}, callback = noop) =>
  (dispatch, getState) => {
    const companyId = getState().global.company.id;
    if (!paymentId || isEmpty(body)) {
      return;
    }

    dispatch(updatePaymentLoading());

    return fetch(apiUrl(`/payments/${paymentId}`, companyId), {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...body, id: paymentId }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((payment) => {
          dispatch(updatePaymentSuccess(payment));
          callback(null, payment);
        });
      }
      callback({ status: res.status });
    });
  };

// Fetch a single payment's invoices
const fetchPaymentInvoicesLoading = createAction(
  types.FETCH_SINGLE_PAYMENT_INVOICES_LOADING,
);
const fetchPaymentInvoicesSuccess = createAction(
  types.FETCH_SINGLE_PAYMENT_INVOICES_SUCCESS,
);
const fetchPaymentInvoicesToExportSuccess = createAction(
  types.FETCH_SINGLE_PAYMENT_TO_EXPORT_INVOICES_SUCCESS,
);
export const fetchPaymentInvoices =
  (paymentId, toExport = false, options) =>
  async (dispatch, getState) => {
    const companyId = getState().global.company.id;

    dispatch(fetchPaymentInvoicesLoading());

    const retryUntilSizeMatches = options?.retryUntilSizeMatches || false;
    const shouldRetryUntilSizeMatches = retryUntilSizeMatches !== false;
    const isInvoiceSizeMatchingExpectedSize = (invoicelist) =>
      invoicelist.length === retryUntilSizeMatches;

    let invoices;
    let attempts = 0;
    do {
      try {
        const res = await companyAPI.get(`/payments/${paymentId}/invoices`, {
          companyId,
        });
        invoices = res.data;

        if (
          shouldRetryUntilSizeMatches &&
          !isInvoiceSizeMatchingExpectedSize(invoices)
        ) {
          await new Promise((resolve) =>
            setTimeout(async () => {
              resolve();
            }, 2000),
          );
        }
      } catch {
        // FIXME: handle the error?
        invoices = [];
      }
      attempts += 1;
    } while (
      shouldRetryUntilSizeMatches &&
      !isInvoiceSizeMatchingExpectedSize(invoices) &&
      attempts < 15
    );

    if (toExport) {
      dispatch(fetchPaymentInvoicesToExportSuccess(invoices));
    } else {
      dispatch(
        fetchPaymentInvoicesSuccess({
          paymentId,
          invoices,
        }),
      );
    }
  };

// Delete invoice
const deleteInvoiceLoading = createAction(types.DELETE_INVOICE_LOADING);
const deleteInvoiceSuccess = createAction(types.DELETE_INVOICE_SUCCESS);
export const deleteInvoice =
  (invoiceId, callback = noop) =>
  (dispatch, getState) => {
    const companyId = getState().global.company.id;

    dispatch(deleteInvoiceLoading());

    return fetch(apiUrl(`/invoices/${invoiceId}`, companyId), {
      method: 'DELETE',
      credentials: 'include',
    }).then((res) => {
      callback(res);

      res.json().then((json) => {
        if (!json.canDelete && json.reason === 'noOtherReceipt') {
          dispatch(
            addNotification({
              type: NotificationType.Danger,
              message: i18next.t('payments.actions.noOtherReceiptError'),
            }),
          );

          return;
        }

        dispatch(deleteInvoiceSuccess(json));
      });
    });
  };

// FIXME: this should be handled by an action handling the invoices upload
export const incrementPaymentInvoices = createAction(
  types.INCREMENT_PAYMENT_INVOICES,
);

const downloadPaymentsLoading = createAction(types.DOWNLOAD_PAYMENTS_LOADING);
const downloadPaymentsSuccess = createAction(types.DOWNLOAD_PAYMENTS_SUCCESS);
export const downloadPayments =
  ({ selection, filters, withReceipts }) =>
  (dispatch, getState) => {
    dispatch(downloadPaymentsLoading());

    const allFilters = [
      ...serialize(filters),
      { type: 'selection', value: selection },
    ];

    const params = {
      filters: JSON.stringify(allFilters),
      withReceipts,
    };

    const baseUrl = apiUrl('/payments/download', getState().global.company.id);
    window.open(`${baseUrl}?${queryString.stringify(params)}`, '_blank');

    dispatch(downloadPaymentsSuccess());
  };

export const resetBulkEditPayments = createAction(
  types.RESET_BULK_EDIT_PAYMENTS,
);
const bulkEditPaymentsLoading = createAction(types.BULK_EDIT_PAYMENTS_LOADING);
const bulkEditPaymentsSuccess = createAction(types.BULK_EDIT_PAYMENTS_SUCCESS);
const bulkEditPaymentsFailure = createAction(types.BULK_EDIT_PAYMENTS_FAILURE);
export const bulkEditPayments =
  (options = {}) =>
  (dispatch, getState) => {
    dispatch(bulkEditPaymentsLoading());

    const genericFilters = [
      ...serialize(options.filters),
      { type: 'selection', value: options.selection },
    ];

    const variables = {
      companyId: getState().global.company.id,
      filtersEdit: [...genericFilters, { type: 'editable', value: true }],
    };
    const request = `
      query FetchPaymentsAndStats($companyId: String!, $filtersEdit: [JSON]) {
        company(id: $companyId) {
          editable: payments(filters_v2: $filtersEdit) {
            edges {
              node {
                databaseId
                description
                request_id
                card_id
                supplier_id
                supplier {
                  name
                  url
                }
                group {
                  databaseId
                  name
                }
                vat_type
                expense_account_id
                nature {
                  id
                  label
                  accounting_code
                }
                custom_fields {
                  field {
                    databaseId
                    name
                    type
                  }
                  value {
                    databaseId
                    value
                  }
                }
              }
            }
          }
        }
      }
    `;

    const request_ = graphql(request, variables)
      .catch(
        (error) =>
          lastRequests.fetchEditablePaymentsCounters === request_ &&
          dispatch(bulkEditPaymentsFailure(error)),
      )
      .then(
        (result) =>
          lastRequests.fetchEditablePaymentsCounters === request_ &&
          dispatch(bulkEditPaymentsSuccess(result.company)),
      );

    lastRequests.fetchEditablePaymentsCounters = request_;

    return request_;
  };

const bulkMarkAsMissingLoading = createAction(
  types.MARK_PAYMENTS_MISSING_RECEIPT_LOADING,
);
const bulkMarkAsMissingFinish = createAction(
  types.MARK_PAYMENTS_MISSING_RECEIPT_FINISH,
);
export const bulkMarkAsMissing =
  ({ selection, filters }) =>
  async (dispatch, getState) => {
    dispatch(bulkMarkAsMissingLoading());

    const companyId = getCompanyId(getState());
    const allFilters = [
      ...serialize(filters),
      { type: 'selection', value: selection },
    ];

    let result;
    try {
      const { data } = await companyAPI.post(
        '/payments/missing-receipts',
        allFilters,
        {
          companyId,
        },
      );
      result = data;
    } finally {
      dispatch(bulkMarkAsMissingFinish());
    }

    dispatch(refreshAllPayments());
    return result;
  };

const remindInvoicesLoading = createAction(types.REMIND_INVOICES_LOADING);
const remindInvoicesSuccess = createAction(types.REMIND_INVOICES_SUCCESS);
const remindInvoicesFailure = createAction(types.REMIND_INVOICES_FAILURE);
export const remindInvoices =
  ({ selection, filters }) =>
  (dispatch, getState) => {
    dispatch(remindInvoicesLoading());

    filters = serialize(filters);
    const variables = {
      input: {
        company_id: getState().global.company.id,
        filters: [...filters, { type: 'selection', value: selection }],
      },
    };

    const request = `
      mutation PaymentsRemindInvoice($input: PaymentsRemindInvoiceInput!) {
        paymentsRemindInvoice(input: $input) {
          nb_payments
        }
      }
    `;

    return graphql(request, variables)
      .catch((error) => dispatch(remindInvoicesFailure(error)))
      .then((result) => {
        const res = dispatch(
          remindInvoicesSuccess(result.paymentsPushBookkeeping),
        );
        dispatch(fetchAllPaymentsCounters({ filters, selection }));
        return res;
      });
  };

export const refreshAllPayments = () => (dispatch, getState) => {
  const filters = serialize(getState().payments.filters);
  dispatch(resetAllPayments());
  dispatch(fetchAllPayments({ filters }));
};

export const setPaymentsAllOpenedPayment = createAction(
  types.SET_PAYMENTS_ALL_OPENED_PAYMENT,
);

const deleteDocumentaryEvidenceLoading = createAction(
  types.DELETE_DOCUMENTARY_EVIDENCE_LOADING,
);
const deleteDocumentaryEvidenceSuccess = createAction(
  types.DELETE_DOCUMENTARY_EVIDENCE_SUCCESS,
);
const deleteDocumentaryEvidenceFailure = createAction(
  types.DELETE_DOCUMENTARY_EVIDENCE_FAILURE,
);

export const deleteDocumentaryEvidence = ({
  paymentId,
  documentaryEvidenceId,
}) => {
  return async (dispatch, getState) => {
    dispatch(deleteDocumentaryEvidenceLoading());
    try {
      const companyId = getCompanyId(getState());
      await companyAPI.delete(
        `/documentary-evidence/${documentaryEvidenceId}`,
        {
          companyId,
        },
      );
    } catch (error) {
      dispatch(deleteDocumentaryEvidenceFailure(error));
      throw error;
    }
    dispatch(
      deleteDocumentaryEvidenceSuccess({ paymentId, documentaryEvidenceId }),
    );
  };
};
