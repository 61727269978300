import { type AvatarBadgeProps } from '@dev-spendesk/grapes';
import React from 'react';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { Counterparty } from 'modules/bookkeep/components/Counterparty';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useFetchSupplier } from 'src/core/modules/suppliers/hooks';

import {
  getCounterpartyMembersCalloutText,
  getCounterpartyMembersTitle,
} from './translations';
import { SupplierOrMemberSyncStatus } from '../../../components/SupplierOrMemberSyncStatus';
import {
  useGetMemberSyncStatus,
  useGetSupplierSyncWithDefault,
} from '../../../hooks';
import { SupplierSyncStatusForIntegration } from '../../../integration/components/SupplierSyncStatusForIntegration';
import type {
  Payable,
  Member,
  Supplier,
  PayableSubType,
} from '../../containers/PayablePanelContainer';
import { formatMemberName } from '../../models/member';
import { isSpanishDPRPayable } from '../../models/payable';

type Props = {
  payable: Payable;
};

export const CounterpartySection = ({ payable }: Props) => {
  const { counterparty } = payable;
  if (!counterparty) {
    return null;
  }

  if (counterparty.type === 'supplier') {
    return (
      <SupplierCounterparty
        counterparty={counterparty}
        subType={payable.subtype}
        shouldDisplayDPRInformations={isSpanishDPRPayable(payable)}
      />
    );
  }
  return (
    <MemberCounterparty
      counterparty={counterparty}
      badge={getAvatarBadge(payable)}
    />
  );
};

const MemberCounterparty = ({
  counterparty,
  badge,
}: {
  counterparty: Member;
  badge: AvatarBadgeProps | undefined;
}) => {
  const { t } = useTranslation('global');
  const syncStatus = useGetMemberSyncStatus(counterparty.id);

  return (
    <>
      <Counterparty
        type="employee"
        name={formatMemberName(counterparty, t)}
        imageUrl={
          counterparty.type !== 'invitee' ? counterparty.avatar : undefined
        }
        badge={badge}
      />

      {syncStatus && (
        <SupplierOrMemberSyncStatus
          {...syncStatus}
          anchor="simple"
          label={
            syncStatus.synced
              ? getCounterpartyMembersTitle(t, syncStatus.integrationName)
                  .synced
              : getCounterpartyMembersTitle(t, syncStatus.integrationName)
                  .notSynced
          }
          tooltipText={getCounterpartyMembersCalloutText(
            t,
            syncStatus.integrationName,
          )}
        />
      )}
    </>
  );
};

export const SupplierCounterparty = ({
  counterparty,
  subType,
  shouldDisplayDPRInformations,
}: {
  counterparty: Supplier & { type: 'supplier' };
  subType: PayableSubType | undefined;
  shouldDisplayDPRInformations?: boolean;
}) => {
  const syncStatus = useGetSupplierSyncWithDefault(counterparty.id, subType);

  const fetchSupplierQuery = useFetchSupplier(counterparty?.id);
  const supplierData = unwrapQuery(fetchSupplierQuery);

  return (
    <>
      <Counterparty
        type="supplier"
        name={counterparty.name}
        imageUrl={counterparty.thumbnailUrl}
        countryIsoCode2={
          shouldDisplayDPRInformations
            ? supplierData?.supplierDetails?.address?.country
            : undefined
        }
        vatNumber={
          shouldDisplayDPRInformations
            ? supplierData?.supplierDetails?.legalInfos?.vatNumber
            : undefined
        }
      />

      {syncStatus && (
        <SupplierSyncStatusForIntegration supplierId={counterparty.id} />
      )}
    </>
  );
};

const getAvatarBadge = (payable: Payable) => {
  if (payable.supplier) {
    return {
      variant: 'square' as const,
      src: payable.supplier.thumbnailUrl,
      text: payable.supplier.name,
      fallbackSrc: fallbackSupplierLogoSrc,
    };
  }
};
