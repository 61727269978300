import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './TableResultsSection.scss';

const TableResultsSection = (props) => {
  const { className, rightText, children } = props;

  return (
    <tr
      className={cx(
        'TableResultsSection page__table__section-header',
        className,
      )}
    >
      <td className="l-subtitle u-dtc">
        <span className="u-oh">{children}</span>
        {rightText && <span className="u-fr">{rightText}</span>}
      </td>
    </tr>
  );
};

TableResultsSection.propTypes = {
  className: PropTypes.string,
  rightText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

TableResultsSection.defaultProps = {
  className: '',
  rightText: undefined,
  children: undefined,
};

export default TableResultsSection;
