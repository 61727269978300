/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import { compose } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CenteredLayout } from 'common/components/layout';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModalGrapes';
import { ExpenseCategoriesContainer } from 'modules/company/expense-categories';
import * as groupsActions from 'src/core/actions/groups';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { getCustomFieldsActivationPendingKey } from 'src/core/constants/storage';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import authorize from 'src/core/utils/authorize';

import { CustomFields } from './components/CustomFields';
import { CustomFieldsDisabled } from './components/CustomFieldsDisabled';
import { CustomFieldsLoader } from './components/CustomFieldsLoader';
import { CustomFieldsPageHeader } from './components/CustomFieldsPageHeader';
import { CustomFieldsPendingActivation } from './components/CustomFieldsPendingActivation';
import { CustomFieldCreationModalContainer } from './containers/CustomFieldCreationModalContainer';
import { useCustomFieldsQuery } from './hooks/useCustomFieldsQuery';
import { useDesignatedCustomFieldQuery } from './hooks/useDesignatedCustomFieldQuery';
import { type CustomField } from './models/customField';
import {
  getIsFeatureActivationLoading,
  getIsFeatureActivationRequested,
} from './redux/selectors';
import { QuerySuspense } from '../../common/components/QuerySuspense';
import { useTranslation } from '../../common/hooks/useTranslation';
import { type AppState } from '../../reducers';
import { AnalyticalSplitDiscoveryBanner } from '../bookkeep/components/AnalyticalSplitDiscoveryBanner/AnalyticalSplitDiscoveryBanner';

import './CustomFieldsContainer.css';

const TOTAL_CUSTOM_FIELDS_AMOUNT = 5;

type BaseProps = {
  company: { id: string; churning_at?: string };
  groups: object[];
  isFeatureActivationLoading: boolean;
  isFeatureActivationRequested: boolean;
  isExpenseCategoriesFeatureEnabled: boolean;
  fetchGroups: () => unknown;
};

// TODO: this container should be moved in the company module
const CustomFieldsContainer = ({
  company,
  groups,
  isFeatureActivationLoading,
  isFeatureActivationRequested,
  isExpenseCategoriesFeatureEnabled,
  fetchGroups,
}: BaseProps) => {
  const customFieldsQueryState = useCustomFieldsQuery({ isEnabled: true });
  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();

  const { t } = useTranslation('global');

  return (
    <ErrorBoundary
      context={{ scope: 'custom-fields', team: 'finance-controller' }}
    >
      <QuerySuspense
        queryState={customFieldsQueryState}
        fallback={() => (
          <CenteredLayout
            className="CustomFieldsContainer text-center"
            verticalAlign="middle"
          >
            {t('misc.errors.networkError')}
          </CenteredLayout>
        )}
        loading={<CustomFieldsLoader />}
      >
        {(customFields) => (
          <CustomFieldsContainerWithData
            expenseCategoryCustomFieldId={
              designatedCustomFieldQueryState.status === 'success'
                ? designatedCustomFieldQueryState.data.customFieldId
                : undefined
            }
            customFields={customFields ?? []}
            company={company}
            groups={groups}
            isFeatureActivationLoading={isFeatureActivationLoading}
            isFeatureActivationRequested={isFeatureActivationRequested}
            isExpenseCategoriesFeatureEnabled={
              isExpenseCategoriesFeatureEnabled
            }
            fetchGroups={fetchGroups}
          />
        )}
      </QuerySuspense>
    </ErrorBoundary>
  );
};
const CustomFieldsContainerWithData = ({
  company,
  groups,
  isFeatureActivationRequested,
  isExpenseCategoriesFeatureEnabled,
  fetchGroups,
  customFields,
  expenseCategoryCustomFieldId,
}: BaseProps & {
  customFields: CustomField[];
  expenseCategoryCustomFieldId?: string;
}) => {
  const expenseCategory = customFields.find(
    (customField) => customField.id === expenseCategoryCustomFieldId,
  );
  const filteredCustomFields = customFields.filter(
    ({ id }) => id !== expenseCategoryCustomFieldId,
  );

  // we only consider custom fields that are not flagged as representing
  // expense categories
  const hasCustomFields = filteredCustomFields.length > 0;

  const [customFieldCreationModal, showCustomFieldCreationModal] = useModal(
    ({ onClose, isOpen }) => {
      return (
        <CustomFieldCreationModalContainer
          isOpen={isOpen}
          onClose={() => onClose()}
        />
      );
    },
  );

  useEffect(() => {
    fetchGroups();

    const localStorageKey = getCustomFieldsActivationPendingKey(company.id);
    if (hasCustomFields && localStorage.getItem(localStorageKey)) {
      // Clear local storage flag because it's no longer necessary
      localStorage.removeItem(localStorageKey);
    }
  }, []);

  // TODO: this is the old flow for custom fields activation request, this will
  // be removed when all the companies will be have the expense categories feature
  if (!isExpenseCategoriesFeatureEnabled) {
    if (!hasCustomFields && isFeatureActivationRequested) {
      return <CustomFieldsPendingActivation />;
    }
    if (!hasCustomFields) {
      return <CustomFieldsDisabled />;
    }
  }

  const canCreateMoreCustomFields =
    filteredCustomFields.length < TOTAL_CUSTOM_FIELDS_AMOUNT;

  return (
    <>
      {customFieldCreationModal}
      <CustomFieldsPageHeader
        onButtonClick={() => showCustomFieldCreationModal()}
        isButtonDisabled={!canCreateMoreCustomFields}
      />
      {isExpenseCategoriesFeatureEnabled && (
        <div className="CustomFieldsContainer__expenseCategory">
          <ExpenseCategoriesContainer
            expenseCategoryCustomField={expenseCategory}
          />
        </div>
      )}

      <AnalyticalSplitDiscoveryBanner />

      {hasCustomFields ? (
        <CustomFields customFields={filteredCustomFields} groups={groups} />
      ) : null}
    </>
  );
};

CustomFieldsContainer.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    churning_at: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    is_account_owner: PropTypes.bool.isRequired,
    is_admin: PropTypes.bool.isRequired,
    is_controller: PropTypes.bool.isRequired,
  }).isRequired,
  groups: PropTypes.array,
  isFeatureActivationLoading: PropTypes.bool.isRequired,
  isFeatureActivationRequested: PropTypes.bool.isRequired,
  isExpenseCategoriesFeatureEnabled: PropTypes.bool.isRequired,
  fetchGroups: PropTypes.func.isRequired,
};

CustomFieldsContainer.defaultProps = {
  groups: undefined,
};

const mapDispatchToProps = {
  fetchGroups: groupsActions.fetchGroups,
};

const mapStateToProps = (state: AppState) => {
  const {
    global: { company, user },
    categories: { customFields, groups },
  } = state;

  return {
    company,
    user,
    customFields,
    groups,
    isFeatureActivationLoading: getIsFeatureActivationLoading(state),
    isFeatureActivationRequested: getIsFeatureActivationRequested(state),
    isExpenseCategoriesFeatureEnabled: getIsFeatureEnabled(
      state,
      FEATURES.EXPENSE_CATEGORIES,
    ),
  };
};

const withAuthorization = authorize(
  ({
    user,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    company,
  }: {
    company: { id: string; churning_at?: string };
    user: {
      is_account_owner: boolean;
      is_admin: boolean;
      is_controller: boolean;
    };
  }) => {
    const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);

    return (
      (user.is_account_owner || user.is_admin || user.is_controller) &&
      hasCustomFieldsFeature
    );
  },
  ({ company }) => {
    return (
      <Redirect
        to={{
          pathname: routeFor(routes.NOT_FOUND.path, {
            company: company.id,
          }),
        }}
      />
    );
  },
);

export default compose<() => JSX.Element>(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization,
)(CustomFieldsContainer);
