import { type ThunkDispatch } from '@reduxjs/toolkit';

import { NotificationType, addNotification } from 'modules/app/notifications';
import { companyAPI } from 'src/core/api/axios';
import i18n from 'src/core/config/i18n';
import { type AppState } from 'src/core/reducers';
import { logger } from 'src/utils/datadog-log-wrapper';

import { type CompanyActions } from './actionTypes';
import {
  fetchAvailablePaymentMethodsRequest,
  fetchAvailablePaymentMethodsSuccess,
  fetchAvailablePaymentMethodsFailure,
  updateCompany,
} from './actions';
import { getCompany, getSelectedId } from './selectors';

export const fetchAvailablePaymentMethods =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, CompanyActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedId(state);

    dispatch(fetchAvailablePaymentMethodsRequest());

    let paymentMethods;
    try {
      const res = await companyAPI.get('/payment-methods', {
        companyId,
      });
      paymentMethods = res.data;
    } catch (error) {
      dispatch(fetchAvailablePaymentMethodsFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('company.fetchPaymentMethodsError'),
        }),
      );

      throw error;
    }

    dispatch(fetchAvailablePaymentMethodsSuccess(paymentMethods));
  };

export const fetchCompanyTotalSpend =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, CompanyActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const company = getCompany(state);

    try {
      const response = await companyAPI.get('/spendings', {
        companyId: company.id,
      });
      const totalSpent = response.data.totalSpent;
      dispatch(
        updateCompany({
          ...company,
          spending_total: totalSpent,
        }),
      );
    } catch (error) {
      logger.warn('Could not fetch company total spend', {
        scope: 'company',
        team: 'employee',
        error,
      });
    }
  };
