import cx from 'classnames';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';
import React, { PureComponent } from 'react';

import Icon, { ICONS } from 'src/core/common/components/legacy/Icon/Icon';
import { white } from 'src/core/utils/color-palette';
import './CheckboxRedesigned.scss';

type Props = {
  name?: string;
  className?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  style?: React.CSSProperties;
  // Some dependencies expect a mouse event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  checkSign?: 'checked' | 'neutral';
  shouldStopClickPropagation?: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
class CheckboxRedesigned extends PureComponent<Props, {}> {
  static defaultProps = {
    name: null,
    className: '',
    checkSign: 'checked',
    isDisabled: false,
    isChecked: false,
    style: {},
    onChange: noop,
    onBlur: noop,
    shouldStopClickPropagation: false,
  };

  name: string = '';

  constructor(props: Props) {
    super(props);
    this.name = props.name || uniqueId('checkbox-redesigned_');
  }

  render() {
    const {
      className,
      style,
      checkSign,
      isDisabled,
      isChecked,
      onChange,
      onBlur,
      shouldStopClickPropagation,
    } = this.props;

    const icon = checkSign === 'neutral' ? ICONS.CHECK_MINUS : ICONS.CHECKED;
    const classes = cx('CheckboxRedesigned', className, {
      neutral: checkSign === 'neutral',
      disabled: isDisabled,
      checked: isChecked,
    });

    return (
      <div className={classes} style={style}>
        {/* FIXME: no onClick on label */}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <label
          htmlFor={this.name}
          className="CheckboxRedesigned__label"
          onClick={
            shouldStopClickPropagation ? (e) => e.stopPropagation() : undefined
          }
        >
          <input
            name={this.name}
            id={this.name}
            type="checkbox"
            className="CheckboxRedesigned__input"
            onChange={onChange}
            onBlur={onBlur}
            checked={isChecked}
            disabled={isDisabled}
          />
          <span className="CheckboxRedesigned__checkmark">
            {isChecked && (
              <Icon icon={icon} fill={white} width={24} height={24} />
            )}
          </span>
        </label>
      </div>
    );
  }
}

export default CheckboxRedesigned;
