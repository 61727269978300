import PropTypes from 'prop-types';
import React from 'react';

import './TableResultsRow.scss';

const TableResultsRow = (props) => {
  const { className, children, onClick } = props;

  return (
    <tr className={className}>
      {/* FIXME: Avoid click handler on td */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <td
        className="TableResultsRow td l-row l-row--request l-spt l-spb"
        onClick={onClick}
      >
        {children}
      </td>
    </tr>
  );
};

TableResultsRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

TableResultsRow.defaultProps = {
  className: '',
  onClick: () => ({}),
  children: undefined,
};

export default TableResultsRow;
