import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icon, {
  ICONS,
} from "src/core/common/components/legacy/Icon/Icon";
import { grey6Cheerful } from "src/core/utils/color-palette";
import './SplashNotif.scss';

class SplashNotif extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static defaultProps = {
    className: '',
    children: undefined,
  };

  render() {
    return (
      <div
        className={cx('SplashNotif', this.props.className, {
          closable: !!this.props.onClose,
        })}
      >
        <div className="SplashNotif__header">
          <span>{this.props.title}</span>
          <Icon
            icon={ICONS.CROSS}
            fill={grey6Cheerful}
            width={30}
            height={30}
            onClick={this.props.onClose}
          />
        </div>
        <div className="SplashNotif__content">{this.props.children}</div>
      </div>
    );
  }
}

export default SplashNotif;
