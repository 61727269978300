import { IconButton, Table } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type IntegrationReverseChargeAccount } from 'modules/bookkeep/settings/accounting/taxAccount';

import { CellWithButton } from '../../../../components/CellWithIconButton/CellWithIconButton';
import { type ModalState } from '../ModalState';
import { TableFooter } from '../TableFooter';

interface Props {
  setModalState: Dispatch<SetStateAction<ModalState>>;
  reverseChargeAccounts: IntegrationReverseChargeAccount[];
  integrationStatus: IntegrationStatusWithIntegration;
}

export const DoubleEntryReverseChargeTable = ({
  setModalState,
  reverseChargeAccounts,
  integrationStatus,
}: Props) => {
  const { t } = useTranslation('global');
  const reverseChargeAccountsErrorsList =
    integrationStatus.settingsValidation.taxAccounts.flatMap(({ id }) => id);
  const data = reverseChargeAccounts.map((reverseChargeAccount) => ({
    hasErrors: reverseChargeAccountsErrorsList.includes(
      reverseChargeAccount.id,
    ),
    ...reverseChargeAccount,
  }));

  return (
    <Table
      rowHeight="compact"
      data={data}
      hasColumnSeparator
      columns={[
        {
          id: 'reverse-charge-account-name',
          header: t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.accountName',
          ),
          width: '33%',
          renderCell(row) {
            return row.name;
          },
        },
        {
          id: 'reverse-charge-debit-code',
          header: t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.debitCode',
          ),
          width: '33%',
          renderCell(row) {
            return row.codeDebit;
          },
        },
        {
          id: 'reverse-charge-account-bu-code',
          header: t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.creditCode',
          ),
          width: '33%',
          getCellVariant: (row) => (row.hasErrors ? 'alert' : undefined),
          renderCell(row, { isRowHovered }) {
            return (
              <CellWithButton
                isButtonVisible={isRowHovered}
                button={
                  <span className="CellWithButton__button">
                    <IconButton
                      iconName="trash"
                      variant="borderless"
                      onClick={() =>
                        setModalState({
                          kind: 'confirmDeleteReverseCharge',
                          id: row.id,
                          name: row.name,
                          codeDebit: row.codeDebit,
                          codeCredit: row.codeCredit,
                        })
                      }
                    />
                  </span>
                }
              >
                {row.codeCredit}
              </CellWithButton>
            );
          },
        },
      ]}
      footer={
        reverseChargeAccounts.length === 0 ? (
          <TableFooter type="reverseCharge" />
        ) : undefined
      }
      getRowId={(row) => row.id}
    />
  );
};
