import { Button, IconButton, ModalOverlay } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { IllustratedBanner } from 'common/components/IllustratedBanner';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import styles from './WelcomeVideo.module.css';

// Replace with localized video URL when available
const VIDEO_URL =
  'https://www.youtube.com/embed/LCX_ulcWhzU?si=kRqSkdunAvE7wRFQ';

export const WelcomeVideo = () => {
  const { t } = useTranslation('global');

  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

  const openVideo = () => {
    track(AnalyticEventName.WELCOME_VIDEO_CLICKED);
    setIsVideoOpen(true);
  };

  return (
    <div className="mb-m">
      <IllustratedBanner
        title={t('homepage.welcomeVideo.title')}
        description={t('homepage.welcomeVideo.description')}
      >
        <Button
          className="mt-xs text-primary"
          variant="contrasted"
          onClick={openVideo}
          text={t('homepage.welcomeVideo.cta')}
        />
      </IllustratedBanner>
      {isVideoOpen && (
        <ModalOverlay isOpen={isVideoOpen}>
          <div>
            <iframe
              className={styles.video}
              src={VIDEO_URL}
              title={t('homepage.welcomeVideo.title')}
              allowFullScreen
            />
            <IconButton
              className={styles.closeVideo}
              onClick={() => setIsVideoOpen(false)}
              iconName="cross"
            />
          </div>
        </ModalOverlay>
      )}
    </div>
  );
};
