import cx from 'classnames';
import size from 'lodash/size';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';

import './FileViewerToolbar.scss';

export const MODES = {
  COMPACT: 'compact',
  NORMAL: 'normal',
};

const FileViewerToolbar = ({ mode, leftActions, rightActions }) => (
  <div
    className={cx('LegacyFileViewerToolbar', {
      'LegacyFileViewerToolbar--compact': mode === MODES.COMPACT,
    })}
  >
    {size(leftActions) > 0 && (
      <div className="LegacyFileViewerToolbar__left flex">{leftActions}</div>
    )}
    {size(rightActions) > 0 && (
      <div className="LegacyFileViewerToolbar__right flex">{rightActions}</div>
    )}
  </div>
);

FileViewerToolbar.propTypes = {
  mode: PropTypes.oneOf(values(MODES)),
  leftActions: PropTypes.arrayOf(PropTypes.element),
  rightActions: PropTypes.arrayOf(PropTypes.element),
};

FileViewerToolbar.defaultProps = {
  mode: MODES.NORMAL,
  leftActions: [],
  rightActions: [],
};

export default FileViewerToolbar;
