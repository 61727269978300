import { z } from 'zod';

export const tokenListSchema = z.array(
  z
    .object({
      id: z.string().nonempty(),
      name: z.string().nonempty(),
      logo: z.string().optional(),
      website: z.string().optional(),
      scope: z.string().nonempty(),
      active: z.boolean(),
      created: z.date({ coerce: true }),
      updated: z.date({ coerce: true }),
    })
    .required(),
);
export type TokenList = z.infer<typeof tokenListSchema>;

export const apiPartnerSchema = z
  .object({
    name: z.string().nonempty(),
    logo: z.string().optional(),
    website: z.string().optional(),
    scope: z.string().nonempty(),
  })
  .required();

export type ApiPartner = z.infer<typeof apiPartnerSchema>;
