import React from 'react';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useParams } from 'src/core/common/hooks/useParams';
import { routes } from 'src/core/constants/routes';
import { type User } from 'src/core/modules/app/hooks/useUser';
import { logger } from 'src/utils/datadog-log-wrapper';

import { PurchaseOrderRequestPanel } from './PurchaseOrderRequestPanel';
import { RequestDetailsPanelLoading } from '../../../components/RequestDetailsPanelLoading';
import { useInvalidateProgressStepQuery } from '../../../hooks/useProgressStepQuery';
import {
  useInvalidateRequestQuery,
  useRequestQuery,
} from '../../../hooks/useRequestQuery';
import { type CustomFieldDefinition } from '../../../models';
import { type PurchaseOrderRequestDetails } from '../../models';

type Props = {
  customFields?: CustomFieldDefinition[];
  user: User;
  onClose: () => void;
  onApprove: (request: PurchaseOrderRequestDetails, options: object) => void;
  onDeny: (
    requestId: string,
    reason: string,
    updateLocally: boolean,
    callback: (response: Response) => void,
  ) => void;
};

export const PurchaseOrderRequestPanelContainer = ({
  customFields,
  user,
  onClose,
  onApprove,
  onDeny,
}: Props) => {
  const { id: requestId } = useParams(routes.REQUESTS.path);
  const invalidateProgressStepQuery = useInvalidateProgressStepQuery();
  const invalidateRequestQuery = useInvalidateRequestQuery();
  const requestQuery = useRequestQuery<PurchaseOrderRequestDetails>(
    requestId ?? '',
  );

  if (!requestId) {
    logger.error(
      'Trying to open the purchase order details panel without an id in the URL',
      {
        team: 'employee',
        scope: 'requests:panel',
      },
    );

    return null;
  }

  return (
    <QuerySuspense
      queryState={requestQuery}
      loading={<RequestDetailsPanelLoading />}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          logContext={{
            team: 'employee',
          }}
        />
      )}
    >
      {(request) => {
        if (request.type !== 'purchase_order') {
          logger.error(
            'Trying to open the purchase order details panel with a non valid request',
            {
              team: 'employee',
              scope: 'requests:panel',
              request,
            },
          );
          return null;
        }
        return (
          <PurchaseOrderRequestPanel
            request={request}
            customFields={customFields}
            user={user}
            onClose={onClose}
            onApprove={onApprove}
            onDeny={onDeny}
            fetchRequest={async () => {
              await invalidateRequestQuery(requestId);
              await invalidateProgressStepQuery(requestId);
            }}
          />
        );
      }}
    </QuerySuspense>
  );
};
