import {
  Avatar,
  Tooltip,
  Icon,
  colors,
  type DateFormatter,
  DATE_FORMAT,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import React, { Component, type MouseEventHandler } from 'react';

import { SupplierLogo } from 'common/components/SupplierLogo';
import CheckboxRedesigned from 'common/components/legacy/CheckboxRedesigned/CheckboxRedesigned';
import { withTranslation } from 'common/components/withTranslation';
import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { metersToDistanceUnit } from 'src/core/utils/geo';
import { formatMoney } from 'src/core/utils/money';

import type { Payment } from './paymentType';
import {
  getPaymentTypeIcon,
  invoiceIconFromPayment,
} from '../../utils/paymentUtils';

import './PaymentRow.scss';

export const PAYMENT_ROW_HEIGHT = 60;

type Props = typeof defaultProps & {
  payment: Payment;
  company: { currency: string };
  style?: React.CSSProperties;
  active?: boolean;
  checked?: boolean;
  canBeChecked: boolean;
  onClick?: () => void;
  onCheck?: () => void;
  localeFormat: DateFormatter;
  t: TGlobalFunctionTyped;
  index: number;
  isTeamsFeatureEnabled: boolean;
};

const defaultProps = {
  active: false,
  checked: false,
  onClick: noop,
  onCheck: noop,
  style: undefined,
};

class PaymentRow extends Component<Props> {
  static defaultProps = defaultProps;

  handleRowClick: MouseEventHandler<HTMLDivElement> = (event) => {
    // Avoid overriding the checkbox's event
    if (!event.currentTarget.dataset.spxLocked) {
      this.props.onClick();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  renderPaymentStatus = (payment: Props['payment']) => {
    const { icon, color, tooltip } = invoiceIconFromPayment(
      payment,
      this.props.t,
    );
    return (
      <Tooltip content={tooltip}>
        <Icon name={icon} color={color} />
      </Tooltip>
    );
  };

  renderPaymentTypeIcon = (payment: Props['payment']) => {
    const { icon, color, tooltip } = getPaymentTypeIcon(payment, this.props.t);

    return (
      <div className="ml-xs flex">
        <Tooltip content={tooltip} triggerAsChild>
          <Icon name={icon} color={color} />
        </Tooltip>
      </div>
    );
  };

  renderUserTitle = (payment: Props['payment']) => {
    const { user, team, costCenter } = payment;
    const teamName = team ? team.name : null;
    const { t, isTeamsFeatureEnabled } = this.props;

    return (
      <span>
        <strong>
          {user.full_name}{' '}
          {isTeamsFeatureEnabled &&
            teamName &&
            `(${t('payments.rowTeam', { name: teamName })})`}{' '}
          {costCenter && `${t('payments.rowFor')} ${costCenter.name}`}
        </strong>
      </span>
    );
  };

  renderStatus(payment: Props['payment']) {
    const { t } = this.props;

    if (payment.state !== 'authorised') {
      return;
    }

    let tooltipMessage = t('payments.awaitingMerchantConfirmation');

    if (
      payment.request &&
      ['expense', 'invoice'].includes(payment.request.type)
    ) {
      tooltipMessage = t('payments.awaitingWireTransfer');
    }

    return (
      <Tooltip content={tooltipMessage} placement="right" triggerAsChild>
        <Icon
          className="pending-payment-clock"
          name="clock"
          color={colors.neutralLight}
        />
      </Tooltip>
    );
  }

  renderOriginIcon = (payment: Props['payment']) => {
    if (
      payment.request &&
      ['mileage_allowance', 'per_diem_allowance'].includes(payment.request.type)
    ) {
      return;
    }

    if (payment.is_atm_withdrawal) {
      return (
        <img
          src="/static/img/icons/atm_withdrawal.svg"
          className="mr-xxs"
          height="18"
          width="18"
          alt="ATM withdrawal"
        />
      );
    }
    return (
      <SupplierLogo
        name={get(payment.supplier, 'name')}
        src={get(payment.supplier, 'url')}
        size="xs"
        className="mr-xxs"
      />
    );
  };

  renderDescription(payment: Props['payment']) {
    const { t, company } = this.props;
    const {
      description,
      supplier,
      is_atm_withdrawal: isWithdrawal,
      transaction,
    } = payment;
    const paymentDescription =
      description || (transaction ? transaction.clean_description : '');
    const requestType = get(payment, 'request.type');

    let origin;
    if (requestType === 'mileage_allowance') {
      const distanceMeters = get(
        payment,
        'request.mileage_allowance_request.distance',
        0,
      );
      const distanceUnit = get(company, 'mileage_scheme.distanceUnit', 'km');
      origin = `${metersToDistanceUnit({
        distanceMeters,
        distanceUnit,
      })} ${distanceUnit}`;
    } else if (supplier) {
      origin = supplier.name;
    } else if (isWithdrawal) {
      origin = t('misc.atmWithdrawal');
    }

    return origin ? (
      <span className="l-row__description" title={paymentDescription}>
        <strong>{origin}</strong> — {paymentDescription}
      </span>
    ) : (
      <span className="l-row__description" title={paymentDescription}>
        {paymentDescription}
      </span>
    );
  }

  renderAmount(payment: Props['payment']) {
    const {
      amount_declared: amountDeclared,
      currency_declared: currencyDeclared,
      amount_billed: amountBilled,
      fx_fee_amount: fxFeesAmount,
    } = payment;
    const { currency } = this.props.company;

    let amountDeclaredElm;
    if (currency !== currencyDeclared) {
      const formattedAmountDeclared =
        Number.parseFloat(amountDeclared) < 0
          ? formatMoney(
              Math.abs(Number.parseFloat(amountDeclared)),
              currencyDeclared,
              true,
            )
          : formatMoney(amountDeclared, currencyDeclared);
      amountDeclaredElm = (
        <span className="txt-low ff-regular">{formattedAmountDeclared}</span>
      );
    }

    const total =
      Number.parseFloat(amountBilled) + Number.parseFloat(fxFeesAmount);
    const totalFormatted =
      total < 0
        ? // We want to show refunds with a +
          formatMoney(Math.abs(total), currency, true)
        : formatMoney(total, currency);
    const amountBilledElm = <strong>{totalFormatted}</strong>;

    if (amountDeclaredElm) {
      return (
        <span className="l-row__highlight PaymentRow__amount">
          {amountDeclaredElm} • {amountBilledElm}
        </span>
      );
    }

    return (
      <span className="l-row__highlight billed-amount PaymentRow__amount">
        {amountBilledElm}
      </span>
    );
  }

  renderDate = () => {
    const { localeFormat, payment } = this.props;
    return (
      <span className="l-row__date">
        {localeFormat(
          new Date(payment.paid_at || payment.created_at),
          DATE_FORMAT.CUSTOM,
          { dateStyle: 'medium', timeZone: 'UTC' },
        )}
      </span>
    );
  };

  renderOverlay = (payment: Props['payment']) => {
    if (payment.state === 'authorised') {
      return <span className="l-row--payment__pending-overlay" />;
    }
    return null;
  };

  render() {
    const { payment, style, active, checked, onCheck } = this.props;
    const { user } = payment;
    const rowClass = cx('l-row--payment', { checked, active });

    return (
      <div className={rowClass} style={style} onClick={this.handleRowClick}>
        <CheckboxRedesigned
          style={{ marginRight: 16 }}
          isDisabled={!this.props.canBeChecked}
          isChecked={checked}
          onChange={() => onCheck(!checked)}
          shouldStopClickPropagation
        />
        <Avatar
          text={user.full_name}
          src={user.avatar ?? undefined}
          size="m"
          className="mr-s"
        />
        <div className="l-row__content">
          <h2 className="l-row__title">
            {this.renderUserTitle(payment)}
            {this.renderStatus(payment)}
          </h2>
          <div className="l-row__meta">
            {this.renderOriginIcon(payment)}
            {this.renderDescription(payment)}
            {this.renderPaymentTypeIcon(payment)}
          </div>
        </div>
        <div className="l-row__aside l-ml">
          <div className="left">
            {this.renderAmount(payment)}
            {this.renderDate()}
          </div>
          <div className="right">{this.renderPaymentStatus(payment)}</div>
        </div>
        {this.renderOverlay(payment)}
      </div>
    );
  }
}

export default withTranslation<Omit<Props, 't' | 'localeFormat'>>('global')(
  // @ts-expect-error
  PaymentRow,
);
