import { Icon, Button, colors } from '@dev-spendesk/grapes';
import React from 'react';

import { ContactUs } from 'common/components/ContactUs';
import ButtonsStacked from 'common/components/legacy/ButtonsStacked/ButtonsStacked';
import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  title?: string;
  questions: {
    title: string;
    link: string;
  }[];
  helpCenterIndex: {
    link: string;
    title: string;
  };
  onLinkClicked: (link: string, title: string) => void;
  onChatClicked: () => void;
  openFixIntercomCookiesModal?: () => void;
};

const HelpTooltip = ({
  title,
  questions,
  helpCenterIndex,
  onLinkClicked,
  onChatClicked,
  openFixIntercomCookiesModal,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="w-[482px]">
      <div className="flex items-center gap-s p-s">
        <Icon name="question-mark" size="l" color={colors.alertLight} />
        <div>
          <div className="text-neutral-darker title-m">
            {title || t('misc.needHelp')}
          </div>
          <div className="text-neutral-dark body-m">
            {t('helpCenter.subtitle')}
          </div>
        </div>
      </div>
      <div className="bg-page-background p-s pb-m">
        <ButtonsStacked
          buttons={questions.map((question) => ({
            text: question.title,
            action: () => {
              onLinkClicked(question.link, question.title);
              window.open(question.link, '_blank');
            },
          }))}
        />
        <div className="mt-s flex items-center justify-between">
          <a
            href={helpCenterIndex.link}
            className="flex items-center justify-center gap-xxs text-neutral-dark title-m hover:text-neutral-darker"
            onClick={() =>
              onLinkClicked(helpCenterIndex.link, helpCenterIndex.title)
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="arrow-right" size="s" />
            <span>{t('helpCenter.findOutMore')}</span>
          </a>
          <ContactUs openFixIntercomCookiesModal={openFixIntercomCookiesModal}>
            {({ handleContactUs }) => (
              <Button
                variant="ghost"
                text={t('helpCenter.contactUs')}
                onClick={() => {
                  handleContactUs();
                  onChatClicked();
                }}
              />
            )}
          </ContactUs>
        </div>
      </div>
    </div>
  );
};

export default HelpTooltip;
