import * as A from '@dev-spendesk/general-type-helpers/Array';
import { Button, Modal, Table } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { ErrorState } from 'common/components/ErrorState';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';

import { getPublicApiScopeTranslation } from '../../../../../domain/public-api/scopes';
import { useGetPublicApiScopes } from '../../../../../hooks/useGetPublicApiScopes';
import {
  useGetOAuth2TokenList,
  useRevokeOAuth2Token,
} from '../../../../pages/hooks';

const OAuth2TokenContent = () => {
  const scopesQueryResult = useGetPublicApiScopes();
  const [revokeOAuth2Token, revokeOAuth2TokenMutationState] =
    useRevokeOAuth2Token();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tokenId, setTokenId] = useState('');
  const { t, activeLanguage } = useTranslation('global');
  const dateTimeFormat = new Intl.DateTimeFormat(activeLanguage, {
    dateStyle: 'short',
  });
  const tokenList = useGetOAuth2TokenList();

  if (
    tokenList.status === 'loading' ||
    revokeOAuth2TokenMutationState.status === 'loading' ||
    scopesQueryResult.status === 'loading'
  ) {
    // TODO skeleton
    return <></>;
  }
  if (
    tokenList.status === 'error' ||
    revokeOAuth2TokenMutationState.status === 'error' ||
    scopesQueryResult.status === 'error'
  ) {
    return <ErrorState title={t('misc.errorState.title')} />;
  }

  const [activeTokens, inactiveTokens] = A.partition(
    tokenList.data,
    ({ active }) => active,
  );
  const getScopeTitle = getPublicApiScopeTranslation({
    t,
    scopeEntries: scopesQueryResult.data,
    type: 'title',
  });

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('publicApi.flowOAuth2.main.title')}
        iconName="settings"
        actions={
          <>
            <Button
              variant="secondary"
              text={t('publicApi.flowOAuth2.main.close')}
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              iconName="trash"
              variant="alert"
              text={t('publicApi.flowOAuth2.main.revoke')}
              onClick={() => {
                revokeOAuth2Token({ endpointParams: { tokenId } });
                setIsModalOpen(false);
              }}
            />
          </>
        }
      >
        {t('publicApi.flowOAuth2.main.modalDescription')}
      </Modal>

      <div className="flex flex-col gap-m">
        <p className="text-neutral-dark body-m">
          {t('publicApi.flowOAuth2.main.accesses.title')}
        </p>

        <div className="separator" />

        <div className="flex flex-row gap-m">
          <h2>{t('publicApi.flowOAuth2.main.accesses.active')}</h2>
        </div>
        <Table
          data={activeTokens}
          columns={[
            {
              id: 'name',
              header: t('misc.name'),
              renderCell: ({ name }) => name,
            },
            {
              id: 'scope',
              header: t('publicApi.scopes.name'),
              renderCell: ({ scope }) => getScopeTitle(scope),
            },
            {
              id: 'created',
              header: t('publicApi.scopes.created'),
              renderCell: ({ created }) => dateTimeFormat.format(created),
            },
            {
              id: 'expiredAt',
              header: t('publicApi.scopes.updated'),
              renderCell: ({ updated }) => dateTimeFormat.format(updated),
            },
          ]}
          emptyState={{ title: t('publicApi.scopes.token.empty') }}
          onRowClick={({ id }) => {
            setTokenId(id);
            setIsModalOpen(true);
          }}
          getRowId={(row) => row.id}
        />

        <h2>{t('publicApi.flowOAuth2.main.accesses.inactive')}</h2>
        <Table
          data={inactiveTokens}
          columns={[
            {
              id: 'id',
              header: t('misc.name'),
              renderCell: ({ name }) => name,
            },
            {
              id: 'scope',
              header: t('publicApi.scopes.name'),
              renderCell: ({ scope }) => getScopeTitle(scope),
            },
            {
              id: 'created',
              header: t('publicApi.scopes.created'),
              renderCell: ({ created }) => dateTimeFormat.format(created),
            },
            {
              id: 'expiredAt',
              header: t('publicApi.scopes.updated'),
              renderCell: ({ updated }) => dateTimeFormat.format(updated),
            },
          ]}
          emptyState={{
            title: t('publicApi.scopes.token.inactive'),
          }}
          getRowId={(row) => row.id}
        />
      </div>
    </>
  );
};

export default withErrorBoundary({
  scope: 'oauth2-token-content',
  team: 'api-integration',
})(OAuth2TokenContent);
