import { Autocomplete, AutocompleteNoOptions } from '@dev-spendesk/grapes';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useRequestersQuery } from './hooks/useRequestersQuery';

type Option = {
  key: string;
  label: string;
};

type Props = {
  onSelect: (option: Option | undefined) => void;
  value: Option;
};

export const RequestersSelectContainer = ({ onSelect, value }: Props) => {
  const [searchPattern, setSearchPattern] = useState('');
  const { t } = useTranslation('global');
  const requestersQueryState = useRequestersQuery(searchPattern.toLowerCase());
  return (
    <Autocomplete
      onSelect={onSelect}
      placeholder={t('filters.requesters.placeholder')}
      fit="parent"
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSearch={debounce((searchPattern) => {
        if (searchPattern) {
          setSearchPattern(searchPattern);
        }
      }, 500)}
      options={
        requestersQueryState.status === 'success'
          ? requestersQueryState.data.map((member) => ({
              key: member.id,
              label: `${member.firstName} ${member.lastName}`,
            }))
          : []
      }
      value={value}
      renderNoOptions={() => {
        return (
          <AutocompleteNoOptions>
            {t('filters.requesters.nothingFound')}
          </AutocompleteNoOptions>
        );
      }}
    />
  );
};
