import { Switch } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { type NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { IntegrationDetailsContainer } from 'src/core/modules/integrations/settings/containers';
import IntegrationsPage from 'src/core/modules/integrations/settings/pages/IntegrationsPage';

export const SettingsIntegrationPage = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const user = useUser();

  const hasApiKeysPageFeature = useFeature(FEATURES.API_KEYS_PAGE);
  const canAccessIntegrationDetailPage =
    !company.churning_at && (user.is_account_owner || user.is_controller);

  const canAccessIntegrationListPage =
    !company.churning_at && (user.is_account_owner || user.is_admin);

  const links = [
    canAccessIntegrationListPage && {
      key: 'all-integrations',
      text: t('integration.sideNav.all'),
      path: routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
        company: company.id,
      }),
    },
    hasApiKeysPageFeature && {
      key: 'api-access-management',
      text: t('integration.sideNav.apiAccessManagement'),
      path: routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path, {
        company: company.id,
      }),
    },
  ].filter(Boolean) as NavigationLinkProps[];
  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('navigation.integrations')}
      </HeaderWithNavigation>
      <Switch>
        <ProtectedRoute
          path={routes.COMPANY_INTEGRATION.path}
          isAccessAllowed={canAccessIntegrationDetailPage}
        >
          <IntegrationDetailsContainer key="integration-details" />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.COMPANY_INTEGRATIONS.path}
          isAccessAllowed={canAccessIntegrationListPage}
        >
          <IntegrationsPage key="integrations-list" />
        </ProtectedRoute>
      </Switch>
    </div>
  );
};
