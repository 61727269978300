import { type HttpStatusCode, type QueryError } from 'src/core/api/queryError';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

export type Translations<TError> = {
  serverError?: I18nKey;
  networkError?: I18nKey;
  requestError?: (
    reason: TError,
    httpStatusCode: HttpStatusCode,
  ) => I18nKey | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useQueryError = <TError, TParams extends object = any>(
  translations: Translations<TError>,
) => {
  const { t } = useTranslation('global');
  return (
    queryError: QueryError<TError>,
    translationsParams?: TParams,
  ): string =>
    t(getErrorTitle({ queryError, translations }), translationsParams);
};

const getErrorTitle = <TError>({
  queryError,
  translations,
}: {
  queryError: QueryError<TError>;
  translations: Translations<TError>;
}): I18nKey => {
  switch (queryError.type) {
    case 'NetworkError':
      return translations.networkError ?? 'misc.errors.networkError';
    case 'RequestError':
      return (
        translations.requestError?.(queryError.data, queryError.status) ??
        getErrorTitleFromHttpStatus(queryError.status) ??
        'misc.errors.unknownError'
      );
    case 'ServerError':
      return translations.serverError ?? 'misc.errors.serverError';
    default:
      return 'misc.errors.unknownError';
  }
};

const getErrorTitleFromHttpStatus = (
  httpStatusCode: HttpStatusCode,
): I18nKey | undefined => {
  switch (httpStatusCode) {
    case 400:
      return 'misc.errors.requestErrors.badRequest';
    case 401:
      return 'misc.errors.requestErrors.unauthorized';
    case 403:
      return 'misc.errors.requestErrors.forbidden';
    case 404:
      return 'misc.errors.requestErrors.notFound';
    case 408:
      return 'misc.errors.requestErrors.timeout';
    case 413:
      return 'misc.errors.requestErrors.payloadTooLarge';
    case 415:
      return 'misc.errors.requestErrors.unsupportedMediaType';
    case 429:
      return 'misc.errors.requestErrors.tooManyRequests';
    default:
      return undefined;
  }
};
