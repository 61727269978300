import { logger } from 'src/utils/datadog-log-wrapper';

export const formatCardExpirationDate = (
  cardExpDate: string | null | undefined,
) => {
  if (!cardExpDate) {
    // Some card can have a null exp date (not sure if normal)
    return '-/-';
  }
  const regex = new RegExp(/[0-9]{2}([0-9]{2})-([0-9]{2})-[0-9]{2}/);
  const matches = cardExpDate.match(regex);
  if (matches) {
    return `${matches[2]}/${matches[1]}`;
  }
  logger.warn('no match to display the subscription date properly', {
    scope: 'subscriptions',
    team: 'employee',
  });
  return '-/-';
};
