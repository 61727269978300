import { DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';

import { Map } from 'src/core/common/components/Map';
import { PanelCard, PanelItemsSection } from 'src/core/common/components/Panel';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';

import type { Payable } from '../../containers/PayablePanelContainer';
import { type PayableType } from '../../models/payable';

type Props = {
  payable: Payable;
};

export const MileageSection = ({ payable }: Props) => {
  const { t, activeLanguage, localeFormat } = useTranslation('global');

  const isMileagePayable = getIsMileagePayable(payable.type);

  if (!isMileagePayable || !payable.mileageDetails) {
    return <></>;
  }

  return (
    <>
      <PanelCard
        content={
          <Map
            lang={activeLanguage}
            path={payable.mileageDetails.journey}
            isInteractive={false}
          />
        }
        items={[
          {
            label: payable.mileageDetails.departureAddress,
            icon: 'location-pin',
          },
          {
            label: payable.mileageDetails.arrivalAddress,
            icon: 'racing-flag',
          },
        ]}
      />

      <PanelItemsSection
        title={t('payables.panel.mileage.title')}
        items={[
          {
            label: t('payables.panel.mileage.vehicleType'),
            value: getReadableVehicleType(
              payable.mileageDetails.vehicle.type,
              payable.mileageDetails.vehicle.electric,
              t,
            ),
          },
          {
            label: t('payables.panel.mileage.taxHorsepower'),
            value: `${payable.mileageDetails.vehicle.taxHorsepower} ${t(
              'payables.panel.mileage.horsepower',
            )}`,
          },
          {
            label: t('payables.panel.mileage.yearTotalDistance'),
            value: payable.mileageDetails.vehicle.totalDistanceOverYear,
          },
          {
            label: t('payables.panel.mileage.journeyDate'),
            value: localeFormat(
              payable.mileageDetails.travelDate,
              DATE_FORMAT.MEDIUM,
            ),
          },
          {
            label: t('payables.panel.mileage.rate'),
            value: getReadableSchemeType(payable.mileageDetails.scheme.type, t),
          },
        ]}
      />
    </>
  );
};

const getReadableVehicleType = (
  rawVehicleType: string,
  electric: boolean,
  translator: TGlobalFunctionTyped,
): string => {
  switch (rawVehicleType) {
    case 'car':
      return translator('payables.panel.mileage.car', {
        context: electric ? 'electric' : undefined,
      });
    case 'motorcycle':
      return translator('payables.panel.mileage.motorcycle', {
        context: electric ? 'electric' : undefined,
      });
    case 'bike':
      return translator('payables.panel.mileage.bike');
    default:
      return '-';
  }
};

const getReadableSchemeType = (
  schemeType: 'french' | 'custom',
  translator: TGlobalFunctionTyped,
): string => {
  if (schemeType === 'french') {
    return translator('payables.panel.mileage.frenchRate');
  }
  return '-';
};

const getIsMileagePayable = (type: PayableType) => {
  return type === 'mileageAllowance';
};
