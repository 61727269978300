import { type ReactNode } from 'react';

import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { MODES as TOOLBAR_MODES } from '../FileViewerToolbar/FileViewerToolbar';
import PDFViewer from '../PDFViewer/PDFViewer';

type PropsForPDFViewer = {
  url: string;
  className?: string;
  withDownload?: boolean;
  withDelete?: boolean;
  withDeleteTooltip?: string | ReactNode;
  withClose?: boolean;
  isInvalid?: boolean;
  invalidProofReason?: string;
  onDownload?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
};

type Props = {
  onClose: () => void;
  fileViewerModalClassName?: string;
} & PropsForPDFViewer;

const PDFViewerModal = ({
  onClose,
  fileViewerModalClassName = '',
  ...pdfViewerProps
}: Props) => (
  <FileViewerModal
    onClose={onClose}
    className={fileViewerModalClassName}
    fullWidth
  >
    <PDFViewer
      {...pdfViewerProps}
      withClose
      onClose={onClose}
      toolbarMode={TOOLBAR_MODES.COMPACT}
    />
  </FileViewerModal>
);

export default PDFViewerModal;
