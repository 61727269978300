/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';
import React, { PureComponent } from 'react';

import Icon from 'common/components/legacy/Icon/Icon';
import Label from 'common/components/legacy/Label/Label';
import { grey5Exasperated, grey6Cheerful } from 'src/core/utils/color-palette';

import './TextInput.scss';

type Props = {
  placeholder: string;
  size: 'small' | 'medium' | 'large' | 'block';
  className: string;
  trueClassName: string;
  type: string;
  default: string;
  label?: string;
  name?: string;
  onChange: (e: any) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onCopy: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onMouseEnter: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  value?: string | number;
  defaultValue?: string | number;
  icon?: string;
  iconText?: React.ReactNode;
  iconAction: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isValid: boolean;
  hasWarning: boolean;
  iconColor: string;
  iconSide: string;
  disabled: boolean;
  prefix?: string;
  onInput?: (value: string | number) => void;
  autoComplete: string;
  autoCorrect: 'on' | 'off';
  spellCheck: boolean;
  inputStyle: object;
  min?: number;
  max?: number;
  debounceTimeout: number;
  maxLength?: number;
  labelledBy?: string;
};

type State = {
  className: string;
  defaultValueSet: boolean;
  value: string | number;
  name: string;
};

// @FIX: Refactor this over-engineered mess
class TextInput extends PureComponent<Props, State> {
  inputNode: any = null;
  debounceInput: any = null;

  static defaultProps = {
    iconSide: 'right',
    placeholder: 'Enter text...',
    size: 'medium',
    className: '',
    trueClassName: '',
    type: 'text',
    default: '',
    autoComplete: 'on',
    autoCorrect: 'on',
    spellCheck: true,
    iconColor: grey6Cheerful,
    disabled: false,
    inputStyle: {},
    onFocus: () => {},
    onClick: () => {},
    onBlur: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onChange: () => {},
    onKeyDown: () => {},
    onCopy: () => {},
    iconAction: () => {},
    isValid: true,
    hasWarning: false,
    debounceTimeout: 3000,
    labelledBy: '',
  };

  constructor(props: Props) {
    super(props);

    const { value, className, size, debounceTimeout, name } = props;

    this.state = {
      className: cx(className, size),
      value: isNil(value) ? this.props.default : value,
      defaultValueSet: false,
      name: name || uniqueId('label_'),
    };

    this.debounceInput = debounce(this.handleInput, debounceTimeout);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      className: cx(nextProps.className, nextProps.size),
    });

    if (!this.state.defaultValueSet && nextProps.default !== '') {
      this.setState({
        value: nextProps.default,
        defaultValueSet: true,
      });
    } else {
      this.setState({
        value: isNil(nextProps.value) ? '' : nextProps.value,
      });
    }
  }

  getRealValue = (value: string | number) => {
    if (this.props.type !== 'number') {
      return value;
    }
    if (value === '') {
      return null;
    }
    return Number(value);
  };

  handleInput = () => {
    const { onInput, isValid } = this.props;

    if (isFunction(onInput) && isValid) {
      onInput(this.state.value);
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({
      className: cx(this.props.className, this.props.size),
      value,
    });

    this.debounceInput(value);
    this.props.onChange(e);
  };

  setRef = (ref: any) => {
    this.inputNode = ref;
  };

  // To use these methods, get a ref first
  focus() {
    if (this.inputNode) {
      return this.inputNode.focus();
    }
  }

  blur() {
    if (this.inputNode) {
      return this.inputNode.blur();
    }
  }

  renderIcon() {
    const { icon, iconText, size, iconAction, iconColor, disabled } =
      this.props;
    if (!icon && !iconText) {
      return null;
    }

    if (icon && !iconText) {
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        type="button"
        className={size}
        onClick={iconAction}
        disabled={disabled}
      >
        <Icon icon={icon} fill={iconColor} />
      </button>;
    }

    const iconStyle = { color: iconColor || grey5Exasperated };
    if (iconAction !== noop) {
      return (
        <button
          type="button"
          className={size}
          onClick={iconAction}
          style={iconStyle}
          disabled={disabled}
        >
          {iconText}
        </button>
      );
    }
    return (
      <span className={cx('icon-text', size)} style={iconStyle}>
        {iconText}
      </span>
    );
  }

  renderInput = () => {
    const {
      iconText,
      onKeyDown,
      onFocus,
      onClick,
      onBlur,
      onCopy,
      disabled,
      min,
      type,
      hasWarning,
      max,
      defaultValue,
      autoComplete,
      autoCorrect,
      spellCheck,
      isValid,
      placeholder,
      maxLength,
      labelledBy,
    } = this.props;
    const { className, value, name } = this.state;
    const classes = cx(className, {
      'with-icon': iconText,
      invalid: !isValid,
      'has-warning': hasWarning,
    });

    return (
      <input
        id={name}
        ref={this.setRef}
        placeholder={placeholder}
        className={classes}
        type={type}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        onCopy={onCopy}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        min={min}
        max={max}
        name={name}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        spellCheck={spellCheck}
        maxLength={maxLength}
        {...(labelledBy ? { 'aria-labelledby': labelledBy } : {})}
      />
    );
  };

  renderInputBlock = () => {
    const { size, label, prefix } = this.props;
    const { name } = this.state;
    if (label) {
      return (
        <div className="text-input__inner">
          <Label htmlFor={name} className={`text-input__label ${size}`}>
            {label}
            {this.props.prefix && (
              <div className={`prefix ${size}`}>{prefix}</div>
            )}
            {this.renderInput()}
            {this.renderIcon()}
          </Label>
        </div>
      );
    }
    return (
      <div className="text-input__inner">
        {prefix && <div className={`prefix ${size}`}>{prefix}</div>}
        {this.renderInput()}
        {this.renderIcon()}
      </div>
    );
  };

  render() {
    const {
      icon,
      iconSide,
      size,
      trueClassName,
      inputStyle,
      onMouseEnter,
      onMouseLeave,
      isValid,
      hasWarning,
    } = this.props;
    const className = cx('text-input__container', size, trueClassName, {
      'icon-left': icon && iconSide === 'left',
      invalid: !isValid,
      'has-warning': hasWarning,
    });

    return (
      <div
        className={className}
        style={inputStyle}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {this.renderInputBlock()}
      </div>
    );
  }
}

export default TextInput;
