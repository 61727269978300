import {
  ListBox,
  Skeleton,
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';

import { type MileageScheme } from 'modules/requests/models/mileageScheme';
import type { LegacyRequest, RequestAPI } from 'modules/requests/types';
import { isDraftsTab, SubnavigationItem } from 'modules/requests/utils';
import { PageNoResult as PageNoResultContainer } from 'src/core/common/components/legacy/PageNoResult/PageNoResultContainer';
import { type Mode } from 'src/core/common/components/legacy/PageNoResult/mode';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { DraftRequestRow } from './DraftRequestRow';
import { RequestListRow } from './RequestRow';
import { getSection, sectionGroupByRequestType } from './utils';
import type { RequestStats, Sections } from '../requestsSectionsProps';

export type Request = (LegacyRequest & RequestAPI) & {
  mileage_scheme: MileageScheme;
};

type RequestsListBoxProps = {
  requests: Request[];
  sectionsStats: Sections<RequestStats>;
  onOptionClick: (request: Request) => void;
  activeRequest: string | undefined;
  type: SubnavigationItem | 'request';
  checkedRequests: string[];
  onRequestToggle: (requestId: string, checked: boolean) => void;
  hasFiltersApplied?: boolean;
  isLoading?: boolean;
};

export const RequestsListBox = ({
  activeRequest,
  checkedRequests,
  hasFiltersApplied,
  isLoading,
  onOptionClick,
  onRequestToggle,
  requests,
  sectionsStats,
  type,
}: RequestsListBoxProps) => {
  const user = useUser();
  const { t } = useTranslation('global');

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col bg-page-background">
        <SkeletonTable
          numberOfRows={14}
          columns={[
            {
              cell: (
                <div className="flex w-full items-center justify-between">
                  <div className="flex grow items-center">
                    <SkeletonCheckbox className="mr-s" />
                    <SkeletonAvatar
                      size="m"
                      variant="square"
                      className="mr-s"
                    />
                    <Skeleton height="var(--sizing-l)" width="60%" />
                  </div>
                  <Skeleton height="var(--sizing-l)" width="150px" />
                </div>
              ),
              header: (
                <div className="flex w-full justify-between">
                  <SkeletonText width="25%" />
                  <SkeletonText width="200px" />
                </div>
              ),
            },
          ]}
          withHeader
        />
      </div>
    );
  }

  if (requests.length === 0) {
    let mode = 'request';
    if (isDraftsTab(type)) {
      mode = 'draftRequest';
    } else if (type === SubnavigationItem.Mine) {
      mode = 'mineRequest';
    } else if (type === SubnavigationItem.ToApprove) {
      mode = 'toApproveRequest';
    }
    return (
      <PageNoResultContainer
        mode={mode as Mode}
        hasFiltersApplied={hasFiltersApplied}
      />
    );
  }

  return (
    <div className="pb-s">
      <ListBox
        getOptionId={(option) => option.id}
        options={requests}
        groupBy={(option) => sectionGroupByRequestType[option.type]}
        checkedOptionIds={checkedRequests}
        onOptionChange={(_, id, checked) => onRequestToggle(id, checked)}
        onAllOptionsChange={() => {}}
        getIsOptionActive={(option) => option.id === activeRequest}
        onOptionClick={onOptionClick}
        renderGroupedOptionsHeader={(value) => {
          const section = getSection(type, user)[value];
          const translationKey = section.translationKey;
          const sectionStats = sectionsStats[section.sectionStatsKey];
          return (
            <div className="flex justify-between">
              <span>{t(translationKey)}</span>
              <div className="flex gap-xxs">
                <span>
                  {t('misc.requestWithCount', {
                    count: sectionStats?.count,
                  })}
                </span>
                {sectionStats?.totalAmount && (
                  <>
                    <span>-</span>
                    <span>{formatMonetaryValue(sectionStats.totalAmount)}</span>
                  </>
                )}
              </div>
            </div>
          );
        }}
      >
        {(option, titleId) => {
          if (isDraftsTab(type)) {
            return <DraftRequestRow request={option} titleId={titleId} />;
          }
          return <RequestListRow request={option} titleId={titleId} />;
        }}
      </ListBox>
    </div>
  );
};
