import { colors, DATE_FORMAT, Icon, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';
import { type LegacyRequest } from 'modules/requests';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useRequestAppraisalsQuery } from './useRequestAppraisalsQuery';
import { displayFullname } from '../../utils/displayFullname';
import { MembersCheckList } from '../MembersCheckList/MembersCheckList';

type Props = {
  requestId: LegacyRequest['id'];
  hasNegativeMarginButton?: boolean;
};

export const ProgressBoxAppraisalsList = ({
  requestId,
  hasNegativeMarginButton,
}: Props) => {
  const user = useUser();
  const { localeFormat, t } = useTranslation('global');

  const appraisalsQuery = useRequestAppraisalsQuery(requestId);

  return (
    <QuerySuspense
      queryState={appraisalsQuery}
      loading={
        <div className="justify-starts mt-l flex flex-col gap-s">
          <SkeletonText width="125px" />
          <SkeletonText width="125px" />
          <SkeletonText width="125px" />
        </div>
      }
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          logContext={{
            team: 'employee',
          }}
        />
      )}
    >
      {(appraisals) => (
        <MembersCheckList
          hasNegativeMarginButton={hasNegativeMarginButton}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          members={appraisals.map((appraisals) => ({
            id: appraisals.id,
            avatar: appraisals.avatar,
            label: displayFullname({
              t,
              fullname: appraisals.fullname,
              isSelf: user.id === appraisals.id,
            }),
            icon: <Icon size="s" name="success" color={colors.success} />,
            tooltip: (
              <>
                {t('approval.progressBox.approvedTooltip')}
                <br />
                {appraisals.approvedAt
                  ? localeFormat(
                      new Date(appraisals.approvedAt),
                      DATE_FORMAT.LONG_WITH_TIME,
                    )
                  : ''}
              </>
            ),
          }))}
          withIcons={appraisals.length > 1}
        />
      )}
    </QuerySuspense>
  );
};
