import { type I18nKey } from 'src/core/common/hooks/useTranslation';

import { type ReadyToSpendTask } from '../../types';

type ReadyToStepContent = {
  title: I18nKey;
  decription: I18nKey;
  cta: I18nKey;
};

export const getContentByTaskType = (
  type: ReadyToSpendTask['type'],
): ReadyToStepContent | null => {
  switch (type) {
    case 'recurring_card':
      return {
        title: 'readyToSpend.items.recurringCard.title',
        decription: 'readyToSpend.items.recurringCard.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'single_use_card':
      return {
        title: 'readyToSpend.items.singleUseCard.title',
        decription: 'readyToSpend.items.singleUseCard.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'expense_claim':
      return {
        title: 'readyToSpend.items.expenseClaim.title',
        decription: 'readyToSpend.items.expenseClaim.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'manage_request':
      return {
        title: 'readyToSpend.items.manageRequest.title',
        decription: 'readyToSpend.items.manageRequest.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'receipt':
      return {
        title: 'readyToSpend.items.receipt.title',
        decription: 'readyToSpend.items.receipt.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'mobile_app':
      return {
        title: 'readyToSpend.items.mobileApp.title',
        decription: 'readyToSpend.items.mobileApp.description',
        cta: 'readyToSpend.items.ctas.download',
      };
    default:
      return null;
  }
};
