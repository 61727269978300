import { normalizeString } from 'common/utils/normalizeString';

import { type Option } from './option';
import {
  LIST_MARGIN,
  OPTIONS_MAX,
  OPTION_HEIGHT,
  MAX_LIST_HEIGHT,
} from './variables';

const isDigit = (keyCode: number): boolean => keyCode >= 48 && keyCode <= 57;

const isLetter = (keyCode: number): boolean => keyCode >= 65 && keyCode <= 90;

export const isAlphaNumeric = (keyCode: number): boolean =>
  isDigit(keyCode) || isLetter(keyCode);

export const computeListHeight = (nbOptionsDisplayed: number): number => {
  if (nbOptionsDisplayed > OPTIONS_MAX) {
    return MAX_LIST_HEIGHT;
  }
  if (nbOptionsDisplayed === OPTIONS_MAX) {
    return OPTION_HEIGHT * OPTIONS_MAX + LIST_MARGIN;
  }
  return nbOptionsDisplayed * OPTION_HEIGHT + LIST_MARGIN;
};

const lemmatize = (string_: string): string => {
  return normalizeString(string_);
};

export const isMatchingOption = (
  { label, sublabel = '' }: Option,
  searchValue: string,
): boolean => {
  return (
    lemmatize(label).includes(lemmatize(searchValue)) ||
    lemmatize(sublabel).includes(lemmatize(searchValue))
  );
};
