import { Avatar, IconButton } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type SelectedApprover, type Member } from '../../../types';
import { EditApproverDropdown } from '../../EditApproverDropdown';

type Approver = Member & { isCostCenterOwner: boolean };
type Props = {
  approver: Approver;
  onDelete: () => void;
  isAllFollowingMode: boolean;
  index: number;
  onUpdateRule: (selectedApprover: SelectedApprover) => void;
  costCenterOwnerId: string;
};

export const ApproversListItem = ({
  approver,
  onDelete,
  isAllFollowingMode,
  index,
  onUpdateRule,
  costCenterOwnerId,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleApproverSelect = (selectedApprover: SelectedApprover) => {
    onUpdateRule(selectedApprover);
    setIsDropdownOpen(false);
  };

  return (
    <>
      {isDropdownOpen ? (
        <EditApproverDropdown
          costCenterOwnerId={costCenterOwnerId}
          onSelect={handleApproverSelect}
          currentApproverId={approver.id}
          onBlur={() => setIsDropdownOpen(false)}
          className="mx-xs my-[5px]"
        />
      ) : (
        <div
          key={approver.id}
          className={classNames(
            'ApproversList__item',
            isAllFollowingMode && 'ApproversList__item--all-following',
          )}
        >
          {isAllFollowingMode && (
            <div className="ApproversList__item__details__index">
              {index + 1}
            </div>
          )}
          <ApproverDisplay approver={approver} />
          <div className="ApproversList__item__icons">
            <IconButton
              iconName="pen"
              variant="borderless"
              onClick={() => {
                setIsDropdownOpen(!isDropdownOpen);
              }}
              data-testid="ApproversList__edit-approver"
            />
            <IconButton
              iconName="trash"
              variant="borderless"
              onClick={() => {
                track(
                  AnalyticEventName.APPROVALS_WORKFLOW_SETUP_DELETE_APPROVER,
                  {
                    approverId: approver.id,
                  },
                );
                onDelete();
              }}
              data-testid="ApproversList__delete-approver"
            />
          </div>
        </div>
      )}
    </>
  );
};

const ApproverDisplay = ({ approver }: { approver: Approver }) => {
  const { t } = useTranslation('global');

  const label = approver.isPending ? approver.email : approver.fullname;
  const avatar = approver.avatar ?? undefined;
  let role = null;
  if (approver.isCostCenterOwner) {
    role = t('teams.approvalFlows.costCenterOwner');
  } else if (approver.isAccountOwner) {
    role = t('profile.roleTitleAccountOwner');
  }

  return (
    <>
      <Avatar src={avatar} text={label} className="shrink-0" />
      <div className="flex items-center gap-xs">
        <div className="ApproversList__item__details__name">{label}</div>
        {role && <div className="text-neutral-dark">({role})</div>}
      </div>
    </>
  );
};
