import { Panel, PanelSection, Tag } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { PanelHeader } from 'common/components/Panel';
import { SupplierLogo } from 'common/components/SupplierLogo';
import { eligibleTypes } from 'common/components/legacy/CustomFieldsSelector/CustomFieldsSelector';
import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { BudgetOverviewReviewContainer } from 'modules/budgets/containers/BudgetOverviewReviewContainer';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { type User } from 'src/core/modules/app/hooks/useUser';
import { CancelRequestModal } from 'src/core/modules/requests/list/components/CancelRequestModal';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getEligibleCustomFields } from 'src/core/utils/custom-fields';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { DenyPurchaseOrderRequestModal } from './DenyPurchaseOrderRequestModal';
import { PurchaseOrderRequestPanelFooter } from './PurchaseOrderRequestPanelFooter';
import { PurchaseOrderRequestProgressBox } from './PurchaseOrderRequestProgressBox/PurchaseOrderRequestProgressBox';
import { PurchaseOrderRequestQuotesContainer } from './PurchaseOrderRequestQuotes';
import { RequestTimeline } from './RequestTimeline';
import { DescriptionSection, DetailsSection } from './Sections';
import { PurchaseOrderItemizationList } from '../../../creation/components/NewPurchaseOrderRequestPanel/NewPurchaseOrderRequestForm/PurchaseOrderItemizationList';
import {
  canAddQuoteToPurchaseOrderRequest,
  type CustomFieldDefinition,
} from '../../../models';
import { type PurchaseOrderRequestDetails } from '../../models';

import './PurchaseOrderRequestPanel.css';

type PurchaseOrderRequestPanelProps = {
  request: PurchaseOrderRequestDetails;
  customFields?: CustomFieldDefinition[];
  user: User;
  onClose: () => void;
  onApprove: (request: PurchaseOrderRequestDetails, options: object) => void;
  onDeny: (
    requestId: string,
    reason: string,
    updateLocally: boolean,
    callback: (response: Response) => void,
  ) => void;
  fetchRequest: () => Promise<void>;
};

export const PurchaseOrderRequestPanel = ({
  customFields,
  user,
  request,
  onClose,
  onApprove,
  onDeny,
  fetchRequest,
}: PurchaseOrderRequestPanelProps) => {
  const { t } = useTranslation('global');

  const [isEditingPurchaseOrder, setIsEditingPurchaseOrder] = useState(false);
  const isPOItemizationFeatureEnabled = useFeature(
    FEATURES.TMP_PURCHASE_ORDER_ITEMIZATION,
  );
  const handleApprove = async (): Promise<void> => {
    await onApprove(request, { updateLocally: false });
    await fetchRequest();
  };

  const handleDeny = async (reason: string): Promise<void> => {
    await onDeny(request.id, reason, true, async (response: Response) => {
      if (response.status === 200) {
        await fetchRequest();
      }
    });
  };

  const [denyPurchaseOrderRequestModal, showDenyPurchaseOrderRequestModal] =
    useModal(({ isOpen, onClose: onCloseDenyModal }) => (
      <DenyPurchaseOrderRequestModal
        isOpen={isOpen}
        onClose={onCloseDenyModal}
        onDeny={handleDeny}
      />
    ));

  const [cancelPurchaseOrderRequestModal, showCancelPurchaseOrderRequestModal] =
    useModal(({ isOpen, onClose: onCloseCancelModal }) => (
      <CancelRequestModal
        isOpen={isOpen}
        requestId={request.id}
        onCancel={onCloseCancelModal}
        onConfirmSuccess={async () => {
          onCloseCancelModal();
          onClose();
        }}
      />
    ));

  const { amount, team, quotes, supplier, id } = request;
  const eligibleCustomFields: CustomFieldDefinition[] = getEligibleCustomFields(
    customFields,
    {
      types: [eligibleTypes.REQUEST],
      teamIds: [team?.id],
      user,
    },
  );

  const canAddQuotes = canAddQuoteToPurchaseOrderRequest(request, user);
  return (
    <>
      <div className={request.deletedAt ? 'panelDisabled' : ''} />
      <Panel
        title={t('requests.panel.purchaseOrder.purchaseOrder')}
        header={
          <PanelHeader
            top={
              <h3 className="PurchaseOrderRequestPanel__supplier title-l">
                <SupplierLogo
                  name={supplier.name}
                  src={supplier.thumbnailUrl}
                  className="mr-xs"
                />
                <span>{supplier.name}</span>
              </h3>
            }
            textPrimary={formatMonetaryValue(amount)}
            textSecondary={
              request.netAmount &&
              t('requests.panel.purchaseOrder.amountPanelExclTaxes', {
                amount: formatMonetaryValue(request.netAmount),
              })
            }
          >
            <PurchaseOrderRequestProgressBox
              key={request.id}
              className="mb-l"
              request={request}
            />
            <Tag variant="neutral">
              {t('requests.panel.purchaseOrder.purchaseOrder')}
            </Tag>
          </PanelHeader>
        }
        footer={
          <PurchaseOrderRequestPanelFooter
            request={request}
            user={user}
            onApprove={handleApprove}
            onCancel={showCancelPurchaseOrderRequestModal}
            onDeny={showDenyPurchaseOrderRequestModal}
            isDisabled={isEditingPurchaseOrder}
          />
        }
        onClose={onClose}
      >
        <PurchaseOrderRequestQuotesContainer
          canAddQuotes={canAddQuotes}
          quotes={quotes}
          requestId={id}
          fetchRequest={fetchRequest}
        />

        {isPOItemizationFeatureEnabled &&
          request.items &&
          request.items.length > 0 && (
            <PurchaseOrderItemizationList items={request.items} />
          )}

        <DescriptionSection
          request={request}
          setIsEditingPurchaseOrder={setIsEditingPurchaseOrder}
          fetchRequest={fetchRequest}
        />
        <DetailsSection
          request={request}
          customFields={eligibleCustomFields}
          setIsEditingPurchaseOrder={setIsEditingPurchaseOrder}
          fetchRequest={fetchRequest}
        />

        {request.state === 'pending' &&
          request.costCenter &&
          (user.is_approver || user.is_account_owner) && (
            <BudgetOverviewReviewContainer
              costCenter={request.costCenter}
              expenseCategory={undefined} // we don't display expense category in the budget overview because it's being displayed in the DetailsSection
              amount={request.netAmount}
              requestType="po"
            />
          )}
        <PanelSection
          title={t('requests.panel.purchaseOrder.requestHistory')}
          isCollapsible
          isDefaultCollapsed
          onExtended={() => {
            track(AnalyticEventName.REQUEST_TIMELINE_OPENED, {
              requestType: 'purchase-order',
            });
          }}
        >
          <RequestTimeline request={request} />
        </PanelSection>
      </Panel>
      {denyPurchaseOrderRequestModal}
      {cancelPurchaseOrderRequestModal}
    </>
  );
};
