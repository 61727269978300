import compact from 'lodash/compact';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';

export const serializeRawFilter = (type, filter) => {
  if (!filter) {
    return null;
  }
  const rawValue =
    isString(filter) || Array.isArray(filter) ? filter : filter.value;
  if (!rawValue) {
    return null;
  }

  const value = map(Array.isArray(rawValue) ? rawValue : [rawValue], (v) =>
    v.key !== undefined ? v.key : v,
  );
  if (value.length === 0) {
    return null;
  }
  return { type, value };
};

/**
 * Formats raw filters from the app's state to API-understandable filters
 *
 * @param  {Object} filters={}        The raw filters to clean / format
 * @return {Object}                   API-ready request search params
 */
export const serialize = (filters = {}) =>
  compact(
    map(keys(filters), (type) => serializeRawFilter(type, filters[type])),
  );

/**
 * Formats raw filters from the URL to API-understandable filters
 *
 * @param  {Object} filters={}  The raw filters to clean / format
 * @return {Object}             API-ready request search params
 */
export const convertFiltersFromUrl = (filters = {}) => {
  const sanitizedFilters = {
    status: filters.status || null,
    group_id: filters.team || null,
    user_id: filters.payer || null,
    supplier_id: filters.supplier || null,
  };

  return omitBy(
    merge({}, filters, sanitizedFilters, { _isFromUrl: true }),
    (element) => element === null,
  );
};
