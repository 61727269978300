import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sum from 'lodash/sum';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';

import PageLoading from 'src/core/common/components/legacy/Loader/LoaderTxt';
import { PageNoResult } from 'src/core/common/components/legacy/PageNoResult';

import PaymentRow, { PAYMENT_ROW_HEIGHT } from './PaymentRow';
import PaymentsHeader, { PAYMENTS_HEADER_HEIGHT } from './PaymentsHeader';
import { mergePaymentsAndStats } from './helpers';

const LOAD_BATCH_SIZE = 60;

class PaymentsResults extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    payments: PropTypes.arrayOf(PropTypes.object),
    paymentStats: PropTypes.arrayOf(PropTypes.object),
    pageInfo: PropTypes.object,
    itemId: PropTypes.string,
    isLoading: PropTypes.bool,
    isSelectionEnabled: PropTypes.bool.isRequired,
    selection: PropTypes.object,
    impersonator: PropTypes.object,
    onItemClick: PropTypes.func.isRequired,
    onItemChecked: PropTypes.func.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    hasFilters: PropTypes.bool.isRequired,
    isTeamsFeatureEnabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    payments: undefined,
    paymentStats: undefined,
    pageInfo: undefined,
    itemId: undefined,
    isLoading: undefined,
    selection: undefined,
  };

  constructor(props) {
    super(props);
    this.offsetTop = 0;
    this.state = {
      selectedItem: this.props.itemId,
      rows: mergePaymentsAndStats(props.payments, props.paymentStats),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const stateChanges = {};
    let shouldRefresh = false;

    if (nextProps.payments && this.props.payments !== nextProps.payments) {
      stateChanges.rows = mergePaymentsAndStats(
        nextProps.payments,
        nextProps.paymentStats,
      );
    }

    if (nextProps.selection && this.props.selection !== nextProps.selection) {
      shouldRefresh = true;
    }

    if (!isEmpty(stateChanges)) {
      this.setState({ ...stateChanges }, this.forceRenderList());
    } else if (shouldRefresh) {
      this.forceRenderList();
    }
  }

  getResultRow = (options) => {
    const { index } = options;
    const row = this.state.rows[index];
    return this.renderRow(row, options);
  };

  getRowHeight = ({ index }) => {
    const row = this.state.rows[index];
    if (row && row.isHeader) {
      return PAYMENTS_HEADER_HEIGHT;
    }
    return PAYMENT_ROW_HEIGHT;
  };

  getLastCursor() {
    return this.props.pageInfo.endCursor;
  }

  getTotalRows() {
    const { paymentStats } = this.props;
    // Sum = all payments + 1 per header
    const totalPayments =
      sum(map(paymentStats, 'total_count')) + paymentStats.length;
    return Math.min(this.state.rows.length + LOAD_BATCH_SIZE, totalPayments);
  }

  updateStickyHeader = ({ scrollTop }) => {
    const { rows } = this.state;
    let currentSize = 0;
    let currentHeader = -1;
    forEach(rows, (_, index) => {
      currentSize += this.getRowHeight({ index });
      currentHeader = rows[index].isHeader ? rows[index] : currentHeader;
      if (currentSize >= scrollTop) {
        return false;
      }
    });
    this.setState({
      scrollTop,
      stickyHeader: currentHeader,
    });
  };

  loadMoreRows = () => {
    const { isLoading, fetchPayments } = this.props;
    if (!isLoading && this.hasMorePayments()) {
      return fetchPayments({
        first: LOAD_BATCH_SIZE,
        after: this.getLastCursor(),
      });
    }
  };

  isRowLoaded = ({ index }) => {
    return !!this.state.rows[index];
  };

  forceRenderList() {
    if (this.list) {
      this.list.recomputeRowHeights();
    }
  }

  isChecked(payment) {
    const { databaseId: id } = payment;
    const { selection } = this.props;

    if (!selection) {
      return false;
    }
    return (
      includes(selection.include, id) ||
      (selection.all && !includes(selection.exclude, id))
    );
  }

  handleRowClick(payment) {
    this.setState({ selectedItem: payment.databaseId }, this.forceRenderList);
    this.props.onItemClick(payment.databaseId);
  }

  hasMorePayments() {
    return this.props.pageInfo.hasNextPage;
  }

  renderHeader(header, options) {
    const { company } = this.props;
    const { key, style, isSticky } = options;
    const {
      month,
      year,
      total_count,
      total_amount_billed,
      total_fx_fee_amount,
    } = header;

    return (
      <PaymentsHeader
        key={key}
        style={style}
        company={company}
        month={month}
        year={year}
        nbPayments={total_count}
        totalAmount={total_amount_billed}
        totalFxFeeAmount={total_fx_fee_amount}
        isSticky={isSticky}
      />
    );
  }

  renderRow = (options) => {
    const row = this.state.rows[options.index];
    if (!row) {
      return this.renderLoadingRow(options);
    }
    if (row.isHeader) {
      return this.renderHeader(row, options);
    }
    return this.renderPayment(row, options);
  };

  renderEmptyResults() {
    return (
      <PageNoResult
        mode="payment"
        impersonator={this.props.impersonator}
        hasFiltersApplied={this.props.hasFilters}
      />
    );
  }

  renderPayment(payment, options) {
    const { key, style } = options;
    const { company, onItemChecked, isTeamsFeatureEnabled } = this.props;
    const { databaseId: id } = payment;
    const active = this.state.selectedItem === id;

    return (
      <PaymentRow
        key={key}
        payment={payment}
        company={company}
        active={active}
        canBeChecked={this.props.isSelectionEnabled}
        checked={this.isChecked(payment)}
        style={style}
        scrollTop={this.offsetTop - this.state.scrollTop}
        onClick={() => this.handleRowClick(payment)}
        onCheck={(newChecked) => onItemChecked(newChecked, id)}
        index={options.index}
        isTeamsFeatureEnabled={isTeamsFeatureEnabled}
      />
    );
  }

  renderLoadingRow(options) {
    const { key, style } = options;
    return (
      <div
        key={key}
        style={style}
        className="l-row--payment l-row--payment-loading"
      >
        <div className="avatar" />
        <div className="details-left">
          <div className="username" />
          <div className="supplier-logo" />
          <div className="supplier-description" />
        </div>
        <div className="details-right">
          <div className="amount" />
          <div className="date" />
        </div>
      </div>
    );
  }

  renderStickyHeader() {
    const { stickyHeader } = this.state;
    if (!stickyHeader) {
      return;
    }
    return this.renderHeader(stickyHeader, { isSticky: true });
  }

  render() {
    const { isLoading } = this.props;
    const { rows } = this.state;

    if (rows.length === 0) {
      if (isLoading) {
        return <PageLoading />;
      }
      return this.renderEmptyResults();
    }

    // FIXME: We really shouldn't use findDOMNode
    /* eslint-disable react/no-find-dom-node */
    return (
      <div>
        {this.renderStickyHeader()}
        <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={this.loadMoreRows}
          rowCount={this.getTotalRows()}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  ref={(ref) => {
                    if (!this.list) {
                      const domElement = ReactDOM.findDOMNode(ref);
                      if (domElement) {
                        this.offsetTop =
                          domElement.getBoundingClientRect().y || 0;
                      }
                    }
                    this.list = ref;
                    return registerChild(ref);
                  }}
                  height={height}
                  width={width}
                  rowCount={this.getTotalRows()}
                  rowHeight={this.getRowHeight}
                  rowRenderer={this.renderRow}
                  onRowsRendered={onRowsRendered}
                  onScroll={this.updateStickyHeader}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

export default PaymentsResults;
