import { Button, Modal, TextArea } from '@dev-spendesk/grapes';
import { type TFunction, type i18n } from 'i18next';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import noop from 'lodash/noop';
import size from 'lodash/size';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { type Company } from 'modules/app/hooks/useCompany';
import { type User } from 'modules/app/hooks/useUser';
import { companyAPI } from 'src/core/api/axios';
import { TableActions } from 'src/core/common/components/legacy/TableLegacy';
import { routes, routeFor } from 'src/core/constants/routes';
import {
  NotificationType,
  type PushNotif,
} from 'src/core/modules/app/notifications';
import {
  isDraftsTab,
  canCancelRequest,
  isRequestApprovable,
  type RequestAPI,
  type LegacyRequest,
} from 'src/core/modules/requests';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getFilenameFromContentDispositionHeader } from 'src/core/utils/contentDisposition';
import { downloadFromBlob } from 'src/core/utils/fileDownloader';
import { logger } from 'src/utils/datadog-log-wrapper';

import { type DraftRequest } from '../../draftRequest.type';
import { isDraftRequest } from '../../utils/requestUtils';

type Props = {
  user: User;
  company: Company;
  history: {
    push: (path: string) => void;
  };
  impersonator: { id: string } | null;
  type: string;
  requests: (LegacyRequest & RequestAPI)[];
  selectedRequests: string[];
  requestActions: {
    approveRequest: (
      request: { id: string },
      options: {
        updateLocally: boolean;
        callback: () => void;
      },
    ) => void;
    denyRequest: (
      requestId: string,
      reason: string,
      updateLocally: boolean,
      callback: () => void,
    ) => void;
    cancelRequest: (requestId: string, callback: () => void) => Promise<void>;
    removeRequestLocally: (requestId: string) => void;
    removeDraftRequestLocally: (draftRequest: DraftRequest) => void;
    updateRequestLocally: (request: { id: string }) => void;
  };
  isPanelOpen: boolean;
  toggleAllItems: (open: boolean) => void;
  pushNotif: PushNotif;
  cancelRequestFailureStats: {
    exportedScheduledPaymentsCount: number;
    paidScheduledPaymentsCount: number;
    wiretransferRequestsCount: number;
    transferScheduleCount: number;
  };
  resetRequestFailure: () => void;
  t: TFunction<'global'>;
  i18n: i18n;
  tReady: boolean;
};

type State = {
  denyReason: string;
  isConfirmModalOpen: boolean;
  leftActionFailed: boolean;
  leftActionToConfirmTarget: null | string;
  leftActionToConfirmElligibleRequests: string[];
  leftActionToConfirmCallback: () => void;
  leftActionToConfirmProps: {
    title: string;
    textYes: string;
    content: string;
  } | null;
  leftActionToConfirmHandler: () => void;
};
class RequestsActions extends Component<Props, State> {
  static defaultProps = {
    type: 'all',
    requests: [],
    selectedRequests: undefined,
    isPanelOpen: undefined,
    impersonator: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isConfirmModalOpen: false,
      leftActionFailed: false,
      leftActionToConfirmTarget: null,
      leftActionToConfirmElligibleRequests: [],
      leftActionToConfirmCallback: noop,
      leftActionToConfirmProps: null,
      leftActionToConfirmHandler: noop,
      denyReason: '',
    };
  }

  renderCancelContent = (eligibleRequests: string[]) => {
    const { t, requests, selectedRequests } = this.props;
    const requestsMap = new Map(
      requests.map((request) => [request.id, request]),
    );

    const hasSelectedInvoiceRequest = selectedRequests.some(
      (selectedRequest) => {
        const request = requestsMap.get(selectedRequest);
        if (request) {
          return request.type === 'invoice';
        }
        return false;
      },
    );

    const hasSelectedExpenseRequest = selectedRequests.some(
      (selectedRequest) => {
        const request = requestsMap.get(selectedRequest);
        if (request) {
          return [
            'expense',
            'per_diem_allowance',
            'mileage_allowance',
          ].includes(get(request, 'type'));
        }
        return false;
      },
    );

    return (
      <>
        <p>
          {t('requests.actions.cancelContent', {
            count: size(eligibleRequests),
          })}
        </p>
        {(hasSelectedExpenseRequest || hasSelectedInvoiceRequest) && (
          <strong>{t('requests.actions.cancellationWarning')}</strong>
        )}
        {hasSelectedExpenseRequest && (
          <p>{t('requests.actions.cancelExpenseClaims')}</p>
        )}
        {hasSelectedInvoiceRequest && (
          <p>{t('requests.actions.cancelInvoiceRequests')}</p>
        )}
      </>
    );
  };

  downloadRequests = async (requestIds: string[]) => {
    const { data, headers } = await companyAPI.post(
      '/requests/download',
      { requestIds },
      {
        companyId: this.props.company.id,
        responseType: 'blob',
      },
    );

    // Due to CORS issue content-disposition is not always passed by the server.
    // So to force the extension to be a csv, hardcode the filename.
    const filename =
      getFilenameFromContentDispositionHeader(headers['content-disposition']) ||
      'requests.csv';

    downloadFromBlob(data, filename);
  };

  archiveRequest = async (requestId: string) => {
    try {
      const { data } = await companyAPI.put(`/requests/${requestId}/archive`, {
        companyId: this.props.company.id,
      });
      this.props.requestActions.removeRequestLocally(data);
      return data;
    } catch {
      logger.warn(`[BULK] failed to archive request ${requestId}`, {
        scope: 'requests::actions::bulk',
        team: 'employee',
      });
      return false;
    }
  };

  deleteDraftRequest = async (requestId: string) => {
    try {
      const { data } = await companyAPI.delete(`/drafts/${requestId}`, {
        companyId: this.props.company.id,
      });
      this.props.requestActions.removeDraftRequestLocally(data);
      return data;
    } catch {
      logger.warn(`[BULK] failed to delete draft request ${requestId}`, {
        scope: 'requests::actions::bulk',
        team: 'employee',
      });
      return false;
    }
  };

  onLeftAction = (action: string, eligibleRequests: string[], done = noop) => {
    const { t, type, requestActions } = this.props;
    const actionsConfirms = {
      deny: {
        title: t('requests.actions.bulkDenyTitle', {
          count: size(eligibleRequests),
        }),
        content: t('requests.actions.bulkDenySubtitle', {
          count: size(eligibleRequests),
        }),
      },
      cancel: {
        title: t('misc.cancelTitle'),
        content: this.renderCancelContent(eligibleRequests),
        textYes: t('requests.actions.confirmCancel'),
      },
      delete: {
        title: t('requests.actions.deleteTitle', {
          count: size(eligibleRequests),
        }),
        content: t('requests.actions.deleteContent', {
          count: size(eligibleRequests),
        }),
      },
    };

    const { approveRequest, denyRequest, cancelRequest } = requestActions;
    const actionsFunctions = {
      approve: (request: string) =>
        approveRequest(
          { id: request },
          {
            updateLocally: true,
            callback: () => this.props.toggleAllItems(false),
          },
        ),
      deny: (requestId: string, callback: () => void) =>
        denyRequest(requestId, this.state.denyReason, true, callback),
      cancel: cancelRequest,
      archive: this.archiveRequest,
      delete: this.deleteDraftRequest,
      download: this.downloadRequests,
    };

    const actionFunction = get(actionsFunctions, action);
    if (!actionFunction) {
      return done();
    }

    const triggerAction = () => {
      track(AnalyticEventName.REQUEST_BULK_ACTION, {
        type: action,
        number: (eligibleRequests ?? []).length,
      });
      this.bulkAction(action, eligibleRequests, actionFunction, () => {
        // Go back to the listing
        this.props.history.push(
          routeFor(routes.REQUESTS.path, {
            company: this.props.company.id,
            type,
          }),
        );
        // Disabled the action CTA loader
        done();
      });
    };

    const actionConfirm = get(actionsConfirms, action);
    if (!actionConfirm) {
      return triggerAction();
    }

    // Confirm action needed, open confirm modal
    this.setState({
      isConfirmModalOpen: true,
      leftActionToConfirmTarget: action,
      leftActionToConfirmElligibleRequests: eligibleRequests,
      leftActionToConfirmCallback: done,
      leftActionToConfirmProps: actionConfirm,
      leftActionToConfirmHandler: actionFunction,
    });
  };

  onRightAction = (action: string) => {
    const { company, history, type } = this.props;

    switch (action) {
      case 'new':
        track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, {
          from: 'filter_bar',
        });
        return history.push(
          routeFor(routes.REQUESTS.path, {
            company: company.id,
            id: 'new',
            type,
          }),
        );
      default:
    }
  };

  bulkAction = async (
    actionType: string | null,
    eligibleRequests: string[] = [],
    function_: (
      requests: string[] | string,
      callback?: (response: Response) => void,
    ) => void,
    done: () => void,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const { t, type } = this.props;

    if (!isFunction(function_) || !isFunction(done)) {
      throw new Error('No bulk action or callback given');
    }

    if (actionType === 'download') {
      try {
        await function_(eligibleRequests);
        this.props.toggleAllItems(false);
      } catch {
        this.setState({
          leftActionFailed: true,
        });
        this.props.pushNotif({
          type: NotificationType.Danger,
          message: t('requests.actions.downloadFailed'),
        });
      } finally {
        done();
      }
      return;
    }

    // Batch send actions
    let promises: Promise<{ id: string } | false | void>[];
    if (actionType === 'deny') {
      promises = map(
        eligibleRequests,
        (r) =>
          new Promise((resolve) => {
            function_(r, (response: Response) =>
              // @ts-expect-error
              response.status === 200 ? resolve(true) : resolve(false),
            );
          }),
      );
    } else if (isDraftsTab(type)) {
      promises = map(eligibleRequests, async (request) => {
        try {
          // eslint-disable-next-line @typescript-eslint/return-await
          return await function_(request);
        } catch {
          return false;
        }
      });
    } else if (actionType === 'archive') {
      promises = map(eligibleRequests, async (request) => {
        try {
          // eslint-disable-next-line @typescript-eslint/return-await
          return await function_(request);
        } catch {
          return false;
        }
      });
    } else {
      promises = map(
        eligibleRequests,
        (r) =>
          new Promise((resolve) => {
            function_(r, (res: Response) =>
              res.status === 200
                ? // eslint-disable-next-line promise/prefer-await-to-then
                  res.json().then((udpatedRequest) => resolve(udpatedRequest))
                : resolve(false),
            );
          }),
      );
    }

    // Then reconciliate, send results / stop loading / display notif
    // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
    Promise.all(promises).then((results) => {
      if (results.includes(false)) {
        this.setState({
          leftActionFailed: true,
        });
      }
      // @ts-expect-error ts seems to not understand that compact from lodash removes falsey values
      const successes: {
        id: string;
      }[] = compact(results);
      const successesCounter = size(compact(results));

      this.props.pushNotif({
        type: successesCounter
          ? NotificationType.Success
          : NotificationType.Danger,
        message: t('requests.actions.successfulWithCount', {
          count: successesCounter,
        }),
      });

      forEach(successes, (updatedRequest) =>
        includes(['cancel', 'archive', 'delete'], actionType)
          ? this.props.requestActions.removeRequestLocally(updatedRequest.id)
          : this.props.requestActions.updateRequestLocally(updatedRequest),
      );

      // eslint-disable-next-line promise/always-return
      if (successesCounter) {
        // Unselect all items once bulk action is done
        this.props.toggleAllItems(false);
      }
      done();
    });
  };

  getLeftActions = () => {
    const { type, t } = this.props;

    if (isDraftsTab(type)) {
      return {
        delete: (count: number) => t('requests.deleteWithCount', { count }),
      };
    }

    return {
      approve: (count: number) => t('requests.approveWithCount', { count }),
      deny: (count: number) => t('requests.denyWithCount', { count }),
      archive: (count: number) => t('requests.archiveWithCount', { count }),
      cancel: (count: number) => t('requests.cancelWithCount', { count }),
      download: (count: number) => t('requests.downloadWithCount', { count }),
    };
  };

  isItemEligibleForAction = (
    request: LegacyRequest & RequestAPI,
    action: string,
  ) => {
    if (!request) {
      return false;
    }
    const { user } = this.props;

    switch (action) {
      case 'approve':
      case 'deny': {
        return isRequestApprovable(request);
      }
      case 'archive':
        return includes(
          ['refused', 'expired', 'cancelled', 'rejected_by_controller'],
          request.state,
        );
      case 'cancel':
        return canCancelRequest(request, user);
      case 'delete':
        return isDraftRequest(request);
      case 'download':
        return true;
      default:
        return false;
    }
  };

  renderCancelFailureModal = () => {
    const {
      resetRequestFailure,
      cancelRequestFailureStats: {
        exportedScheduledPaymentsCount,
        paidScheduledPaymentsCount,
        wiretransferRequestsCount,
        transferScheduleCount,
      },
      t,
    } = this.props;
    const { leftActionFailed } = this.state;

    const hasNonCancellableRequests =
      exportedScheduledPaymentsCount +
        paidScheduledPaymentsCount +
        wiretransferRequestsCount +
        transferScheduleCount >
      0;

    if (hasNonCancellableRequests && leftActionFailed) {
      const resetStates = () => {
        resetRequestFailure();
        this.setState({
          leftActionFailed: false,
        });
      };
      return (
        <Modal
          isOpen
          iconName="warning"
          iconVariant="warning"
          onClose={resetStates}
          title={t('requests.actions.failureCancel.title')}
          subtitle={t('requests.actions.failureCancel.content')}
          actions={[]}
        />
      );
    }
    return null;
  };

  renderConfirmModal = () => {
    const {
      isConfirmModalOpen,
      leftActionToConfirmTarget,
      leftActionToConfirmElligibleRequests,
      leftActionToConfirmCallback,
      leftActionToConfirmProps,
      leftActionToConfirmHandler,
    } = this.state;

    if (!isConfirmModalOpen) {
      return null;
    }

    const { t, company, type } = this.props;
    const triggerAction = () => {
      this.bulkAction(
        leftActionToConfirmTarget,
        leftActionToConfirmElligibleRequests,
        leftActionToConfirmHandler,
        () => {
          track(AnalyticEventName.REQUEST_BULK_ACTION, {
            type: leftActionToConfirmTarget ?? 'unknown',
            number: (leftActionToConfirmElligibleRequests ?? []).length,
          });
          // Go back to the listing
          this.props.history.push(
            routeFor(routes.REQUESTS.path, { company: company.id, type }),
          );
          // Disabled the action CTA loader
          leftActionToConfirmCallback();
        },
      );
    };

    const onChoose = (result: boolean) => {
      this.setState({
        isConfirmModalOpen: false,
        leftActionToConfirmTarget: null,
        leftActionToConfirmElligibleRequests: [],
        leftActionToConfirmCallback: noop,
        leftActionToConfirmProps: null,
      });
      return result ? triggerAction() : leftActionToConfirmCallback();
    };

    const isDenyAction = leftActionToConfirmTarget === 'deny';
    return (
      <Modal
        isOpen
        title={leftActionToConfirmProps?.title ?? ''}
        subtitle={leftActionToConfirmProps?.content ?? ''}
        iconName="warning"
        iconVariant="alert"
        actions={[
          <Button
            key="cancel"
            variant="secondary"
            onClick={() => {
              onChoose(false);
            }}
            text={t('misc.goBack')}
          />,
          <Button
            key="confirm"
            variant="alert"
            onClick={() => {
              onChoose(true);
            }}
            isDisabled={isDenyAction && !this.state.denyReason}
            text={leftActionToConfirmProps?.textYes ?? t('misc.yes')}
          />,
        ]}
      >
        {isDenyAction && (
          <TextArea
            onChange={(event) =>
              this.setState({ denyReason: event.target.value })
            }
            name="deny_motif"
            placeholder={t('requests.denyPlaceholder')}
            value={this.state.denyReason}
          />
        )}
      </Modal>
    );
  };

  render() {
    const {
      user,
      isPanelOpen,
      requests,
      selectedRequests,
      t,
      impersonator,
      type,
    } = this.props;
    const rightActions =
      user.is_requester && !impersonator
        ? { new: () => t('requests.new') }
        : {};

    return (
      <div>
        {this.renderConfirmModal()}
        {this.renderCancelFailureModal()}
        <TableActions
          leftActions={this.getLeftActions()}
          rightActions={rightActions}
          items={requests}
          selectedItems={selectedRequests}
          selectedItemsCounter={size(selectedRequests)}
          getBulkSelectedTemplate={(count: number) =>
            this.props.t('requests.actions.nRequestsSelected', { count })
          }
          isItemEligibleForAction={this.isItemEligibleForAction}
          isPanelOpen={isPanelOpen}
          toggleAllItems={this.props.toggleAllItems}
          onLeftAction={this.onLeftAction}
          onRightAction={this.onRightAction}
          shouldHideTheAllCheckbox={
            isDraftsTab(type) && user.is_controller && !user.is_requester
          }
        />
      </div>
    );
  }
}

export default withTranslation('global')(RequestsActions);
