import { Callout, Link, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import compact from 'lodash/compact';
import noop from 'lodash/noop';
import replace from 'lodash/replace';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { ICONS } from 'src/core/common/components/legacy/Icon/Icon';

import FileViewerToolbar, {
  MODES,
} from '../FileViewerToolbar/FileViewerToolbar';
import ToolbarAction from '../FileViewerToolbar/ToolbarAction';
import InvalidProofBanner from '../InvalidProofBanner/InvalidProofBanner';

import './PDFViewer.scss';

class PDFViewer extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    toolbarMode: PropTypes.oneOf(values(MODES)),
    withDownload: PropTypes.bool,
    withDelete: PropTypes.bool,
    withDeleteTooltip: PropTypes.string,
    withClose: PropTypes.bool,
    isInvalid: PropTypes.bool,
    invalidProofReason: PropTypes.string,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    toolbarMode: null,
    withDownload: false,
    withDelete: false,
    withDeleteTooltip: '',
    withClose: false,
    isInvalid: false,
    invalidProofReason: null,
    onDownload: noop,
    onDelete: noop,
    onClose: noop,
  };

  getLeftActions = () => {
    const {
      t,
      withDownload,
      withDeleteTooltip,
      onDownload,
      withDelete,
      onDelete,
      withClose,
    } = this.props;

    const downloadAction = withDownload ? (
      <ToolbarAction
        key="download"
        onClick={onDownload}
        text={t('misc.download')}
        icon={ICONS.DOWNLOAD}
      />
    ) : null;

    if (withClose) {
      return compact([
        withDelete && (
          <Tooltip
            key="delete"
            content={withDeleteTooltip}
            placement="right"
            maxWidth={544}
            isDisabled={withDeleteTooltip === ''}
          >
            <ToolbarAction
              onClick={onDelete}
              text={t('misc.delete')}
              icon={ICONS.DELETE}
              disabled={withDeleteTooltip !== ''}
            />
          </Tooltip>
        ),
        downloadAction,
      ]);
    }

    return compact([downloadAction]);
  };

  getRightActions = () => {
    const { t, withDelete, withDeleteTooltip, onDelete, withClose, onClose } =
      this.props;

    if (withClose) {
      return [
        <ToolbarAction
          key="close"
          onClick={onClose}
          text={t('misc.close')}
          icon={ICONS.CLOSE}
        />,
      ];
    }

    return compact([
      withDelete && (
        <Tooltip
          key="delete"
          content={withDeleteTooltip || ''}
          placement="right"
          maxWidth={544}
          isDisabled={withDeleteTooltip === ''}
        >
          <ToolbarAction
            onClick={onDelete}
            text={t('misc.delete')}
            icon={ICONS.DELETE}
          />
        </Tooltip>
      ),
    ]);
  };

  render() {
    const { url, className, toolbarMode, isInvalid, invalidProofReason } =
      this.props;
    const downloadLink = replace(url, /\/view/, '');

    return (
      <div className={cx('LegacyPDFViewer', className)}>
        <FileViewerToolbar
          leftActions={this.getLeftActions()}
          rightActions={this.getRightActions()}
          mode={toolbarMode}
        />
        {isInvalid && <InvalidProofBanner reason={invalidProofReason} />}
        <object
          key={url} // force the re-render of the HTML tag (hide the error if the new url is valid)
          data={url}
          // !! Do not add the type here it makes some versions of Safari explorer crash https://discussions.apple.com/thread/252247740
          // type="application/pdf"
          className="LegacyPDFViewer__iframe"
        >
          <Callout
            variant="alert"
            className="LegacyPDFViewer__iframe-error"
            title={
              <Trans i18nKey="fileViewer.errorViewer">
                <Link
                  href={downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  -
                </Link>
              </Trans>
            }
          />
        </object>
      </div>
    );
  }
}

export default withTranslation()(PDFViewer);
