import get from 'lodash/get';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import reduce from 'lodash/reduce';
import replace from 'lodash/replace';
import set from 'lodash/set';
import size from 'lodash/size';
import trim from 'lodash/trim';

import {
  SEPACountries,
  IBANCountries,
} from "src/core/config/country";
import type { CurrenciesKey } from "src/core/config/money";

enum BankFields {
  AccountHolderName = 'account_holder_name',
  Iban = 'iban',
  BicSwift = 'bic_swift',
  SortCode = 'sort_code',
  AccountCode = 'account_code',
  AccountNumber = 'account_number',
  RoutingNumber = 'routing_number',
}

/**
 * Trims input string, removes whitespaces, dashes (-), underscores (_) and dots (.) characters, then convert the
 * whole string to uppercase and returns the result.
 * Note that the Lodash's methods we are using here always call `toString` before manipulating the input value, so
 * `null` and `undefined` will return an empty string and numbers will be converted to strings.
 * @param {string} str String to sanitize
 * @return {string} Sanitized string
 */
const sanitizeBankInfo = (string_: string): string =>
  replace(trim(string_), /[\s-_.]+/g, '').toUpperCase();

/**
 * Returns the alpha2 country code of a given IBAN.
 *
 * @param {String} iban Input IBAN to search country in
 * @returns {String|null} The found alpha2 country code or null if not found
 */
const getCountryCodeFromIban = (iban?: string): string | null => {
  if (!isString(iban)) {
    return null;
  }
  const inputCountryCode = iban.slice(0, 2);
  const formattedCountryCode = inputCountryCode.toUpperCase();
  return IBANCountries[formattedCountryCode] ? formattedCountryCode : null;
};

/**
 * Validates the format of a given IBAN.
 * IBAN format depends on the country but starts with a prefix and has a fixed length.
 * Source: https://www.iban.com/structure
 * Source: https://bank.codes/iban/structure/
 * @param {string} value The raw IBAN string data, without formatting
 * @return {boolean}
 */
const isIbanValid = (value?: string): boolean => {
  const countryCode = getCountryCodeFromIban(value);

  if (!countryCode) {
    return false;
  }

  const match = get(IBANCountries, countryCode, null);

  return match
    ? Boolean(
        value &&
          (value ?? '').startsWith(match.prefix) &&
          size(value) === match.size,
      )
    : false;
};

/**
 * Validates a given UK bank Sort Code.
 * Sort code should be 6 digits exactly.
 * Source: https://www.iban.com/country/united-kingdom
 * Source: https://bank.codes/iban/structure/united-kingdom/
 * @param {string} value The raw UK bank Sort Code string data, without formatting
 * @return {boolean}
 */
const isSortCodeValid = (value?: string): boolean =>
  Boolean(value && /^\d{6}$/.test(value));

/**
 * Validates a given US Account Code (i.e. an account number).
 * Account number for US should be 6 to 12 digits.
 * Source: https://www.quora.com/How-long-are-US-bank-account-numbers
 * Source: https://www.usbank.com/bank-accounts/checking-accounts/checking-customer-resources/aba-routing-number.html
 *
 * Update: a client came up with a 26 digits account number. So we upgraded in DB to 255 max
 * and update inch per inch here
 * Update: a client came up with a 7 digits account number. Some online resources claim thar account number could be 6 chars only
 * @param {string} value The raw US Account Code string data, without formatting
 * @return {boolean}
 */
const isAccountCodeValid = (value?: string): boolean =>
  Boolean(value && /^\d{6,26}$/.test(value));

/**
 * Validate a given bank Account Number.
 * Account number for UK should be 8 to 17 digits.
 * Account number for Mexico can up to 18 digits.
 * Account number for Canada goes from 7 digits.
 * Source: https://www.iban.com/country/united-kingdom
 * Source: https://bank.codes/iban/structure/united-kingdom/
 * Source: https://en.wikipedia.org/wiki/CLABE
 * @param {string} value The raw bank Account Number string data, without formatting
 * @return {boolean}
 */
// TODO@AccountNumber: perform a validation specific to the country, this is causing issues
// + reimplement UT (search for TODO@AccountNumber)
// https://github.com/Spendesk/app-desktop/pull/3368
const isAccountNumberValid = (_?: string): true => true;

/**
 * Validate a given US bank Routing Number.
 * Routing number for US should be 9 digits.
 * Source: https://en.wikipedia.org/wiki/ABA_routing_transit_number
 * @param {string} value The raw US bank Routing Number string data, without formatting
 * @return {boolean}
 */
const isRoutingNumberValid = (value?: string): boolean =>
  Boolean(value && /^\d{9}$/.test(value));

/**
 * Validate a given BIC/SWIFT bank code.
 * For simplicity, we consider it valid if it's a suite of 8 or 11 alphanumeric characters.
 * Source: https://en.wikipedia.org/wiki/ISO_9362
 * @param {*} value The raw BIC/SWICFT string data, without formatting
 */
const isBicSwiftValid = (value?: string): boolean =>
  Boolean(
    // eslint-disable-next-line security/detect-unsafe-regex
    value && /^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/.test(value),
  );

/**
 * Validate a given Account holder name.
 * Account holder name should only contain alphanumeric characters, spaces, dots and dashes.
 * @param {*} value The raw Account holder name string data.
 */
const isAccountHolderNameValid = (value?: string): boolean =>
  Boolean(value && /^[a-z0-9 .-]+$/i.test(value));

/**
 * Country code where the user only needs to provide the account number and swift
 */
const countriesWithAccountAndSwift = [
  'CN',
  'MY',
  'AU',
  'JP',
  'BR',
  'MA',
  'HK',
  'SG',
];

/**
 * Country code where the user only needs to provide the IBAN and swift
 */
const countriesWithIbanAndSwift = ['IL', 'RS', 'TN'];

const getBankInfoFieldsCombo = (
  companyCountry: string | null,
  bankCountry: string | null,
  companyCurrency: CurrenciesKey,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): BankFields[] => {
  const GB = 'GB';
  const US = 'US';
  const CN = 'CN';
  const IL = 'IL';

  if (companyCurrency === 'GBP' && bankCountry === GB) {
    return [
      BankFields.AccountHolderName,
      BankFields.SortCode,
      BankFields.AccountNumber,
    ];
  }

  if (companyCountry === GB) {
    if (bankCountry === GB) {
      return [
        BankFields.AccountHolderName,
        BankFields.SortCode,
        BankFields.AccountNumber,
      ];
    }
    if (bankCountry === US) {
      return [
        BankFields.AccountHolderName,
        BankFields.RoutingNumber,
        BankFields.AccountCode,
        BankFields.BicSwift,
      ];
    }
    return [BankFields.AccountHolderName, BankFields.Iban, BankFields.BicSwift];
  }

  if (companyCountry === US) {
    if (bankCountry === US) {
      return [
        BankFields.AccountHolderName,
        BankFields.RoutingNumber,
        BankFields.AccountCode,
        BankFields.BicSwift,
      ];
    }
    if (bankCountry === CN) {
      return [
        BankFields.AccountHolderName,
        BankFields.AccountNumber,
        BankFields.BicSwift,
      ];
    }
    if (bankCountry === IL) {
      return [
        BankFields.AccountHolderName,
        BankFields.Iban,
        BankFields.BicSwift,
      ];
    }
    if (includes(SEPACountries, bankCountry)) {
      return [
        BankFields.AccountHolderName,
        BankFields.Iban,
        BankFields.BicSwift,
      ];
    }
  }

  if (bankCountry === US) {
    return [
      BankFields.AccountHolderName,
      BankFields.RoutingNumber,
      BankFields.AccountCode,
      BankFields.BicSwift,
    ];
  }

  if (bankCountry && countriesWithAccountAndSwift.includes(bankCountry)) {
    return [
      BankFields.AccountHolderName,
      BankFields.AccountNumber,
      BankFields.BicSwift,
    ];
  }

  if (bankCountry && countriesWithIbanAndSwift.includes(bankCountry)) {
    return [BankFields.AccountHolderName, BankFields.Iban, BankFields.BicSwift];
  }

  if (includes(SEPACountries, bankCountry)) {
    return [BankFields.AccountHolderName, BankFields.Iban, BankFields.BicSwift];
  }

  // By default we should the account number / BIC fields
  return [
    BankFields.AccountHolderName,
    BankFields.AccountNumber,
    BankFields.BicSwift,
  ];
};

type BankInfoErrors = {
  [BankFields.Iban]: boolean;
  [BankFields.BicSwift]: boolean;
  [BankFields.SortCode]: boolean;
  [BankFields.AccountCode]: boolean;
  [BankFields.AccountNumber]: boolean;
  [BankFields.RoutingNumber]: boolean;
  [BankFields.AccountHolderName]: boolean;
};

type BankInfoErrorsInput = {
  [BankFields.Iban]: string | null;
  [BankFields.BicSwift]: string | null;
  [BankFields.SortCode]: string | null;
  [BankFields.AccountCode]: string | null;
  [BankFields.AccountNumber]: string | null;
  [BankFields.RoutingNumber]: string | null;
  [BankFields.AccountHolderName]: string | null;
};

const getBankInfoErrors = (
  values: Partial<BankInfoErrorsInput> = {},
  companyCountry: string | null,
  bankCountry: string | null,
  companyCurrency: CurrenciesKey,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Partial<BankInfoErrors> | undefined => {
  const fieldsCombo = getBankInfoFieldsCombo(
    companyCountry,
    bankCountry,
    companyCurrency,
  );
  if (!fieldsCombo.length) {
    return undefined;
  }

  const errors = reduce(
    fieldsCombo,
    (errs, field) => {
      const value = values[field];
      if (
        !value ||
        (field === BankFields.Iban && !isIbanValid(value)) ||
        (field === BankFields.SortCode && !isSortCodeValid(value)) ||
        (field === BankFields.AccountCode && !isAccountCodeValid(value)) ||
        (field === BankFields.AccountNumber && !isAccountNumberValid(value)) ||
        (field === BankFields.RoutingNumber && !isRoutingNumberValid(value)) ||
        (field === BankFields.BicSwift && !isBicSwiftValid(value))
      ) {
        set(errs, field, true);
      }
      return errs;
    },
    {},
  );

  if (Object.keys(errors).length === 0) {
    return undefined;
  }

  return errors;
};

export {
  BankFields,
  sanitizeBankInfo,
  getCountryCodeFromIban,
  isIbanValid,
  isSortCodeValid,
  isAccountCodeValid,
  isAccountHolderNameValid,
  isAccountNumberValid,
  isRoutingNumberValid,
  isBicSwiftValid,
  getBankInfoFieldsCombo,
  getBankInfoErrors,
};
