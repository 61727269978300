import { Callout, DATE_FORMAT, FileCard } from '@dev-spendesk/grapes';

import { getSpanishDPRFromCompliances } from 'modules/documentary-evidence/utils/getSpanishDPRFromCompliances';
import {
  PanelPreviewsSection,
  PanelTextSection,
} from 'src/core/common/components/Panel';
import {
  type LocaleFormat,
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { SpanishDPRCheckmarkFileCard } from 'src/core/modules/documentary-evidence/components/SpanishDPRCheckmark';
import { isTravelperkPayable } from 'src/core/modules/integrations/utils/travelperk';
import { getFileTypeFromMime } from 'src/core/utils/files';

import { AutomaticallyCollectedReceipt } from '../../../../integrations/settings/components/AutomaticallyCollectedReceipt';
import type {
  DocumentViewable,
  Payable,
} from '../../containers/PayablePanelContainer';

export const ReceiptSection = ({
  payable,
  onDocumentClick,
}: {
  payable: Payable;
  onDocumentClick(document: DocumentViewable): void;
}) => {
  const { t, localeFormat } = useTranslation('global');
  const {
    id: payableId,
    documentaryEvidence,
    documentaryEvidenceCannotBeProvided,
  } = payable;
  const title = t('payables.panel.receipt');

  if (documentaryEvidenceCannotBeProvided?.reportMethod === 'manual') {
    return (
      <PanelTextSection isEmphasized title={title}>
        {t('payables.panel.receiptNotRequiredReason', {
          reporter: documentaryEvidenceCannotBeProvided.reporterGivenName,
          reason: documentaryEvidenceCannotBeProvided.reason,
        })}
      </PanelTextSection>
    );
  }

  if (documentaryEvidenceCannotBeProvided?.reportMethod === 'automatic') {
    return (
      <PanelTextSection isEmphasized title={title}>
        {t('payables.panel.missingInvoiceIsRefundAutomatic')}
      </PanelTextSection>
    );
  }

  if (documentaryEvidence?.type === 'declarationOfMissingInvoice') {
    return (
      <PanelPreviewsSection
        title={title}
        noPreviewContent={t('payables.panel.noReceipt')}
        previews={documentaryEvidence.attachments.map((attachment) => (
          <FileCard
            key={attachment.url}
            onClick={() => {
              onDocumentClick({ ...documentaryEvidence, ...attachment });
            }}
            title={getAttachmentPreviewText(attachment, t, localeFormat, true)}
            mimeType={attachment.mediaType}
          />
        ))}
      />
    );
  }

  if (documentaryEvidence?.validity.isValid) {
    return (
      <PanelPreviewsSection
        title={title}
        noPreviewContent={t('payables.panel.noReceipt')}
        previews={[
          ...documentaryEvidence.attachments.map((attachment) => {
            const { hasSpanishDPR, state: spanishDPRState } =
              getSpanishDPRFromCompliances(attachment.compliances);

            return (
              <div key={attachment.url} className="rounded bg-neutral-lightest">
                <FileCard
                  onClick={() => {
                    onDocumentClick({ ...documentaryEvidence, ...attachment });
                  }}
                  title={getAttachmentPreviewText(attachment, t, localeFormat)}
                  mimeType={attachment.mediaType}
                />
                {hasSpanishDPR && (
                  <SpanishDPRCheckmarkFileCard state={spanishDPRState} />
                )}
              </div>
            );
          }),
          ...(isTravelperkPayable(payable)
            ? [
                <AutomaticallyCollectedReceipt
                  key={payableId}
                  integrationId="travelperk"
                  payableId={payableId}
                />,
              ]
            : []),
        ]}
      />
    );
  }

  if (documentaryEvidence && !documentaryEvidence.validity.isValid) {
    return (
      <PanelPreviewsSection
        title={title}
        noPreviewContent={t('payables.panel.noReceipt')}
        previews={documentaryEvidence.attachments.map((attachment) => (
          <FileCard
            key={attachment.url}
            onClick={() => {
              onDocumentClick({ ...documentaryEvidence, ...attachment });
            }}
            title={getAttachmentPreviewText(attachment, t, localeFormat)}
            mimeType={attachment.mediaType}
          />
        ))}
        beforePreviews={
          <Callout
            variant="warning"
            title={t('payments.panel.invalidReceiptTitle')}
          >
            “{documentaryEvidence.validity.reason}”
          </Callout>
        }
      />
    );
  }

  return (
    <PanelPreviewsSection
      title={title}
      previews={[]}
      noPreviewContent={t('payables.panel.noReceipt')}
    />
  );
};

const getAttachmentPreviewText = (
  attachment: {
    mediaType: string;
    downloadUrl: string;
    attachedAt: Date | undefined;
  },
  t: TGlobalFunctionTyped,
  localeFormat: LocaleFormat,
  isAffidavit?: boolean,
): string => {
  const fileType = getFileTypeFromMime(attachment.mediaType);
  const readableDate = t('payables.panel.uploadedOn', {
    date: attachment.attachedAt
      ? localeFormat(attachment.attachedAt, DATE_FORMAT.SHORT)
      : t('misc.unknown'),
  });

  if (!fileType) {
    return readableDate;
  }

  if (isAffidavit) {
    return `Affidavit | ${readableDate}`;
  }

  return `${fileType} | ${readableDate}`;
};
