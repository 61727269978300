import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type CompanyWalletSummary } from '../companyWalletSummary';

export const useCompanyWalletSummariesQuery = (
  companyId: string,
): QueryState<CompanyWalletSummary[]> => {
  return useQuery<CompanyWalletSummary[], CompanyWalletSummary[]>({
    key: 'companyWalletSummaries',
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: '/companies/wallets-summary',
      params: { includeDetailsCompanyId: [companyId] },
    },
  });
};
