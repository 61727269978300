import React from 'react';
import { useSelector } from 'react-redux';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import { LatePaymentsSection } from 'modules/control-rules/components/LatePaymentsSection';
import { RequesterSpendingPolicy } from 'modules/profile';
import { PurchaseOrderDashboardSummary } from 'modules/purchase-orders/components/';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import FEATURES from 'src/core/constants/features';
import { getUserPaymentCount } from 'src/core/selectors/users';

import { PaymentsListSection } from './components/PaymentsListSection';
import { PaymentsListSectionLoader } from './components/PaymentsListSectionLoader';
import { RestrictedAccessPanel } from './components/RestrictedAccessPanel';
import { SpenderRulesPanel } from './components/SpenderRulesPanel';
import { usePaymentsToCompleteQuery, useUserControlRuleQuery } from './hooks';
import { SfsMigrationWidget } from '../Homepage/components/SfsMigrationWidget';
import { useCardsAccessQuery } from '../Homepage/hooks';

import './RequesterHomepage.css';

const RequesterHomepage = () => {
  const company = useCompany();
  const userPaymentCount = useSelector((state) =>
    getUserPaymentCount(state, company.id),
  );
  const hasPurchaseOrderFeatureActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );

  const controlRuleQueryState = useUserControlRuleQuery();
  const paymentsListQueryState = useQueryStates({
    states: {
      payments: usePaymentsToCompleteQuery({
        companyCurrency: company.currency,
      }),
      controlRule: controlRuleQueryState,
    },
    reshapeData({ payments, controlRule }) {
      const paymentsWithLateCompletionDeadline = payments.filter(
        (payment) => payment.completionDeadline?.status === 'late',
      );
      return {
        payments,
        controlRule,
        overduePaymentsCount: paymentsWithLateCompletionDeadline.length,
      };
    },
  });
  const rulesPanelQueryState = useQueryStates({
    states: {
      cardsAccess: useCardsAccessQuery(),
      controlRule: controlRuleQueryState,
    },
  });

  return (
    <div className="RequesterHomepage">
      <div className="RequesterHomepage__grid">
        <section className="RequesterHomepage__payments-overview">
          {hasPlayByTheRulesReceiptAndFieldsFeature ? (
            <LatePaymentsSection />
          ) : (
            <div className="box mb-m pb-0">
              <QuerySuspense
                queryState={paymentsListQueryState}
                loading={<PaymentsListSectionLoader />}
                fallback={(error) => (
                  <QueryError
                    queryError={error}
                    componentType="Text"
                    className="mb-m block text-alert"
                  />
                )}
              >
                {({ payments, controlRule, overduePaymentsCount }) => (
                  <PaymentsListSection
                    context={{
                      companyId: company.id,
                      userHasMadePayments: userPaymentCount > 0,
                    }}
                    payments={payments}
                    controlRule={controlRule}
                    overduePaymentsCount={overduePaymentsCount}
                  />
                )}
              </QuerySuspense>
            </div>
          )}
          {hasPurchaseOrderFeatureActivated && (
            <PurchaseOrderDashboardSummary />
          )}
        </section>
        <section className="RequesterHomepage__rules">
          <SfsMigrationWidget />
          <QuerySuspense
            queryState={rulesPanelQueryState}
            loading={null}
            fallback={() => null}
          >
            {({ cardsAccess, controlRule }) => {
              if (!cardsAccess.hasAccess && cardsAccess.initiator === 'user') {
                return <RestrictedAccessPanel />;
              }

              return (
                <>
                  <RequesterSpendingPolicy className="mb-m" />
                  <SpenderRulesPanel
                    incompletePaymentsLimit={
                      controlRule.incompletePaymentsLimit
                    }
                    completionDeadline={controlRule.completionDeadline}
                    mode={!cardsAccess.hasAccess ? 'danger' : 'normal'}
                  />
                </>
              );
            }}
          </QuerySuspense>
        </section>
      </div>
    </div>
  );
};

const ConnectedRequesterHomepage = withErrorBoundary({
  team: 'employee',
  scope: 'requester-home-page',
})(RequesterHomepage);

export { ConnectedRequesterHomepage as RequesterHomepage };
