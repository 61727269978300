import { CheckboxField } from '@dev-spendesk/grapes';
import { type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type SyncReportingManagerProps = {
  syncReportingManagerSelected: boolean;
  setSyncReportingManagerSelected: (value: SetStateAction<boolean>) => void;
};

export const SyncReportingManager = ({
  syncReportingManagerSelected,
  setSyncReportingManagerSelected,
}: SyncReportingManagerProps) => {
  const { t } = useTranslation('global');

  return (
    <CheckboxField
      className="ps-[67px]"
      fit="parent"
      label={t('integration.kombo.settings.filters.managerCheckbox')}
      isChecked={syncReportingManagerSelected}
      onChange={(event) => {
        setSyncReportingManagerSelected(event.target.checked);
      }}
    />
  );
};
