import { type ReactNode } from 'react';

import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { MODES as TOOLBAR_MODES } from '../FileViewerToolbar/FileViewerToolbar';
import ImageViewer from '../ImageViewer/ImageViewer';

type PropsForImageViewer = {
  url: string;
  className?: string;
  withZoom?: boolean;
  withDownload?: boolean;
  withRotate?: boolean;
  withDelete?: boolean;
  withDeleteTooltip?: string | ReactNode;
  withClose?: boolean;
  isInvalid?: boolean;
  invalidProofReason?: string;
  onDownload?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  onToggleZoom?: () => void;
};

type Props = {
  onClose: () => void;
  fileViewerModalClassName?: string;
} & PropsForImageViewer;

const ImageViewerModal = ({
  onClose,
  fileViewerModalClassName = '',
  ...imageViewerProps
}: Props) => (
  <FileViewerModal onClose={onClose} className={fileViewerModalClassName}>
    <ImageViewer
      {...imageViewerProps}
      withClose
      onClose={onClose}
      toolbarMode={TOOLBAR_MODES.COMPACT}
    />
  </FileViewerModal>
);

export default ImageViewerModal;
