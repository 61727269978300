import { Icon, Popover, Tooltip } from '@dev-spendesk/grapes';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FixIntercomCookiesModal } from 'common/components/ContactUs/FixIntercomCookiesModal';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { getQuestionsFactory } from 'common/utils/help-center';
import FEATURES from 'src/core/constants/features';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import HelpTooltip from './HelpTooltip';

import './HelpButton.css';

type Props = {
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
};

const HelpButton = ({ user }: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const hasPaymentInboxFeature = useFeature(FEATURES.PAYMENT_INBOX);
  const location = useLocation();
  const [isFixIntercomCookiesModalOpen, setIsFixIntercomCookiesModalOpen] =
    useState(false);

  // using useMemo with i18n.language is unecessary as we reload the page when changing language
  // but that may change in the future
  const getQuestions = useMemo(() => {
    return getQuestionsFactory({
      featureFlags: {
        [FEATURES.PAYMENT_INBOX]: hasPaymentInboxFeature,
      },
      lang: activeLanguage,
      t,
      user,
    });
  }, [activeLanguage]);

  const {
    questions = [],
    helpCenterIndex,
    title,
  } = useMemo(
    () => getQuestions(location.pathname),
    [location.pathname, getQuestions],
  );

  const handleClick = (onClick: () => void) => () => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_BUTTON);
    onClick();
  };

  const onChatClicked = () => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_CHAT);
  };

  const onLinkClicked = (link: string, linkTitle: string) => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_LINK, {
      link,
      title: linkTitle,
    });
  };

  if (!questions.length) {
    return null;
  }

  return (
    <div>
      <Popover
        placement="bottom-end"
        renderTrigger={({ onClick, isDropdown: _, ...rest }) => {
          return (
            <Tooltip content={t('misc.needHelp')} triggerAsChild>
              <button
                {...rest}
                onClick={handleClick(onClick)}
                className="HelpButton"
                aria-label={t('misc.needHelp')}
                type="button"
              >
                <Icon name="question-mark" aria-hidden="true" />
              </button>
            </Tooltip>
          );
        }}
      >
        {(closeDropdown) => (
          <HelpTooltip
            title={title}
            questions={questions}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            helpCenterIndex={helpCenterIndex!}
            onLinkClicked={onLinkClicked}
            onChatClicked={onChatClicked}
            openFixIntercomCookiesModal={() => {
              closeDropdown();
              setIsFixIntercomCookiesModalOpen(true);
            }}
          />
        )}
      </Popover>
      {/* cannot be moved inside ContactUs because can't be inside the Popover*/}
      <FixIntercomCookiesModal
        isOpen={isFixIntercomCookiesModalOpen}
        onClose={() => setIsFixIntercomCookiesModalOpen(false)}
      />
    </div>
  );
};

export default HelpButton;
