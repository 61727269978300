import cx from 'classnames';
import React, { PureComponent } from 'react';

import './TableLegacy.scss';

class TableLegacy extends PureComponent {
  constructor(props) {
    super(props);
    this.options = {
      hasSubNav: false,
    };
  }

  /**
   * These methods needs to be overriden
   * by the Table children component
   */
  isLoading = () => {
    return false;
  };

  hasResults = () => {
    return false;
  };

  hasFiltersBar = () => {
    return false;
  };

  hasFiltersApplied = () => {
    return false;
  };

  renderSubNav = () => {
    throw new Error('The method `renderSubNav` needs to be overriden');
  };

  renderFilters = () => {
    throw new Error('The method `renderFilters` needs to be overriden');
  };

  renderActions = () => {
    throw new Error('The method `renderActions` needs to be overriden');
  };

  renderResults = () => {
    throw new Error('The method `renderResults` needs to be overriden');
  };

  renderPanel = () => {
    throw new Error('The method `renderPanel` needs to be overriden');
  };

  shouldHideHeader = () => {
    return !this.isLoading() && !this.hasResults() && !this.hasFiltersApplied();
  };

  render() {
    const shouldHideHeader = this.shouldHideHeader();

    const classes = cx(
      'TableLegacy page__container page__container--table',
      this.options.className,
      {
        open: !!this.state.isPanelOpen,
        'TableLegacy--has-sub-nav':
          !!this.options.hasSubNav && !shouldHideHeader,
        'TableLegacy--has-filter': !!this.hasFiltersBar() && !shouldHideHeader,
      },
    );

    return (
      <div className={classes}>
        <div className="page__sub-nav">{this.renderSubNav()}</div>
        {!shouldHideHeader && (
          <div className="page__header" id="controller-header">
            {this.renderFilters()}
            {this.renderActions()}
          </div>
        )}
        <div
          className={cx('page__content', {
            'page__content--full': !!this.isLoading(),
          })}
        >
          {this.renderResults()}
        </div>
        <div className="page__panel">{this.renderPanel()}</div>
      </div>
    );
  }
}

export default TableLegacy;
