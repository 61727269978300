import {
  Button,
  colors,
  Icon,
  Navigation,
  Popover,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { AvatarMenu } from 'common/components/AvatarMenu/AvatarMenu';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { getHasCommitmentsTab } from 'modules/app';
import { GringottsButton, GringottsContainer } from 'modules/app/gringotts';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { CompanySwitcherContainer } from 'modules/app/layout/components/CompanySwitcher';
import { CompanyDropdownContainer } from 'modules/company';
import { useCanAccessInvoicesPage } from 'modules/invoices';
import { JiraIssueReporter } from 'modules/jira-issue-reporter/JiraIssueReporter';
import { useCanAccessExpenseClaimsPage } from 'modules/reimbursements';
import { useNewRequestRoute } from 'modules/requests/hooks';
import CONFIG from 'src/core/config';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import {
  getHasHomepage,
  getHasImpersonationTargets,
  getImpersonator,
} from 'src/core/selectors/globalSelectors';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  getLeftNavigationItems,
  getPlusDropdownItems,
  getResponsiveLeftNavigationItems,
} from './helpers';
import HelpButton from '../../HelpButton/HelpButton';
import { ReadyToSpendSetupGuide } from '../../ReadyToSpendSetupGuide';

import './MainNavigation.css';

export const MainNavigation = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const history = useHistory();
  const { width } = useWindowSize();
  const requestRoute = useNewRequestRoute({});
  const hasLoadsToLimitsFeature = useFeature(FEATURES.TMP_LOADS_TO_LIMITS);
  const hasWalletAccess = useFeature(FEATURES.WALLET_ACCESS);
  const isPurchaseOrdersFeatureActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );
  const hasVirtualCards = useFeature(FEATURES.VIRTUAL_CARDS);
  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);
  const isBudgetsFeatureEnabled = useFeature(FEATURES.BUDGETS);
  const isBookkeepEnabled = useFeature(FEATURES.BOOKKEEP);
  const isProcurementEnabled = useFeature(FEATURES.PROCUREMENT);
  const canAccessInvoicesPage = useCanAccessInvoicesPage();
  const canAccessExpenseClaimsPage = useCanAccessExpenseClaimsPage();
  const impersonator = useSelector(getImpersonator);
  const hasHomepage = useSelector(getHasHomepage);
  const hasImpersonationTargets = useSelector(getHasImpersonationTargets);
  const hasCommitmentsTab = useSelector(getHasCommitmentsTab);

  if (!company) {
    return null;
  }

  const renderNewRequestButton = () => {
    if (
      company.churning_at ||
      (!user.is_requester && !user.is_account_owner) ||
      impersonator
    ) {
      return null;
    }

    return (
      <Button
        key="request"
        data-marketing="top-menu_new-request"
        variant="contrasted"
        className="Header__newRequestBtn"
        text={t('misc.newRequest_short')}
        onClick={() => {
          track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, {
            from: 'nav_bar',
          });
          history.push(requestRoute);
        }}
      />
    );
  };
  const maybeRenderGringottsButton = () => {
    const gringottsRunningLocally =
      CONFIG.gringottsBaseUrl === 'https://localhost:3002';
    // Button should only be displayed when Gringotts is running locally
    // To access Gringotts from another env, we still have access from the Avatar menu
    if (!CONFIG.gringottsEnabled || !gringottsRunningLocally) {
      return null;
    }
    return (
      <GringottsContainer
        companyId={company.id}
        bankingProvider={company.banking_provider}
        key="gringotts"
      >
        {(toggleGringottsIframe) => {
          return (
            <GringottsButton onToggleGringottsIframe={toggleGringottsIframe} />
          );
        }}
      </GringottsContainer>
    );
  };

  const renderImpersonatorButton = () => {
    if (!hasImpersonationTargets) {
      return null;
    }

    return (
      <Button
        key="impersonator"
        className="mr-s"
        iconName="caret-left"
        iconPosition="left"
        text={t('misc.accountantAccount')}
        variant="contrasted"
        component="a"
        href="/auth/login/select"
      />
    );
  };

  const features = {
    hasCommitmentsTab,
    isPurchaseOrdersFeatureActivated,
    hasVirtualCards,
    hasPlasticCardsFeature,
    isBudgetsFeatureEnabled,
    canAccessInvoicesPage,
    canAccessExpenseClaimsPage,
    isBookkeepEnabled,
    isProcurementEnabled,
  };

  const leftNavigationItems = getLeftNavigationItems({
    user,
    company,
    features,
  });

  const responsiveLeftNavigationItems = getResponsiveLeftNavigationItems({
    width,
    leftNavigationItems,
  });

  const renderPlusDropdown = () => {
    if (width >= 1600) {
      return null;
    }

    const itemsInDropdown = getPlusDropdownItems({
      width,
      leftNavigationItems,
    });

    if (itemsInDropdown.length === 0) {
      return null;
    }

    return (
      <Popover
        key="plus"
        className="MainNavigation__plus"
        renderTrigger={({ isDropdown: _, ...triggerProps }) => (
          <button
            type="button"
            className="MainNavigation__plus-button body-m"
            {...triggerProps}
          >
            {t('misc.plus')}
            <Icon
              className="ml-xs"
              name="double-caret-right"
              aria-hidden="true"
            />
          </button>
        )}
      >
        {(closeDropdown) => (
          <div className="my-xxs">
            {itemsInDropdown.map(({ name, component: NavigationLink }) => (
              <NavigationLink
                className="MainNavigation__plus-item"
                user={user}
                company={company}
                onClick={closeDropdown}
                isInDropdown
                key={name}
              />
            ))}
          </div>
        )}
      </Popover>
    );
  };

  return (
    <Navigation
      className="px-m"
      aria-label="Main navigation"
      leftNavigationItems={[
        renderImpersonatorButton(),
        <NavLink
          key="homepage"
          id="nav_homepage"
          aria-label={t('homepage.name')}
          className={cx('MainNavigation__logo', {
            'MainNavigation__logo--homepage': hasHomepage,
          })}
          to={routeFor(hasHomepage ? routes.HOMEPAGE.path : routes.APP.path, {
            company: company.id,
          })}
        >
          <Icon
            className="MainNavigation__logo-image"
            name="spendesk"
            color={colors.white}
            aria-hidden="true"
          />
          {hasHomepage && (
            <Icon
              className="MainNavigation__logo-home"
              name="home"
              color={colors.white}
              aria-hidden="true"
            />
          )}
        </NavLink>,
        ...(hasWalletAccess &&
        hasLoadsToLimitsFeature &&
        company.type !== 'branch_expense_entity'
          ? [<CompanyDropdownContainer key="dropdown" />]
          : []),
        ...((hasWalletAccess && !hasLoadsToLimitsFeature) ||
        company.type === 'branch_expense_entity'
          ? [<CompanySwitcherContainer key="switcher" />]
          : []),
        <ReadyToSpendSetupGuide key="ready-to-spend" />,
        <span className="flex gap-xs" key="items">
          {responsiveLeftNavigationItems.map(
            ({ name, component: NavigationLink }) => (
              <NavigationLink user={user} company={company} key={name} />
            ),
          )}
        </span>,
        renderPlusDropdown(),
        maybeRenderGringottsButton(),
      ].filter(Boolean)}
      rightNavigationItems={[
        <HelpButton user={user} key="help" />,
        <JiraIssueReporter key="jira" />,
        renderNewRequestButton(),
        <AvatarMenu key="avatar" />,
      ].filter(Boolean)}
    />
  );
};
