import { type ApprovalRule } from '../structure/approval-flows';

export type CostCenter = {
  id: string;
  name: string;
  ownerId: string;
  viewerIds: string[];
  approvalRules: ApprovalRule[];
};

export type CostCenterWithBudgets = CostCenter & { hasBudgets: boolean };

export type Budget = {
  id: string;
  costCenterId: string;
};

export const updateApprovalRulesOnOwnerChange = (
  ownerId: string,
  approvalRules: ApprovalRule[],
): ApprovalRule[] => {
  // Updating the rules if several rules were created can lead to an unexpected result.
  // It's safer to only replace the owner if only one rule with one approver exists.
  const shouldUpdateApprovalRules =
    approvalRules.length === 1 &&
    approvalRules
      .flatMap((approvalRule) => approvalRule.steps)
      .flatMap((step) => step.rights).length === 1;
  if (shouldUpdateApprovalRules) {
    const modifiedRule = approvalRules[0];
    modifiedRule.steps.forEach((step) => {
      step.rights.forEach((right) => {
        right.approverId = ownerId;
        right.approverType = 'user';
      });
    });
    return [modifiedRule];
  }
  return approvalRules;
};
