import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useGetOrganisationEntities } from 'modules/company/billing/components/CompanyPlan/hooks/useGetOrganisationEntities';
import { DropdownSwitcherContent } from "src/core/common/components/DropdownSwitcherContent";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { sortByNameAsc } from "src/core/common/utils/sortByNameAsc";
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import {
  type CompanyWalletSummary,
  isCreatingCompany,
  isInReviewCompany,
} from '../../../companyWalletSummary';
import { CompanyDropdownLimitedAccess } from '../CompanyDropdownLimitedAccess';
import { CompanyDropdownWalletDetails } from '../CompanyDropdownWalletDetails';
import './CompanyDropdownContent.css';

type Props = {
  currentCompany: CompanyWalletSummary;
  companies: CompanyWalletSummary[];
  onCompanyClick(company: CompanyWalletSummary): void;
  onNewCompanyClick?(): void;
  closeDropdown(): void;
};

export const CompanyDropdownContent = ({
  currentCompany,
  companies,
  onCompanyClick,
  onNewCompanyClick,
  closeDropdown,
}: Props) => {
  const { t } = useTranslation();
  const { isAllowedToOpenEntity } = useGetOrganisationEntities();
  const activeItem = {
    left: currentCompany.name,
    right:
      !isCreatingCompany(currentCompany) && currentCompany.totalAmount
        ? formatMonetaryValue(currentCompany.totalAmount)
        : undefined,
    details: isInReviewCompany(currentCompany) ? (
      <CompanyDropdownLimitedAccess />
    ) : (
      <CompanyDropdownWalletDetails
        company={currentCompany}
        onRedirectToWalletSettings={closeDropdown}
      />
    ),
  };

  const items = sortByNameAsc(companies).map((company) => {
    let right;
    let onClick: (() => void) | undefined = () => onCompanyClick(company);

    if (isCreatingCompany(company)) {
      right = (
        <Tag variant="primary" className="CompanyDropdownContent__tag">
          {t('wallet.warningStepsRemaining')}
        </Tag>
      );
    } else if (
      isInReviewCompany(company) &&
      company.bankingProvider === 'sfs'
    ) {
      right = (
        <Tag variant="primary" className="CompanyDropdownContent__tag">
          {t('wallet.companyInReview')}
        </Tag>
      );
      onClick = undefined;
    } else {
      right = company.totalAmount && formatMonetaryValue(company.totalAmount);
    }
    return {
      id: company.id,
      left: company.name,
      right,
      onClick,
    };
  });

  const button = onNewCompanyClick && {
    label: t('wallet.walletDetails.addAccount'),
    onClick: () => onNewCompanyClick(),
  };

  return (
    <DropdownSwitcherContent
      activeItem={activeItem}
      items={items}
      button={isAllowedToOpenEntity ? button : undefined}
    />
  );
};
