import { useNotifications } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import TeamsSelection from './TeamsSelection';
import { type CustomField } from '../../models/customField';
import { CustomFieldPanelHeader } from '../CustomFieldPanelHeader/CustomFieldPanelHeader';

type Props = {
  customField: CustomField;
  groups: object[];
};

export const BooleanCustomField = ({ customField, groups }: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const companyId = useCompanyId();

  const booleanCustomFieldValues = [
    { value: 1, label: t('misc.yes') },
    { value: 0, label: t('misc.no') },
  ];

  return (
    <>
      <div className="mb-s mt-xl rounded-xs border border-solid border-neutral-light bg-white shadow-10">
        <CustomFieldPanelHeader customField={customField} />
        <div className="separator" />
        <div className="flex w-full flex-col items-stretch">
          {booleanCustomFieldValues.map((field, index, array) => {
            return (
              <>
                <div key={field.value} className="p-s">
                  <p className="truncate text-complementary body-m">
                    {field.label}
                  </p>
                </div>
                {index < array.length - 1 && <div className="separator" />}
              </>
            );
          })}
        </div>
      </div>
      {customField.isScopable && (
        <TeamsSelection
          cfId={customField.id}
          groups={groups ?? []}
          isAllScopes={customField.isAllScopes}
          pushNotif={pushNotif}
          companyId={companyId}
        />
      )}
    </>
  );
};
