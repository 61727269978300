import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import DashboardIllustration from './assets/dashboard-illustration.svg';
import { useExternalUrlsQuery } from '../../hooks/useExternalUrlsQuery';
import { PlaceholderBox } from '../PlaceholderBox';
import './DashboardTipBox.css';

export const DashboardTipBox = () => {
  const externalUrlsQueryState = useExternalUrlsQuery();

  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={externalUrlsQueryState}
      loading={<PlaceholderBox />}
      fallback={(queryError) => (
        <QueryError componentType="Callout" queryError={queryError} />
      )}
    >
      {(externalUrls) => {
        return (
          <div className="Tips box">
            <div className="DashboardTipBox">
              <div className="Tips__header">
                <h3 className="Tips__title title-l">
                  {t('homepage.tips.dataDashboardTitle')}
                </h3>
              </div>
              <p className="Tips__text body-m">
                {t('homepage.tips.dataDashboard.text')}
              </p>
              <img
                width="410"
                height="188"
                alt=""
                src={DashboardIllustration}
                className="Tips__illustration"
              />
              <div className="mt-s">
                <Button
                  variant="contrasted"
                  onClick={() =>
                    track(AnalyticEventName.HOMEPAGE_TIPS_CLICKED, {
                      tip: 'data_dashboard',
                    })
                  }
                  component="a"
                  href={externalUrls.controlDashboard}
                  text={t('homepage.tips.dataDashboard.cta')}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </div>
            </div>
          </div>
        );
      }}
    </QuerySuspense>
  );
};
