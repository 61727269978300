import cx from 'classnames';
import Fuse from 'fuse.js';
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import map from 'lodash/map';
import noop from 'lodash/noop';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';

import { ICONS } from "src/core/common/components/legacy/Icon/Icon";
import Label from "src/core/common/components/legacy/Label/Label";
import TextInput from "src/core/common/components/legacy/TextInput/TextInput";
import { grey6Cheerful } from "src/core/utils/color-palette";

import warningIcon from './icons/icon-warning.svg';
import './AutoComplete.scss';

class AutoComplete extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    activeItemClassName: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
    displayCreate: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    onBlur: PropTypes.func,
    onEnter: PropTypes.func,
    preventEnterEvent: PropTypes.bool,
    maxResults: PropTypes.number,
    getResultTemplate: PropTypes.func,
    emptyResultText: PropTypes.string,
    getSelectedDisplay: PropTypes.func,
    getSelectedPreview: PropTypes.func,
    renderCreateContent: PropTypes.func,
    isResultMatching: PropTypes.func,
    ellipsisResults: PropTypes.bool,
    isValid: PropTypes.bool,
    hasWarning: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    required: PropTypes.bool,
    alwaysShowResults: PropTypes.bool,
    showRightIcon: PropTypes.bool,
    resetInputOnSelect: PropTypes.bool,
    t: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    labelledBy: PropTypes.string,
  };

  static defaultProps = {
    values: undefined,
    placeholder: 'Enter text...',
    emptyResultText: undefined,
    size: 'medium',
    className: '',
    activeItemClassName: 'active',
    value: '',
    displayCreate: false,
    preventEnterEvent: false,
    disabled: false,
    onChange: noop,
    onBlur: noop,
    onCreate: noop,
    label: undefined,
    onEnter: null,
    maxResults: null,
    ellipsisResults: false,
    required: false,
    alwaysShowResults: false,
    getResultTemplate(result) {
      return <span>{result.value}</span>;
    },
    getSelectedDisplay(result) {
      return result ? result.value : result;
    },
    getSelectedPreview: null,
    isResultMatching(row, search) {
      return includes(row.value?.toLowerCase(), search?.toLowerCase());
    },
    renderCreateContent: null,
    isValid: true,
    hasWarning: false,
    showRightIcon: true,
    resetInputOnSelect: false,
    readOnly: false,
  };

  constructor(props) {
    super(props);
    const normalizedValue = this.getNormalizedValue(props.value);
    this.state = {
      preview: isFunction(props.getSelectedPreview)
        ? props.getSelectedPreview(normalizedValue)
        : null,
      value: props.getSelectedDisplay(normalizedValue),
      values: this.getNormalizedValues(props.values),
      results: this.getUniqValues(this.getNormalizedValues(props.values)),
      displayResults: props.alwaysShowResults,
      error: false,
      keyboardSelectedItemId: -1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const normalizedValue = this.getNormalizedValue(nextProps.value);

    const changes = {
      preview: isFunction(this.props.getSelectedPreview)
        ? this.props.getSelectedPreview(normalizedValue)
        : null,
      values: this.getUniqValues(this.getNormalizedValues(nextProps.values)),
      rawValue: nextProps.value,
    };

    if (!isEqual(nextProps.value, this.props.value)) {
      changes.value = nextProps.getSelectedDisplay(normalizedValue);
    }

    if (
      !isEqual(nextProps.value, this.props.value) ||
      !isEqual(nextProps.values, this.props.values)
    ) {
      changes.results = this.getUniqValues(
        this.getNormalizedValues(nextProps.values),
      );
    }

    this.setState(changes);
  }

  get rawValue() {
    return this.textInput && this.textInput.state
      ? this.textInput.state.value
      : null;
  }

  getNormalizedValue = (value) => {
    return isString(value) ? { value } : value;
  };

  getNormalizedValues = (values) =>
    map(values, (value) => this.getNormalizedValue(value));

  getUniqValues = (values) =>
    values.filter(
      (element, index) =>
        values.findIndex((step) => isEqual(element, step)) === index,
    );

  showResults = () => this.setState({ displayResults: true });

  hideResults = () =>
    !this.props.alwaysShowResults && this.setState({ displayResults: false });

  toggleResults = () =>
    !this.props.alwaysShowResults &&
    this.setState((previousState) => ({
      displayResults: !previousState.displayResults,
    }));

  // used by react-onclickoutside
  handleClickOutside = this.hideResults;

  selectValue = (result, index, isCreate = false) => {
    this.setState(
      {
        value: this.props.resetInputOnSelect
          ? null
          : this.props.getSelectedDisplay(result),
        preview: isFunction(this.props.getSelectedPreview)
          ? this.props.getSelectedPreview(result)
          : null,
        keyboardSelectedItemId: index || -1,
        error: false,
      },
      () => {
        if (isCreate) {
          return this.props.onCreate(result);
        }
        return this.props.onSelect(result);
      },
    );
    this.hideResults();
  };

  search = (query) => {
    const { isResultMatching } = this.props;
    const { values } = this.state;
    const filteredValues = filter(values, (value) =>
      isResultMatching(value, query),
    );

    if (!query) {
      return filteredValues;
    }

    // sort the remaining values using fuse to display the most pertinent results on top
    const fuse = new Fuse(filteredValues, {
      shouldSort: true,
      tokenize: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: keys(head(values)),
    });

    return fuse.search(query).map((result) => result.item);
  };

  isValueMatching = (value, results) => {
    const { getSelectedDisplay } = this.props;
    const result = head(results);
    const resultValue = getSelectedDisplay(result);
    return (
      results.length === 1 &&
      resultValue?.toLowerCase() === value?.toLowerCase()
    );
  };

  hasMatchingResults = (value, results) => {
    const { getSelectedDisplay } = this.props;

    return results.some((result) => {
      const displayedValue = getSelectedDisplay(result);
      return displayedValue?.toLowerCase() === value?.toLowerCase();
    });
  };

  handleChange = (e) => {
    if (this.props.readOnly) {
      return;
    }
    const { onChange } = this.props;
    const { value } = e.target;
    const results = this.search(value);
    const result = head(results);
    const error = value ? false : this.state.error; // empty value resets error state

    if (this.isValueMatching(value, results)) {
      this.selectValue(result, 0);
      return;
    }

    // When emptying field, trigger select with null
    if (value === '') {
      this.selectValue({ key: null, name: null }, null);
    }

    this.setState(
      {
        value,
        results,
        error,
      },
      () => onChange(value),
    );
    this.showResults();
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleNavigation = (e) => {
    const { results, keyboardSelectedItemId, value } = this.state;
    const { displayCreate, preventEnterEvent } = this.props;

    const navigationEvents = {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      13: (e) => {
        if (preventEnterEvent) {
          e.preventDefault();
        }

        // Enter key
        let result = get(results, keyboardSelectedItemId);
        if (this.props.onEnter) {
          this.toggleResults();

          if (keyboardSelectedItemId === -1 && displayCreate) {
            return this.props.onCreate(value);
          }
          if (result) {
            this.setState({ value: result.name });
          }
          return this.props.onEnter(e, result);
        }
        if (!result && results.length === 1) {
          result = head(results);
        }
        if (result) {
          this.selectValue(result, keyboardSelectedItemId);
        }
      },
      40: () => {
        // Arrow down key
        let selectedId =
          keyboardSelectedItemId < results.length - 1
            ? keyboardSelectedItemId + 1
            : 0;
        const hasCircled =
          selectedId === keyboardSelectedItemId ||
          selectedId < keyboardSelectedItemId;

        if (!results.length && displayCreate) {
          selectedId = -1; // if the only option to display is "create value"
        }
        if (hasCircled && displayCreate) {
          selectedId = -1; // if the selection should go back to the start, highlight "create" instead
        }
        this.setState({ keyboardSelectedItemId: selectedId });
      },
      38: () => {
        // Arrow up key
        const selectedId =
          keyboardSelectedItemId > 0
            ? keyboardSelectedItemId - 1
            : results.length - 1;
        this.setState({ keyboardSelectedItemId: selectedId });
      },
      27: this.props.onBlur, // Escape key
    };

    if (navigationEvents[e.keyCode]) {
      navigationEvents[e.keyCode](e);
    }
  };

  handleFocus = () => {
    this.textInput.inputNode.select();
    this.showResults();
  };

  handleBlur = (e) => {
    const { value } = this.state;
    const { onBlur, displayCreate } = this.props;
    const results = this.search(value);
    const hasMatchingResults = this.hasMatchingResults(value, results);
    const error = !displayCreate && !hasMatchingResults;
    this.setState({ error });
    this.hideResults();

    if (onBlur) {
      return onBlur(e);
    }
  };

  isActiveItem = (value, index) => {
    const { keyboardSelectedItemId, rawValue } = this.state;

    // Selected item by keyboard navigation (up/down)
    if (keyboardSelectedItemId === index) {
      return true;
    }

    // Otherwise, currently selected value
    return (
      keyboardSelectedItemId !== -1 &&
      Array.isArray(rawValue) &&
      some(rawValue, { key: value.key })
    );
  };

  isSelectionValid = (value) => {
    const { error } = this.state;
    const { isValid } = this.props;

    if (!isValid) {
      return false;
    }

    if (!value && !this.props.required) {
      return true;
    }
    return !error && isValid;
  };

  defaultRenderCreateContent = (searchValue) => (
    <>
      {this.props.t('misc.create')} <strong>{searchValue}</strong>
    </>
  );

  renderCreateOption = (searchValue) => {
    const { keyboardSelectedItemId } = this.state;
    const isActive = keyboardSelectedItemId === -1;

    return (
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      (<div
        onMouseDown={() => this.selectValue(searchValue, null, true)}
        className={cx('autocomplete__result-row', {
          [this.props.activeItemClassName]: isActive,
        })}
      >
        {this.props.renderCreateContent
          ? this.props.renderCreateContent(searchValue)
          : this.defaultRenderCreateContent(searchValue)}
      </div>)
    );
  };

  renderEmptyResult = (emptyResultText, searchValue) => (
    <div className="autocomplete__results-empty">
      <img
        className="autocomplete__results-empty-icon"
        src={warningIcon}
        alt="icon"
      />
      {emptyResultText ? (
        <div>{emptyResultText}</div>
      ) : (
        <div>
          <div className="autocomplete__results-empty-title">
            {this.props.t('misc.noResultFor', { search: searchValue || '' })}
          </div>
        </div>
      )}
    </div>
  );

  renderResult = (result, index) => {
    const { ellipsisResults } = this.props;

    return (
      <div
        key={index}
        onMouseDown={() => this.selectValue(result, index)}
        className={cx(
          'autocomplete__result-row',
          { 'autocomplete__result-row--ellipsis': ellipsisResults },
          {
            [this.props.activeItemClassName]: this.isActiveItem(result, index),
          },
        )}
      >
        {this.props.getResultTemplate(result)}
      </div>
    );
  };

  renderPreview = () => {
    const { preview } = this.state;
    if (!preview) {
      return null;
    }
    return <div className="preview">{preview}</div>;
  };

  render() {
    const {
      emptyResultText,
      label,
      disabled,
      size,
      displayCreate,
      className,
      placeholder,
      alwaysShowResults,
      hasWarning,
      showRightIcon,
    } = this.props;
    const { results, value, displayResults } = this.state;

    const hasResults = results && results.length;
    const forceDisplayResults = !hasResults && displayCreate;

    return (
      <div
        className={cx('autocomplete__container', size, className, { disabled })}
      >
        {this.renderPreview()}
        {label && <Label>{label}</Label>}
        <TextInput
          labelledBy={this.props.labelledBy}
          ref={(input) => {
            this.textInput = input;
          }}
          size={this.props.size}
          placeholder={placeholder}
          onChange={this.handleChange}
          onKeyDown={this.handleNavigation}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={value}
          icon={
            !alwaysShowResults && showRightIcon ? ICONS.BOTTOM_ARROW_HEAD : null
          }
          iconAction={this.toggleResults}
          iconColor={grey6Cheerful}
          isValid={this.isSelectionValid()}
          hasWarning={hasWarning}
          disabled={disabled}
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
        />
        {displayResults && (hasResults || forceDisplayResults) ? (
          <div className={cx('autocomplete__results', size)}>
            {map(
              [...results].splice(0, this.props.maxResults || results.length),
              this.renderResult,
            )}
            {displayCreate &&
              value &&
              !this.hasMatchingResults(value, results) &&
              this.renderCreateOption(value)}
          </div>
        ) : null}
        {displayResults && !results.length && !displayCreate && (
          <div className={cx('autocomplete__results', 'empty')}>
            {this.renderEmptyResult(emptyResultText, value)}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('global', { withRef: true })(
  onClickOutside(AutoComplete),
);
