import get from 'lodash/get';

/**
 * This check is based on 5 checks used at 2 levels: user and company:
 * - The company must have the Slack feature activated
 * - The company must have the Slack integration live
 * - The user must not have already linked his/her Slack account
 * - The user must not have already seen or dismissed the popup
 *
 * @return {Boolean} Whether the user should see the popup
 */
export const canUserSeeSlackPopup = (user, company, isSlackFeatureEnabled) => {
  if (!isSlackFeatureEnabled) {
    return false;
  }

  const userCompanyConfig = get(user, `data_by_company[${company.id}]`, {});
  const isSlackIntegrationActivated = get(
    userCompanyConfig,
    'provider_integration_statuses.has_slack_enabled',
    false,
  );
  const userHasLinkedAccount = get(
    userCompanyConfig,
    'provider_integration_statuses.has_slack_linked',
    false,
  );
  const userHasSeenPopup = get(
    userCompanyConfig,
    'seen_popups.has_seen_slack_annoucement',
    false,
  );

  return (
    isSlackIntegrationActivated && // The company activated Slack
    !userHasLinkedAccount && // The user hasn't already linked his/her Slack account
    !userHasSeenPopup
  ); // The user hasn't seen the popup yet (DB)
};
