import { Autocomplete, DropdownItem } from '@dev-spendesk/grapes';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

import { type Location } from 'modules/requests/mileage/creation/model';
import { baseAPI } from 'src/core/api/axios';
import { logger } from 'src/utils/datadog-log-wrapper';

type Props = {
  onPlaceSelect: (
    city: string | undefined,
    countryCode: string | undefined,
  ) => void;
  placeholder: string;
  initialInputValue?: string;
};

type CityOption = {
  key: string;
  label: string;
  city?: string;
  countryCode?: string;
};

const DATE_OF_BIRTH_TYPES = [
  'country',
  'region',
  'place',
  'locality',
  'district',
];

export const ScreeningCityAutocomplete = ({
  onPlaceSelect,
  placeholder,
  initialInputValue = '',
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<CityOption | undefined>(
    initialInputValue
      ? {
          key: initialInputValue,
          label: initialInputValue,
          city: undefined,
          countryCode: undefined,
        }
      : undefined,
  );
  const [placeSuggestionsOptions, setPlaceSuggestionsOptions] = useState<
    CityOption[]
  >([]);

  const handleInputChange = async (searchText: string | undefined) => {
    if (!searchText || searchText.length < 3) {
      return;
    }

    try {
      const { data } = await baseAPI.get('/geo/places', {
        params: {
          searchText,
          types: DATE_OF_BIRTH_TYPES,
        },
      });
      if (data.matchingPlaces) {
        setPlaceSuggestionsOptions(
          data.matchingPlaces.map((location: Location) => ({
            key: location.description,
            label: location.description,
            city: location.name,
            countryCode: location.countryCode,
          })),
        );
      }
    } catch (error) {
      logger.warn('could not get places from /geo/places API', {
        team: 'employee', // b.e for this call is employee so makes sense!
        scope: 'screening',
        error,
      });
    }
  };

  const debouncedOnChange = useCallback(debounce(handleInputChange, 500), []);

  return (
    <Autocomplete
      fit="parent"
      placeholder={placeholder}
      value={selectedValue}
      onSelect={(selectedOption: CityOption | undefined) => {
        setSelectedValue(selectedOption);
        onPlaceSelect(selectedOption?.city, selectedOption?.countryCode);
      }}
      onSearch={debouncedOnChange}
      options={placeSuggestionsOptions}
      onAddOption={(newOption: string) => {
        return {
          key: newOption,
          label: newOption,
          city: newOption,
          countryCode: undefined,
        };
      }}
      renderAddOption={(newOption: string) => (
        <DropdownItem
          label={<span className="text-complementary">{newOption}</span>}
        />
      )}
    />
  );
};
