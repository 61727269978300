import { Button } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, {
  type ReactNode,
  type MouseEventHandler,
  useRef,
  useEffect,
  useState,
} from 'react';

import './DropdownSwitcherContent.scss';

type Props = {
  activeItem: {
    left: ReactNode;
    right?: ReactNode;
    details?: ReactNode;
  };
  items: {
    id: string;
    left: ReactNode;
    right?: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
  }[];
  button?: {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
  };
};

export const DropdownSwitcherContent = ({
  activeItem,
  items,
  button,
}: Props) => {
  const [hasOverflowed, setHasOverflowed] = useState(false);
  const contentReference = useRef<HTMLDivElement>(null);

  // Make sure the dropdown cannot go offscreen
  useEffect(() => {
    const dropdown = contentReference.current;
    if (dropdown) {
      const top = dropdown.getBoundingClientRect().top;

      if (top > 0) {
        const additionalMargin = 36; // just so it's not too close to the bottom of the screen
        dropdown.style.maxHeight = `calc(100vh - ${top + additionalMargin}px)`;

        setHasOverflowed(dropdown.scrollHeight > dropdown.offsetHeight);
      }
    }
  }, [contentReference.current]);

  return (
    <div className="DropdownSwitcherContent" ref={contentReference}>
      <div className="DropdownSwitcherContent__active">
        <div className="DropdownSwitcherContent__active__header title-m ">
          <span className="DropdownSwitcherContent__active__header__left">
            {activeItem.left}
          </span>
          {activeItem.right && <span>{activeItem.right}</span>}
        </div>
        {activeItem.details && (
          <div className="DropdownSwitcherContent__active__details">
            <span>{activeItem.details}</span>
          </div>
        )}
      </div>
      {items.length > 0 && (
        <div
          className={cx('DropdownSwitcherContent__items', {
            'DropdownSwitcherContent__items--no-scroll': hasOverflowed,
          })}
        >
          {items.map((item) => {
            const inside = (
              <>
                <span className="DropdownSwitcherContent__items__button__left">
                  {item.left}
                </span>
                {item.right && (
                  <span className="DropdownSwitcherContent__items__button__right body-m ">
                    {item.right}
                  </span>
                )}
              </>
            );

            if (item.onClick) {
              return (
                <button
                  key={item.id}
                  type="button"
                  className="DropdownSwitcherContent__items__button title-m"
                  onClick={item.onClick}
                >
                  {inside}
                </button>
              );
            }

            return (
              <span
                key={item.id}
                className="flex items-center justify-between p-xs text-neutral-dark title-m"
              >
                {inside}
              </span>
            );
          })}
        </div>
      )}
      {button && (
        <div className="DropdownSwitcherContent__button">
          <Button
            fit="parent"
            variant="ghost"
            text={button.label}
            onClick={button.onClick}
          />
        </div>
      )}
    </div>
  );
};
