import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ButtonRedesigned from "src/core/common/components/legacy/ButtonRedesigned/ButtonRedesigned";
import FEATURES from "src/core/constants/features";
import { routes, routeFor } from "src/core/constants/routes";
import { fetchSlackProviderSettings } from "src/core/modules/profile/redux/actions";
import { getIsFeatureEnabled } from "src/core/selectors/globalSelectors";
import { apiUrl } from "src/core/utils/api";

import { saveSlackPopupSeenState } from './actions';
import { canUserSeeSlackPopup } from './utils';
import SplashNotif from '../../SplashNotif';

import './SlackAnnouncementSplash.scss';

export const getSlackConnectUrl = (input) => {
  const { clientId, companyId, authorize, url } = input;

  if (!clientId || !companyId) {
    return null;
  }

  return `${url}${authorize}&${queryString.stringify({
    client_id: clientId,
    redirect_uri: apiUrl('/login-slack', companyId),
  })}`;
};

const SLACK_HELP_CENTER_LINK =
  'https://helpcenter.spendesk.com/articles/4031076';

class SlackAnnouncementSplash extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    slackProviderSettings: PropTypes.object.isRequired,
    isSlackFeatureEnabled: PropTypes.bool.isRequired,
    saveSlackPopupSeenState: PropTypes.func.isRequired,
    fetchSlackProviderSettings: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { user, company, isSlackFeatureEnabled } = this.props;
    if (canUserSeeSlackPopup(user, company, isSlackFeatureEnabled)) {
      this.props.fetchSlackProviderSettings();
    }
  }

  linkSlackAccount = async () => {
    const { company, history, slackProviderSettings } = this.props;
    await this.saveSettingAndClose();

    // Try to create a Slack connect URL
    if (slackProviderSettings) {
      const { clientId, url, authorize } = slackProviderSettings;
      window.location.href = getSlackConnectUrl({
        clientId,
        companyId: company.id,
        authorize,
        url,
      });
      return;
    }

    // If the settings haven't been received yet,
    // redirect to the user's profile page instead
    history.push(
      routeFor(routes.ACCOUNT_ME_PROFILE.path, { company: company.id }),
    );
  };

  openHelpCenter = () => window.open(SLACK_HELP_CENTER_LINK, '_blank');

  saveSettingAndClose = async () => {
    await this.props.saveSlackPopupSeenState(); // Save "seen" state in DB
    this.props.onClose();
  };

  /**
   * Depending on the current user's role(s),
   * we may show a different message
   */
  getUserMessage = () => {
    const { t, user } = this.props;
    const isAdmin = user.is_admin;
    const isController = user.is_controller;
    const isRequester = user.is_requester;
    const isTeamManager = user.is_group_admin;
    const isAo = user.is_account_owner;
    const isOo = user.is_organisation_owner;
    const isUserApprover = user.is_approver;
    const isRequesterOnly =
      isRequester &&
      !isTeamManager &&
      !isAdmin &&
      !isController &&
      !isAo &&
      !isOo;

    if (isRequesterOnly) {
      return t('misc.slackAnnouncement.bodyRequester');
    }

    if (isTeamManager || isUserApprover) {
      return t('misc.slackAnnouncement.bodyTeamManagers');
    }

    if (isAo || isController) {
      return t('misc.slackAnnouncement.bodyAoControllers');
    }

    return null;
  };

  render() {
    const { t, user, company, isSlackFeatureEnabled } = this.props;
    if (!canUserSeeSlackPopup(user, company, isSlackFeatureEnabled)) {
      return null;
    }

    const message = this.getUserMessage();
    if (!message) {
      return null;
    }

    return (
      <SplashNotif
        title={t('misc.slackAnnouncement.title')}
        onClose={this.saveSettingAndClose}
      >
        <div className="SlackAnnouncementSplash">
          <div className="SlackAnnouncementSplash__top">
            <img src="/static/img/social/Slack_Mark.svg" alt="logo Slack" />
            <p>{message}</p>
          </div>
          <div className="SlackAnnouncementSplash__bottom">
            <ButtonRedesigned
              text={t('misc.slackAnnouncement.ctaConnect')}
              type="primary"
              size="small"
              onClick={this.linkSlackAccount}
            />
            <button
              type="button"
              className="learn-more"
              onClick={this.openHelpCenter}
            >
              {t('misc.learnMore')}
            </button>
          </div>
        </div>
      </SplashNotif>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state;
  return {
    isSlackFeatureEnabled: getIsFeatureEnabled(
      state,
      FEATURES.SLACK_INTEGRATION,
    ),
    slackProviderSettings: profile.slackProviderSettings,
  };
};

const mapDispatchToProps = {
  saveSlackPopupSeenState,
  fetchSlackProviderSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(SlackAnnouncementSplash)));
