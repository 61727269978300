import {
  Avatar,
  Icon,
  InfoTip,
  Table,
  colors,
  type TableColumn,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import FEATURES from 'src/core/constants/features';
import { HrStatusIcon } from 'src/core/modules/integrations/members/components';

import { getPolicy, getPolicyName } from './getPolicy';
import {
  memberIntegrationMetadata,
  classifyManagedEmployees,
} from '../../../integrations/utils';
import { type UsersManagedByKomboHr } from '../../hooks/useGetManagedUsersByKomboHrIntegrationQuery';
import { getMemberName, type Member } from '../../models/member';
import { translateRoleName } from '../../models/roles';
import { translateControlRuleName } from '../../utils/translateControlRuleName';
import './MembersTable.css';

type Props = {
  isPending: boolean;
  members: Member[];
  selectedIds: string[];
  onRowSelectionChange: (_: Member, id: string, checked: boolean) => void;
  onMemberClick({ id }: { id: string }): void;
  onAllRowsSelectionChange: (
    _: Member[],
    ids: string[],
    checked: boolean,
  ) => void;
  usersManagedByIntegration: UsersManagedByKomboHr | undefined;
};

export const MembersTable = ({
  isPending,
  members,
  selectedIds,
  onRowSelectionChange,
  onMemberClick,
  onAllRowsSelectionChange,
  usersManagedByIntegration,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isPlayByRulesFeatureEnabled = useFeature(FEATURES.PLAY_BY_RULES);
  const isSpendingPoliciesFeatureEnabled = useFeature(
    FEATURES.SPENDING_POLICIES,
  );

  const onRowClick = React.useCallback(
    (row: Member) => onMemberClick({ id: row.id }),
    [onMemberClick],
  );
  const getRowId = React.useCallback((member: Member) => member.id, []);
  const columnNameWidth = usersManagedByIntegration ? '200px' : '280px';
  const hideMemberAvatar = !!usersManagedByIntegration;
  const columns: TableColumn<Member>[] = React.useMemo(() => {
    const tableColumns: TableColumn<Member>[] = [
      {
        header: t('members.table.name'),
        id: 'name',
        width: columnNameWidth,
        renderCell: (member) => {
          return (
            <div className="MembersTable__name">
              {!hideMemberAvatar ? (
                <Avatar
                  src={member.avatar}
                  text={member.displayName}
                  size="m"
                />
              ) : (
                <></>
              )}
              <div className="MembersTable__name__name">
                <div className="flex items-center gap-xs">
                  <div className="max-w-[190px]">
                    <EllipsisTooltip
                      className="MembersTable__name__name__name body-m"
                      text={member.displayName}
                    />
                  </div>
                  {!member.cardsAccess?.hasAccess &&
                    member.cardsAccess !== undefined && (
                      <Icon
                        name="card-lock"
                        color={colors.alert}
                        className="relative top-[2px]"
                      />
                    )}
                </div>
                {!member.isPending && (
                  <EllipsisTooltip
                    className="MembersTable__name__name__email body-s"
                    text={member.email}
                    tooltipMaxWidth={400}
                  />
                )}
              </div>
            </div>
          );
        },
        getSortValue: (member: Member) => getMemberName(member),
      },
    ];

    // If it is not defined the feature is not enabled
    if (usersManagedByIntegration) {
      tableColumns.push({
        header: t('members.table.hrIntegrationStatus'),
        id: 'hrIntegrationStatus',
        width: '100px',
        align: 'center',
        renderCell: (member) => {
          const memberMetadata = memberIntegrationMetadata({
            memberId: member.id,
            usersManagedByIntegration,
            t,
          });
          return HrStatusIcon(memberMetadata);
        },
        getSortValue: (member) =>
          classifyManagedEmployees({
            memberId: member.id,
            usersManagedByIntegration,
          }),
      });
    }

    if (isCostCentersFeatureEnabled) {
      tableColumns.push({
        header: t('members.table.costCenter'),
        id: 'costCenter',
        width: 'auto',
        renderCell: (member) => {
          const isSuggestedCostCenter =
            member.costCenter?.selectionMode === 'suggested';
          return (
            <div
              className={cx({
                MembersTable__textSecondary:
                  !member.costCenter || isSuggestedCostCenter,
                MembersTable__textPrimary:
                  member.costCenter && !isSuggestedCostCenter,
                MembersTable__iconContainer: isSuggestedCostCenter,
              })}
            >
              <EllipsisTooltip
                text={
                  member.costCenter?.name ?? t('members.table.noCostCenter')
                }
              />
              {isSuggestedCostCenter && (
                <InfoTip
                  content={t('costCenters.suggestedCostCenterTooltip')}
                />
              )}
            </div>
          );
        },
        getSortValue: (member: Member) => member.costCenter?.name ?? null,
      });
    }

    tableColumns.push({
      header: t('members.table.roles'),
      id: 'roles',
      width: 'auto',
      renderCell: (member) => (
        <EllipsisTooltip
          text={member.roles
            .map((role) => translateRoleName(role.name, t))
            .join(', ')}
        />
      ),
      getSortValue: (member: Member) =>
        member.roles.map((role) => translateRoleName(role.name, t)).join(', '),
    });

    if (isSpendingPoliciesFeatureEnabled) {
      tableColumns.push({
        header: t('members.table.approvalPolicy'),
        id: 'policy',
        width: 'auto',
        renderCell: (member) => getPolicy({ member, company, t }),
        getSortValue: (member) => getPolicyName({ member, t, company }),
      });
    }

    if (isPlayByRulesFeatureEnabled) {
      tableColumns.push({
        header: t('members.table.controlRule'),
        id: 'controlRule',
        width: 'auto',
        renderCell: (member) => {
          return (
            <EllipsisTooltip
              text={translateControlRuleName(member.controlRule, t)}
              className={cx('MembersTable__ellipsis', {
                MembersTable__textSecondary: !member.controlRule,
                MembersTable__textPrimary: member.controlRule,
              })}
            />
          );
        },

        getSortValue: (member: Member) => member.controlRule?.name ?? null,
      });
    }
    return tableColumns;
  }, [
    isPlayByRulesFeatureEnabled,
    isSpendingPoliciesFeatureEnabled,
    isCostCentersFeatureEnabled,
    usersManagedByIntegration,
  ]);

  return (
    <Table
      className={cx('MembersTable', {
        MembersTable__isPending: isPending,
      })}
      columns={columns}
      data={members}
      onRowSelectionChange={onRowSelectionChange}
      onAllRowsSelectionChange={onAllRowsSelectionChange}
      selectedRowIds={selectedIds}
      getRowId={getRowId}
      onRowClick={onRowClick}
      emptyState={{
        title: t('members.table.noResults.title'),
        subtitle: t('members.table.noResults.subtitle'),
      }}
      defaultSortState={{
        columnId: 'name',
        direction: 'ascending',
      }}
      nextSortDirection={{
        none: 'descending',
        ascending: 'descending',
        descending: 'ascending',
      }}
    />
  );
};
