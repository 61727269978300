import cx from 'classnames';
import omit from 'lodash/omit';
import React, { PureComponent } from 'react';

import './ButtonRedesigned.scss';

export type Props = {
  text?: string;
  children?: React.ReactNode;
  icon?: string;
  title?: string;
  type?: 'primary' | 'danger' | 'link' | 'warning' | '';
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
  isLoading?: boolean;
  isIconStackedBtn?: boolean;
  isIconBtn?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event?: any) => void;
  size?: 'normal' | 'small' | 'block';
  href?: string;
  target?: '_blank';
  rel?: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
class ButtonRedesigned extends PureComponent<Props, {}> {
  static defaultProps = {
    children: null,
    isDisabled: false,
    isLoading: false,
    isIconStackedBtn: false,
    isIconBtn: false,
    size: 'normal',
    onClick: () => {},
    type: '',
    className: '',
    htmlType: 'button',
  };

  render() {
    const {
      icon,
      text,
      size,
      children,
      htmlType,
      type,
      className,
      isDisabled,
      isLoading,
      isIconStackedBtn,
      isIconBtn,
      onClick,
      href,
      target,
      rel,
    } = this.props;

    // Find custom non-default props to inject them directly into the button
    const otherProps = omit(this.props, [
      'icon',
      'text',
      'type',
      'htmlType',
      'className',
      'isDisabled',
      'isLoading',
      'isIconStackedBtn',
      'isIconBtn',
      'onClick',
    ]);

    // Process button classes & modifiers
    const computedClassName = cx('ButtonRedesigned', className, type, size, {
      'is-disabled': isDisabled,
      'is-loading': isLoading,
      'is-stacked': isIconStackedBtn,
      'is-icon-only': isIconBtn,
    });

    // If it's a link and it's disabled we render a button since there is no common way to disable an <a> tag
    if (!isDisabled && href) {
      return (
        <a
          href={href}
          onClick={onClick}
          className={computedClassName}
          target={target}
          rel={rel}
          {...otherProps}
        >
          {icon && <span className={cx('ButtonRedesigned__icon', icon)} />}
          {text && <span className="ButtonRedesigned__text">{text}</span>}
          {children}
        </a>
      );
    }

    /* eslint-disable react/button-has-type */
    return (
      <button
        className={computedClassName}
        onClick={(e) => !isDisabled && onClick && onClick(e)}
        type={htmlType}
        disabled={isDisabled}
        {...otherProps}
      >
        {icon && <span className={cx('ButtonRedesigned__icon', icon)} />}
        {text && <span className="ButtonRedesigned__text">{text}</span>}
        {children}
      </button>
    );
    /* eslint-enable react/button-has-type */
  }
}

export default ButtonRedesigned;
