import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { Payable } from './payable';
import { type RawResult, query } from './query';
import {
  reshapeAllocation,
  reshapeAllocationPeriod,
  reshapeCounterparty,
  reshapeCustomField,
  reshapeDocumentaryEvidence,
  reshapeDocumentaryEvidenceCannotBeProvided,
  reshapeItemLine,
  reshapeMember,
  reshapeMileageDetails,
  reshapeCreditNoteDetails,
  reshapePayableState,
  reshapePayableType,
  reshapePerDiem,
  reshapeSupplier,
  reshapeTeam,
} from './reshaper';

export type PayableGraphQLRawResponse = RawResult;

export type PayableGraphQLResponse = Payable | undefined;

export type PayableGraphQlVariables = { payableId: string };

export const usePayableQuery = (
  payableId: string,
): QueryState<Payable | undefined> => {
  return useQuery<Payable | undefined, RawResult>({
    key: `payablePanel::${payableId}`,
    request: {
      type: 'graphQL',
      target: 'v2',
      query,
      variables: {
        payableId,
      },
    },
    reshapeData({ payable: rawPayable }) {
      if (!rawPayable) {
        return;
      }

      return {
        id: rawPayable.id,
        version: rawPayable.version,
        kind: rawPayable.kind,
        type: reshapePayableType(rawPayable.type),
        subtype: rawPayable.subtype || undefined,
        state: reshapePayableState(rawPayable.state),
        description: rawPayable.description,
        grossAmount: rawPayable.grossAmount,
        functionalAmount: rawPayable.functionalAmount,
        counterparty: rawPayable.counterparty
          ? reshapeCounterparty(rawPayable.counterparty)
          : undefined,
        member: rawPayable.member
          ? reshapeMember(rawPayable.member)
          : undefined,
        supplier: rawPayable.supplier
          ? reshapeSupplier(rawPayable.supplier)
          : undefined,
        team: rawPayable.team ? reshapeTeam(rawPayable.team) : undefined,
        receiptNumber: rawPayable.receiptNumber,
        costCenter: rawPayable.costCenter,
        accountPayable: rawPayable.accountPayable,
        documentaryEvidence: rawPayable.documentaryEvidence
          ? reshapeDocumentaryEvidence(rawPayable.documentaryEvidence)
          : undefined,
        documentaryEvidenceCannotBeProvided:
          rawPayable.documentaryEvidenceCannotBeProvided
            ? reshapeDocumentaryEvidenceCannotBeProvided(
                rawPayable.documentaryEvidenceCannotBeProvided,
              )
            : undefined,
        itemLines: rawPayable.itemLines.map(reshapeItemLine),
        customFields: rawPayable.customFields.map(reshapeCustomField),
        allocations: rawPayable.allocations.map(reshapeAllocation),
        creationDate: new Date(rawPayable.creationDate),
        mileageDetails: rawPayable.mileageDetails
          ? reshapeMileageDetails(rawPayable.mileageDetails)
          : undefined,
        perDiem: rawPayable.perDiem
          ? reshapePerDiem(rawPayable.perDiem)
          : undefined,
        creditNoteDetails: rawPayable.creditNoteDetails
          ? reshapeCreditNoteDetails(rawPayable.creditNoteDetails)
          : undefined,
        spendingAllocationPeriod: rawPayable.spendingAllocationPeriod
          ? reshapeAllocationPeriod(rawPayable.spendingAllocationPeriod)
          : undefined,
        accountingDate: rawPayable.accountingDate
          ? new Date(rawPayable.accountingDate)
          : undefined,
      };
    },
  });
};
