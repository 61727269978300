import i18next, { t } from 'i18next';

import { companyAPI } from 'src/core/api/axios';
import cities from 'src/core/config/per-diem-cities.json';
import { logger } from 'src/utils/datadog-log-wrapper';

import * as perDiemActions from './redux/actions';
import { COUNTRIES } from '../../config/country';
import { NotificationType, addNotification } from '../app/notifications';
import { type AppDispatch } from '../app/redux/store';

type Cities = {
  [key: string]: {
    [key: string]: string;
  };
};

export interface Location {
  country: string;
  city: string;
  description?: string;
  lat?: number;
  lon?: number;
}

export interface SuggestedLocation {
  description: string;
  longitude: number;
  latitude: number;
  relevance: number;
  countryCode: string;
  postalCode: string;
  signature: string;
}

const getCountryNameFromCode = (
  countryCode: string,
  locale: string,
): string => {
  if (COUNTRIES[countryCode]) {
    return t(COUNTRIES[countryCode].translationKey, 'countries', {
      lng: locale,
    });
  }

  logger.warn('trying to translate an unknown country code', {
    team: 'employee',
    scope: 'request',
    countryCode,
  });

  return '';
};

export const toLocaleString = (
  location: Location,
  userLocale: string,
): string => {
  // TODO: i18n German & Italian
  const locale = ['en', 'fr'].includes(userLocale) ? userLocale : 'en';
  if (location.country === '') {
    return '';
  }
  const citiesObject: Cities = cities;
  const city = citiesObject[location.city]
    ? `${citiesObject[location.city][locale]}`
    : '';

  const country = location.country
    ? getCountryNameFromCode(location.country, locale)
    : '';

  if (location.city === 'default') {
    return country;
  }
  return `${city}, ${country}`;
};

export const getCountryName = (
  location: Location,
  userLocale: string,
): string => {
  // TODO: i18n German & Italian
  const locale = ['en', 'fr'].includes(userLocale) ? userLocale : 'en';
  if (location.country === '') {
    return '';
  }
  return location.country
    ? getCountryNameFromCode(location.country, locale)
    : '';
};

export const getLegalLocation = async ({
  year,
  suggestedLocation,
  dayStayId,
  dispatch,
  companyId,
}: {
  year: number;
  suggestedLocation: SuggestedLocation;
  dayStayId: string;
  dispatch: AppDispatch;
  companyId: string;
}): Promise<void> => {
  if (!suggestedLocation || Object.keys(suggestedLocation).length === 0) {
    dispatch(
      perDiemActions.locationChanged({ city: '', country: '' }, dayStayId),
    );
  }
  dispatch(perDiemActions.fetchLegalLocationRequest(dayStayId));

  try {
    const response = await companyAPI.get('/per-diem/legal-location', {
      companyId,
      params: {
        year,
        description: suggestedLocation.description,
        latitude: suggestedLocation.latitude,
        longitude: suggestedLocation.longitude,
        relevance: suggestedLocation.relevance,
        countryCode: suggestedLocation.countryCode,
        postalCode: suggestedLocation.postalCode,
        signature: suggestedLocation.signature,
      },
    });
    const location = {
      country: response.data.countryCode,
      city: response.data.area,
      description: suggestedLocation.description,
      lat: suggestedLocation.latitude,
      lon: suggestedLocation.longitude,
    };

    dispatch(perDiemActions.fetchLegalLocationSuccess(location, dayStayId));
    dispatch(perDiemActions.locationChanged(location, dayStayId));
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationType.Danger,
        message: i18next.t('forms.perDiemAllowance.legalLocationError'),
      }),
    );
    logger.info('Error while fetching legal location', {
      team: 'employee',
      scope: 'requests::panel::create::perdiem',
    });

    dispatch(
      perDiemActions.fetchLegalLocationFailure(error.response?.data, dayStayId),
    );
  }
};
