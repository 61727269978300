import cx from 'classnames';
import { type TFunction } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Icon, { ICONS } from 'common/components/legacy/Icon/Icon';
import { type CurrenciesKey } from "src/core/config/money";
import { grey9Annoying } from "src/core/utils/color-palette";
import { currencySymbol } from "src/core/utils/money";

import './InputAmountMoney.scss';

type Props = {
  amount?: number | null;
  currency?: {
    key: CurrenciesKey;
  };
  name?: string;
  placeholder?: string;
  className?: string;
  availableCurrencies?: {
    key: CurrenciesKey;
    name?: string;
  }[];
  isValid?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  onCurrencyChange?: (currency: { key: string }) => void;
  onAmountChange?: (amount: number) => void;
  onBlur?: (amount: number | null) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  t: TFunction<'global'>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  i18n: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tReady: any;
};

type State = {
  amount?: number | null;
  currency?: { key: CurrenciesKey };
  isCurrencyListOpen?: boolean;
  isFocused?: boolean;
};

class InputAmountMoney extends Component<Props, State> {
  static defaultProps = {
    currency: { key: 'EUR' } as { key: CurrenciesKey },
    availableCurrencies: [],
    isValid: true,
    isLoading: false,
    disabled: false,
  };

  input: HTMLInputElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      amount: props.amount,
      currency: props.currency || { key: 'EUR' },
      isCurrencyListOpen: false,
      isFocused: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const changes: State = {};
    if (nextProps.amount !== this.state.amount) {
      changes.amount = nextProps.amount;
    }
    if (nextProps.currency?.key !== this.state.currency?.key) {
      changes.currency = nextProps.currency;
    }
    if (!isEmpty(changes)) {
      this.setState(changes);
    }
  }

  handleCurrencyChange = (currency: { key: CurrenciesKey }) => {
    this.setState({ currency, isCurrencyListOpen: false });
    if (this.props.onCurrencyChange) {
      this.props.onCurrencyChange(currency);
    }
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
    if (this.props.onBlur) {
      this.props.onBlur(this.state.amount || null);
    }
  };

  handleChange = (value: string) => {
    if (this.state.isCurrencyListOpen) {
      this.setState({ isCurrencyListOpen: false });
    }
    if (this.props.onAmountChange) {
      this.props.onAmountChange(Number.parseFloat(value));
    }
  };

  // We don't want users to type E
  // See https://stackoverflow.com/questions/31706611
  suppressEOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 69) {
      e.preventDefault();
    }
  };

  suppressEOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    if (clipboardData.getData('Text').toLowerCase().includes('e')) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  toggleCurrencyList = () =>
    this.setState({ isCurrencyListOpen: !this.state.isCurrencyListOpen });

  renderMoneyChoices = () =>
    this.props.availableCurrencies?.map((current) => (
      <div
        key={current.key}
        className={cx('InputAmountMoney__choices-item', {
          active: current.key === this.state.currency?.key,
        })}
        onClick={() => this.handleCurrencyChange(current)}
      >
        {current.name}
      </div>
    ));

  render() {
    const { currency, amount, isCurrencyListOpen, isFocused } = this.state;
    const { isValid, placeholder, availableCurrencies, className, t } =
      this.props;
    const hasSeveralCurrencies =
      availableCurrencies !== undefined
        ? availableCurrencies.length > 1
        : false;
    const classes = cx('InputAmountMoney text-input__container', className, {
      open: isCurrencyListOpen,
      focus: isFocused,
      invalid: !isValid,
      'multiple-currencies': hasSeveralCurrencies,
    });

    return (
      <div className={classes}>
        <div className="InputAmountMoney__inputs">
          <div
            className={cx(
              { 'is-disabled': this.props.disabled },
              'InputAmountMoney__field',
            )}
          >
            <input
              ref={(node) => {
                this.input = node;
              }}
              type="number"
              lang="fr" // Hack to force allowing comma notation when 'en' is the locale of the page, see https://bugzilla.mozilla.org/show_bug.cgi?id=1199665
              name={this.props.name}
              placeholder={placeholder || t('forms.amount.placeholder')}
              value={isNaN(amount) || isNil(amount) ? '' : amount}
              disabled={this.props.disabled}
              min={this.props.min}
              max={this.props.max}
              step="0.01" // allows for decimal values
              onChange={(e) => this.handleChange(e.target.value)}
              onFocus={() => this.setState({ isFocused: true })}
              onBlur={this.handleBlur}
              onKeyPress={this.props.onKeyPress}
              onKeyDown={this.suppressEOnKeyDown}
              onPaste={this.suppressEOnPaste}
              onWheel={() => this.input?.blur()}
            />
          </div>
          {hasSeveralCurrencies && (
            <div
              className="InputAmountMoney__currencies"
              onClick={this.toggleCurrencyList}
            >
              <span>{`${currencySymbol(currency?.key || 'EUR')} - ${
                currency?.key
              }`}</span>
              <Icon
                icon={ICONS.BOTTOM_ARROW_HEAD}
                width={16}
                height={16}
                fill={grey9Annoying}
              />
            </div>
          )}
          {!hasSeveralCurrencies && (
            <div className="InputAmountMoney__currency">
              {currencySymbol(currency?.key || 'EUR')}
            </div>
          )}
        </div>
        <ul className="InputAmountMoney__choices">
          {this.renderMoneyChoices()}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(InputAmountMoney);
