import * as types from './actionTypes';

const initialState = {
  card: undefined,
  behavior: {
    hasError: false,
    isLoading: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PLASTIC_CARD_LOADING:
      return {
        ...state,
        behavior: { isLoading: true, hasError: false },
      };
    case types.FETCH_PLASTIC_CARD_FAILURE:
      return {
        ...state,
        behavior: { isLoading: false, hasError: true },
      };
    case types.FETCH_PLASTIC_CARD_SUCCESS:
      return {
        ...state,
        card: action.payload.card,
        isLoading: false,
        hasError: false,
      };
    default:
      return state;
  }
};
