import { Callout } from '@dev-spendesk/grapes';
import cx from 'classnames';
import Downshift from 'downshift';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import size from 'lodash/size';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { withTranslation } from 'react-i18next';
import { Manager, Reference, Popper } from 'react-popper';
import scrollIntoView from 'scroll-into-view-if-needed';

import Icon, { ICONS } from 'src/core/common/components/legacy/Icon/Icon';
import Label from 'src/core/common/components/legacy/Label/Label';
import { darkblueIvy } from 'src/core/utils/color-palette';

import warningIcon from './icons/icon-warning.svg';

import './SelectAutoComplete.scss';

const BACKSPACE_KEYCODE = 8;
const RETURN_KEYCODE = 13;

class SelectAutoComplete extends PureComponent {
  // FIXME: TMP add displayName for debugging in production
  static displayName = 'SelectAutocomplete';

  static propTypes = {
    t: PropTypes.func.isRequired,
    values: PropTypes.array,
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          isDisabled: PropTypes.bool,
        }),
        PropTypes.any,
      ]),
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isOptional: PropTypes.bool,
    placeholder: PropTypes.string,
    itemKey: PropTypes.string.isRequired,
    shouldSearchInItems: PropTypes.bool,
    allowMultipleValues: PropTypes.bool,
    allowNewValue: PropTypes.bool,
    hasTooManyValues: PropTypes.bool,
    readOnly: PropTypes.bool,
    openOnTop: PropTypes.bool,
    withPortal: PropTypes.bool,
    hasError: PropTypes.bool,
    hasWarning: PropTypes.bool,
    itemToString: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCreateNewValue: PropTypes.func,
  };

  static defaultProps = {
    values: [],
    items: [],
    label: undefined,
    isOptional: false,
    placeholder: undefined,
    itemToString: undefined, // Downshift already has a default function for this
    shouldSearchInItems: true,
    allowMultipleValues: false,
    allowNewValue: false,
    hasTooManyValues: false,
    readOnly: false,
    openOnTop: true,
    withPortal: false,
    hasError: false,
    hasWarning: false,
    onCreateNewValue: noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      input: '',
      isOpen: false,
      items: props.items,
    };

    this.itemsRef = React.createRef();
    this.previewRef = React.createRef();
    this.inputRef = React.createRef();
    this.popperRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.items, nextProps.items)) {
      this.setState({
        items: nextProps.items,
      });
    }

    if (!isEqual(this.props.values, nextProps.values)) {
      this.setState({
        input: '',
      });
    }
  }

  componentDidUpdate(_, previousState) {
    if (!previousState.isOpen && this.state.isOpen && this.itemsRef.current) {
      scrollIntoView(this.itemsRef.current, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }

  search = (query) => {
    const { items, shouldSearchInItems, onSearch } = this.props;

    if (shouldSearchInItems) {
      // Use `onSearch` as an exec filters function to filter current items
      const filteredItems = !size(query) ? items : onSearch(query, items);
      this.setState({ items: filteredItems });
    } else {
      // Simply call `onSearch` function (useful for async task that will update items prop)
      onSearch(query);
    }
  };

  handleCreateNewValue = () => {
    const { allowMultipleValues, onCreateNewValue } = this.props;
    const { input } = this.state;

    onCreateNewValue(input);

    this.setState({
      // Keep dropdown opened when multiple, close it immediately otherwise
      isOpen: allowMultipleValues,
    });
  };

  handleKeyDown = (e) => {
    const { values, allowNewValue, onChange } = this.props;
    const { items, input } = this.state;

    if (
      size(values) &&
      isEmpty(this.state.input) &&
      e.keyCode === BACKSPACE_KEYCODE
    ) {
      // Remove previous value from the list of values
      onChange((values ?? []).slice(0, size(values) - 1));
    }

    if (
      // is showing empty state
      isEmpty(items) &&
      !isEmpty(input) &&
      allowNewValue &&
      e.keyCode === RETURN_KEYCODE
    ) {
      this.handleCreateNewValue();

      if (this.inputRef.current) {
        this.inputRef.current.blur();
      }
    }
  };

  handleInputChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ input: inputValue });
    this.search(inputValue);
  };

  handleFocus = () => {
    this.setState({ isOpen: true });
  };

  handleBlur = () => {
    this.setState({ isOpen: false });
  };

  handleClickInside = () => {
    if (this.props.readOnly) {
      return;
    }

    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }

    this.setState({ isOpen: true });
  };

  handleChange = (item) => {
    const index = (this.props.values ?? []).findIndex((value) =>
      isEqual(value, item),
    );

    if (index >= 0) {
      // Value was previously selected so we unselect it
      this.props.onChange([
        ...(this.props.values ?? []).slice(0, index),
        ...(this.props.values ?? []).slice(index + 1),
      ]);
    } else if (this.props.allowMultipleValues) {
      // Value wasn't previsously selected so we select it (multiple mode)
      this.props.onChange([...this.props.values, item]);
    } else {
      // Value wasn't previsously selected so we select it (single mode)
      this.props.onChange([item]);
    }

    // If not multiple, leave the field immediately
    if (!this.props.allowMultipleValues && this.inputRef.current) {
      this.inputRef.current.blur();
    }

    this.setState({
      input: '',
      // Keep dropdown opened when multiple, close it immediately otherwise
      isOpen: this.props.allowMultipleValues,
    });
  };

  setPopperWidth = (data) => {
    // Set the size of the popper as the same size of its reference
    // Inspiration from https://codesandbox.io/s/cool-rosalind-ss23s?fontsize=14&file=/src/index.js
    const {
      instance: { popper, reference },
    } = data;
    popper.style.width = `${reference.offsetWidth}px`;
    return data;
  };

  shouldDisplayCount() {
    const { allowMultipleValues } = this.props;

    if (!this.previewRef.current || !allowMultipleValues) {
      return false;
    }

    return (
      this.previewRef.current.offsetWidth < this.previewRef.current.scrollWidth
    );
  }

  renderItemsOrEmpty = (highlightedIndex, getItemProps) => {
    const { input, items } = this.state;

    if (isEmpty(items) && !isEmpty(input)) {
      const { allowNewValue, t } = this.props;

      // handle click manually
      const itemProps = omit(getItemProps({ item: {} }), ['onClick']);

      return (
        <div
          className={
            allowNewValue
              ? 'SelectAutoComplete__items'
              : 'SelectAutoComplete__empty'
          }
          ref={this.itemsRef}
          {...itemProps}
        >
          {allowNewValue ? (
            <button
              type="button"
              className="SelectAutoComplete__item SelectAutoComplete__item-button SelectAutoComplete__item--active"
              onClick={this.handleCreateNewValue}
            >
              {t('misc.create')} <strong>{input}</strong>
            </button>
          ) : (
            <>
              <img
                className="SelectAutoComplete__empty__icon"
                src={warningIcon}
                alt="Warning"
              />
              <span className="SelectAutoComplete__empty__label">
                {t('misc.noResultFor', { search: this.state.input })}
              </span>
            </>
          )}
        </div>
      );
    }

    return (
      !isEmpty(items) && (
        <div className="SelectAutoComplete__items" ref={this.itemsRef}>
          {map(items, (item, index) => {
            const selected = some(this.props.values, item);

            const itemProps = getItemProps({
              item,
              selected,
              disabled: item.isDisabled,
            });

            return (
              <div
                {...itemProps}
                key={item[this.props.itemKey]}
                className={cx('SelectAutoComplete__item', {
                  'SelectAutoComplete__item--active':
                    highlightedIndex === index,
                })}
              >
                {selected && this.props.allowMultipleValues && (
                  <Icon icon={ICONS.CHECK} />
                )}
                {this.props.itemToString(item)}
              </div>
            );
          })}
        </div>
      )
    );
  };

  renderMenu = (highlightedIndex, getItemProps) => {
    return (
      <Popper
        placement="bottom-start"
        innerRef={this.popperRef}
        positionFixed // seems to fix alignment issues between the popper and the reference when not using portal
        modifiers={{
          setPopperWidth: {
            enabled: true,
            fn: this.setPopperWidth,
          },
          // Used to allow the popper to be displayed outside of its reference
          // Especially useful to force the popper to be displayed at the bottom of a scrollable reference
          // Doc: https://popper.js.org/docs/v1/#modifierspreventoverflow
          preventOverflow: {
            escapeWithReference: true,
          },
        }}
      >
        {({ placement, style, ref }) => {
          return (
            <div
              className="SelectAutoComplete__items-container"
              data-placement={placement}
              style={style}
              ref={ref}
            >
              {this.renderItemsOrEmpty(highlightedIndex, getItemProps)}
            </div>
          );
        }}
      </Popper>
    );
  };

  render() {
    const {
      t,
      label,
      isOptional,
      values,
      itemToString,
      allowMultipleValues,
      hasTooManyValues,
      readOnly,
      openOnTop,
      withPortal,
      hasError,
      hasWarning,
    } = this.props;
    const bodyElement = document.querySelector('body');

    return (
      <Manager>
        <Reference>
          {({ ref }) => {
            return (
              <div ref={ref}>
                <Downshift
                  inputValue={this.state.input}
                  onChange={this.handleChange}
                  selectedItem={values}
                  isOpen={this.state.isOpen}
                  itemToString={itemToString}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    isOpen,
                    selectedItem,
                    highlightedIndex,
                  }) => (
                    <div
                      className={cx('SelectAutoComplete', {
                        'SelectAutoComplete--read-only': readOnly,
                        'SelectAutoComplete--open-on-top': openOnTop,
                        'SelectAutoComplete--has-error': hasError,
                        'SelectAutoComplete--has-warning':
                          !hasError && hasWarning,
                      })}
                    >
                      {!isNil(label) && (
                        <Label
                          {...getLabelProps({
                            helpText: isOptional
                              ? t('misc.optional')
                              : undefined,
                          })}
                        >
                          {this.props.label}
                        </Label>
                      )}
                      {hasTooManyValues && (
                        <Callout
                          variant="info"
                          className="SelectAutoComplete__too-many-values-msg"
                          title={t('misc.fieldHasTooManyValues')}
                        />
                      )}
                      <div
                        className="SelectAutoComplete__input-wrapper"
                        onClick={this.handleClickInside}
                      >
                        <span
                          className="SelectAutoComplete__preview-value"
                          ref={this.previewRef}
                        >
                          {allowMultipleValues &&
                            map(selectedItem, itemToString).join(', ')}
                          {!allowMultipleValues &&
                            head(selectedItem) &&
                            itemToString(head(selectedItem))}
                        </span>
                        {this.shouldDisplayCount() && (
                          <span className="SelectAutoComplete__preview-count">
                            ({size(selectedItem)})
                          </span>
                        )}
                        <input
                          {...getInputProps({
                            disabled: readOnly,
                            placeholder: !head(selectedItem)
                              ? this.props.placeholder
                              : undefined,
                            onChange: this.handleInputChange,
                            onKeyDown: this.handleKeyDown,
                            onFocus: this.handleFocus,
                            onBlur: this.handleBlur,
                          })}
                          ref={this.inputRef}
                          className={cx('SelectAutoComplete__input', {
                            'SelectAutoComplete__input--wrapped':
                              head(selectedItem),
                            'SelectAutoComplete__input--empty': isEmpty(values),
                          })}
                        />
                        {!readOnly && (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label
                          <button
                            type="button"
                            className="SelectAutoComplete__button"
                          >
                            <Icon
                              icon={ICONS.BOTTOM_ARROW_HEAD}
                              fill={darkblueIvy}
                            />
                          </button>
                        )}
                      </div>

                      {isOpen &&
                        (withPortal && bodyElement
                          ? createPortal(
                              this.renderMenu(highlightedIndex, getItemProps),
                              bodyElement,
                            )
                          : this.renderMenu(highlightedIndex, getItemProps))}
                    </div>
                  )}
                </Downshift>
              </div>
            );
          }}
        </Reference>
      </Manager>
    );
  }
}

export default withTranslation(null, {
  innerRef: (ref) => {
    if (ref?.props?.childRef) {
      ref.props.childRef(ref);
    }
  },
})(SelectAutoComplete);
