import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Member } from '../types';

export const useApproversQuery = (): QueryState<Member[]> => {
  return useQuery<Member[]>({
    key: 'members',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/users?includePending=true',
    },
  });
};
