import createReducer from 'src/core/config/createReducer';

import * as types from './actionTypes';

type NewInvoiceState = {
  invoiceRequest: { [key: string]: unknown };
  isUploadingInvoice: boolean;
};

export const initialState: NewInvoiceState = {
  invoiceRequest: {},
  isUploadingInvoice: false,
};

const nukeState = () => initialState;

const submitDraftInvoiceRequestLoading = (state: NewInvoiceState) => ({
  ...state,
  isUploadingInvoice: true,
});

const submitDraftInvoiceRequestSuccess = (state: NewInvoiceState) => ({
  ...state,
  isUploadingInvoice: false,
});

const submitDraftInvoiceRequestFailure = (state: NewInvoiceState) => ({
  ...state,
  isUploadingInvoice: false,
});

export default createReducer(
  {
    [types.NUKE_NEW_INVOICE_STATE]: nukeState,
    [types.SUBMIT_DRAFT_INVOICE_REQUEST_LOADING]:
      submitDraftInvoiceRequestLoading,
    [types.SUBMIT_DRAFT_INVOICE_REQUEST_SUCCESS]:
      submitDraftInvoiceRequestSuccess,
    [types.SUBMIT_DRAFT_INVOICE_REQUEST_FAILURE]:
      submitDraftInvoiceRequestFailure,
  },
  initialState,
);
