import { DropdownItem, DropdownMenu, Icon } from '@dev-spendesk/grapes';

import {
  DEFAULT_PORTAL_URL,
  useJiraLink,
} from 'src/core/modules/jira-issue-reporter/useJiraLink';

const OPTIONS = [
  {
    iconName: 'spendesk' as const,
    key: 'general',
    label: 'Spendesk app support',
  },
  {
    iconName: 'sparkles' as const,
    key: 'nav',
    label: 'New navigation support',
  },
];
const NAV_PORTAL_URL =
  'https://spendesk.atlassian.net/servicedesk/customer/portal/2/group/4/create/634';

export const JiraIssueReporter = () => {
  const defaultLink = useJiraLink(DEFAULT_PORTAL_URL);
  const navLink = useJiraLink(NAV_PORTAL_URL);

  if (defaultLink === null || navLink === null) {
    return null;
  }

  const OPTIONS_TO_LINK: Record<string, string> = {
    nav: navLink,
    general: defaultLink,
  };

  return (
    <li>
      <DropdownMenu
        placement="top-start"
        options={OPTIONS}
        className="w-full"
        renderButton={(getToggleButtonProps) => (
          <button
            {...getToggleButtonProps()}
            className="navigation-item h-xl"
            type="button"
          >
            <Icon
              name="service-bell"
              aria-hidden="true"
              size="m"
              className="shrink-0"
            />
            <span className="navigation-toggle-display grow">
              Report an issue
            </span>
          </button>
        )}
        renderOption={(option) => (
          <DropdownItem
            prefix={<Icon name={option.iconName} />}
            label={option.label}
            isSelected={false}
          />
        )}
        onSelect={(option) => {
          if (OPTIONS_TO_LINK[option.key]) {
            window.open(OPTIONS_TO_LINK[option.key], '_blank');
          }
        }}
      />
    </li>
  );
};
