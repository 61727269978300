import { Navigation } from '@dev-spendesk/grapes';
import cx from 'classnames';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { TableLegacy } from 'src/core/common/components/legacy/TableLegacy';
import { routes, routeFor } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { LazyPanelPayment } from './LazyPanelPayment';
import { BulkEditPaymentsContainer } from './components/all/BulkEditPaymentsContainer';
import PaymentsActions from './components/all/PaymentsActions';
import PaymentsResults from './components/all/PaymentsResults';
import PaymentsFilters from './components/shared/PaymentsFilters';
import { getLeftLinks, getRightLinks } from './utils/navigation';

import './PaymentsAll.scss';

const isAccountingWorkflowVisible = (user) => {
  return user.is_account_owner || user.is_controller;
};

class PaymentsAll extends TableLegacy {
  static propTypes = {
    payment: PropTypes.object,
    payments: PropTypes.arrayOf(PropTypes.object),
    invoices: PropTypes.arrayOf(PropTypes.object),
    paymentStats: PropTypes.arrayOf(PropTypes.object),
    selection: PropTypes.object,
    pageInfo: PropTypes.object,
    accountingStats: PropTypes.object,
    bulkEditPayments: PropTypes.arrayOf(PropTypes.object),
    customFields: PropTypes.arrayOf(PropTypes.object),
    expenseCategoryCustomFieldId: PropTypes.string,
    notifications: PropTypes.arrayOf(PropTypes.object),
    customExports: PropTypes.arrayOf(PropTypes.object),
    counters: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.object),
    teams: PropTypes.arrayOf(PropTypes.object),
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    filters: PropTypes.object,
    params: PropTypes.object,
    itemId: PropTypes.string,
    stepId: PropTypes.string,
    isLoading: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isFiltersPanelOpen: PropTypes.bool.isRequired,
    connectors: PropTypes.arrayOf(PropTypes.object),
    isPayableFeatureEnabled: PropTypes.bool.isRequired,
    isTeamsFeatureEnabled: PropTypes.bool.isRequired,
    hasNewNavigation: PropTypes.bool.isRequired,
    isSupervisionActive: PropTypes.bool.isRequired,
    impersonator: PropTypes.object,

    toggleFiltersPanel: PropTypes.func.isRequired,
    fetchPayment: PropTypes.func.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    fetchCustomFields: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    updatePayment: PropTypes.func.isRequired,
    updateSelection: PropTypes.func.isRequired,
    remindInvoices: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    bulkEdit: PropTypes.func.isRequired,
    bulkMarkAsMissing: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    bulkActions: PropTypes.object,
    updatePaymentLocally: PropTypes.func.isRequired,
    deleteInvoice: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    fetchPaymentInvoices: PropTypes.func.isRequired,
    savePaymentVatType: PropTypes.func.isRequired,
    savePaymentVat: PropTypes.func.isRequired,
    pushNotif: PropTypes.func.isRequired,
    deleteDocumentaryEvidence: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // Custom props
    this.options = {
      className: cx('payments-table', {
        'has-accounting': isAccountingWorkflowVisible(props.user),
      }),
      hasSubNav:
        isAccountingWorkflowVisible(props.user) &&
        props.isPayableFeatureEnabled,
    };

    this.state = {
      payment: null,
      checkedPayments: [],
      localQuery: '',
      isPanelOpen: this.isPanelOpen(props),
      isFiltersPanelOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const stateChanges = {};

    // A single payment item has been fetched
    if (nextProps.payment && this.props.payment !== nextProps.payment) {
      stateChanges.payment = nextProps.payment;
    }

    stateChanges.isPanelOpen = this.isPanelOpen(nextProps);

    // Eventually apply state changes
    if (!isEmpty(stateChanges)) {
      this.setState({ ...stateChanges });
    }

    if (nextProps.errors && !isEqual(nextProps.errors, this.props.errors)) {
      this.props.history.push(
        routeFor(routes.PAYMENTS_ALL.path, { company: this.props.company.id }),
      );
    }
  }

  isLoading = () => {
    return this.props.isLoading;
  };

  hasFiltersBar = () => {
    return true;
  };

  hasResults = () => {
    return (this.props.payments ?? []).length > 0;
  };

  hasFiltersApplied = () => {
    return !isEmpty(omit(this.props.filters, '_isFromUrl'));
  };

  flattenedEntries = (entries) => flatten(values(entries));

  isPanelOpen = (props) => !!props.itemId || !!props.bulkEditPayments;

  togglePanel = () => {
    this.setState((previousState) => ({
      isPanelOpen: !previousState.isPanelOpen,
    }));

    if (this.state.isPanelOpen) {
      const { history, company } = this.props;
      history.push(
        routeFor(routes.PAYMENTS_ALL.path, { company: company.id }) +
          history.location.search,
      );
    }
  };

  handleFiltersToggle = (isPanelOpen) => {
    this.props.toggleFiltersPanel(isPanelOpen);
  };

  renderSubNav = () => {
    const { company, user, accountingStats, t, isPayableFeatureEnabled } =
      this.props;

    if (!isPayableFeatureEnabled || !isAccountingWorkflowVisible(user)) {
      return null;
    }

    // Sub navigation is defined outside of this component when on the new navigation
    if (this.props.hasNewNavigation) {
      return null;
    }

    return (
      <Navigation
        variant="subNavigation"
        aria-label="Sub navigation"
        leftNavigationItems={getLeftLinks({
          accountingStats,
          company,
          translator: t,
        })}
        rightNavigationItems={getRightLinks({
          company,
          translator: t,
        })}
      />
    );
  };

  // Un-check payments, close panel and apply new filters
  updateFiltersAndCloseBulkEdit = (...arguments_) => {
    const filters = arguments_[0];

    const activeFilters = Object.values(filters)
      .filter((filter) => filter.value)
      .map((filter) => filter.label);

    track(AnalyticEventName.PAYMENTS_ALL_FILTERING_CHANGED, {
      filters: activeFilters,
    });

    this.setState({ checkedPayments: [] }, () => {
      this.props.updateFilters(...arguments_);
      this.togglePanel();
    });
  };

  handleItemClick = (id) => {
    const { toggleFiltersPanel, history, company } = this.props;

    toggleFiltersPanel(false);
    history.push({
      pathname: routeFor(routes.PAYMENTS_ALL.path, {
        company: company.id,
        id,
      }),
      search: history.location?.search,
    });
  };

  renderFilters = () => {
    const {
      user,
      users,
      company,
      customFields,
      params,
      history,
      fetchPayments,
      fetchCustomFields,
      teams,
      filters,
    } = this.props;

    return (
      <PaymentsFilters
        user={user}
        users={users}
        teams={teams}
        company={company}
        customFields={customFields}
        params={params}
        filters={filters}
        history={history}
        isPanelOpen={this.props.isFiltersPanelOpen}
        toggleFiltersPanel={this.handleFiltersToggle}
        onSearch={(localQuery) => this.setState({ localQuery })}
        fetchPayments={fetchPayments}
        fetchCustomFields={fetchCustomFields}
        updateFilters={this.updateFiltersAndCloseBulkEdit}
        hasSubNav={this.options.hasSubNav}
      />
    );
  };

  renderActions = () => {
    const {
      company,
      user,
      history,
      selection,
      updateSelection,
      filters,
      pushNotif,
      counters,
      remindInvoices,
      bulkActions,
      download,
      customExports,
      bulkEdit,
      bulkMarkAsMissing,
      isSupervisionActive,
      payments,
    } = this.props;
    const { checkedPayments } = this.state;
    const paymentActions = {};

    return (
      <PaymentsActions
        user={user}
        company={company}
        history={history}
        counters={counters}
        selection={selection}
        payments={this.flattenedEntries(payments)}
        selectedPayments={checkedPayments}
        isPanelOpen={this.state.isPanelOpen}
        isSelectionEnabled={!this.props.bulkEditPayments}
        togglePanel={this.togglePanel}
        hidePanel={this.props.hidePanel}
        paymentActions={paymentActions}
        updateSelection={updateSelection}
        filters={filters}
        remindInvoices={remindInvoices}
        download={download}
        customExports={customExports}
        bulkEdit={bulkEdit}
        bulkMarkAsMissing={bulkMarkAsMissing}
        bulkActions={bulkActions}
        pushNotif={pushNotif}
        isSupervisionActive={isSupervisionActive}
      />
    );
  };

  renderResults = () => {
    const {
      company,
      user,
      config,
      itemId,
      fetchPayments,
      pageInfo,
      payments,
      paymentStats,
      impersonator,
      isTeamsFeatureEnabled,
    } = this.props;

    return (
      <PaymentsResults
        itemId={itemId}
        company={company}
        user={user}
        config={config}
        payments={payments}
        paymentStats={paymentStats}
        pageInfo={pageInfo}
        isLoading={this.isLoading()}
        selection={this.props.selection}
        fetchPayments={fetchPayments}
        hasFilters={this.hasFiltersApplied()}
        isSelectionEnabled={!this.props.bulkEditPayments}
        isTeamsFeatureEnabled={isTeamsFeatureEnabled}
        impersonator={impersonator}
        onItemClick={(id) => {
          // Prevent opening an item if we already opened a few in bulk mode
          if (this.props.bulkEditPayments) {
            return;
          }

          this.handleItemClick(id);
        }}
        onItemChecked={(checked, id) => {
          const changes = checked ? { include: [id] } : { exclude: [id] };
          return this.props.updateSelection(changes);
        }}
      />
    );
  };

  renderPanel = () => {
    if (!this.props.itemId && !this.props.bulkEditPayments) {
      return null;
    }

    const { payment, predictedVat } = this.state;
    const {
      user,
      users,
      company,
      invoices,
      customFields,
      expenseCategoryCustomFieldId,
      uploadFile,
      pushNotif,
      deleteInvoice,
      fetchPaymentInvoices,
      updatePayment,
      updatePaymentLocally,
      config,
      teams,
      fetchPayment,
      bulkEditPayments,
      itemId,
      history,
      fetchCustomFields,
      savePaymentVatType,
      savePaymentVat,
      hidePanel,
      incrementPaymentInvoices,
      deleteDocumentaryEvidence,
    } = this.props;

    if (!payment && !bulkEditPayments) {
      return null;
    }

    if (itemId) {
      return (
        <LazyPanelPayment
          user={user}
          users={users}
          company={company}
          payment={payment}
          invoices={invoices}
          history={history}
          groups={teams}
          customFields={customFields}
          expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
          predictedVat={predictedVat}
          updatePayment={updatePayment}
          updatePaymentLocally={updatePaymentLocally}
          fetchPayment={fetchPayment}
          deleteInvoice={deleteInvoice}
          fetchCustomFields={fetchCustomFields}
          config={config}
          uploadFile={uploadFile}
          fetchPaymentInvoices={fetchPaymentInvoices}
          savePaymentVatType={savePaymentVatType}
          savePaymentVat={savePaymentVat}
          pushNotif={pushNotif}
          togglePanel={this.togglePanel}
          incrementPaymentInvoices={incrementPaymentInvoices}
          deleteDocumentaryEvidence={deleteDocumentaryEvidence}
        />
      );
    }

    return (
      <BulkEditPaymentsContainer
        user={user}
        groups={teams ?? []}
        payments={bulkEditPayments}
        customFields={customFields}
        onClose={hidePanel}
      />
    );
  };
}

export default withTranslation()(PaymentsAll);
