import { isValid } from 'date-fns';

import { type VehicleType } from '@finance-review/models/expense';
import { metersToDistanceUnit } from 'src/core/utils/geo';

import { type MileageScheme } from './mileageScheme';
import { type ComputationCost } from '../mileage/creation/api/useComputationCostQuery';
import { type LegacyRequest, type RequestAPI } from '../types';

const getSixthOfAprilOfCurrentYear = (currentDate: Date) => {
  return new Date(currentDate.getFullYear(), 3, 6, 0, 0, 0);
};

const getCurrentTaxYearForUK = (currentDate: Date) => {
  const startOfThisYearForUK = getSixthOfAprilOfCurrentYear(currentDate);
  if (currentDate < startOfThisYearForUK) {
    return currentDate.getFullYear() - 1;
  }
  return currentDate.getFullYear();
};

export function getTotalDistanceInTheYear(
  vehicleBalances: { year: number; schemeId: string; distance: number }[],
  journeyDate: Date,
  scheme: { id: string; schemeType: string; distanceUnit: 'km' | 'mi' },
) {
  if (!isValid(journeyDate)) {
    return 0;
  }
  const currentYear =
    scheme.schemeType === 'UK'
      ? getCurrentTaxYearForUK(journeyDate)
      : journeyDate.getFullYear();
  let vehicleBalancesCurrentYear = vehicleBalances.filter(
    (vehicleBalance) => vehicleBalance.year === currentYear,
  );
  if (vehicleBalancesCurrentYear.length > 1) {
    vehicleBalancesCurrentYear = vehicleBalancesCurrentYear.filter(
      (vehicleBalanceCurrentYear) =>
        vehicleBalanceCurrentYear.schemeId === scheme.id,
    );
  }
  return vehicleBalancesCurrentYear[0]?.distance ?? 0;
}

export function getTotalDistanceInTheYearFormatted(
  vehicleBalances: { year: number; schemeId: string; distance: number }[],
  journeyDate: Date,
  scheme: { id: string; schemeType: string; distanceUnit: 'km' | 'mi' },
) {
  const totalDistanceInMetersForCurrentYear = getTotalDistanceInTheYear(
    vehicleBalances,
    journeyDate,
    scheme,
  );
  return (
    metersToDistanceUnit({
      distanceMeters: totalDistanceInMetersForCurrentYear,
      distanceUnit: scheme.distanceUnit,
    }) + scheme.distanceUnit
  );
}

export const getRequestDistanceFormatted = ({
  mileageRequest,
  precision = 0,
}: {
  mileageRequest: MileageRequest;
  precision?: number;
}) => {
  const {
    mileage_allowance_request: mileageAllowanceRequest,
    mileage_scheme: { distanceUnit },
  } = mileageRequest;

  const { distance } =
    mileageAllowanceRequest.mileage_allowance_request_journeys[0];

  return `${metersToDistanceUnit({
    distanceMeters: distance,
    distanceUnit,
    precision,
  })} ${distanceUnit}`;
};

export type MileageRequest = Omit<LegacyRequest, 'mileage_allowance_request'> &
  Omit<RequestAPI, 'mileage_allowance_request'> & {
    mileage_allowance_request: {
      mileage_allowance_request_journeys: {
        date: string;
        mileage_allowance_request_journey_locations: { description: string }[];
        distance: number;
      }[];
      vehicle: {
        balances: { year: number; schemeId: string; distance: number }[];
        type: VehicleType;
        horsepower?: string;
        taxableHorsepower?: string;
        electric: boolean;
      };
    };
    mileage_scheme: MileageScheme;
    computation?: ComputationCost['computation'];
  };
