import { useSelector } from 'react-redux';

import withErrorBoundary from 'common/components/withErrorBoundary/withErrorBoundary';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import {
  getHasControllerHomepage,
  getHasRequesterHomepage,
} from 'src/core/selectors/globalSelectors';

import { DisplayModeSwitch } from './components/DisplayModeSwitch';
import { WelcomePanelContent } from './components/WelcomePanelContent/WelcomePanelContent';

export const WelcomePanel = withErrorBoundary({
  team: 'finance-accountant',
  scope: 'HomePage::MorningPanel',
})(() => {
  const { t } = useTranslation('global');

  const user = useUser();

  const hasRequesterHomepage = useSelector(getHasRequesterHomepage);
  const hasControllerHomepage = useSelector(getHasControllerHomepage);
  const isSwitchDisplayed = hasRequesterHomepage && hasControllerHomepage;

  const welcomeMessage = getWelcomeMessage(t, { name: user.first_name });

  return (
    <div className="mb-xxl flex">
      <div className="flex flex-col justify-center gap-xs">
        <div className="flex items-center gap-s">
          <h1 className="leading-[50px] text-complementary body-xxl">
            {welcomeMessage}
          </h1>
          {isSwitchDisplayed && <DisplayModeSwitch />}
        </div>
        <WelcomePanelContent />
      </div>
    </div>
  );
});

const getWelcomeMessage = (
  t: TGlobalFunctionTyped,
  params: { name: string },
): string => {
  const currentHours = new Date().getHours();
  if (currentHours >= 5 && currentHours < 12) {
    return t('homepage.welcome.morning', params);
  }
  if (currentHours >= 12 && currentHours < 18) {
    return t('homepage.welcome.hello', params);
  }

  return t('homepage.welcome.evening', params);
};
