import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import set from 'lodash/set';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { CustomFieldFormField } from 'src/core/common/components/CustomFieldFormField';
import {
  getCfLabel,
  getEligibleCustomFields,
  harmonizeCustomFieldsAssociations,
} from 'src/core/utils/custom-fields';

import './CustomFieldsSelector.css';

export const eligibleTypes = {
  REQUEST: 'request',
  EXPENSE: 'expense',
  PAYMENT: 'payment',
  SUBSCRIPTION: 'subscription',
};

class CustomFieldsSelector extends Component {
  static propTypes = {
    customFields: PropTypes.arrayOf(PropTypes.object),
    customFieldsValues: PropTypes.arrayOf(PropTypes.object),
    types: PropTypes.arrayOf(PropTypes.oneOf(values(eligibleTypes))),
    team: PropTypes.string,
    teamIds: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    t: PropTypes.func.isRequired,
    fit: PropTypes.oneOf(['parent', 'content']),
    placement: PropTypes.oneOf(['bottom-start', 'top-end', 'top-start']),
    withAllChanges: PropTypes.bool,
  };

  static defaultProps = {
    customFields: [],
    customFieldsValues: [],
    types: [],
    team: null,
    teamIds: [],
    isDisabled: false,
    errors: {},
    onChange: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      eligibleCustomFields: this.getEligibleCustomFields(props),
      customFieldsAssociations: harmonizeCustomFieldsAssociations(
        props.customFields,
        props.customFieldsValues,
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const changes = {};
    const userHasChanged = !isEqual(this.props.user, nextProps.user);
    const teamHasChanged = !isEqual(this.props.team, nextProps.team);
    const customFieldsHaveChanged = !isEqual(
      this.props.customFields,
      nextProps.customFields,
    );
    const customFieldsValuesHaveChanged = !isEqual(
      this.props.customFieldsValues,
      nextProps.customFieldsValues,
    );

    if (customFieldsValuesHaveChanged) {
      changes.customFieldsAssociations = harmonizeCustomFieldsAssociations(
        nextProps.customFields,
        nextProps.customFieldsValues,
      );
    }

    if (userHasChanged || teamHasChanged || customFieldsHaveChanged) {
      changes.eligibleCustomFields = this.getEligibleCustomFields(nextProps);
    }

    if (!isEmpty(changes)) {
      this.setState(changes);
    }
  }

  // Update chosen value locally & propagate changes
  handleSelectValue = (customField, value) => {
    const { customFieldsAssociations } = this.state;
    const { withAllChanges } = this.props;
    const matchingCfAssocIndex = (customFieldsAssociations ?? []).findIndex(
      (item) => item.customFieldId === customField.id,
    );

    const updatedAssociation = {
      customFieldId: customField.id,
      customFieldValueId: isObject(value) ? value.key : null,
      value: isObject(value) ? value.name : value,
      updated: true,
    };

    if (matchingCfAssocIndex !== -1) {
      // Existing CF association mapping
      set(customFieldsAssociations, matchingCfAssocIndex, updatedAssociation);
    } else {
      // New CF association mapping
      customFieldsAssociations.push(updatedAssociation);
    }

    this.setState({ customFieldsAssociations }, () => {
      if (withAllChanges) {
        const sanitizedAssociations = map(customFieldsAssociations, (a) =>
          omit(a, ['hasMultipleMatchingValues', 'updated']),
        );
        return this.props.onChange(sanitizedAssociations, customField);
      }
      // Clear utils variables before propagating changes
      const updatedAssociations = filter(customFieldsAssociations, 'updated');
      const sanitizedAssociations = map(updatedAssociations, (a) =>
        omit(a, ['hasMultipleMatchingValues', 'updated']),
      );
      return this.props.onChange(sanitizedAssociations, customField);
    });
  };

  getEligibleCustomFields = ({ customFields, user, team, types }) => {
    const teamIds = this.props.teamIds.length ? this.props.teamIds : [];

    if (team && !teamIds.length) {
      teamIds.push(team);
    }

    const options = { user, types, teamIds };

    return getEligibleCustomFields(customFields, options);
  };

  getPlaceholder = (customFieldValue) => {
    const { t, isDisabled } = this.props;
    const fieldHasManySelectedValues = get(
      customFieldValue,
      'hasMultipleMatchingValues',
    );
    if (fieldHasManySelectedValues) {
      return t('misc.multipleValues');
    }
    if (isDisabled) {
      return t('misc.noValueSelected');
    }
    return t('misc.chooseValue');
  };

  renderField = (customField, customFieldValue) => {
    const { errors, isDisabled, fit, placement } = this.props;
    const hasError = !!errors[customField.id];

    return (
      <CustomFieldFormField
        className="CustomFieldsSelector__item"
        key={`${customField.id}_${get(customFieldValue, 'customFieldValueId')}`}
        customField={customField}
        customFieldAssociation={customFieldValue}
        label={getCfLabel(customField)}
        placeholder={this.getPlaceholder(customFieldValue)}
        isDisabled={isDisabled || customField.deleted_at}
        isInvalid={hasError}
        onSelectCustomFieldValue={(newCustomFieldValue) => {
          this.handleSelectValue(customField, newCustomFieldValue);
        }}
        onSelectNewCustomFieldValue={(rawValue) =>
          this.handleSelectValue(customField, rawValue)
        }
        fit={fit}
        placement={placement}
      />
    );
  };

  render() {
    const { eligibleCustomFields, customFieldsAssociations } = this.state;
    if (isEmpty(eligibleCustomFields)) {
      return null;
    }

    return (
      <div>
        {map(eligibleCustomFields, (customField) => {
          const customFieldValue = find(customFieldsAssociations, {
            customFieldId: customField.id,
          });

          // The field has been deleted and no value was assigned before
          if (customField.deleted_at && !customFieldValue) {
            return null;
          }

          return this.renderField(customField, customFieldValue);
        })}
      </div>
    );
  }
}

export default withTranslation()(CustomFieldsSelector);
