import { type FormikErrors, useFormik } from 'formik';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import {
  useUpdatePayable,
  useUpdatePayableErrorMessage,
} from 'src/core/modules/payable';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  type ExpenseCategoryValue,
  getExpenseCategoryValueFromCustomField,
} from '../../../models/expenseCategory';
import { type Payable } from '../../PayablePanelContainer';

type CostCenter = {
  id: string;
  name: string;
};

export type AccountingFormValues = {
  costCenter: CostCenter | undefined;
  expenseCategoryValue: ExpenseCategoryValue | undefined;
};

export const usePayableAccountingEditForm = ({
  payable,
  customFieldIdForExpenseCategory,
}: {
  payable: Payable;
  customFieldIdForExpenseCategory?: string;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useTranslation('global');
  const [updatePayable] = useUpdatePayable(payable.id);
  const getUpdatePayableErrorMessage = useUpdatePayableErrorMessage();
  const { dangerNotif } = useNotifications();

  const handleOnSectionCancelEdit = () => formik.resetForm();
  const handleOnSectionSaveEdit = async () => {
    // this will trigger 2 form validations
    // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
    // https://github.com/formium/formik/issues/1580
    const { submitForm, validateForm } = formik;
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      throw new Error('Validation error');
    }
    return submitForm();
  };

  const formik = useFormik<AccountingFormValues>({
    initialValues: {
      costCenter: payable.costCenter,
      expenseCategoryValue: customFieldIdForExpenseCategory
        ? getExpenseCategoryValueFromCustomField(
            payable.customFields,
            customFieldIdForExpenseCategory,
          )
        : undefined,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: FormikErrors<AccountingFormValues> = {};
      if (!values.costCenter) {
        errors.costCenter = t(
          'payables.panel.detailsSection.validation.costCenter',
        );
      }
      return errors;
    },
    validateOnBlur: false,
    onSubmit: async (values) => {
      // make sure the value is set (even no change is made)
      if (values.costCenter) {
        const customFieldAssociations = payable.customFields.map(
          (customField) => ({
            customFieldId: customField.id,
            customFieldListValueId:
              customField.type === 'list'
                ? customField.values[0]?.id
                : customField.value.toString(),
          }),
        );

        track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_EDITED, {
          payableId: payable.id,
          whatChanged: 'costCenter',
        });

        try {
          await updatePayable({
            payableVersion: payable.version,
            update: {
              costCenterId: values.costCenter.id,
              ...(values.expenseCategoryValue
                ? {
                    customFieldAssociations: [
                      {
                        customFieldId: customFieldIdForExpenseCategory ?? '',
                        customFieldListValueId: values.expenseCategoryValue?.id,
                      },
                      ...customFieldAssociations.filter(
                        (customFieldAssociation) =>
                          customFieldIdForExpenseCategory !== undefined &&
                          customFieldAssociation.customFieldId !==
                            customFieldIdForExpenseCategory,
                      ),
                    ],
                  }
                : {
                    customFieldAssociations,
                  }),
            },
          });
        } catch (error) {
          const errorMessage = getUpdatePayableErrorMessage(error);
          dangerNotif(errorMessage);
        }
      }
    },
  });

  return { formik, handleOnSectionCancelEdit, handleOnSectionSaveEdit };
};
