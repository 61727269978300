import { type DateRange } from '@dev-spendesk/grapes';
import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { formatDateToApiString } from 'src/core/common/utils/formatDateToApiString';

const today = new Date();
const lastMonth = subMonths(today, 1);

const defaultPeriod: [Date, Date] = [
  startOfMonth(lastMonth),
  endOfMonth(lastMonth),
];

const payableExportFiltersSchema = yup
  .object({
    period: yup
      .tuple([
        yup.date().default(() => defaultPeriod[0]),
        yup.date().default(() => defaultPeriod[1]),
      ])
      .default(() => defaultPeriod),
    includeExportedPayables: yup.boolean().default(false),
    includePayablesFromPreviousPeriod: yup.boolean().default(false),
    exportPurchaseJournal: yup.boolean().default(true),
    exportBankJournal: yup.boolean().default(false),
    exportReceipts: yup.boolean().default(false),
  })
  .transform(
    ({ from, to, ...rest }): PayableExportFilters => ({
      period: [from, to],
      ...rest,
    }),
  );

type PayableExportFilters = Omit<
  yup.InferType<typeof payableExportFiltersSchema>,
  'period'
> & {
  period: DateRange;
};

export const urlSearchToFilters = (urlSearch: string): PayableExportFilters => {
  return payableExportFiltersSchema.validateSync(
    Object.fromEntries(new URLSearchParams(urlSearch).entries()),
  );
};

export const usePayableExportFilters = (): PayableExportFilters => {
  const location = useLocation();
  return urlSearchToFilters(location.search);
};

export const useSyncPayableExportFilters = () => {
  const location = useLocation();

  const history = useHistory();

  return (filters: PayableExportFilters) => {
    const newSearch = new URLSearchParams(location.search);

    const [from, to] = filters.period;
    if (from && to) {
      newSearch.set('from', formatDateToApiString(from));
      newSearch.set('to', formatDateToApiString(to));
    }

    const {
      includeExportedPayables,
      includePayablesFromPreviousPeriod,
      exportPurchaseJournal,
      exportBankJournal,
      exportReceipts,
    } = filters;

    newSearch.set(
      'includeExportedPayables',
      includeExportedPayables.toString(),
    );

    newSearch.set(
      'includePayablesFromPreviousPeriod',
      includePayablesFromPreviousPeriod.toString(),
    );

    newSearch.set('exportPurchaseJournal', exportPurchaseJournal.toString());
    newSearch.set('exportBankJournal', exportBankJournal.toString());
    newSearch.set('exportReceipts', exportReceipts.toString());

    location.search = newSearch.toString();

    history.replace(location);
  };
};
