import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTrialAccountLoadLimit } from 'modules/app/layout/redux/actions';
import type { AppDispatch } from 'modules/app/redux/store';
import { useProcedure } from 'modules/procedures';
import TopNotif from "src/core/common/components/legacy/TopNotif/TopNotif";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { formatMoney } from "src/core/utils/money";

import styles from './HeaderLoadNotif.module.css';
import { getTrialLimit } from '../../redux/selectors';

type Props = {
  company: {
    currency: string;
    is_kyb_validated: boolean;
  };
};

export const HeaderLoadNotif = ({ company }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const trialLimit = useSelector(getTrialLimit);
  const procedure = useProcedure();
  const { t } = useTranslation('global');

  useEffect(() => {
    const reactSpxElement = document.getElementById('react__spx');
    if (reactSpxElement) {
      reactSpxElement.classList.add('has-flash-message');
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTrialAccountLoadLimit());
  }, [dispatch]);

  if (trialLimit && procedure?.state?.limitedAccessOpenedAt) {
    const { sum, limit } = trialLimit;
    const formattedLoadLimit = formatMoney(limit, company.currency);
    const formattedTotalLoad = formatMoney(sum, company.currency);
    if (sum >= limit) {
      return (
        <div className={styles.headerLoadNotif}>
          <TopNotif type="danger">
            {t('headerLoadNotif.hasReachedLimit', {
              loadLimit: formattedLoadLimit,
            })}
          </TopNotif>
        </div>
      );
    }

    if (sum > 0) {
      return (
        <div className={styles.headerLoadNotif}>
          <TopNotif type="danger">
            {t('headerLoadNotif.hasLoadedFunds', {
              loadLimit: formattedLoadLimit,
              totalLoad: formattedTotalLoad,
            })}
          </TopNotif>
        </div>
      );
    }

    return (
      <div className={styles.headerLoadNotif}>
        <TopNotif type="success">
          {t('headerLoadNotif.limitApproved', {
            loadLimit: formattedLoadLimit,
          })}
        </TopNotif>
      </div>
    );
  }

  return (
    <div className={styles.headerLoadNotif}>
      <TopNotif type="pending">{t('headerLoadNotif.inReview')}</TopNotif>
    </div>
  );
};
