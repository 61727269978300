/* eslint-disable @typescript-eslint/no-explicit-any */
import { SkeletonText } from '@dev-spendesk/grapes';
import { compose } from '@reduxjs/toolkit';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams, withRouter } from 'react-router-dom';

import { useFeature } from 'common/hooks/useFeature';
import withErrorBoundary from 'src/core/common/components/withErrorBoundary';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { getCompany } from 'src/core/selectors/globalSelectors';
import { getSelf } from 'src/core/selectors/users';
import authorize from 'src/core/utils/authorize';

import { RecardActivation } from './RecardActivation';
import { RecardCardLost } from './RecardCardLost';
import { Shipping } from './Shipping';
import * as cardActions from '../../redux/actions';

import './RecardContainer.css';

type Props = {
  company: any;
  user: any;
  card: any;
  history: any;
  fetchPlasticCard: (cardId: string) => void;
};

const RecardContainer = ({
  company,
  user,
  card,
  history,
  fetchPlasticCard,
}: Props) => {
  useEffect(() => {
    const cardId = get(user, `data_by_company.${company.id}.plastic_card.id`);
    if (!cardId) {
      return history.push(
        routeFor(routes.ACCOUNT_ME_PROFILE.path, { company: company.id }),
      );
    }
    fetchPlasticCard(cardId);
  }, []);

  const {
    status,
  }: {
    status: 'activate' | 'card_lost_validation' | 'card_lost_reorder';
  } = useParams();

  if (!card) {
    return (
      <div className="page__container flex flex-col items-center bg-page-background">
        <SkeletonText className="mb-s mt-l" size="xl" width="250px" />
        <SkeletonText className="mb-xs" width="400px" />
        <SkeletonText width="400px" />
      </div>
    );
  }

  if (card && !get(card, 'recard_request')) {
    // The recard page is just if current card
    // has a recard request attached
    history.push(routeFor(routes.CARD.path, { company: company.id }));
    return null;
  }

  // Already has a next card order,
  // show activation flow
  if (card?.recard_request?.next_card_order && status === 'activate') {
    return (
      <div className="page__container Recard">
        <RecardActivation card={card} />
      </div>
    );
  }

  if (
    card?.recard_request?.next_card_order?.considered_as_lost &&
    status === 'card_lost_validation'
  ) {
    return (
      <div className="page__container Recard">
        <RecardCardLost card={card} />
      </div>
    );
  }

  if (
    card?.recard_request?.next_card_order?.considered_as_lost &&
    status === 'card_lost_reorder'
  ) {
    return (
      <div className="page__container Recard">
        <Shipping card={card} fetchPlasticCard={fetchPlasticCard} reOrder />
      </div>
    );
  }

  return (
    <div className="page__container Recard">
      <Shipping
        card={card}
        fetchPlasticCard={fetchPlasticCard}
        reOrder={false}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: getSelf(state),
  company: getCompany(state),
  card: state.card.card,
});

const mapDispatchToProps = {
  fetchPlasticCard: cardActions.fetchPlasticCard,
};

const withAuthorization = authorize<Props>(
  ({ user, company }) => {
    const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);

    return (
      !company.churning_at && user.has_plastic_card && hasPlasticCardsFeature
    );
  },
  ({ company }) => (
    <Redirect
      to={{
        pathname: routeFor(routes.NOT_FOUND.path, {
          company: company.id,
        }),
      }}
    />
  ),
);

const ConnectedRecardContainer = compose<
  (
    p: Omit<
      Props,
      'user' | 'companyId' | 'card' | 'history' | 'fetchPlasticCard'
    >,
  ) => JSX.Element
>(
  withErrorBoundary({
    scope: 'recard',
    team: 'employee',
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withAuthorization,
)(RecardContainer);

export { ConnectedRecardContainer as RecardContainer };
