import { fromNumber } from 'ezmoney';

import { getSlackConnectUrl } from 'common/components/legacy/SplashNotif/types/SlackAnnouncementSplash/SlackAnnouncementSplash';
import { reshapeToSpendingTypes } from 'modules/company/members/components/ApprovalPolicies/reshapers';
import { type BankInfoValues } from 'modules/profile/models/bankInfo';
import {
  type GenderForDisplay,
  type Gender,
} from 'modules/profile/models/gender';
import { type Lang } from 'modules/profile/models/lang';
import {
  type Role,
  accountOwnerRole,
  adminRole,
  controllerRole,
  requesterRole,
} from 'modules/profile/models/role';
import { type SpendingPolicy } from 'modules/profile/models/rules';
import { type User } from 'modules/profile/models/user';
import {
  isIbanValid,
  isBicSwiftValid,
  isSortCodeValid,
  isAccountNumberValid,
  isRoutingNumberValid,
  isAccountHolderNameValid,
} from 'src/core/utils/bankInfoFormats';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export const getGendersForDisplayByLang = (lang: Lang): GenderForDisplay[] => {
  const gendersForDisplay: GenderForDisplay[] = ['mr', 'mrs'];

  // In France and German, no distinction between Ms and Mrs
  if (lang !== 'fr' && lang !== 'de') {
    gendersForDisplay.push('ms');
  }

  // In German, Miss is not used anymore
  if (lang !== 'de') {
    gendersForDisplay.push('miss');
  }

  return gendersForDisplay;
};

/**
 * Get the gender ('F' or 'M') following the gender selected in user's profile ('Mrs', 'Ms', 'Miss', etc.)
 */
export const getUserGenderFromGenderForDisplay = (
  genderForDisplay: GenderForDisplay | undefined,
): Gender | undefined => {
  if (!genderForDisplay) {
    return;
  }

  switch (genderForDisplay) {
    case 'mr':
      return 'M';

    case 'miss':
    case 'mrs':
    case 'ms':
      return 'F';

    default:
      return rejectUnexpectedValue('genderForDisplay', genderForDisplay);
  }
};

export const getBankInfoErrors = (
  values: BankInfoValues,
): BankInfoValues | undefined => {
  const errors = {};

  if (values.iban && !isIbanValid(values.iban)) {
    Object.assign(errors, { iban: true });
  }

  if (values.bic && !isBicSwiftValid(values.bic)) {
    Object.assign(errors, { bic: true });
  }

  if (values.sortCode && !isSortCodeValid(values.sortCode)) {
    Object.assign(errors, { sortCode: true });
  }

  if (
    values.accountHolderName &&
    !isAccountHolderNameValid(values.accountHolderName)
  ) {
    Object.assign(errors, { accountHolderName: true });
  }

  if (values.accountNumber && !isAccountNumberValid(values.accountNumber)) {
    Object.assign(errors, { accountNumber: true });
  }

  if (values.routingNumber && !isRoutingNumberValid(values.routingNumber)) {
    Object.assign(errors, { routingNumber: true });
  }

  if (!Object.keys(errors).length) {
    return undefined;
  }

  return errors;
};

export const filterBankInfoValuesToValidate = (
  values: BankInfoValues,
  requiredInfoTypesForEmployeeBankAddress: string[],
): BankInfoValues => {
  const valuesAsArray = Object.entries(values);

  const valuesAsArrayToValidate = valuesAsArray.filter(([key]) =>
    requiredInfoTypesForEmployeeBankAddress.includes(key),
  );

  return Object.fromEntries(valuesAsArrayToValidate);
};

/**
 * Warning: empty string values won't be considered as fulfilled.
 */
export const areAllValuesFulfilled = (
  object: Record<string, string> | undefined,
): boolean => {
  if (!object) {
    return false;
  }

  const values = Object.values(object);
  if (values.length === 0) {
    return false;
  }

  const emptyValues = values.filter((value) => !value || value === '');
  return emptyValues.length === 0;
};

export const getSpendingPolicy = (
  user: Pick<User, 'transaction_max' | 'spending_limit' | 'spending_policy'>,
  currency: string,
): SpendingPolicy => {
  return {
    maxTransaction: fromNumber(user.transaction_max, currency, 2),
    spendingLimit: fromNumber(user.spending_limit, currency, 2),
    spendingTypes: reshapeToSpendingTypes(
      user.spending_policy.spending_types ?? 'single_purchase|card_load',
    ),
    approvalNeeded: user.spending_policy.approval_needed,
  };
};

export const getUserRoles = (user: User): Role[] => {
  const userRoles: Role[] = [];

  if (user.is_account_owner) {
    userRoles.push(accountOwnerRole);
  } else {
    if (user.is_admin) {
      userRoles.push(adminRole);
    }
    if (user.is_controller) {
      userRoles.push(controllerRole);
    }
    if (user.is_requester) {
      userRoles.push(requesterRole);
    }
  }

  return userRoles;
};

type SlackProviderSettings = {
  clientId: string;
  authorize: string;
  url: string;
};

export const getSlackLink = (
  companyId: string,
  slackProviderSettings: SlackProviderSettings,
): string => {
  const { clientId, authorize, url } = slackProviderSettings;

  const slackConnectUrl = getSlackConnectUrl({
    clientId,
    companyId,
    authorize,
    url,
  });

  return slackConnectUrl || '';
};
