import { Button } from '@dev-spendesk/grapes';
import { type SetStateAction } from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import {
  type KomboAvailableFilterByDimension,
  type FiltersForDimension,
  type SupportedKomboFilterDimension,
} from 'src/core/modules/integrations/types';

import { FilterLayout } from './FilterLayout';
import { FilterOption } from './FilterOption';
import { SyncReportingManager } from './SyncReportingManager';
import { type CurrentConfiguration, type DimensionOption } from '../types';

type FilterProps = {
  availableFiltersByDimension: KomboAvailableFilterByDimension;
  dimensionOptions: DimensionOption[];
  currentFilters: FiltersForDimension[];
  hasNewFilter: boolean;
  setState: (value: SetStateAction<CurrentConfiguration>) => void;
  setCheckErrors: (value: SetStateAction<boolean>) => void;
  checkErrors: boolean;
  syncReportingManagerSelected: boolean;
  setSyncReportingManagerSelected: (value: SetStateAction<boolean>) => void;
};

export const Filters = ({
  availableFiltersByDimension,
  dimensionOptions,
  currentFilters,
  hasNewFilter,
  setState,
  setCheckErrors,
  checkErrors,
  syncReportingManagerSelected,
  setSyncReportingManagerSelected,
}: FilterProps) => {
  const { t } = useTranslation('global');

  const displayAddNewRuleButton =
    !hasNewFilter &&
    currentFilters.length < dimensionOptions.length &&
    currentFilters.every(({ values }) => values.some((value) => value !== ''));

  const hasReportingManagerFeature = useFeature(
    FEATURES.TMP_CONMAN_REPORTING_MANAGERS,
  );

  const calculateAvailableFilters = (
    dimension: SupportedKomboFilterDimension,
  ) => {
    const availableFilters = availableFiltersByDimension.get(dimension) || [];

    return availableFilters.map(({ komboName, komboId }) => ({
      key: komboId,
      label: komboName,
    }));
  };

  return (
    <div className="flex flex-col gap-xs">
      {currentFilters.map(({ dimension, values }, index) => (
        <FilterOption
          key={dimension}
          availableDimensions={dimensionOptions.filter(
            (option) =>
              currentFilters.some(
                (filter) => filter.dimension === option.key,
              ) === false || option.key === dimension,
          )}
          selectedDimension={dimension}
          selectedValues={values}
          availableFilters={calculateAvailableFilters(dimension)}
          onChange={(key, value) => {
            currentFilters[index] = {
              dimension: key,
              values: value,
            };
            setState({ currentFilters: [...currentFilters], hasNewFilter });
            setCheckErrors(false);
          }}
          hasDeleteButton={currentFilters.length + Number(hasNewFilter) > 1}
          onDelete={() => {
            currentFilters.splice(index, 1);
            setState({ currentFilters: currentFilters, hasNewFilter });
            setCheckErrors(false);
          }}
          index={index}
          checkErrors={checkErrors}
        />
      ))}
      {hasNewFilter && (
        <FilterOption
          key="new"
          availableDimensions={dimensionOptions.filter(
            (option) =>
              currentFilters.some(
                (filter) => filter.dimension === option.key,
              ) === false,
          )}
          selectedDimension={null}
          selectedValues={[]}
          availableFilters={[]}
          onChange={(key, values) => {
            setState({
              currentFilters: [
                ...currentFilters,
                { dimension: key, values: values },
              ],
              hasNewFilter: false,
            });
            setCheckErrors(false);
          }}
          hasDeleteButton={!!currentFilters.length}
          onDelete={() => {
            setState({
              currentFilters,
              hasNewFilter: false,
            });
            setCheckErrors(false);
          }}
          index={Array.from(currentFilters).length}
          checkErrors={checkErrors}
        />
      )}
      {displayAddNewRuleButton && (
        <FilterLayout
          groupOperator={null}
          dimension={
            <Button
              text={t('integration.kombo.settings.filters.add')}
              variant="ghost"
              iconName="plus"
              iconPosition="left"
              onClick={() => {
                setState({
                  currentFilters,
                  hasNewFilter: true,
                });
                setCheckErrors(false);
              }}
            />
          }
          values={null}
          actions={null}
        />
      )}
      {hasReportingManagerFeature && (
        <SyncReportingManager
          syncReportingManagerSelected={syncReportingManagerSelected}
          setSyncReportingManagerSelected={setSyncReportingManagerSelected}
        />
      )}
    </div>
  );
};
