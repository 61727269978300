import { Callout } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React, { useEffect } from 'react';

import { isArrayOfString } from "src/core/utils/array";

import type { FormValues } from './formValues';
import { ApprovalRulesContainer } from '../../../structure/approval-flows';
import type { Member } from '../../member';

type Props = {
  members: Member[];
  costCenterOwnerId?: string;
} & FormikProps<FormValues>;

export const CostCenterFormApprovalFlowContent = ({
  members,
  costCenterOwnerId,
  values,
  errors,
  setFieldValue,
  setFieldTouched,
}: Props) => {
  useEffect(() => {
    setFieldTouched('approvalRules', true);
  }, []);

  return (
    <div className="mt-l">
      <ApprovalRulesContainer
        rules={values.approvalRules}
        members={members}
        costCenterOwnerId={costCenterOwnerId}
        erroredRulesIds={
          isArrayOfString(errors.approvalRules) ? errors.approvalRules : []
        }
        onChange={(approvalRules, isNewRule) => {
          setFieldValue('approvalRules', approvalRules, !isNewRule);
        }}
      />
      {errors.approvalRules && typeof errors.approvalRules === 'string' && (
        <Callout variant="alert" title={errors.approvalRules} />
      )}
    </div>
  );
};
