import React from 'react';

import ImageViewer from '../ImageViewer/ImageViewer';
import PDFViewer from '../PDFViewer/PDFViewer';

export type MediaType =
  | 'application/pdf'
  | 'image/png'
  | 'image/jpeg'
  | 'image/gif';

type Props = {
  className?: string;
  fileUrl: string;
  fileMediaType: MediaType;
  onClose: () => void;
};

export const FileViewerInline = ({
  className,
  fileUrl,
  fileMediaType,
  onClose,
}: Props) =>
  fileMediaType === 'application/pdf' ? (
    <PDFViewer
      className={className}
      url={fileUrl}
      withDownload
      onDownload={() => window.open(fileUrl, '_blank')}
      onClose={onClose}
    />
  ) : (
    <ImageViewer
      className={className}
      url={fileUrl}
      withZoom
      withDownload
      onDownload={() => window.open(fileUrl, '_blank')}
      onClose={onClose}
    />
  );
