export type ApprovalStep = {
  approverId: string;
  actingApproverId: string;
  state: ApprovalStepState;
  event?: {
    appraisedAt: string;
    reason: null | string;
    type: 'approval' | 'denial';
  };
  as: 'approver' | 'account_owner';
};

export type ApprovalStepState =
  | 'in_review'
  | 'upcoming'
  | 'approved'
  | 'denied'
  | 'pending';

export type ApprovalRuleState =
  | 'in_review'
  | 'pending'
  | 'approved'
  | 'denied'
  | 'obsolete';

export type ApprovalRule = {
  state: ApprovalRuleState;
  steps: ApprovalStep[];
  from: {
    value: number | null;
  };
  upTo: {
    value: number | null;
  };
  isAoRule: boolean;
};

export type Approver = {
  id: string;
  avatar: string | undefined;
  name: string;
  email: string;
};

export type ApproversListItem<T> = {
  approver: T;
  state: ApprovalRuleState;
};

export type ListType = 'queue' | 'any';

export const getListType = <T>(rules: { steps: T[] }[]): ListType => {
  // we assume that if the first rule has more than one step, the list approval flow is set as 'approve one after another'
  if (rules[0].steps.length > 1) {
    return 'queue';
  }
  return 'any';
};

export const extractNonLegacyRequestApproverIds = (approvalState?: {
  rules: { steps: { actingApproverId: string }[] }[];
}) => {
  if (!approvalState || !approvalState.rules) {
    return [];
  }
  return approvalState.rules.flatMap((rule) => {
    return rule.steps.map((step) => step.actingApproverId);
  });
};
