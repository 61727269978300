import assignIn from 'lodash/assignIn';

import * as types from './actionTypes';
import * as splashTypes from '../splashTypes';

const initialState = {
  isOpen: false,
  type: null,
  data: null,
};

const splashNotifReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_SPLASH: {
      const { type, data = {} } = action.payload;

      if (!type || !splashTypes[type]) {
        return state;
      }

      return assignIn({}, state, { isOpen: true, type, data });
    }
    case types.CLOSE_SPLASH:
      return initialState;
    default:
      return state;
  }
};

export default splashNotifReducer;
