import React from 'react';

import {
  CocktailIllustration,
  DraftIllustration,
  ExpenseIllustration,
  FlamingoIllustration,
  LostContentIllustration,
  MagicReceiptIllustration,
  NoResultsIllustration,
  PaperclipIllustration,
  PaymentIllustration,
  ReceiptIllustration,
  ReminderIllustration,
  RequestIllustration,
  SubscriptionIllustration,
} from './illustrations';

const getBaseProps = (): React.ImgHTMLAttributes<HTMLImageElement> => {
  // FIXME: add title and aria-labelledby=title?
  return {
    width: 136,
    height: 136,
  };
};

export const getComponentByIllustrationName = (illustrationName: string) => {
  switch (illustrationName) {
    case 'cocktail':
      return <img src={CocktailIllustration} alt="" {...getBaseProps()} />;
    case 'draft':
      return <img src={DraftIllustration} alt="" {...getBaseProps()} />;
    case 'expense':
      return <img src={ExpenseIllustration} alt="" {...getBaseProps()} />;
    case 'flamingo':
      return <img src={FlamingoIllustration} alt="" {...getBaseProps()} />;
    case 'lost-content':
      return <img src={LostContentIllustration} alt="" {...getBaseProps()} />;
    case 'magic-receipt':
      return <img src={MagicReceiptIllustration} alt="" {...getBaseProps()} />;
    case 'no-results':
      return <img src={NoResultsIllustration} alt="" {...getBaseProps()} />;
    case 'paperclip':
      return <img src={PaperclipIllustration} alt="" {...getBaseProps()} />;
    case 'payment':
      return <img src={PaymentIllustration} alt="" {...getBaseProps()} />;
    case 'receipt':
      return <img src={ReceiptIllustration} alt="" {...getBaseProps()} />;
    case 'reminder':
      return <img src={ReminderIllustration} alt="" {...getBaseProps()} />;
    case 'request':
      return <img src={RequestIllustration} alt="" {...getBaseProps()} />;
    case 'subscription':
      return <img src={SubscriptionIllustration} alt="" {...getBaseProps()} />;
    default:
      throw new Error(
        `Unsupported empty state with illustration name: ${illustrationName}`,
      );
  }
};

export type IllustrationName =
  | 'cocktail'
  | 'draft'
  | 'expense'
  | 'flamingo'
  | 'lost-content'
  | 'magic-receipt'
  | 'no-results'
  | 'paperclip'
  | 'payment'
  | 'receipt'
  | 'reminder'
  | 'request'
  | 'subscription';
